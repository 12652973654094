import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import uuidGenerator from "../../../SharedItems/UUIDGenerator";
import { db } from "../../fb/fbSetup";
import { ArrayOfComm } from "../../Models/Models";

export interface SelectTopicsOfInterestModel { 
    itemsOfInterest : CommunityInterestDataModel[]; 
    setItemsOfInterests : React.Dispatch<React.SetStateAction<CommunityInterestDataModel[]>>
}
export interface CommunityInterestDataModel { title: string; selected: boolean; }

export function saveFilterData(
userID: string,
itemsOfInterest: CommunityInterestDataModel[],
customInterestPrompt: string[],
sentenceTopics: string[],
callback: (sucess: boolean) => void
) {
    const _data_obj_ = {
        itemsOfInterest: itemsOfInterest,
        customInterestPrompt: customInterestPrompt,
        sentenceTopics: sentenceTopics,
        dateAdded: new Date(),
    };

    const _metaObj_recommend = {
        recommendations: [_data_obj_]
    }
    const _collection_ = "user_generated_recommendation_controlls";
    const _document_ = `${userID}`;
    const document = doc(db, `${_collection_}/${_document_}`);
    getDoc(document)
    .then(snap => {
        if (snap.exists() && snap.data()) {
            const docData = snap.data();
            if (docData.recommendations && Array.isArray(docData.recommendations)) {
                updateDoc(document, { recommendations: arrayUnion(_data_obj_) })
                .then(() => { 
                    callback(true);
                    console.log("task_for_nav_complete__navigate_back");
                })
                .catch(() => {
                    callback(false);
                })
            }
        } else {
            setDoc(document, _metaObj_recommend)
            .then(() => { 
                callback(true);
                console.log("task_for_nav_complete__navigate_back");
            })
            .catch(() => {
                callback(false);
            })
        }
    })
    .catch(error => {
        setDoc(document, _metaObj_recommend)
        .then(() => { 
            callback(true);
            console.log("task_for_nav_complete__navigate_back");
        })
        .catch(() => {
            callback(false);
        })
    })
}