import React, { createContext, useState, useEffect, useContext } from "react";
import { onAuthStateChanged } from 'firebase/auth';
import { storage, db, auth } from '../fb/fbSetup';
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { userprofile_storage_path , userprofile_Landing_storage_path, AddNewUser, ContentModel } from "../fb/dbMethods";
import Compressor from "compressorjs";
import { CurrentUserDataModel, PagePaths } from "../Models/Models";
import { doc, getDoc } from "firebase/firestore";
import { ErrorIconOption } from "../Components/ErrorMessage/ErrorMessage";

interface ControllerContext {
    errorMessage: string;
    renderErrorMessagePopUp: boolean;
    showErrorPopup: ( 
        bool: boolean, 
        message: string, 
        title? : string,
        backgroundColor? : string,
        icontType?: ErrorIconOption,
        allowUserToClose?: boolean,
        amClosing?: boolean,
        errorMessageDisplayTime?: number,
    )  => void;
    errorMessageBackgroundColor : string | undefined;
    iconOption : ErrorIconOption | undefined;
    errorMessageTitle: string | undefined;
    allowUserToCloseErrorMessage: boolean;
    errorMessageCloseTime: number | undefined;
    //
    showCommentSection: boolean;
    contentModel: ContentModel | undefined;
    showCommentSectionPopOut: (show: boolean, c: ContentModel) => void;
    // 
    showConstitution: boolean;
    setShowConstitutionView: (showView: boolean) => void;
    //
    showImageCloserLook: boolean;
    imageUrl : string;
    toggleImageCloserLook: (url: string, show: boolean) => void;
    //
    showAnonymousPostController: boolean;
    toggleShowAnonymousPostController: (show: boolean) => void;
    //
    showEditProfileView: boolean;
    toggleShowEditProfileView: (show: boolean) => void;
    //
    showLiveSessionView: boolean;
    toggleLiveSessionView: (show: boolean) => void;
    //
    theaterPostModelData?: ContentModel | undefined;
    showTheaterView: boolean;
    toggleTheaterView: (post: ContentModel | undefined, show: boolean) => void;
    //
    showNotificationView: boolean;
    toggleNotificationView: (show: boolean) => void;
    //
    showSettiings: boolean,
    toggleSettings: (show: boolean) => void,
    //
    showDesiredFeaturesView: boolean,
    toggleDesiredFeaturesView: (show: boolean) => void,
    //
    showSearchView: boolean,
    toggleSearchView: (show: boolean) => void,
    //
    showFilterView: boolean,
    toggleFilterView: (show: boolean) => void,
    //
    showMobileSettingsView: boolean;
    toggleMobileSettingsView: (show: boolean) => void;
    //
    directMessageToUser: CurrentUserDataModel | undefined;
    directMessagePostID: string | undefined;
    showStartDirectMessageView: boolean,
    toggleStartDirectMessageView: (
        show: boolean, toUser: CurrentUserDataModel | undefined, postID?: string
    ) => void,
    //
    //
    //
    showJumpInSignUp: boolean,
    toggleJumpInSignUp: (show: boolean) => void,
}

const initObj: ControllerContext = {
    errorMessage: "",
    renderErrorMessagePopUp: false,
    showErrorPopup: ( 
        bool: boolean, 
        message: string, 
        title? : string,
        backgroundColor? : string,
        icontType?: ErrorIconOption,
        allowUserToClose?: boolean,
        amClosing?: boolean,
        errorMessageDisplayTime?: number,
    )  => {},
    errorMessageBackgroundColor: undefined,
    iconOption : undefined,
    errorMessageTitle: undefined,
    allowUserToCloseErrorMessage: false, // Defualt state is setting it to no...
    errorMessageCloseTime: undefined,
    //
    showCommentSection: false,
    contentModel: undefined,
    showCommentSectionPopOut: (show: boolean, c: ContentModel) => {},
    showConstitution: false,
    setShowConstitutionView: (showView: boolean) => {},
    showImageCloserLook: false,
    imageUrl: "",
    toggleImageCloserLook: (url: string, show: boolean) => {},
    //
    showAnonymousPostController: false,
    toggleShowAnonymousPostController: (show: boolean) => {},
    //
    showEditProfileView: false,
    toggleShowEditProfileView: (show: boolean) => {},
    //
    showLiveSessionView: false,
    toggleLiveSessionView: (show: boolean) => {},
    //
    theaterPostModelData: undefined,
    showTheaterView: false,
    toggleTheaterView: (post: ContentModel | undefined, show: boolean) => {},
    //
    showNotificationView: false,
    toggleNotificationView: (show: boolean) => {},
    //
    showSettiings: false,
    toggleSettings: (show: boolean) => {},
    //
    showDesiredFeaturesView: false,
    toggleDesiredFeaturesView: (show: boolean) => {},
    //
    showSearchView: false,
    toggleSearchView: (show: boolean) => {},
    //
    showFilterView: false,
    toggleFilterView: (show: boolean) => {},
    //
    showMobileSettingsView: false,
    toggleMobileSettingsView: (show: boolean) => {},
    //
    directMessageToUser: undefined,
    directMessagePostID: undefined,
    showStartDirectMessageView: false,
    toggleStartDirectMessageView: (
        show: boolean, toUser: CurrentUserDataModel, postID?: string
    ) => {},
    //
    //
    //
    showJumpInSignUp: false,
    toggleJumpInSignUp: (show: boolean) => {},
}

const ControllerContext = createContext<ControllerContext>(initObj);
export default ControllerContext; // Making The Context Objec the default export

interface ControllerContextModel { children: any }
export const ControllerContextProvider = ({ children }: ControllerContextModel) => {
    // Error Message Controls
    // Error Message Controls
    // Error Message Controls
    const [ errorMessage, setErrorMessage ] = useState<string>("");
    const [ renderErrorMessagePopUp, setRenderErrorMessagePopup ] = useState<boolean>(false);
    //Error Extra Items
    const [ allowUserToCloseErrorMessage, setAllowUsersToCloseErrorMessage ] = useState<boolean>(false);
    const [ errorMessageCloseTime, setErrorMessageCloseTime ] = useState<number | undefined>(undefined);// This will be used for the user to either set a very long error message diplay time or show it to the defualt time
    const [ errorMessageBackgroundColor, setErrorMessageBackgroundColor ] = useState<string | undefined>(undefined);
    const [ iconOption, setIconOption ] = useState<ErrorIconOption | undefined>(undefined); 
    const [ errorMessageTitle, setErrorMessageTitle ] = useState<string | undefined>(undefined);
    /**
     * showErrorPopup: 
     * @param bool : boolean
     * @param message : string
     * @param title : (optional) string
     * @param backgroundColor : (optional) string
     * @param icontType : (optional) ErrorIconOption
     * @param allowUserToClose : (optional) boolean
     * @param amClosing: (optional) boolean
     * @param errorMessageDisplayTime : (optional) number
     */
    function showErrorPopup( 
        bool: boolean, 
        message: string, 
        title? : string,
        backgroundColor? : string,
        icontType?: ErrorIconOption,
        allowUserToClose?: boolean,
        amClosing?: boolean,
        errorMessageDisplayTime?: number,
    ) {
        setRenderErrorMessagePopup(_=> { return bool; });
        if (!amClosing) {
            setErrorMessage(_=> { return message; });
            setErrorMessageTitle(_=> { return title; });
            setIconOption(_=> { return icontType; });
            setErrorMessageBackgroundColor(_=> { return backgroundColor; });
            setAllowUsersToCloseErrorMessage(_=> { return allowUserToClose; });
            setErrorMessageCloseTime(_=> { return errorMessageDisplayTime; });
        }
    }
    // Comment Section Pop Out  
    const [ contentModel, setContentModel ] = useState< ContentModel | undefined >(undefined);
    const [ showCommentSection, setChowCommentSection ] = useState<boolean>(false);
    function showCommentSectionPopOut( show: boolean, c: ContentModel) {
        console.log("Show Comment Section: ");
        setContentModel(_=> { return c; })
        setChowCommentSection(_=> { 
            console.log("Show Comment Section: ", _);
            return show; 
        });
    }

    const [ showConstitution, setShowConstitution] = useState<boolean>(false);
    function setShowConstitutionView (showView: boolean) { setShowConstitution(_=> { return showView }); };
    //
    //

    const [ imageUrl , setImageUrl ] = useState<string>("")
    const [ showImageCloserLook, setShowImageCloserLook ] = useState<boolean>(false);
    function toggleImageCloserLook(url: string, show: boolean) {
        setImageUrl(_=> { return url; });
        setShowImageCloserLook(_=> { return show; });
    };
    //
    //
    const [ showAnonymousPostController, setShowAnonymousPostController ] = useState<boolean>(false);
    function toggleShowAnonymousPostController (show: boolean) {
        setShowAnonymousPostController(_ => { return show; })
    }
    //
    //
    const [ showEditProfileView, setShowEditProfileView ] = useState<boolean>(false);
    function toggleShowEditProfileView (show: boolean) {
        setShowEditProfileView(_ => { return show; })
    }
    //
    //
    const [ showLiveSessionView, setShowLiveSessionView ] = useState<boolean>(false);
    function toggleLiveSessionView (show: boolean) {
        setShowLiveSessionView(_ => { return show; })
    }

    //
    //
    const [ theaterPostModelData, setTheaterPostModelData ] = useState<ContentModel | undefined>(undefined)
    const [ showTheaterView, setShowTheaterView ] = useState<boolean>(false);
    function toggleTheaterView (post: ContentModel | undefined, show: boolean) {
        setTheaterPostModelData(_ => { return post; });
        setShowTheaterView(_ => { return show; })
    }

    //
    //
    const [ showNotificationView, setShowNotificationView ] = useState<boolean>(false);
    function toggleNotificationView (show: boolean) {
        setShowNotificationView(_ => { return show; })
    }

    //
    //
    const [ showSettiings, setShowSettiings ] = useState<boolean>(false);
    function toggleSettings (show: boolean) {
        setShowSettiings(_ => { return show; })
    }

    //
    //
    const [ showDesiredFeaturesView, setShowDesiredFeaturesView ] = useState<boolean>(false);
    function toggleDesiredFeaturesView (show: boolean) {
        setShowDesiredFeaturesView(_ => { return show; })
    }

    //
    //
    const [ showSearchView, setShowSearchView ] = useState<boolean>(false);
    function toggleSearchView (show: boolean) {
        setShowSearchView(_ => { return show; })
    }

    //
    //
    const [ showFilterView, setShowFilterView ] = useState<boolean>(false);
    function toggleFilterView (show: boolean) {
        setShowFilterView(_ => { return show; })
    }

    //
    //
    const [ directMessageToUser, setDirectMessageToUser ] = useState<CurrentUserDataModel | undefined>(undefined);
    const [ directMessagePostID, setDirectMessagePostID] = useState< string | undefined >(undefined);
    const [ showStartDirectMessageView, setShowStartDirectMessageView ] = useState<boolean>(false);
    function toggleStartDirectMessageView (show: boolean, toUser: CurrentUserDataModel, postID?: string) {
        setDirectMessageToUser(_=> { return toUser; });
        setDirectMessagePostID(_=>{ return postID; });
        setShowStartDirectMessageView(_ => { return show; })
    }
    
    //
    //
    const [ showJumpInSignUp, setShowJumpInSignUp] = useState<boolean>(false);
    function toggleJumpInSignUp (show: boolean) {
        setShowJumpInSignUp(_ => { return show; })
    }
    
    //
    //
    const [ showMobileSettingsView, setShowMobileSettingsView] = useState<boolean>(false);
    function toggleMobileSettingsView (show: boolean) {
        setShowMobileSettingsView(_ => { return show; })
    }

    // Controller Model Obj.
    const val: ControllerContext = {
        errorMessage,
        renderErrorMessagePopUp,
        showErrorPopup,
        errorMessageBackgroundColor,
        iconOption,
        errorMessageTitle,
        allowUserToCloseErrorMessage,
        errorMessageCloseTime,
        //
        contentModel,
        showCommentSection,
        showCommentSectionPopOut,
        //
        showConstitution,
        setShowConstitutionView,
        //
        showImageCloserLook,
        imageUrl,
        toggleImageCloserLook,
        //
        //
        showAnonymousPostController,
        toggleShowAnonymousPostController,
        //
        //
        showEditProfileView,
        toggleShowEditProfileView,
        //
        //
        showLiveSessionView,
        toggleLiveSessionView,
        //
        //
        theaterPostModelData,
        showTheaterView,
        toggleTheaterView,
        //
        //
        showNotificationView,
        toggleNotificationView,
        //
        //
        showSettiings,
        toggleSettings,
        //
        //
        showDesiredFeaturesView,
        toggleDesiredFeaturesView,
        //
        //
        showSearchView,
        toggleSearchView,
        //
        //
        showFilterView,
        toggleFilterView,
        //
        //
        showMobileSettingsView, 
        toggleMobileSettingsView,
        //
        //
        directMessageToUser,
        directMessagePostID,
        showStartDirectMessageView,
        toggleStartDirectMessageView,
        //
        //
        //
        showJumpInSignUp,
        toggleJumpInSignUp
    } 
    return (
        <ControllerContext.Provider value={ val }>
            { children }
        </ControllerContext.Provider>
    )
}