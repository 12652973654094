import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useState, useEffect, useContext } from "react";
import ControllerContext from "../../Contexts/ControllerContexts";
import UserContext from "../../Contexts/UserContext";
import { AddComment, _AddComment_ } from "./Comments_DB_Methods";
import { ContentModel, Posts_Coll_And_Doc_Path, userprofile_Landing_storage_path, userprofile_storage_path } from "../../fb/dbMethods";
import { auth, db, storage } from "../../fb/fbSetup";
import { CurrentUserDataModel } from "../../Models/Models";
import './CommentSection.css';
import './CommentSectionMobile.css'
import { CommentDocModel, CommentModel, getAllComments, getAllNestedComments_for_this_comment, ReplyToComoment } from "./Comments_DB_Methods";
import ColorUI from "../ColorUI/ColorUI";
import { signInWithCustomToken } from "firebase/auth";
dayjs.extend(relativeTime)

const CommentSectionViewMobile = () => {
    const [ userData, setUserData ] = useState<{ userID: string } | undefined>(undefined);
    const [ showComments , setShowComments ] = useState<boolean>(false);
    const [ contentModel, setContentModel ] = useState<ContentModel | undefined>(undefined);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tkn = urlParams.get('t');  
        const ud = urlParams.get('ud');
        const postID = urlParams.get('postid');  
        if (tkn) {
            signInWithCustomToken(auth, tkn)
            .then(sucess => {
                if (sucess.user.uid) {
                    setUserData(_=> { return { userID: `${ud}` }; });
                    getPost(postID);
                }
                // sucess sign in with token.
            })
            .catch(error => {
                setShowComments(false);
            });
        }
    }, []);

    function getPost(postID: string) {
        getDoc(doc(db,`${Posts_Coll_And_Doc_Path}/${postID}`))
        .then((document) => {
            const data = document.data() as ContentModel;
            if (data) {
                setContentModel(_=> { return data; });
                setShowComments(_=> { return true; });
            } else {
                setShowComments(false);
            }
        })
        .catch(error => {
            // Error
            setShowComments(false);
        })
    }
    
    return (
        <>
            { (showComments && contentModel) &&
                <_CommentSectionView_ userData={ userData } contentModel={ contentModel }/>
            }
        </>
    );
}

interface _CommentSectionView_Type { userData: { userID: string }; contentModel: ContentModel; }
const _CommentSectionView_ = ({
    userData,
    contentModel
}: _CommentSectionView_Type) => {
    const [ comment, setComment ] = useState<string>("");
    const [ thereAreComments , setThereAreComments ] = useState<boolean>(true);
    const [ comments, setComments ] = useState<CommentModel[]>([]);
    //
    useEffect(() => {
        loadComments()
    }, []);
    //
    function loadComments() {
        getAllComments(contentModel, (status, commentList) => {
            if (status) {
                setComments(_=> { return commentList});
                if (commentList.length < 1) setThereAreComments( b => { return false });
                if (commentList.length > 0) setThereAreComments( b => { return true });
            }
        });
    }
    //
    return (
        <div className="commentSectionView comment-section-mobile" style={{ width: "100vw" }}>
            <div className='comment-section-container'>
                <h2 className="comment-section-title">Comments:</h2>
                {(contentModel && contentModel.title) && <h4 style={ { marginTop: "12px", color: "var(--app-blue)"} }>{ contentModel.title }</h4>}
                <div className="comment-section-content">
                    <div className="comment-list-container">
                        { (thereAreComments === true) ?
                            comments.map((comment, index) => {
                                return ( 
                                    <CommentItem currentUser={ userData.userID } contentModel={ contentModel } commentModel={ comment } index={ index }/>
                                )
                            })
                            :
                            <h4 style={{ 
                                color: "gray", 
                                fontSize: "14px",
                                marginTop: "8px",
                            }}>😕 Sorry, there are no comments for this post.</h4>
                        }
                    </div>
                </div>
                { (contentModel) &&
                    <div className="comment-container">
                        <div className='comment-send-button-container'>
                            <textarea className='comment-area' placeholder='Comment...' value={ comment } onChange={e=> { setComment(_=> { return e.target.value; })}} />
                            <button className='comment-area-send-button' onClick={()=> { 
                                _AddComment_(contentModel, comment, userData, loadComments);
                                setComment(_=> { return ""; });
                            }}>send</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CommentSectionViewMobile;

interface CommentItemModel {
    currentUser: string;
    contentModel: ContentModel;
    commentModel: CommentModel;
    index: number;
}
export const CommentItem = ({ currentUser, contentModel, commentModel, index }: CommentItemModel) => {
    const [ CommentUser, setCommentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    const [ showReplies, setShowReplies ] = useState<boolean>(false);
    const [ writeReply, setWriteReply ] = useState<boolean>(false);
    const [ reply, setReply ] = useState<string>("");
    useEffect(()=>{
        getCommentUserData();
        getCommentUserProfileImage();
    },[]);
    async function getCommentUserData(){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ commentModel.userID }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                setCommentUser(_=> { return data; });
            }
        })
        .catch( err => {
        });
    }

    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${commentModel.userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    return (
        <div className="comment-item" key={ index }>
            <div className="comment-user-info-and-date">
                { CommentUser &&
                    <div className="comment-user-profile-info">
                        { imageSrc &&
                            <div className="comment-user-profile-image-container">
                                <img loading={"lazy"} src={ imageSrc }/>
                                <span className="image-cover"/>
                            </div>
                        }
                        <div className="first-last-username">
                            <h5 className="first-last">{ `${CommentUser.firstName} ${CommentUser.lastName}` }</h5>
                            <label className="username">{ `@${CommentUser.customUserName}` }</label>
                        </div>
                    </div>
                }
                <label className="time-of-post">{ `${ dayjs(commentModel.dateOfComment).fromNow() }` }</label>
            </div>
            <label className="comment-text">{ commentModel.comment }</label>
            <div className="comment-reply-and-replies">
                <div className="reply-top-comment-container">
                    { writeReply && <textarea className="reply-textarea" placeholder="reply..." value={ reply } autoFocus={ true } onChange={e => { setReply(_=> { return e.target.value; } ) }}/> }
                    
                    <div className="reply-button-containers">
                        {/* Reply Button */}
                        <button 
                            className={`reply-to-comment-button${ (reply.trim() !== "") ? " active" : "" }` }
                            style={{
                                backgroundColor: (reply.trim() !== "") ? "var(--app-green-80)" : "rgba(0,0,0,0.05)",
                                color: (reply.trim() !== "") ? "white" : "black",
                            }}
                            onClick={()=> {
                            if (reply.trim() !== "") {
                                ReplyToComoment(contentModel, commentModel, currentUser, reply);
                                setReply(_=> { return ""; });
                                setWriteReply(_=> { return false; });
                            } else {
                                setWriteReply(b => { return true; });
                            }
                        }}>reply</button>
                    </div>

                    {/* Cancel */}
                    {writeReply && 
                        <button className="cancel-reply" onClick={()=> {
                            setReply(_=> { return ""; });
                            setWriteReply(_=> { return false; });
                        }}>cancel</button> 
                    }
                </div>
                { (commentModel.responseRefferenced.hasReplies) &&
                    <div className="comment-replies">
                        <button className="show-replies-button" onClick={() => {
                            setShowReplies(b=> { return !b; });
                        }}>{ showReplies ? "hide replies" : "show replies" }</button>
                        { (showReplies) &&
                            <NestedCommentView post={ contentModel } commentModel={ commentModel }/>
                        }
                    </div>
                }
            </div>
        </div>  
    )
}

interface NestedCommentViewModel{
    post: ContentModel;
    commentModel : CommentModel;
}
const NestedCommentView = ({post, commentModel}: NestedCommentViewModel) => {
    const [ comments, setComments ] = useState< CommentModel[] | undefined >([]);
    useEffect(() => {
        getAllNestedComments_for_this_comment(post, commentModel, (status, commentList) => {
            if (status) {
                setComments(_=> { return  commentList; });
            }
        })
    }, []);
    return (
        <div>
            <div className="replies-container">
                { 
                    comments.map((commentModel,index) => {
                        return (<NestedCommentItem commentModel={ commentModel }/>)
                    })
                }
            </div>
        </div>
    )
}

interface NestedCommentItemModel {
    commentModel: CommentModel;
}
const NestedCommentItem = ({ commentModel,}: NestedCommentItemModel ) => {
    const { userData } = useContext(UserContext)
    const [ CommentUser, setCommentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    const [ showReplies, setShowReplies ] = useState<boolean>(false);
    const [ writeReply, setWriteReply ] = useState<boolean>(false);
    const [ reply, setReply ] = useState<string>("");
    useEffect(()=>{
        getCommentUserData();
        getCommentUserProfileImage();
    },[]);
    async function getCommentUserData(){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ commentModel.userID }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                setCommentUser(_=> { return data; });
            }
        })
        .catch( err => {
        });
    }

    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${commentModel.userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }
    return (
        <div className="comment-container-nested">
            <div className="comment-user-info-and-date">
                { CommentUser &&
                    <div className="comment-user-profile-info">
                        { imageSrc &&
                            <div className="comment-user-profile-image-container">
                                <img loading={"lazy"} src={ imageSrc }/>
                                <span className="image-cover"/>
                            </div>
                        }
                        <div className="first-last-username">
                            <h5 className="first-last">{ `${CommentUser.firstName} ${CommentUser.lastName}` }</h5>
                            <label className="username">{ `@${CommentUser.customUserName}` }</label>
                        </div>
                    </div>
                }
                <label className="time-of-post">{ `${ dayjs(commentModel.dateOfComment).fromNow() }` }</label>
            </div>
            <label className="comment-text">{ commentModel.comment }</label>
        </div>
    )
}