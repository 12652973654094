import React, { useState, useEffect, useContext } from "react";
import ControllerContext from "../../Contexts/ControllerContexts";
import "./StartDirectMessageController.css";
import { chat_storage_path, MessageModel, message_storage_path, _Start_A_New_Message_Chain } from "../../fb/MessageLogic/MessageLogic";
import UserContext from "../../Contexts/UserContext";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { CurrentUserDataModel, MessageRelationModel } from "../../Models/Models";
import { auth, db } from "../../fb/fbSetup";
import { signInWithCustomToken } from "firebase/auth";
import { userprofile_storage_path } from "../../fb/dbMethods";
import Lottie from "lottie-react";
import confettie from "../../../Resources/Lottie/confettie.json";

const StartDirectMessageController = () => {
    const { userData } = useContext(UserContext);
    const { showStartDirectMessageView, toggleStartDirectMessageView, directMessagePostID, directMessageToUser, showErrorPopup } = useContext(ControllerContext);
    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView, setAnimateView ] = useState<boolean>(false);

    useEffect(() => {
        if(showStartDirectMessageView) {
            setRenderView(showStartDirectMessageView);
            setTimeout(() => {
                setAnimateView(showStartDirectMessageView);
            }, 150);
        } else {
            setAnimateView(showStartDirectMessageView);
            setTimeout(() => {
                setRenderView(showStartDirectMessageView);
            }, 400);
        }
    }, [ showStartDirectMessageView ]);
    //
    //
    //
    //
    const [ message, setMessage ] = useState<string>("");
    function sendMessage() {
        if (message.trim() !== "" && directMessageToUser) {
            let messageRelationModel: MessageRelationModel | undefined = undefined;
            userData.messagesRefferences.forEach(i => { 
                if ((i.user_one_ID === directMessageToUser.userID) || (i.user_two_ID === directMessageToUser.userID)) {
                     messageRelationModel = i; 
                } 
            });
            if (messageRelationModel === undefined) {
                const u = userData;
                const m = message;
                const other = directMessageToUser;
                _Start_A_New_Message_Chain(u, m, other.userID, other.customUserName, other.firstName, other.lastName, (documentID, complete) => {
                    toggleStartDirectMessageView(false, undefined, undefined);
                    showErrorPopup(true, "Sucess! You have sucessfully started a direct message thread.", "", "var(--app-blue-70)")
                });
            } else {
                _thread_already_started_SendMessage(messageRelationModel);
            }
        }
    }
    //
    //
    //
    async function _thread_already_started_SendMessage(messageRelationModel: MessageRelationModel) {
        const currentMessageRef =  `${message_storage_path}/${messageRelationModel.documentID}/${chat_storage_path}/${ messageRelationModel.current_doc_index }`
        // If a thousand messages or more are coming from a document, then create a new document with an updated index to allow messages to come through and be sure to update the host document for the chat with that index
        const messageTime = new Date();
        const messageObject: MessageModel = {
            firstname: userData.firstName,
            lastname: userData.lastName,
            userName: userData.customUserName,
            from: userData.userID,
            to: "",
            ats:[],
            message: message,
            images: [],
            date: messageTime,
        }
        await updateDoc(
            doc(db, currentMessageRef),
            { "messages": arrayUnion(messageObject), }
        ).then(status => {
            console.log("Document Being Added");
            UpdateChatSourceDoc(messageTime, message, messageRelationModel);
            setMessage("");
        }).catch(err => {})
    }

    async function UpdateChatSourceDoc(messageTime : Date, message: string, messageRelationModel: MessageRelationModel) {
        const messageCollRef =  `${message_storage_path}/${messageRelationModel.documentID}`
        const docToUpdate = await updateDoc(
            doc(db,  messageCollRef),
            { documentID: messageRelationModel.documentID, mostRecentMessage: message, mostRecentInteractionDate: messageTime, current_doc_index:  messageRelationModel.current_doc_index }
        ).then(status => {}).catch(err => {})
    }
    //
    //
    //
    return (
        <>
            { renderView &&
                <div className="startDirectMessage">
                    <div className="directMessage-dismissable-view" style={{ opacity: animateView ? "100%" : "0%" }} onClick={()=>{ toggleStartDirectMessageView(false, undefined, undefined); }}/>
                    <div className="direct-message-pop-up" style={{transform : animateView ? "translateX(0)" : "translateX(110vw)"}}>
                        <div className="direct-message-content">
                            <h3 className="pop-up-title">Start Direct Message</h3>
                            <div className="message-controlls-container">
                                <textarea className="message-field" placeholder="message..." value={message} onChange={e => { setMessage(_=> { return e.target.value; })}}/>
                                <button className="send-button" onClick={() => { sendMessage(); }}>send</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default StartDirectMessageController;





export const MOBILE_StartDirectMessageController_MOBILE = () => {
    // const [ userData, setUserData ] = useState<{ userID: string } | undefined>(undefined);
    const [fromUser, setFromUser] = useState<CurrentUserDataModel | undefined>(undefined);
    const [toUser, setToUser] = useState<CurrentUserDataModel | undefined>(undefined);
    //    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tkn = urlParams.get('t');  
        const ud = urlParams.get('ud');
        const other_user = urlParams.get('otherud');
        if (tkn) {
            signInWithCustomToken(auth, tkn)
            .then(sucess => {
                if (sucess.user.uid) {
                    console.log("loaded data: ", ud) //.user.uid)
                    getUserData(ud, (sucess, user) => { if (sucess) { setFromUser(_=> { return user;}); }  });
                    getUserData(other_user, (sucess, user) => { if (sucess) { setToUser(_=> { return user;}); }  });
                }
            })
            .catch(error => {
                console.log("LiveSessionMobile: ", error)
            });
        }
    }, []);

    async function getUserData(id: string, callack: (sucess: boolean, data?: CurrentUserDataModel) => void){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ id }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                callack(true, data)
            } else {
                callack(false, null)
            }
        })
        .catch( err => {
            callack(false, null)
        });
    }
    
    return (
        <>
            { (fromUser && toUser) &&
                <_MOBILE_StartDirectMessageController_MOBILE_ userData={ fromUser } directMessageToUser={ toUser }/>
            }
        </>
    );
}

interface MobileStartDirectMessageController {
    userData: CurrentUserDataModel;
    directMessageToUser: CurrentUserDataModel;
}

const _MOBILE_StartDirectMessageController_MOBILE_ = ({
    userData, directMessageToUser
}: MobileStartDirectMessageController) => {
    const [ hideMessage, setHideMessage ] = useState<boolean>(false);
    const [ message, setMessage ] = useState<string>("");
    function sendMessage() {
        if (message.trim() !== "" && directMessageToUser) {
            let messageRelationModel: MessageRelationModel | undefined = undefined;
            userData.messagesRefferences.forEach(i => { 
                if ((i.user_one_ID === directMessageToUser.userID) || (i.user_two_ID === directMessageToUser.userID)) {
                     messageRelationModel = i; 
                } 
            });
            if (messageRelationModel === undefined) {
                const u = userData;
                const m = message;
                const other = directMessageToUser;
                _Start_A_New_Message_Chain(u, m, other.userID, other.customUserName, other.firstName, other.lastName, (documentID, complete) => {
                    setMessage(_=> { return ""; });
                    setHideMessage(_=> { return true; });
                    console.log("starting_direct_message_sucessfully_sent");
                });
            } else {
                _thread_already_started_SendMessage(messageRelationModel);
            }
        }
    }
    //
    //
    //
    async function _thread_already_started_SendMessage(messageRelationModel: MessageRelationModel) {
        // If a thousand messages or more are coming from a document, then create a new document with an updated index to allow messages to come through and be sure to update the host document for the chat with that index
        const currentMessageRef =  `${message_storage_path}/${messageRelationModel.documentID}/${chat_storage_path}/${ messageRelationModel.current_doc_index }`
        const messageTime = new Date();
        const messageObject: MessageModel = {
            firstname: userData.firstName,
            lastname: userData.lastName,
            userName: userData.customUserName,
            from: userData.userID,
            to: "",
            ats:[],
            message: message,
            images: [],
            date: messageTime,
        }
        await updateDoc(
            doc(db, currentMessageRef),
            { "messages": arrayUnion(messageObject), }
        ).then(status => {
            console.log("Document Being Added");
            UpdateChatSourceDoc(messageTime, message, messageRelationModel);
            //
            setMessage(_=> { return ""; });
            setHideMessage(_=> { return true; });
            console.log("starting_direct_message_sucessfully_sent");
        }).catch(err => {})
    }

    async function UpdateChatSourceDoc(messageTime : Date, message: string, messageRelationModel: MessageRelationModel) {
        const messageCollRef =  `${message_storage_path}/${messageRelationModel.documentID}`
        const docToUpdate = await updateDoc(
            doc(db,  messageCollRef),
            { documentID: messageRelationModel.documentID, mostRecentMessage: message, mostRecentInteractionDate: messageTime, current_doc_index:  messageRelationModel.current_doc_index }
        ).then(status => {}).catch(err => {})
    }
    //
    //
    //
    return (
        <> 
            { !hideMessage ?
                <div className="startDirectMessage mobileStartDirectMessage">
                    <div className="direct-message-pop-up" style={{}}>
                        <div className="direct-message-content">
                            <h3 className="pop-up-title">Start Direct Message</h3>
                            <div className="message-controlls-container">
                                <textarea className="message-field" placeholder="message..." value={message} onChange={e => { setMessage(_=> { return e.target.value; })}}/>
                                <button className="send-button" onClick={() => { sendMessage(); }}>send</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div id="waitlist-container" className="added-to-wait-list-animation" style={{
                    width: "100vw",
                    height: "500px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "0", 
                    backgroundColor: "white",
                    background: "linear-gradient(white, var(--app-green-50), white)"
                    }}>
                    <div style={{
                        position: "absolute",
                        width: "500px",
                        height: "500px",
                        zIndex: "0", 
                    }}>
                        <Lottie animationData={ confettie } loop={true}/>
                    </div>
                    <h1 style={{ zIndex: "1", textAlign: "center", padding: "30px" }}>{ "😃 Message Sent!" }</h1>
                </div>
            }
        </>
    )
}