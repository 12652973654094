import React, { useState, useEffect, useContext } from "react";
import Filter_icon from '../../../../Resources/filter.png';
import ControllerContext from "../../../Contexts/ControllerContexts";
import "./FilterViewButton.css";

const FilterViewButton = () => {
    const {  toggleFilterView } = useContext(ControllerContext);
    const urlParams = new URLSearchParams(window.location.search);
	const tkn = urlParams.get('t');  
	const ud = urlParams.get('ud');
    return (
        <div className="filter-button" onClick={() => {
            if (tkn && ud) {
                toggleFilterView(true);
            } else {
                window.location.href = "/filter-controls";
            }
        }}>
            <div className="filter-icon">
                <img src={ Filter_icon }/>
                <div className="cover"/>
            </div>

            <div className="filter-text">
                <label>Filter</label>
            </div>
        </div>
    )
}

export default FilterViewButton;