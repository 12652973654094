import { db } from "../../fb/fbSetup";
import { doc, setDoc } from "firebase/firestore";
import uuidGenerator from "../../../SharedItems/UUIDGenerator";

export const DesiredFeaturesReffernceRefPath = "_D_E_S_I_R_E_D__F_E_A_T_U_R_S";

export interface FeatureRequestModel {
    userID: string;
    requestType: FeatureRequestType;
    title: string;
    description: string;
    reason?: string;
    requestID?: string;
}

export enum FeatureRequestType {
    feature = "feature",
    bug = "bug",
}

export async function addFeatureRequest(
    _object: FeatureRequestModel,
    callBack: (completedWithoutError: boolean) => void,
) {
    const documentID = uuidGenerator();
    const ref = `${DesiredFeaturesReffernceRefPath}/${documentID}`;
    const docRef = doc(db, ref);
    const object = {
        userID: _object.userID,
        requestType: _object.requestType,
        title: _object.title,
        description: _object.description,
        reason: _object.reason,
        requestID: documentID,
    }
    await setDoc(docRef, object)
    .then(() => {
        callBack(true);
    }).catch(() => {
        callBack(false)
    });
    
}