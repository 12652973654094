import { useState, useEffect } from "react";
import { db, auth } from "../../fb/fbSetup";
import './TestView.css';
import { TestModel, ExamScoreDocModel, PagePaths } from "../../Models/Models";
import { physics_multipleChoice } from "../../Models/TestData";
import { collection, doc, getDoc, getDocs, limit, query, where } from "firebase/firestore";
import { Exam_Score_Coll_Doc_Path, userprofile_storage_path, User_Invitaion_Coll_Doc_Path } from "../../fb/dbMethods";
import { onAuthStateChanged } from "firebase/auth";
import _TestView_ from "./_TestView_";



const exampleModel : TestModel = {
    multipleChoiceQuestion: physics_multipleChoice,//exampleMultpleChoice,
    multiSelectQuestions: [], // exampleMultiSelect,
    trueOrFalseQuestions: [], // exampleTrueFalse,
};

const TestView = () => {
        // Add useEffect
        useEffect(() => {
            // signOut(auth)
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const docRef = `${ userprofile_storage_path }/${user.uid}`;
                    const document = doc(db, docRef);
                    getDoc(document)
                    .then( docObj => {
                        if (docObj.exists()) {
                            // window.location.href = PagePaths.Home;
                        } else {
                            // Checking if user has initiated the invitation process already, didn't save any prof. data
                            const collRef = collection(db, User_Invitaion_Coll_Doc_Path);
                            const q = query(collRef, limit(1), where("invitationTo", "==" , user.uid))
                            getDocs(q)
                            .then(async (q) => {
                                if (q.size < 1) {
                                    // if user didn't get invited => Checking if user has passed the exam already process already, didn't save any prof. data
                                    const docRef = doc(db, `${ Exam_Score_Coll_Doc_Path }/${ user.uid }`);
                                    await getDoc(docRef)
                                    .then(document => {
                                        const data = document.data() as ExamScoreDocModel;
                                        // if (data && data.didPass === true) { window.location.href = PagePaths.newUser; }
                                    })
                                    .catch(() => {});
                                } 
                                if (q.size > 1) { 
                                    //window.location.href = PagePaths.newUser; 
                                }
                            })
                            .catch(() => {});
                        }
                    }).catch(e=> {})
                } else {
                   // window.location.href = PagePaths.Authenticate;
                }
            })
            // setTestItems(t=> { return exampleModel; });
            // return () => {} // After Sorting Through The UseEffect Values
            
        }, []);
    
        const [ submitPressed , setSubmitPressed ] = useState<boolean>(false);
        const [ endExam, setEndExam ] = useState<boolean>(false);
        const [ startExam, setStartExam ] = useState<boolean>(false);
        const [ showInitationView, setShowInitiaionView ] = useState<boolean>(true);

        return (
            <_TestView_ 
                submitPressed={ submitPressed }
                setSubmitPressed={ setSubmitPressed }
                endExam={ endExam }
                setEndExam={ setEndExam }
                startExam={ startExam }
                setStartExam={ setStartExam }
                showInitationView={ showInitationView }
                setShowInitiaionView={ setShowInitiaionView }
            />
        )
}

export default TestView;
