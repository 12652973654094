import React, { useState, useEffect, useContext } from "react";
import { PagePaths } from "../../Models/Models";
import "./BottomTabBar.css"
import Home_icon from '../../../Resources/SideMenuIcons/home.png';
import Message_icon from '../../../Resources/SideMenuIcons/message.png';
import DunbarColony_icon from '../../../Resources/SideMenuIcons/colony.png';
import Profile_icon from '../../../Resources/SideMenuIcons/user.png';
import History_icon from '../../../Resources/SideMenuIcons/history.png';
import ViewLater_icon from '../../../Resources/SideMenuIcons/viewlater.png';
import Favorites_icon from '../../../Resources/SideMenuIcons/favorites.png';
import LiveChat_icon from "../../../Resources/SideMenuIcons/livechat.png";
import Post from "../../../Resources/SideMenuIcons/livechat.png";
// 
import addBlue from '../../../Resources/SideMenuIcons/add_blue.png';
import addBlueGradient from "../../../Resources/SideMenuIcons/add_blue_grad.png";
import addBlackMulti from "../../../Resources/SideMenuIcons/add_black_multi.png";
// 

export enum SideMenuOptions {
    BecomeMemberOfNewCommunity = "BecomeMemberOfNewCommunity",
    Home = "Home",
    DunbarColony = "DunbarColony",
    LiveChat = "LiveChatSession",
    Messages = "Messages",
    Profile = "Profile",
    History = "VideoHistory",
    Favorites = "Favorites",
    ViewLater = "ViewLater",
    Settings = "Settings",
    Report = "Report",
    DesireFeatures = "DesireFeatures",
    //
    Post = "Post"
}

// const reportingAndSafteyTitle = "Reporting / Safety";
// const desiredFeaturesTitle = "Desired Features";
const sideMenuItems = [
    { label: "Home", icon: Home_icon, option: SideMenuOptions.Home, path: PagePaths.Home },
    { label : "Colony", icon : DunbarColony_icon, option: SideMenuOptions.DunbarColony, path: PagePaths.DunbarColony },
    { label : "Live Chat", icon : LiveChat_icon, option: SideMenuOptions.LiveChat, path: PagePaths.LiveChatSession  },

    { label: "Post", icon: addBlue, option: SideMenuOptions.Post, path: "" },

    { label: "Messages", icon: Message_icon, option: SideMenuOptions.Messages, path: PagePaths.Messages },
    { label: "Profile", icon: Profile_icon, option: SideMenuOptions.Profile, path: PagePaths.Profile },
    // { label: "Video History", icon: History_icon, option: SideMenuOptions.History, path: PagePaths.VideoHistory },
    // { label: "View Later", icon: ViewLater_icon, option: SideMenuOptions.ViewLater, path: PagePaths.ViewLater },
    { label: "Favorites", icon: Favorites_icon, option: SideMenuOptions.Favorites, path: PagePaths.Favorites  }
];
enum DeviceType {
    Mobile,
    Tablet,
    Desktop,
    Unknown,
}
  
function detectDeviceType(): DeviceType {
    const screenWidth = window.innerWidth;
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const userAgent = navigator.userAgent.toLowerCase();

    if ((screenWidth < 768 && isTouchDevice) || /iphone|android|webos|blackberry|bb10|iemobile|opera mini/i.test(userAgent)) {
    return DeviceType.Mobile;
    } else if (screenWidth < 1024 || /ipad|tablet|kindle|playbook|silk/i.test(userAgent)) {
    return DeviceType.Tablet;
    } else {
    return DeviceType.Desktop;
    }
}

interface BottomTabProps {
	selectedItem : SideMenuOptions;
	setSelectedItem: (value: React.SetStateAction<SideMenuOptions>) => void;
	setShowSetting: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomTabBar = ({ selectedItem, setSelectedItem, setShowSetting }: BottomTabProps) => {
    const urlParams = new URLSearchParams(window.location.search);
	const tkn = urlParams.get('t');  
	const ud = urlParams.get('ud');
    const passedDeviceType = urlParams.get('type');
	const [userID, setUserID] = useState<string | null | undefined>(null);

    function activeOption(): SideMenuOptions {
        if (window.location.href.includes(PagePaths.DunbarColony) || selectedItem === SideMenuOptions.DunbarColony) {
            return SideMenuOptions.DunbarColony;
        }
        if (window.location.href.includes(PagePaths.LiveChatSession) || selectedItem === SideMenuOptions.LiveChat) {
            return SideMenuOptions.LiveChat;
        }
        if (window.location.href.includes(PagePaths.Messages) || selectedItem === SideMenuOptions.Messages) {
            return SideMenuOptions.Messages;
        }
        if (window.location.href.includes(PagePaths.Profile) || selectedItem === SideMenuOptions.Profile) {
            return SideMenuOptions.Profile;
        }
        if (window.location.href.includes(PagePaths.Favorites) || selectedItem === SideMenuOptions.Favorites) {
            return SideMenuOptions.Favorites;
        }
        return SideMenuOptions.Home;
    }

    function stopCamera() {
        // @ts-ignore
        if (window.localStream) {
            // @ts-ignore
            let tracks = window.localStream.getTracks();
            tracks.forEach(track => { track.stop(); });
            // @ts-ignore
            window.localStream = null;
        }
    }

    return (
        <div 
            className={`BottomTabBar${(passedDeviceType && passedDeviceType === "ios") ? " ios" : ""} ${(passedDeviceType) ? " mobile-app" : ""} ${activeOption()}`}>
            <div className="bottom-tabbar-options-container">
            { 
                    sideMenuItems.map( SideMenuItem => {
                        return (
                            <span className={`bottom-tabbar-option${  SideMenuItem.option === activeOption() ? ' active': ''}${SideMenuItem.option === SideMenuOptions.Post ? " post-button" : "" }`}
                                onClick={() => {
                                    if (detectDeviceType() === DeviceType.Mobile && tkn && ud && SideMenuItem.option !== SideMenuOptions.Post) {
                                        setSelectedItem(item => { return SideMenuItem.option; })
                                        stopCamera();
                                    } else if (SideMenuItem.option === SideMenuOptions.Post) {
                                        console.log("show_post_controller_on_mobile_app");
                                    } else {
                                        if (SideMenuItem.option !== SideMenuOptions.LiveChat) stopCamera();
                                        window.location.href = SideMenuItem.path; 
                                    }
                                }}
                            >
                                <div className="tab-icon-container">
                                    <img loading={"lazy"} alt="A House Icon" src={ SideMenuItem.icon }/>
                                    <div className="image-cover"/>
                                </div>
                                {/* <label className="tab-label">{ SideMenuItem.label }</label> */}
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default BottomTabBar;