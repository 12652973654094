import { MultpleChoice, QuestionType } from "./Models"

const physics_multipleChoice: MultpleChoice[] = 
[
    {
        question: "The working principle of a washing machine is",
        exampleImages: [],
        correctAnswer: "centrifugation",
        a: "reverse osmosis",
        b: "diffusion",
        c: "centrifugation",
        d: "dialysis",
        questionType: QuestionType.MultpleChoice,
    },
    {
        question: "Nuclear sizes are expressed in a unit named",
        exampleImages: [],
        correctAnswer: "Fermi",
        a: "Fermi",
        b: "Angstrom",
        c: "Newton",
        d: "Tesla",
        questionType: QuestionType.MultpleChoice,
    },
    {
        question: "The speed of light will be minimum while passing through",
        exampleImages: [],
        correctAnswer: "glass",
        a: "water",
        b: "vaccum",
        c: "air",
        d: "glass",
        questionType: QuestionType.MultpleChoice,
    },
    {
        question: "Which of the following is not a vector quantity?",
        exampleImages: [],
        correctAnswer: "speed",
        a: "speed",
        b: "velocity",
        c: "torque",
        d: "displacement",
        questionType: QuestionType.MultpleChoice,
    },
    {
        question: `A part of a roller coaster’s track is shown. Assume friction is negligible.
        Four points on the track are labeled W, X, Y, and Z.
        Where on the track would a car have the most gravitational potential energy?
        `,
        exampleImages: [
            { 
                imageID: "1", 
                imageTitle: "Roller Coaster Image",
                imageURL: "https://firebasestorage.googleapis.com/v0/b/newtopia-a4327.appspot.com/o/_E_X_A_M_IMAGES%2FImage%209-2-23%20at%207.57%20PM.jpeg?alt=media&token=37143156-7c29-454f-99e8-5ffed95cb0d2"
            }
        ],
        correctAnswer: "point W",
        a: "point W",
        b: "point X",
        c: "point Y",
        d: "point Z",
        questionType: QuestionType.MultpleChoice,
    }
]

export { physics_multipleChoice }