import { useState, useEffect } from "react";
import { db } from "../../fb/fbSetup";
import { doc, getDoc } from "firebase/firestore";
import './Profile.css';
import { ContentModel, Posts_Coll_And_Doc_Path, PostType } from "../../fb/dbMethods";
import { ContentCard } from "../Home/ContentView/ContentView";

export interface PostItemModel {
    postID : string;
    index: number;
}
export const PostItem = ({ postID, index }: PostItemModel) => {
    const [ contentObj, setContentObj ] = useState<ContentModel | undefined>(undefined);
    useEffect(()=>{
        GetPostModel();
    }, []);

    async function GetPostModel() {
        await getDoc(doc(db, `${ Posts_Coll_And_Doc_Path }/${ postID }`))
        .then( document => {
            if (document.exists()) {
                const postDocumentModel = document.data() as ContentModel;
                if (postDocumentModel.postType !== PostType.longFormVideo && postDocumentModel.postType !== PostType.shortFormVideo) {
                    setContentObj(_=> { return postDocumentModel; });
                }
            }
        })
        .catch(err => {
            console.log("Error getting post : ");
        })
        
    }

    return (
        <>
            { contentObj &&
                <ContentCard item={ contentObj } showEmojiResponse={ true } index={ index }/>
            }
        </>
    )
} 