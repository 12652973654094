import React, { useEffect  } from 'react';

const TempAdUnderneathVideo = () => {
    return (
        <>
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-5619224767870187"
                data-ad-format="fluid"
                data-ad-slot={"9683180911"}
                // Testing Ad
                data-adbreak-test="on"
                // @ts-ignore
                data-ad-format={"auto"}
                data-ad-layout-key="-fl-l+36-6e+5j"
                data-full-width-responsive="true">
            </ins>
        </>
    );
};
    
export default TempAdUnderneathVideo;
