import { 
    signInWithEmailAndPassword, 
    onAuthStateChanged, 
    createUserWithEmailAndPassword, 
    getAuth, 
    signInWithPopup, 
    GoogleAuthProvider,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    ConfirmationResult,
} from "firebase/auth";
import React, { useState, useEffect, useContext } from "react";
import { auth } from "../../fb/fbSetup";
import GoogleLogo from "../../../Resources/googleLogo.jpeg";
import PhoneInput from 'react-phone-number-input';
import { ApplicationName } from "../../Models/Models";
import ControllerContext from "../../Contexts/ControllerContexts";
import { AuthInviteItem } from "../../Pages/Auth/AuthView";


interface JumpinViewProps {
    animateView: boolean;
}
const JumpInView = ({ animateView }: JumpinViewProps) => {
    
    return (
        <div className="auth-jump-in-container" style={{
            transform: animateView ? "translateX(0)" : "translateX(100vw)"
        }}>
            { (window.innerWidth < 1084) ?
                <AuthInviteItem />
                :
                <AuthCard/>
            }
        </div>
    )
}

export default JumpInView;

interface CloseJumpInPopupProp {
    setShowJumpInSignUp? : React.Dispatch<React.SetStateAction<boolean>>
}

export const CloseJumpInPopup = ({ setShowJumpInSignUp } : CloseJumpInPopupProp) => {
	const { toggleJumpInSignUp } = useContext(ControllerContext)
   return( 
    <button className="close-jumpin-button" onClick={() => { 
        toggleJumpInSignUp(false);
        if (setShowJumpInSignUp) {
            setShowJumpInSignUp(false);
        }
    }}>
        <label>x</label>
    </button>
   )
}




const AuthCard = () => {
    async function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            // Passing In Empty Values Once A New User Attempts to Sign In... They can
            window.location.reload();
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }
    // Spend sometime jumping back into the zone...
    return (
        <div className="auth-card">
            <div className="auth-card-container">
                <h1 style={{marginBottom: "8px"}} dangerouslySetInnerHTML={{__html: `
                    <span>
                        Sign In to 
                        <span style="color:var(--app-blue); font-family:'Cabin Sketch' !important">${ ApplicationName }</span> 
                    </span>`}}/>
                    <p style={{
                        margin: "20px 0 12px 0",
                        maxWidth: "calc(100% - 200px)",
                        fontSize: "18px",
                        color: "rgba(0,0,0,0.5)",
                        textAlign: "center",
                        fontWeight: "600",
                    }}>A Social Community App</p>
                    <p style={{
                        maxWidth: "calc(100% - 200px)",
                        fontSize: "24px",
                        color: "rgba(0,0,0,0.85)",
                        textAlign: "center",
                        fontWeight: "600",
                    }}>Built for people to share and celebrate everything bright & nerdy.</p>
                <PhoneNumberSignIn/>
                <label style={{ marginTop: "10px" }}>- or- </label>
                <button className="google-auth-button" onClick={()=> { signInWithGoogle(); }}>
                    <img src={ GoogleLogo }/>
                    <label>Google</label>
                </button>
            </div>
        </div>
    );
}



export const PhoneNumberSignIn = () => {
    const [ phoneNumber, setPhoneNumber ] = useState<string>("");
    const [ confirmationNumber, setConfirmationNumber ] = useState<string>("");
    const [ confirmationResult, setConfirmationResult ] = useState<ConfirmationResult | undefined>(undefined);

    useEffect(()=> { 
        // @ts-ignore
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
        // appVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {'callback': (response) => { console.log("recaptchaVerifier response: ", response); } });
    }, []);

    async function signInViaPhoneNumber() {
        // @ts-ignore
        signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier )
            .then((confirmationRes) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            // @ts-ignore
            window.confirmationResult = confirmationRes;
            setConfirmationResult(_=> { return confirmationRes; });
            // ...
            }).catch((error) => {
            // Error; SMS not sent
            // ...
        });
    }
    
    function ConfirmCode() {
        // @ts-ignore
        if(confirmationResult) {
            confirmationResult.confirm(confirmationNumber).then((result) => {
                // User signed in successfully.
                const user = result.user;
                // ...
                // Now That We Have A New User We Have To Take Them To The New User Page
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
            });
        }
    }

    return (
        <div className="phone-sign-in-up" style={{ display:"flex", flexDirection: "column"}}>
            { (!confirmationResult) &&
                <span className="phone-number-code-container">
                    <label className="phone-number-label" htmlFor="phone-number">Sign In / Up via Phone Number</label>
                    {/*  +1 7062482304 */}
                    {/* <input placeholder="Phone Number..." type={ "text" } value={phoneNumber} onChange={ e => { setPhoneNumber( _=> { return e.target.value }) } }/> */}
                    <PhoneInput placeholder="Phone #" value={phoneNumber} onChange={setPhoneNumber}/>
                    <button className="phone-sign-in-up-submit-button"  onClick={()=> { signInViaPhoneNumber(); }}>Next</button>
                </span>
            }
            { confirmationResult &&
                <span className="confirmation-code-container">
                    <label htmlFor="confirmation-code">Enter The Confirmation Number</label>
                    <input id="confirmation-code" placeholder="000000" type={ "text" } value={ confirmationNumber } onChange={ e => { setConfirmationNumber( _=> { return e.target.value }) } }/>
                    <button className="phone-sign-in-up-submit-button" onClick={()=> { ConfirmCode(); }}>Done</button>
                </span>
            }
            <div id="recaptcha-container" className="justify-center flex" style={{ marginTop: "20px",}}/>
        </div>
    );
}


export const ExistingUser_test_email_password = () => {
    const userID  = auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : undefined;
    const [ email, setEmail ] = useState<string>("");
    const [ password, setPassword ] = useState<string>("");
    return (
        <div style={{ position:"relative", bottom: "0px", display: "flex", opacity: "0.025", transform: "translate( 0px ,calc(100% + 20px))" }}>
            <input style={{ background: "rgba(255,255,255,0.75)", border: "none", color: "black", marginLeft: "4px" }} type={"email"} placeholder="person@email.com" onChange={e => { setEmail(_=> { return e.target.value; }); } }/>
            <input style={{ background: "rgba(255,255,255,0.75)", border: "none", color: "black", marginLeft: "4px" }} type={"password"} placeholder="*******" onChange={e => { setPassword(_=> { return e.target.value; }); } }/>
            <button style={{ background: "rgba(255,255,255,0.75)", border: "none", color: "black", marginLeft: "4px" }} onClick={() => {
                if ( email.trim() !== "" && password.trim() !== "" ) {
                    signInWithEmailAndPassword(auth, email, password).then(cred => {
                        const userID : string | undefined = cred ? cred.user.uid : undefined;
                        if (userID) {
                            window.location.href = "/"
                        }
                    })
                }
            }}>submit</button>
        </div>
    )
}