import { MathParser_New, ChemistryModeParse, CodeModeParse, DefaultModeParse } from "../app/Components/TextTransformers/MathModeTransformer";
import { TranscriptionModeType } from "../app/fb/dbMethods";
function uuidString() {
    let uuidOptions = "aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ"
    let uuidString = "";
    for(let x = 0; x < 80; x++) {
        let position = Math.floor(Math.random() * (uuidOptions.length - 1) );
        uuidString = uuidString + uuidOptions[ position ];
    }
    return uuidString;
}

function uuidGenerator(): string {
    //@ts-ignore
    const uuid = uuidString();//Array.from(Array(16)).map(e => Math.floor(Math.random() * 255).toString(16).padStart(2,"0")).join('').match(/.{1,4}/g).join('');
    const stringUUID = `${uuid}`;
    return stringUUID;
}

export function AddingEnters(text: string, transformationType?: TranscriptionModeType): string {
	let transformedText = text;
    switch (transformationType) {
        case TranscriptionModeType.Math:
            MathParser_New(text, (transformedTextRet) => {
                transformedText = transformedTextRet; 
            });
            break;
        case TranscriptionModeType.Chemistry:
            ChemistryModeParse(text, (transformedTextRet) => {
                transformedText = transformedTextRet; 
            });
        break;

        case TranscriptionModeType.Code:
            CodeModeParse(text, (transformedTextRet) => {
                transformedText = transformedTextRet; 
            });
        break;
    
        default:
            DefaultModeParse(text, (transformedTextRet) => {
                transformedText = transformedTextRet; 
            });
        break;
    }
	return transformedText;
}

export default uuidGenerator;