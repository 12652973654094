import { db, storage, auth } from "../fb/fbSetup";
import { doc, collection ,updateDoc, setDoc, getDoc, query, limit, orderBy, getDocs, arrayUnion, increment, runTransaction } from "firebase/firestore";
import { ContentModel, Posts_Coll_And_Doc_Path, userprofile_storage_path } from "../fb/dbMethods";
import uuidGenerator from "../../SharedItems/UUIDGenerator";

export interface InteractionsDocModel {
    date_doc_created: Date;
    interactions: InteractionsModel[];
}

export interface InteractionsModel {
    dateAdded : Date;
    postID: string;
    type: InteractionTypeOptions;
    interaction_stored_document: string;
}

export enum InteractionTypeOptions {
    like = "like",
    dislike = "dislike",
    funny = "funny",
    smart = "smart",
    sad = "sad",
    mindBlown = "mind-blown",
    proudOfYou = "proud-of-you",
    yert = "yert",
    fingersCrossed = "fingers-crossed",
    love = "love",
}

export const InteractionCollectionRef = "_I_N_T_E_R_A_C_T_I_O_N_S";
export const MaxNumberOfInteractionInADoc = 50000; // Currently set to 50,000
/**
 * AddItemToYourInteraction: * This method adds a new item to a users interactions
 * @param userID : string
 * @param postID : string
 * @param interaction : InteractionTypeOptions
 * @param callBack : (sucess: boolean) => void
 */
export async function AddItemToYourInteraction(userID: string, postID: string, interaction: InteractionTypeOptions, callBack: (sucess: boolean) => void) {
    const userInteractionPath = `${ userprofile_storage_path }/${ userID }/${ InteractionCollectionRef }`;
    const userFavoritesRef = collection(db, userInteractionPath);
    const mostRecentFavoritesDoc = query(userFavoritesRef, orderBy("date_doc_created","desc"), limit(1));
    const getMostRecentInteractionsDoc = await getDocs(mostRecentFavoritesDoc);
    if (getMostRecentInteractionsDoc.size > 0) {
        getMostRecentInteractionsDoc.forEach( async document => {
            const interactionDoc = document.data() as InteractionsDocModel;
            if (interactionDoc.interactions.length < MaxNumberOfInteractionInADoc) {
                const firstFavoritesDocRef = `${ userInteractionPath }/${ document.id }`;
                const firstTimeInteractionRef = await updateDoc(doc(db, firstFavoritesDocRef), {
                    interactions: arrayUnion({ dateAdded: new Date(), postID: postID, type: interaction,  interaction_stored_document: document.id })
                })
                .then(status => {
                    callBack(true);
                })
                .catch(err => {
                    callBack(false);
                })


            } else {
                // Adding A New Document Because Favorites Has Reached It's Max Size
                const newDocumentID = uuidGenerator();
                const firstFavoritesDocRef = `${ userInteractionPath }/${ newDocumentID }`
                const interactionsDocObject: InteractionsDocModel = {
                    date_doc_created: new Date(),
                    interactions: [ 
                        { dateAdded: new Date(), postID: postID, type: interaction, interaction_stored_document: newDocumentID } 
                    ]
                }
                const firstTimeFavoritesRef = setDoc(doc(db, firstFavoritesDocRef), interactionsDocObject)
                .then(status => {
                    callBack(true);
                })
                .catch(err => {
                    callBack(false);
                });
            }// End of if else for item reaching its max count
        }) // End of forEach loop of all the 1 document tha that should be being called for getting the most recent Interactions doc
    } else {
        // This is for the Interactions list that is created if the user doesn't / hasn't stored any Interactions on their user model yet.
        const newDocumentID = uuidGenerator();
        const firstFavoritesDocRef = `${ userInteractionPath }/${ newDocumentID }`
        const interactionsDocObject: InteractionsDocModel = {
            date_doc_created: new Date(),
            interactions: [ 
                
                { dateAdded: new Date(), postID: postID, type: interaction, interaction_stored_document: newDocumentID } 
            ]
        }
        const firstTimeFavoritesRef = setDoc(doc(db, firstFavoritesDocRef), interactionsDocObject)
        .then(status => {
            callBack(true);
        })
        .catch(err => {
            callBack(false);
        })
    }// End of else statment for the condition where the user is saving an item to Interactions for the first time
}

export async function _GetAllOfUsersInteractions(userID: string, callBack: (sucess: boolean, interactions: InteractionsModel[]) => void) {
    const userFavoritesRef = `${ userprofile_storage_path }/${ userID }/${ InteractionCollectionRef }`;
    const userFavoritesCollection = collection(db, userFavoritesRef);
    const FavoritesQuery = query(userFavoritesCollection, orderBy("date_doc_created","desc"));
    const getUserFavoritesDoc = await getDocs(FavoritesQuery);
    // Need Items for loop
    let interactionDocCounter: number = 0;
    let interactionsList: InteractionsModel[] = [];
    if (getUserFavoritesDoc.size > 0) {
        getUserFavoritesDoc.forEach(document => {
            const data: InteractionsDocModel = document.data() as InteractionsDocModel;
            const dateToJSDateList = data.interactions.map(item => {
                let cleanDate : any = item.dateAdded;
                cleanDate = cleanDate.toDate();
                const interactionDateTime = new Date(cleanDate);
                const newFavorite: InteractionsModel = { 
                    dateAdded: interactionDateTime,
                    postID: item.postID,
                    type: item.type,
                    interaction_stored_document: item.interaction_stored_document,
                }
                return newFavorite;
            });

            interactionDocCounter = interactionDocCounter + 1;
            if (interactionDocCounter >= getUserFavoritesDoc.size) {
                let newList : InteractionsModel[] = [ ...interactionsList, ...dateToJSDateList ];
                interactionsList = newList;
                //@ts-ignore
                interactionsList = interactionsList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                callBack( true, interactionsList );
            } else {
                let newList : InteractionsModel[] = [ ...interactionsList, ...dateToJSDateList ];
                interactionsList = newList;
            }
        });
    }
}



export function intereraction_IncrementField( type: InteractionTypeOptions ) : ({ [key: string]: any } | undefined)  {
    switch(type) {
        case InteractionTypeOptions.like:
            return { like : increment(1) };
            break;
        case InteractionTypeOptions.dislike:
            return { dislike : increment(1) };
            break;
        case InteractionTypeOptions.funny:
            return { funny : increment(1) };
            break;
        case InteractionTypeOptions.smart:
            return { smart : increment(1) };
            break;
        case InteractionTypeOptions.sad:
            return { sad : increment(1) };
            break;
        case InteractionTypeOptions.mindBlown:
            return { mindBlown : increment(1) };
            break;
        case InteractionTypeOptions.proudOfYou:
            return { proudOfYou : increment(1) };
            break;
        case InteractionTypeOptions.yert:
            return { yert : increment(1) };
            break;
        case InteractionTypeOptions.fingersCrossed:
            return { fingersCrossed : increment(1) };
            break;
        case InteractionTypeOptions.love:
            return { love : increment(1) };
            break;
        default:
            return undefined;
            break;
    }
}

export function intereraction_DecrementField( type: InteractionTypeOptions ) : ({ [key: string]: any } | undefined) {
    switch(type) {
        case InteractionTypeOptions.like:
            return { like : increment(-1) };
            break;
        case InteractionTypeOptions.dislike:
            return { dislike : increment(-1) };
            break;
        case InteractionTypeOptions.funny:
            return { funny : increment(-1) };
            break;
        case InteractionTypeOptions.smart:
            return { smart : increment(-1) };
            break;
        case InteractionTypeOptions.sad:
            return { sad : increment(-1) };
            break;
        case InteractionTypeOptions.mindBlown:
            return { mindBlown : increment(-1) };
            break;
        case InteractionTypeOptions.proudOfYou:
            return { proudOfYou : increment(-1) };
            break;
        case InteractionTypeOptions.yert:
            return { yert : increment(-1) };
            break;
        case InteractionTypeOptions.fingersCrossed:
            return { fingersCrossed : increment(-1) };
            break;
        case InteractionTypeOptions.love:
            return { love : increment(-1) };
            break;
        default:
            return undefined;
            break;
    }
}

export async function AddInteraction(
    userID: string,
    post: ContentModel,
    incremetnObject: ({ [key: string]: number} | undefined),
    decrementObject: ({ [key: string]: number} | undefined),
    constItemToRemove? : InteractionsModel,
    callBack? : (status: boolean) => void
) {
    const InteractionRef = `${ Posts_Coll_And_Doc_Path }/${ post.documentID }`;
    const InteractionDocRef = doc(db, InteractionRef) 
        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(InteractionDocRef);
                if (!sfDoc.exists()) {
                throw "Document does not exist!";
                }

                let newObject = {};
                newObject = { ...newObject , ...post };
                if (incremetnObject) {
                    newObject = { ...newObject, ...incremetnObject };
                } 
                if (decrementObject) {
                    newObject = { ...newObject, ...decrementObject };
                    RemoveInteractionFromInteractionList( userID, constItemToRemove, callBack);
                }

                transaction.update( InteractionDocRef, newObject );
            });
            if (!decrementObject) { callBack(true); }
        } catch (e) {
        }
}

async function RemoveInteractionFromInteractionList(
    userID: string, 
    interactionItem: InteractionsModel, 
    callBack : (status: boolean) => void
) {
    const interactionStringRef = `${ userprofile_storage_path }/${ userID }/${ InteractionCollectionRef }/${ interactionItem.interaction_stored_document }`;
    const InteractionDocRef = doc(db, interactionStringRef);
        try {
            await runTransaction(db, async (transaction) => {
                const interactionDoc = await transaction.get(InteractionDocRef);
                if (!interactionDoc.exists()) {
                throw "Document does not exist!";
                }

                let data = interactionDoc.data() as InteractionsDocModel;
                let newInteractionModel = { ...data }; 
                let interactionList = data.interactions.filter(item => {
                    if((interactionItem.postID !== item.postID) && (interactionItem.type === item.type)) {
                        return item;
                    }
                });
                newInteractionModel = { ...newInteractionModel, interactions: interactionList };
                transaction.update(InteractionDocRef, newInteractionModel);
            });
            callBack(true);
        } catch (e) {
        }
}