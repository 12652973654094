import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useState, useEffect, useContext } from "react";
import ControllerContext from "../../Contexts/ControllerContexts";
import UserContext from "../../Contexts/UserContext";
import { AddComment } from "./Comments_DB_Methods";
import { ContentModel, userprofile_Landing_storage_path, userprofile_storage_path } from "../../fb/dbMethods";
import { db, storage } from "../../fb/fbSetup";
import { CurrentUserDataModel } from "../../Models/Models";
import './CommentSection.css';
import { CommentDocModel, CommentModel, getAllComments, getAllNestedComments_for_this_comment, ReplyToComoment } from "./Comments_DB_Methods";
import ColorUI from "../ColorUI/ColorUI";
dayjs.extend(relativeTime)

const CommentSectionView = () => {
    const { userData } = useContext(UserContext);
    const [ comment, setComment ] = useState<string>("");
    const [ thereAreComments , setThereAreComments ] = useState<boolean>(true);
    const { contentModel , showCommentSection , showCommentSectionPopOut } = useContext(ControllerContext);
    const [ comments, setComments ] = useState<CommentModel[]>([]);
    //
    //
    const [ animateView, setAnimateView ] = useState<boolean>(false);
    const [ renderView, setRenderView ] = useState<boolean>(false);
    // const [ animateView, setAnimateView ] = useState<boolean>(true);
    
    useEffect(()=>{
        if (showCommentSection) {
            // Animate View In
            setRenderView(showCommentSection);
            setTimeout(() => {
                 setAnimateView(_=> { return true; }); 
            }, 350);
            // Load Comments
            getAllComments(contentModel, (status, commentList) => {
                if (status) {
                    setComments(_=> { return commentList});
                    if (commentList.length < 1) setThereAreComments( b => { return false });
                    if (commentList.length > 0) setThereAreComments( b => { return true });
                }
            });
        } else {
            setAnimateView(_=> { return false; }); 
            setTimeout(() => {
                setRenderView(showCommentSection);
                setComments([]);
            }, 350);
        }
    }, [ showCommentSection ])

    return (
        <>
            { renderView &&
                <div className="commentSectionView" style={{ width: "100vw" }}>
                    <div className="comment-section-click-away" style={{ backgroundColor: animateView ? "rgba(0, 0, 0, 0.2)": "rgba(0, 0, 0, 0.0)"}}  onClick={()=>{ 
                        setAnimateView(_=> { return false; });
                        showCommentSectionPopOut(false, contentModel);
                    }}/>
                    <div className='comment-section-container' style={{ right: animateView ? "0vw" : "-50vw"}}>
                        <div style={{ position: "absolute", top: "0", left: "0" }}>
                            <ColorUI/>
                        </div>
                        <h2 className="comment-section-title">{`${""}Comments:`}</h2>
                        {(contentModel && contentModel.title) && <h4 style={ { marginTop: "12px", color: "var(--app-blue)"} }>{ contentModel.title }</h4>}
                        {/* <textarea/> */}
                        { showCommentSection &&
                            <div className="comment-section-content">
                                <div className="comment-list-container">
                                    { (thereAreComments === true) ?
                                        comments.map((comment, index) => {
                                            return ( 
                                                <CommentItem contentModel={ contentModel } commentModel={ comment } index={ index }/>
                                            )
                                        })
                                        :
                                        <h4 style={{ 
                                            color: "gray", 
                                            fontSize: "14px",
                                            marginTop: "8px",
                                        }}>😕 Sorry, there are no comments for this post.</h4>
                                    }
                                </div>
                            </div>
                            
                        }
                        { (contentModel) &&
                            <div className="comment-container">
                                <div className='comment-send-button-container'>
                                    <textarea className='comment-area' placeholder='Comment...' value={ comment } onChange={e=> { setComment(_=> { return e.target.value; })}} />
                                    <button className='comment-area-send-button' onClick={()=> { 
                                        AddComment(contentModel, comment, userData);
                                        setComment(_=> { return ""; })
                                    }}>send</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default CommentSectionView;

interface CommentItemModel {
    contentModel: ContentModel;
    commentModel: CommentModel;
    index: number;
}
export const CommentItem = ({ contentModel, commentModel, index }: CommentItemModel) => {
    const { userData } = useContext(UserContext)
    const [ CommentUser, setCommentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    const [ showReplies, setShowReplies ] = useState<boolean>(false);
    const [ writeReply, setWriteReply ] = useState<boolean>(false);
    const [ reply, setReply ] = useState<string>("");
    useEffect(()=>{
        getCommentUserData();
        getCommentUserProfileImage();
    },[]);
    async function getCommentUserData(){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ commentModel.userID }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                setCommentUser(_=> { return data; });
            }
        })
        .catch( err => {
        });
    }

    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${commentModel.userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    return (
        <div className="comment-item" key={ index }>
            <div className="comment-user-info-and-date">
                { CommentUser &&
                    <div className="comment-user-profile-info">
                        { imageSrc &&
                            <div className="comment-user-profile-image-container">
                                <img loading={"lazy"} src={ imageSrc }/>
                                <span className="image-cover"/>
                            </div>
                        }
                        <div className="first-last-username">
                            <h5 className="first-last">{ `${CommentUser.firstName} ${CommentUser.lastName}` }</h5>
                            <label className="username">{ `@${CommentUser.customUserName}` }</label>
                        </div>
                    </div>
                }
                <label className="time-of-post">{ `${ dayjs(commentModel.dateOfComment).fromNow() }` }</label>
            </div>
            <label className="comment-text">{ commentModel.comment }</label>
            <div className="comment-reply-and-replies">
                <div className="reply-top-comment-container">
                    { writeReply && <textarea className="reply-textarea" placeholder="reply..." value={ reply } autoFocus={ true } onChange={e => { setReply(_=> { return e.target.value; } ) }}/> }
                    
                    <div className="reply-button-containers">
                        {/* Reply Button */}
                        <button 
                            className={`reply-to-comment-button${ (reply.trim() !== "") ? " active" : "" }` }
                            style={{
                                backgroundColor: (reply.trim() !== "") ? "var(--app-green-80)" : "rgba(0,0,0,0.05)",
                                color: (reply.trim() !== "") ? "white" : "black",
                            }}
                            onClick={()=> {
                            if (reply.trim() !== "") {
                                ReplyToComoment(contentModel, commentModel, userData.userID, reply);
                                setReply(_=> { return ""; });
                                setWriteReply(_=> { return false; });
                            } else {
                                setWriteReply(b => { return true; });
                            }
                        }}>reply</button>
                    </div>

                    {/* Cancel */}
                    {writeReply && 
                        <button className="cancel-reply" onClick={()=> {
                            setReply(_=> { return ""; });
                            setWriteReply(_=> { return false; });
                        }}>cancel</button> 
                    }
                </div>
                { (commentModel.responseRefferenced.hasReplies) &&
                    <div className="comment-replies">
                        <button className="show-replies-button" onClick={() => {
                            setShowReplies(b=> { return !b; });
                        }}>{ showReplies ? "hide replies" : "show replies" }</button>
                        { (showReplies) &&
                            <NestedCommentView post={ contentModel } commentModel={ commentModel }/>
                        }
                    </div>
                }
            </div>
        </div>  
    )
}

interface NestedCommentViewModel{
    post: ContentModel;
    commentModel : CommentModel;
}
const NestedCommentView = ({post, commentModel}: NestedCommentViewModel) => {
    const [ comments, setComments ] = useState< CommentModel[] | undefined >([]);
    useEffect(() => {
        getAllNestedComments_for_this_comment(post, commentModel, (status, commentList) => {
            if (status) {
                setComments(_=> { return  commentList; });
            }
        })
    }, []);
    return (
        <div>
            <div className="replies-container">
                { 
                    comments.map((commentModel,index) => {
                        return (<NestedCommentItem commentModel={ commentModel }/>)
                    })
                }
            </div>
        </div>
    )
}

interface NestedCommentItemModel {
    commentModel: CommentModel;
}
const NestedCommentItem = ({ commentModel,}: NestedCommentItemModel ) => {
    const { userData } = useContext(UserContext)
    const { contentModel , showCommentSection , showCommentSectionPopOut } = useContext(ControllerContext);
    const [ CommentUser, setCommentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    const [ showReplies, setShowReplies ] = useState<boolean>(false);
    const [ writeReply, setWriteReply ] = useState<boolean>(false);
    const [ reply, setReply ] = useState<string>("");
    useEffect(()=>{
        getCommentUserData();
        getCommentUserProfileImage();
    },[]);
    async function getCommentUserData(){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ commentModel.userID }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                setCommentUser(_=> { return data; });
            }
        })
        .catch( err => {
        });
    }

    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${commentModel.userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }
    return (
        <div className="comment-container-nested">
            <div className="comment-user-info-and-date">
                { CommentUser &&
                    <div className="comment-user-profile-info">
                        { imageSrc &&
                            <div className="comment-user-profile-image-container">
                                <img loading={"lazy"} src={ imageSrc }/>
                                <span className="image-cover"/>
                            </div>
                        }
                        <div className="first-last-username">
                            <h5 className="first-last">{ `${CommentUser.firstName} ${CommentUser.lastName}` }</h5>
                            <label className="username">{ `@${CommentUser.customUserName}` }</label>
                        </div>
                    </div>
                }
                <label className="time-of-post">{ `${ dayjs(commentModel.dateOfComment).fromNow() }` }</label>
            </div>
            <label className="comment-text">{ commentModel.comment }</label>
        </div>
    )
}