import { db } from "../../fb/fbSetup";
import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, runTransaction } from "firebase/firestore";
import { Comments_Coll_And_Doc_Path, Comments_Replies_Coll_And_Doc_Path, ContentModel, Posts_Coll_And_Doc_Path } from "../../fb/dbMethods";
import { CurrentUserDataModel } from "../../Models/Models";
import uuidGenerator from "../../../SharedItems/UUIDGenerator";


export interface CommentDocModel {
    date_doc_created: Date;
    comments: CommentModel[];
}

export interface LikeDislikeObj {
    [ key: string ] : LikeDislike;
}

export interface LikeDislike {
    likeCount: number;
    dislikeCount: number;
}

export interface CommentModel {
    commentID?: string;
    userID: string;
    comment: string;
    responseRefferenced: ResponseRefferenced;// Should be a reference to docs of  comments related to the post in a nested manner
    dateOfComment: Date;
    documentIDRef: string;
    isNested? : boolean,
}

export interface ResponseRefferenced {
    docRepliesRefferenceID: string;
    hasReplies: boolean;
}

const MaxCommentSize = 50000;
// https://stackoverflow.com/questions/59671657/how-do-i-retrieve-the-most-recent-document-added-to-a-collection
export async function AddComment(post: ContentModel, comment: string, userModel: CurrentUserDataModel) {
    //const multiDocumentQuery = query(collectionRef, limit(10), orderBy("dateOfPost", orderDirectionDesc ? "desc" : "asc") );
    const commentCollectionRef = collection(db, `${Posts_Coll_And_Doc_Path}/${post.documentID}/${Comments_Coll_And_Doc_Path}`);
    const MostRecentDoc = query(commentCollectionRef, orderBy("date_doc_created", "desc"), limit(1));
    const getCommentSourceDoc = await getDocs(MostRecentDoc)
    getCommentSourceDoc.forEach(async (document) => {

        const commentDocModel = document.data() as CommentDocModel;
        if (commentDocModel && commentDocModel.comments && commentDocModel.comments.length < MaxCommentSize) {
            const mostRecentCommentRef = doc(db, `${Posts_Coll_And_Doc_Path}/${post.documentID}/${Comments_Coll_And_Doc_Path}/${document.id}`);
            const docRepliesRef =  uuidGenerator();
            const docUpdate = await updateDoc(mostRecentCommentRef, {
                comments: arrayUnion({
                    commentID: uuidGenerator(),
                    userID: userModel.userID,
                    comment: comment,
                    responseRefferenced: {
                        docRepliesRefferenceID: docRepliesRef,
                        hasReplies: false,
                    },
                    documentIDRef: document.id,                 
                    dateOfComment: new Date(),
                    isNested: false,
                }),
            })
        } else {
            const commentDocID = uuidGenerator();
            const newCommentDocumentRef = doc(db, `${ Posts_Coll_And_Doc_Path }/${ post.documentID }/${ Comments_Coll_And_Doc_Path }/${ commentDocID }`);
            const commnetDocumentObj: CommentDocModel = {
                date_doc_created: new Date(),
                comments : [
                    {
                        commentID: uuidGenerator(),
                        userID: userModel.userID,
                        comment: comment,
                        responseRefferenced: {
                            docRepliesRefferenceID: uuidGenerator(),
                            hasReplies: false,
                        },
                        dateOfComment: new Date(),
                        documentIDRef: commentDocID,
                        isNested: false,
                    }
                ]
            }
            const addingNewCommentDocument = setDoc(newCommentDocumentRef, commnetDocumentObj)
            .then(status => {
            })
            .catch(err => {
            })
        }
    })
}

export async function _AddComment_(post: ContentModel, comment: string, userModel: { userID: string}, method: () => void) {
    //const multiDocumentQuery = query(collectionRef, limit(10), orderBy("dateOfPost", orderDirectionDesc ? "desc" : "asc") );
    const commentCollectionRef = collection(db, `${Posts_Coll_And_Doc_Path}/${post.documentID}/${Comments_Coll_And_Doc_Path}`);
    const MostRecentDoc = query(commentCollectionRef, orderBy("date_doc_created", "desc"), limit(1));
    const getCommentSourceDoc = await getDocs(MostRecentDoc)
    getCommentSourceDoc.forEach(async (document) => {

        const commentDocModel = document.data() as CommentDocModel;
        if (commentDocModel && commentDocModel.comments && commentDocModel.comments.length < MaxCommentSize) {
            const mostRecentCommentRef = doc(db, `${Posts_Coll_And_Doc_Path}/${post.documentID}/${Comments_Coll_And_Doc_Path}/${document.id}`);
            const docRepliesRef =  uuidGenerator();
            const docUpdate = await updateDoc(mostRecentCommentRef, {
                comments: arrayUnion({
                    commentID: uuidGenerator(),
                    userID: userModel.userID,
                    comment: comment,
                    responseRefferenced: {
                        docRepliesRefferenceID: docRepliesRef,
                        hasReplies: false,
                    },
                    documentIDRef: document.id.trim(),                 
                    dateOfComment: new Date(),
                    isNested: false,
                }),
            })
        } else {
            const commentDocID = uuidGenerator();
            const newCommentDocumentRef = doc(db, `${ Posts_Coll_And_Doc_Path }/${ post.documentID }/${ Comments_Coll_And_Doc_Path }/${ commentDocID }`);
            const commnetDocumentObj: CommentDocModel = {
                date_doc_created: new Date(),
                comments : [
                    {
                        commentID: uuidGenerator(),
                        userID: userModel.userID,
                        comment: comment,
                        responseRefferenced: {
                            docRepliesRefferenceID: uuidGenerator(),
                            hasReplies: false,
                        },
                        dateOfComment: new Date(),
                        documentIDRef: commentDocID.trim(),
                        isNested: false,
                    }
                ]
            }
            const addingNewCommentDocument = setDoc(newCommentDocumentRef, commnetDocumentObj)
            .then(status => {
                method();
            })
            .catch(err => {
                method();
            })
        }
    })
}

// The Plan Here is to get the most recent comment doc an update it without having to make a source doc to read... will work on it, may in the future be a object as opposed to an array.
export async function getAllComments(post: ContentModel, callBack:(sucess: boolean, comments: CommentModel[]) => void) {
    const commentCollectionRef = collection(db, `${Posts_Coll_And_Doc_Path}/${ post.documentID}/${Comments_Coll_And_Doc_Path}`);
    const commentDocs = query(commentCollectionRef, orderBy("date_doc_created", "desc"));
    const getComments = await getDocs(commentDocs);
    let commentDocCounter: number = 0;
    let commentsList: CommentModel[] = [];
    getComments.forEach(async (document) => {
        const data: CommentDocModel = document.data() as CommentDocModel;

        const dateToJSDateList = data.comments.map(item => {
            let cleanDate : any = item.dateOfComment;
            cleanDate = cleanDate.toDate();
            const commentDateTime = new Date(cleanDate);
            const newComment: CommentModel = {
                commentID: item.commentID,
                comment: item.comment, 
                dateOfComment: commentDateTime,
                responseRefferenced: item.responseRefferenced,
                userID: item.userID,
                documentIDRef: document.id,
                isNested: item.isNested,
            }
            return newComment;
        });

        commentDocCounter = commentDocCounter + 1;
        if (commentDocCounter >= getComments.size) {
            let newList : CommentModel[] = [ ...commentsList, ...dateToJSDateList ];
            commentsList = newList;
            //@ts-ignore
            commentsList = commentsList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
            callBack( true, commentsList );
        } else {
            let newList : CommentModel[] = [ ...commentsList, ...dateToJSDateList ];
            commentsList = newList;
        }

        
    });

}


export async function ReplyToComoment(post: ContentModel, commentModel: CommentModel, currentUser: string, comment: string) {
    const baseRef = `${Posts_Coll_And_Doc_Path}/${ post.documentID }`;

    const repliesStringRef = `${ baseRef }/${ commentModel.isNested ? Comments_Replies_Coll_And_Doc_Path : Comments_Coll_And_Doc_Path }/${ commentModel.documentIDRef }`;

    const repliesDocRef = doc(db, repliesStringRef);

    if (commentModel.responseRefferenced.hasReplies === false) {
        try {
            await runTransaction(db, async (transaction) => {
            const transDoc = await transaction.get(repliesDocRef);
            if (!transDoc.exists()) {
                // throw "Document does not exist!";
            }
            const data = transDoc.data() as CommentDocModel;
            const newCommentArray: CommentModel[] = data.comments.map((item) => {
                if (item.responseRefferenced.docRepliesRefferenceID === commentModel.responseRefferenced.docRepliesRefferenceID) {
                    let newCommentItem = item;
                    newCommentItem.responseRefferenced.hasReplies = true;
                    return newCommentItem;
                } else {
                    return item;
                }
            })
            transaction.update(repliesDocRef, { comments: newCommentArray });
            });
            // Upload Nested Comment
            Upload_NestedComment(
                post,
                commentModel,
                currentUser,
                comment,
            )
        } catch (e) {
        }
    } else {
        Upload_NestedComment(
            post,
            commentModel,
            currentUser,
            comment,
        )
    }
}

async function Upload_NestedComment(post: ContentModel, commentModel: CommentModel, currentUser: string, comment: string) {
    // Upload Basics
    // The Base Ref Without the document Id for the condition to which thed document does not exist
    const baseRef = `${Posts_Coll_And_Doc_Path}/${ post.documentID }`;
    const collectionPathString = `${ baseRef }/${ Comments_Replies_Coll_And_Doc_Path }/${ commentModel.responseRefferenced.docRepliesRefferenceID }/${ Comments_Replies_Coll_And_Doc_Path }`
    const commentCollectionRef = collection(db, collectionPathString);
    const commentDocID = uuidGenerator();// Comment Doc ID For A New Doc ID under the condition that it is needed.
    let commentObj: CommentModel = {
        commentID: uuidGenerator(),
        userID: currentUser,
        comment: comment,
        responseRefferenced: {
            docRepliesRefferenceID: uuidGenerator(),
            hasReplies: false,
        },
        documentIDRef: "",                 
        dateOfComment: new Date(),
        isNested: true,
    }
    
    // Query Parameters
    // Query Parameters
    // Query Parameters
    // Grabbing One Document that was most recently updated and updating it or a new one based on the length of the message array being less or greater than the max message list size which is 50,000 at the time.
    const MostRecentDoc = query(commentCollectionRef, orderBy("date_doc_created", "desc"), limit(1));
    const getCommentSourceDoc = await getDocs(MostRecentDoc);
    // If there are any existing documents then update it in the fashion that one does for top level documents
    if (getCommentSourceDoc.size > 0) {
        getCommentSourceDoc.forEach(async (document) => {
            const commentDocModel = document.data() as CommentDocModel;
            if (commentDocModel && commentDocModel.comments && commentDocModel.comments.length < MaxCommentSize) {
                const mostRecentCommentRef = doc(db, `${ collectionPathString }/${ document.id }`);
                commentObj["documentIDRef"] = document.id;
                const docUpdate = await updateDoc(mostRecentCommentRef, {
                    comments: arrayUnion(commentObj),
                })
                .then(status => {  })
                .catch(err => { })
            } else {
                const newCommentDocumentRef = doc(db, `${ collectionPathString }/${ commentDocID }`);
                commentObj["documentIDRef"] = commentDocID;
                const commnetDocumentObj: CommentDocModel = {
                    date_doc_created: new Date(),
                    comments : [ commentObj ],
                }
                const addingNewCommentDocument = setDoc(newCommentDocumentRef, commnetDocumentObj)
                .then(status => {  })
                .catch(err => {  })
            }
        })
    } else {
        // If there are not any existing documents then add a document ... which often will be the case for a nested comment
        // I can use the same 'commentDocID' in this case becase the condition above was never met because no document existed at all.
        const newCommentDocumentRef = doc(db, `${ collectionPathString }/${ commentDocID }`);
        commentObj["documentIDRef"] = commentDocID;
        const commnetDocumentObj: CommentDocModel = {
            date_doc_created: new Date(),
            comments : [ commentObj ],
        }
        const addingACommentDocument = setDoc(newCommentDocumentRef, commnetDocumentObj)
        .then(status => { })
        .catch(err => { })
    }
}




async function UpadateHasRepliesStatusToCommentWithNoComments(post: ContentModel, commentModel: CommentModel,) {
    const baseRef = `${Posts_Coll_And_Doc_Path}/${ post.documentID }`;
    const repliesStringRef = `${ baseRef }/${ commentModel.isNested ? Comments_Coll_And_Doc_Path : Comments_Replies_Coll_And_Doc_Path }/${ commentModel.documentIDRef }`;
    const repliesDocRef = doc(db, repliesStringRef);
    try {
        await runTransaction(db, async (transaction) => {
        const transDoc = await transaction.get(repliesDocRef);
        if (!transDoc.exists()) {
            throw "Document does not exist!";
        }
        const data = transDoc.data() as CommentDocModel;
        const newCommentArray: CommentModel[] = data.comments.map((item) => {
            if (item.responseRefferenced.docRepliesRefferenceID === commentModel.responseRefferenced.docRepliesRefferenceID) {
                let newCommentItem = item;
                newCommentItem.responseRefferenced.hasReplies = true;
                return newCommentItem;
            } else {
                return item;
            }
        })
        transaction.update(repliesDocRef, { comments: newCommentArray });
        });
    } catch (e) {
    }
}

export async function getAllNestedComments_for_this_comment(post: ContentModel, commentModel: CommentModel, callBack:(sucess: boolean, comments: CommentModel[]) => void) {
    const baseRef = `${Posts_Coll_And_Doc_Path}/${ post.documentID }`;
    const commentCollectionRefString = `${ baseRef }/${ Comments_Replies_Coll_And_Doc_Path }/${ commentModel.responseRefferenced.docRepliesRefferenceID }/${ Comments_Replies_Coll_And_Doc_Path }`;
    const commentCollectionRef = collection(db, commentCollectionRefString);
    const commentDocs = query(commentCollectionRef, orderBy("date_doc_created", "desc"));
    const getComments = await getDocs(commentDocs);
    let commentDocCounter: number = 0;
    let commentsList: CommentModel[] = [];
    getComments.forEach(async (document) => {
        const data: CommentDocModel = document.data() as CommentDocModel;
        const dateToJSDateList = data.comments.map(item => {
            let cleanDate : any = item.dateOfComment;
            cleanDate = cleanDate.toDate();
            const commentDateTime = new Date(cleanDate);
            const newComment: CommentModel = { 
                comment: item.comment, 
                dateOfComment: commentDateTime,
                responseRefferenced: item.responseRefferenced,
                userID: item.userID,
                documentIDRef: document.id,
                isNested: item.isNested,
            }
            return newComment;
        });
        commentDocCounter = commentDocCounter + 1;
        if (commentDocCounter >= getComments.size) {
            let newList : CommentModel[] = [ ...commentsList, ...dateToJSDateList ];
            commentsList = newList;
            //@ts-ignore
            commentsList = commentsList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
            callBack( true, commentsList );
        } else {
            let newList : CommentModel[] = [ ...commentsList, ...dateToJSDateList ];
            commentsList = newList;
        }
    });
}

