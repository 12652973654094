import { useState, useEffect, useContext } from 'react';
import { auth } from '../../fb/fbSetup';
import UserContext, { UserContextProvider } from '../../Contexts/UserContext';
import './ProductView.css';
import ProductHeader from '../../Components/ProductHeader/ProductHeader';
import SideMenuContainer, { SideMenuOptions } from './SideMenu/SideMenu';
import BackgroundUI, { BackgroundTypes } from '../../Components/BackgroundUI/BackgroundUI';
import TestView from '../TestView/TestView';
import ProductMainConent from '../Home/Home';
import DirectMessages from '../Messages/DirectMessages';
import Profile from '../Profile/Profile';
import '../../Components/TextTransformers/MathModeStyles.css'
import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import { ApplicationName, PagePaths } from '../../Models/Models';
import ControllerContext, { ControllerContextProvider } from '../../Contexts/ControllerContexts';
import CommentSectionView from '../../Components/CommentSection/CommentSection';
import FavoritesView from '../FavoritesView/FavoritesView';
import DunbarColony from '../DunbarColony/DunbarColony';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import ViewLater from '../ViewLater/ViewLater';
import ConstitutionView, { ImageCloserLook } from '../../Components/ConstitutionView/ConstitutionView';
import AnonymousPostController from '../../Components/Anonymous/AnonymousPostController/AnonymousPostController';
import EditProfile from '../Profile/EditProfile/EditProfile';
import LiveSessionView from '../../Components/LiveSession/LiveSessionView/SimpleLiveSession';
import Theater from '../../Components/Theater/Theater';
import NotificationView from '../../Components/NotificationView/Notification';
import DesiredFeatures from '../../Components/DesiredFeatures/DesiredFeatures';
import StartDirectMessageController from '../../Components/StartDirectMessageController/StartDirectMessageController';
import VideoHistory from '../VideoHistory/VideoHistory';
import { useDetectAdBlock } from 'adblock-detect-react';
import logo from "../../../Resources/AppIcon.png"// "../../../Resources/AppIcon.png"
import { NOT_AN_AUTHENTICATED_USER } from '../../Contexts/unsignedUserDataModle';
import JumpInView, { CloseJumpInPopup } from '../../Components/AuthCard/AuthCard';
import LiveSessionView_SideMenu from '../../Components/LiveSession/SideMenuSimpleLiveSession.tsx/SideMenuSimpleLiveSession';
import SettingsView from '../../Components/SettingsView/SettingsView';
import BottomTabBar from '../../Components/BottomTabBar/BottomTabBar';
import SearchView from '../../Components/SearchView/SearchView';
import FilterPopUp from '../FilterView/FilterPopUp/FilterPopUp';

if (2 % 2 === 0) {
	//@ts-ignore  
	// import('./Mars.css');
}


function ProductView() {
	const [ userID, setUserID ] = useState<string | undefined>(undefined);
	useEffect(function(){
        onAuthStateChanged(auth, (user) => {
            if (user && user.uid && user.uid !== "undefined") {
				setUserID(user.uid);
				if (user) {
					//@ts-ignore  
					// import('./Mars.css');
				}
			} else {
				setUserID(_=> { return "NOT_AN_AUTHENTICATED_USER"; });
				signOut(auth)
				.then(val => {
					// window.location.href = "/auth"
				})
				.catch(err => {
					// window.location.href = "/auth"
				})
			}
        });
	}, []);

	enum DeviceType {
		Mobile,
		Tablet,
		Desktop,
		Unknown,
	}
	  
	  function detectDeviceType(): DeviceType {
			const screenWidth = window.innerWidth;
			const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
			const userAgent = navigator.userAgent.toLowerCase();

			if ((screenWidth < 768 && isTouchDevice) || /iphone|android|webos|blackberry|bb10|iemobile|opera mini/i.test(userAgent)) {
			return DeviceType.Mobile;
			} else if (screenWidth < 1024 || /ipad|tablet|kindle|playbook|silk/i.test(userAgent)) {
			return DeviceType.Tablet;
			} else {
			return DeviceType.Desktop;
			}
		}

	const adBlockDetected = useDetectAdBlock();
	useEffect(() => {
		if (detectDeviceType() !== DeviceType.Desktop) {
			// window.location.href = "/auth";
		}
	}, [])
	  
	return (
		<>
			{ userID &&
				<UserContextProvider>
					<ControllerContextProvider>
						<div className='product-with-color-ui'>
							<BackgroundUI type={ BackgroundTypes.ColorUI } color={ undefined } imageUrl={ undefined }/>
							{ (
								//detectDeviceType() === DeviceType.Desktop && 
								adBlockDetected === false) ?
								<ProductItems/>
								:
								<div
								style={{
									position:"absolute",
									top: "50%", left: "50%",
									width: "calc(100% - 80px)",
									display: "flex",
									flexDirection: "column",
									transform: "translate(-50%, -50%)",
								}}
								> 
									<div className='logo-container img-container'
									style={{ height: "70px", width: "70px", margin: "0 auto 20px auto"}}>
										<img src={ logo } />
										<div className='cover'></div>
									</div>
								{ adBlockDetected ?
									<h1 
									className='mvp-web-value'
									style={{
										position:"relative",
										height: "fit-content",
										width: "calc(100% - 10px)",
										zIndex: "10000000000000000",
										color: "var(--app-blue)",
										textAlign: "center",
										fontSize: "34px", 
										fontWeight: "600",
										background: "rgba(255,255,255, 0.15)",
										padding: "40px 20px",
										borderRadius: "20px",
										border: "0px solid rgba(0,0,0,0.05)",
										backdropFilter: "blur(10px)",
										boxShadow: "rgba(0, 0, 0, 0.025) 0px 48px 100px 0px"
									}}
								><span style={{ color: "var(--app-blue)"}}>😕 Sorry, </span>{ ApplicationName } <span style={{ color: "var(--app-blue)"}}>has to make money to stay operational,</span> so we don't allow ad blockers.</h1>
								:
									// <h1 
									// 	className='mvp-web-value'
									// 	style={{
									// 		position:"absolute",
									// 		top: "50%", left: "50%",
									// 		height: "fit-content",
									// 		width: "calc(100% - 80px)",
									// 		zIndex: "10000000000000000",
									// 		color: "var(--app-blue)",
									// 		fontFamily: `'Cabin Sketch'`,
									// 		textAlign: "center",
									// 		transform: "translate(-50%, -50%)",
									// 		fontSize: "34px", 
									// 		fontWeight: "600",
									// 		background: "rgba(255,255,255, 0.15)",
									// 		padding: "40px 20px",
									// 		borderRadius: "20px",
									// 		border: "0px solid rgba(0,0,0,0.05)",
									// 		backdropFilter: "blur(10px)",
									// 		boxShadow: "rgba(0, 0, 0, 0.025) 0px 48px 100px 0px"
									// 	}}
									// >{ ApplicationName } <br/> <span style={{ color: "var(--app-green)"}}>Web-Beta</span> <br/> Currently Must <br/>Be Viewed <br/>On Desktop.</h1>
									<></>
								}	
								</div>
							}
						</div>
					</ControllerContextProvider>
				</UserContextProvider>
			}
		</>
	);
}

export default ProductView;


// Map paths to their corresponding SideMenuOptions
const pathToMenuOption = {
    [PagePaths.Home]: SideMenuOptions.Home,
    [PagePaths.DunbarColony]: SideMenuOptions.DunbarColony,
	[PagePaths.LiveChatSession]: SideMenuOptions.LiveChat,
    [PagePaths.Messages]: SideMenuOptions.Messages,
    [PagePaths.Profile]: SideMenuOptions.Profile,
    [PagePaths.VideoHistory]: SideMenuOptions.History,
    [PagePaths.ViewLater]: SideMenuOptions.ViewLater,
    [PagePaths.Favorites]: SideMenuOptions.Favorites,
};

// Function to determine the initial state based on the current path
const getInitialSelectedItem = () => {
    const currentPath = window.location.pathname;
	const viewPath = pathToMenuOption[currentPath] // || SideMenuOptions.Home;
    return viewPath;
};

const ProductItems = () => {
	const { userData, init } = useContext(UserContext);
	const { showJumpInSignUp } = useContext(ControllerContext)
	const [ selectedItem , setSelectedItem ]  = useState<SideMenuOptions>(getInitialSelectedItem);
	const [ showSettings, setShowSetting ] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const tkn = urlParams.get('t');  
	const ud = urlParams.get('ud');

	useEffect(() => {
		if (tkn && ud) {
			SignInUserViaToken(tkn, ud);
		} else {
			init((auth.currentUser && auth.currentUser.uid) ? auth.currentUser.uid : NOT_AN_AUTHENTICATED_USER); 
		}
		if (auth.currentUser && auth.currentUser.uid) { 
			//
		} else if (!tkn && !ud) { signOut(auth); }
	}, [])


	function SignInUserViaToken(tkn, ud) {
        if (tkn) {
            signInWithCustomToken(auth, tkn)
            .then(sucess => {
                if (sucess.user.uid) {
					init(sucess.user.uid);
                }
                // sucess sign in with token.
            })
            .catch(error => {
            });
        }
    };

	// View Animation Controller:
	const [ animateView, setAnimateView ] = useState<boolean>(false);
	const [ renderView, setRenderView ] = useState<boolean>(false);
	useEffect(() => {
		if(showJumpInSignUp) {
			setRenderView(showJumpInSignUp);
			setTimeout(() => {
				setAnimateView(showJumpInSignUp);
			}, 150);
		} else {
			setAnimateView(showJumpInSignUp);
			setTimeout(() => {
				setRenderView(showJumpInSignUp);
			}, 400);
		}
	}, [ showJumpInSignUp ]);

	return (
		<>
			{ userData &&
					<>
						<div className="product">
							<ProductHeader appName={ ApplicationName }/>
							<div className="content-view">
								<SideMenuContainer selectedItem={ selectedItem } setSelectedItem={ setSelectedItem } setShowSetting={ setShowSetting }/>
								{ (selectedItem === SideMenuOptions.Home) && <ProductMainConent/> }
								{ (selectedItem === SideMenuOptions.DunbarColony) && <DunbarColony/>}
								{ (selectedItem === SideMenuOptions.LiveChat) && <LiveSessionView_SideMenu/> }
								{ (selectedItem === SideMenuOptions.Messages) && <DirectMessages/> } 
								{ (selectedItem === SideMenuOptions.Profile) && <Profile/> }
								{ (selectedItem === SideMenuOptions.History) && <VideoHistory/>}
								{ (selectedItem === SideMenuOptions.ViewLater) && <ViewLater/>}
								{ (selectedItem === SideMenuOptions.Favorites) && <FavoritesView/> }
								{/* { (selectedItem === SideMenuOptions.BecomeMemberOfNewCommunity) && <TestView/> } */}
							</div>
						</div>
						<CommentSectionView/>
						<ErrorMessage/>
						<ConstitutionView/>
						<ImageCloserLook/>
						<EditProfile/>
						<LiveSessionView/>
						<Theater/>
						<NotificationView/>
						<DesiredFeatures/>
						<SettingsView/>
						<StartDirectMessageController/>
						<BottomTabBar  selectedItem={ selectedItem } setSelectedItem={ setSelectedItem } setShowSetting={ setShowSetting }/>
						<SearchView/>
						<FilterPopUp/>
						{/* <AnonymousPostController/> */}
						{/* For Not Authenticated Users */}
						{ renderView &&
							<div className="auth-jump-in-pop-out" style={ { opacity: animateView ? "100%" : "0%"} }>
								<CloseJumpInPopup/>
								<JumpInView animateView={ animateView }/>
							</div>
						}
						{/*  */}
					</>
			}
		</>
	)
}



