import React, { useState, useEffect } from "react";
import "./page.css";
import WorkingPeople from './Images/workingPeople.jpg'
import { ApplicationName } from "../../Models/Models";
import { AuthFooter, AuthViewHeader } from "../../Pages/Auth/AuthView";
import ColorUI from "../ColorUI/ColorUI";

const Careers = () => {
    const [ showJumpInSignUp, setShowJumpInSignUp] = useState<boolean>(false);
    return (
        <div className="CareersPage-container">
            <div className="CareersPage">
                <div className="background-container">
                    <ColorUI/>
                </div>
                <div className="content-view">
                    <AuthViewHeader  scrolled={ false } setShowJumpInSignUp={ setShowJumpInSignUp }/>
                    <div className="page-title-header">
                        <h1>Careers</h1>
                    </div>
                    <div className="landing-image-container">
                        <img className="landing-image" src={ WorkingPeople } />
                    </div>
                    <div className="content"> 
                        <h1> { "😕 Sorry currently all positions are filled..." } </h1>
                    </div>
                    <AuthFooter/>
                </div>
            </div>
        </div>
    )
}
export default Careers;

//https://www.pexels.com/photo/woman-wearing-black-and-red-gingham-sport-shirt-1181471/