import React, { useState, useEffect, useContext, useRef } from "react";
import MuxPlayer from '@mux/mux-player-react';
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";
import "./MobileMoviePlayer.css";

const MobileMoviePlayer = () => {
	const [ playbackId, setPlaybackId ] = useState<string | undefined>(undefined);
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const pbid = urlParams.get('pbid'); 
		if (pbid) {
			setPlaybackId(_ => { return pbid; })
		};
		console.log("MobileMoviePlayer I've been called")
	}, []);

	useEffect(() => {
		setInterval(() => { callMethod(); }, 1000)
		const body = document.querySelector("body");
		body.style.backgroundColor = "black";
		//
		const mobileMoviePlayer = document.querySelector(".mobile-movie-player");
		// @ts-ignore
		mobileMoviePlayer.style.width = `${window.outerWidth}px`;
		// @ts-ignore
		mobileMoviePlayer.style.height = `${window.outerHeight}px`;
	}, []);
	
	return (
		//  Adding cusomt uniquer identifier to the element to be better able to get the video id
		<div className={`mobile-movie-player`} id={ uuidGenerator() } >
			{/* MuxPlayer */}
			{
				playbackId && 
				<MuxPlayer
					streamType="on-demand"
					playbackId={ playbackId }
					autoPlay={ true }
					metadataVideoTitle="Placeholder (optional)"
					metadataViewerUserId="Placeholder (optional)" //  media-controller:not(.sm) media-fullscreen-button
					primaryColor="#FFFFFF"
					secondaryColor="#000000"
					playsInline={ true }
					
					onResize={() => {
						// callMethod();
					}}
					onCanPlay={_=> {
						console.log("video_ready_to_play")
						// callMethod();
					}}
					onPlay={()=> { 
						//callMethod(); 
					}}
					onDurationChange={()=> {
						// callMethod(); 
					}}
				/>
			}
		</div>
	);
};

export default MobileMoviePlayer;

function removeAllEventListeners(element) {
    // Get a list of all registered event types
    const eventTypes = Object.keys(element.__proto__.__proto__);

    // Iterate over each event type and remove its listeners
    eventTypes.forEach(eventType => {
        element.removeEventListener(eventType, element.__proto__.__proto__[eventType]);
    });
}


function callMethod() {
	const body = document.querySelector("body");
	const parentElement = body.querySelector(".mobile-movie-player");
	// Loop through all child elements
	var childElements = parentElement.querySelectorAll('*');
	childElements.forEach(function(element) {
		// Remove each child element
		var _childElements = element.shadowRoot;
		if (_childElements) {
			var p_childElements = _childElements.querySelector('media-theme');
			var __childElements = p_childElements.shadowRoot;//.querySelectorAll('*');
			if (__childElements) {
				var mediaController = __childElements.querySelector('media-controller');
				var mediaControlBarList = mediaController.querySelectorAll('media-control-bar');
				if (mediaControlBarList) {
					mediaControlBarList.forEach((mediaControlBar_element, index) => {
						if (index > 0) {
							var mux_fullscreenButtonElem = mediaControlBar_element.querySelector('media-fullscreen-button');
							if (mux_fullscreenButtonElem) {
								mux_fullscreenButtonElem.remove();
							}			
							const custom_mux_fullscreenButtonElem = mediaControlBar_element.querySelector('.mobile-full-screen-elem-custom');
							if (custom_mux_fullscreenButtonElem) {
								custom_mux_fullscreenButtonElem.remove();
							}
							mediaControlBar_element.insertAdjacentHTML("beforeend", new_full_screen_element());
							const add_custom_mux_fullscreenButtonElem = mediaControlBar_element.querySelector('.mobile-full-screen-elem-custom');
							// removeAllEventListeners(add_custom_mux_fullscreenButtonElem);
							add_custom_mux_fullscreenButtonElem.removeEventListener("click", fullScreenPromptCall);
							add_custom_mux_fullscreenButtonElem.addEventListener("click", fullScreenPromptCall);
						}
					});
				}
			}
			// webkit-playsInline
			// Mux Vide Additions: Currently only webkit-playsInline			
			var muxVideoElment = p_childElements.querySelector("mux-video");
			var muxVideoElment__childElements = muxVideoElment.shadowRoot;//.querySelectorAll('*');
			if (muxVideoElment__childElements) {
				var videoElements = muxVideoElment__childElements.querySelectorAll('video');
				if (videoElements.length > 0) {
					videoElements.forEach(video_element => {
						video_element.setAttribute("playsInline", "true");
						video_element.setAttribute("webkit-playsInline", "true");
						video_element.setAttribute("type", "video/mp4");
					})
				}
			}

		}
	});
};

function fullScreenPromptCall(e) {
	e.preventDefault();
	//@ts-ignore
	mobile_video_view_should_be_full_screen = !mobile_video_view_should_be_full_screen;
	//@ts-ignore
	console.log(`_full_screen_tap_called__should_be_full_screen_${mobile_video_view_should_be_full_screen ? "true" : "false" }`);
}

function new_full_screen_element() {
	const elem = `
	<div class="mobile-full-screen-elem-custom" style="width: 36px; height: 36px; display: flex; justify-content:center; align-items: center; background-color:#ffffff7d !important; border-radius: 4px; margin-top: 4px;">
		<svg aria-hidden="true" viewBox="0 0 24 24" className="enter" color="white">
			<path d="M20.25 14.5a.76.76 0 0 0-.75.75v4.25h-4.25a.75.75 0 1 0 0 1.5h5a.76.76 0 0 0 .75-.75v-5a.76.76 0 0 0-.75-.75Zm0-11.5h-5a.76.76 0 0 0-.75.75.76.76 0 0 0 .75.75h4.25v4.25a.75.75 0 1 0 1.5 0v-5a.76.76 0 0 0-.75-.75ZM8.75 19.5H4.5v-4.25a.76.76 0 0 0-.75-.75.76.76 0 0 0-.75.75v5a.76.76 0 0 0 .75.75h5a.75.75 0 1 0 0-1.5Zm0-16.5h-5a.76.76 0 0 0-.75.75v5a.76.76 0 0 0 .75.75.76.76 0 0 0 .75-.75V4.5h4.25a.76.76 0 0 0 .75-.75.76.76 0 0 0-.75-.75Z"></path>
		</svg>
	</div>
	`
	return elem;
}

/*
	<svg aria-hidden="true" viewBox="0 0 24 24" className="exit">
		<path d="M20.25 14.5h-5a.76.76 0 0 0-.75.75v5a.75.75 0 1 0 1.5 0V16h4.25a.75.75 0 1 0 0-1.5Zm-5-5h5a.75.75 0 1 0 0-1.5H16V3.75a.75.75 0 1 0-1.5 0v5a.76.76 0 0 0 .75.75Zm-6.5 5h-5a.75.75 0 1 0 0 1.5H8v4.25a.75.75 0 1 0 1.5 0v-5a.76.76 0 0 0-.75-.75Zm0-11.5a.76.76 0 0 0-.75.75V8H3.75a.75.75 0 0 0 0 1.5h5a.76.76 0 0 0 .75-.75v-5A.76.76 0 0 0 8.75 3Z"></path>
	</svg>
*/

// Custom hook to listen for changes in width or height of an element
const useElementResize = (elementRef: React.RefObject<HTMLElement>, callback: (dimensions: { width: number; height: number }) => void) => {
	useEffect(() => {
	  const observer = new ResizeObserver(entries => {
		const entry = entries[0];
		if (entry && entry.target === elementRef.current) {
		  const { width, height } = entry.contentRect;
		  callback({ width, height });
		}
	  });
  
	  if (elementRef.current) {
		observer.observe(elementRef.current);
	  }
  
	  return () => {
		if (elementRef.current) {
		  observer.unobserve(elementRef.current);
		}
	  };
	}, [elementRef, callback]);
  };
