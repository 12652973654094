import { async } from "@firebase/util";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect, useRef, useContext } from "react";
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";
import ControllerContext from "../../../Contexts/ControllerContexts";
import UserContext from "../../../Contexts/UserContext";
import { userprofile_storage_path } from "../../../fb/dbMethods";
import { db } from "../../../fb/fbSetup";
import { CurrentUserDataModel, ProjectExpereinceModel, StateOfProject } from "../../../Models/Models";
import "./EditProfile.css"

export default function EditProfile() {
    const {
        showEditProfileView,
        toggleShowEditProfileView
    } = useContext(ControllerContext);
    const { userData, userProfileImageURL, userLandingImageURL, setIsUserProfileImageType, SetUserProfileImage, isUserProfileImageType } = useContext( UserContext );
    // The new user data object begins with the passing of spreading it then modifying it with the new data as it is altered, which in turn will be update the model on the backend.
    const [ newUserData, setNewUserData ] = useState<CurrentUserDataModel>({ ...userData });
    //
    //
    const [ fistname, setFirstname ] = useState<string>( userData.firstName );
    const [ lastname, setLastname ] = useState<string>( userData.lastName );
    const [ username, setUsername ] = useState<string>( userData.customUserName );
    const [ bio, setBio ] = useState<string>( userData.bio );

    useEffect(() => {
        // userData
        setFirstname(_=> { return userData.firstName });
        setLastname(_=> { return userData.lastName });
        setUsername(_=> { return userData.customUserName });
        setBio(_=> { return userData.bio });
    }, [])

    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView, setAnimateView ] = useState<boolean>(true);
    useEffect(() => {
        if(showEditProfileView) {
            setRenderView(showEditProfileView);
            setTimeout(() => {
                setAnimateView(showEditProfileView);
            }, 150);
        } else {
            setAnimateView(showEditProfileView);
            setTimeout(() => {
                setRenderView(showEditProfileView);
            }, 400);
        }
    }, [ showEditProfileView ]);

    // Add New Projects 
    // Add New Projects
    // Add New Projects
    const [ showNewProjectItemForm, setShowNewProjectItemForm ] = useState<boolean>(false);
    const [ projectBeingEdited, setProjectBeingEdited ] = useState<ProjectExpereinceModel>( ResetProjectModel );
    const [ isBeingUpdated, setIsBeingUpdated ] = useState<boolean>(false);
    //
    const [ renderProjectFormView, setRenderProjectFormView ] = useState<boolean>(false);
    const [ animateProjectFormView, setAnimateProjectFormView ] = useState<boolean>(true);

    function toggleNewProjectItemForm(item: ProjectExpereinceModel, isBeingUpdated: boolean) {
        setIsBeingUpdated(_=> { return isBeingUpdated; });
        setProjectBeingEdited(_=> { return item; });
        setShowNewProjectItemForm(_=> { return true; });
    }

    useEffect(() => {
        if(showNewProjectItemForm) {
            setRenderProjectFormView(showNewProjectItemForm);
            setTimeout(() => {
                setAnimateProjectFormView(showNewProjectItemForm);
            }, 150);
        } else {
            setAnimateProjectFormView(showNewProjectItemForm);
            setTimeout(() => {
                setRenderProjectFormView(showNewProjectItemForm);
            }, 400);
        }
    }, [ showNewProjectItemForm ]);




    return (
        <>
        { renderView &&
            <div className="editProfileView" style={{top: animateView ? "0" : "200vh"}}>
                <button className="close-view-button" onClick={() => { toggleShowEditProfileView(false); }}>x</button>
                <div className="edit-profile-container hideScrollIndicator">
                    <div className="edit-profile-content-view">
                        {/* View Title */}
                        <div className="view-title">
                            <h1>Edit Profile</h1>
                        </div>
                        {/* Header Image */}
                        <div className="header-image">
                            <img loading={"lazy"} className="image" src={ userLandingImageURL }/>
                            <div className="image-cover" onClick={() => {
                                setIsUserProfileImageType(_=> { return false; });
                                if ( isUserProfileImageType === false ) { SetUserProfileImage(userData.userID); }
                            }}>
                                <label><span className="material-symbols-outlined">edit</span> edit</label>
                            </div>
                        </div>

                        {/* h-stack */}
                        <div className="hStackEdit profImg-first-last" style={{ width: "100%", justifyContent: "space-between" }}>
                            {/* Profile Image */}
                            <div className="profile-image">
                                <img loading={"lazy"} className="image" src={ userProfileImageURL }/>
                                <div className="image-cover" onClick={() => {
                                        setIsUserProfileImageType(_=> { return true; });
                                        if (isUserProfileImageType === true) { SetUserProfileImage(userData.userID); } 
                                    }}>
                                    <label><span className="material-symbols-outlined">edit</span> edit</label>
                                </div>
                            </div>
                            {/* First Name */}
                            <div className="medium-field-container">
                                <label className="purpose-label">First Name</label>
                                <input 
                                    className="large-input" 
                                    type={"text"} 
                                    placeholder={ fistname } 
                                    value={ fistname }
                                    onChange={e => { setFirstname(_ => { return e.target.value; }); }}
                                />
                            </div>
                            {/* First Name */}
                            <div className="medium-field-container">
                                <label className="purpose-label">Last Name</label>
                                <input 
                                    className="large-input" 
                                    type={"text"} 
                                    placeholder={ lastname }
                                    onChange={e => { setLastname(_ => { return e.target.value; }); }}
                                    value={ lastname }
                                />
                            </div>
                            {/* Custom Name */}
                            <div className="medium-field-container">
                                <label className="purpose-label">Username</label>
                                <div>
                                    <label style={{ color: "var(--app-blue)", fontWeight: "600" }}>@</label>
                                    <input 
                                        className="medium-input" type={"text"} 
                                        placeholder={ username }
                                        value={ username }
                                        onChange={e => { setUsername(_ => { return e.target.value; }); }}
                                        style={{ 
                                            color: "var(--app-blue)",
                                            fontWeight: "600",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Bio */}
                        <div className="large-field-container" style={{ width: "100%", marginTop: "20px" }}>
                            <label className="purpose-label">Bio</label>
                            <textarea 
                                className="large-textarea" 
                                placeholder={ bio } 
                                value={ bio }
                                onChange={e => { setBio(_ => { return e.target.value; }); }}
                            />
                        </div>
                        <ProjectListView userData={ newUserData } setNewUserData= { setNewUserData } toggleNewProjectItemForm={ toggleNewProjectItemForm }/>
                        <UserFeedImagesView userData={ newUserData }/>
                        <div className="submit-button">
                            <button onClick={ async () => {
                                const ref = `${ userprofile_storage_path }/${ userData.userID }`;
                                const data = {
                                    userID: userData.userID,
                                    firstName: fistname,
                                    lastName: lastname,
                                    customUserName: username,
                                    bio: bio,
                                    posts: newUserData.posts, // Should be used to pull in all posts
                                    contentColonies: newUserData.contentColonies,
                                    communities: newUserData.communities,
                                    peopleOfCare: newUserData.peopleOfCare,
                                    peopleYouFollow: newUserData.peopleYouFollow,
                                    allowDirectMessage: newUserData.allowDirectMessage,
                                    followerCount: newUserData.followerCount,
                                    followingCount: newUserData.followingCount,
                                    profilePrivate: newUserData.profilePrivate,
                                    prioritizeExperience: newUserData.prioritizeExperience,
                                    professionalExperience: newUserData.professionalExperience,
                                    projectExpience: newUserData.projectExpience,
                                    currentProjectItems: newUserData.currentProjectItems,
                                    messagesRefferences: newUserData.messagesRefferences,
                                    TopicsOfInterest: newUserData.TopicsOfInterest ? newUserData.TopicsOfInterest : [],
                                    DunbarColonyID: userData.DunbarColonyID,
                                    feedImages: newUserData.feedImages ? newUserData.feedImages : [],
                                    customUserImages: newUserData.customUserImages ? newUserData.customUserImages : [],
                                }

                                if (userData.age) {
                                    data["age"] = userData.age;
                                }
                                if (userData.disclosedUserPsycheType) {
                                    data["disclosedUserPsycheType"] = userData.disclosedUserPsycheType;
                                }
                                if (userData.userContentFilterModel) {
                                    data["userContentFilterModel"] = userData.userContentFilterModel;
                                }
                                if (userData.imageProfileImageURLs) {
                                    data["imageProfileImageURLs"] = userData.imageProfileImageURLs;
                                }

                                const update = await updateDoc(doc(db, ref), data)
                                .then(() => {
                                    console.log("data updated");
                                })
                                .catch(err => {
                                    console.log("err: ", err);
                                })
                            }}>save</button>
                        </div>
                    </div>
                </div>
            </div>
        }

        { renderProjectFormView && 
            <NewProjectItemForm 
                setShowNewProjectItemForm={ setShowNewProjectItemForm }
                animateView={ animateProjectFormView } 
                newUserData={ newUserData } 
                dataPassed={ projectBeingEdited } 
                isAnUpdate={ isBeingUpdated }
                setNewUserData={ setNewUserData }
            /> 
        }
        </>
    );
}


interface UserFeedImagesViewProps {
    userData: CurrentUserDataModel;
}

const UserFeedImagesView = ({ userData }: UserFeedImagesViewProps) => {
    const [ feedIamges, setFeedImages ] = useState<string[]>( [] );
    return (
        <>
            {userData.feedImages &&    
                <div className="feed-image-scrollview hideScrollIndicator">
                    { userData.feedImages.map( (item, index) => {
                        return (
                            <div className="feed-image-content-view">
                                <img loading={"lazy"} className="feed-image-item" src={ item.fullImageURL }/>
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )
}


// export interface ProjectExpereinceModel {
//     projectName: string;
//     ideaInEightyCharacters: string;
//     summaryOfIdea: string;
//     websiteOrLink: string;
//     stateOfProject: StateOfProject;
// }

// // The major point of existence... Not to be liked, but loved. That includes a very few exceptional in spirit.
// export enum StateOfProject {
//     done = "done",
//     mostlyDone = "mostlyDone",
//     inprogress = "inprogress",
//     mostlyDoneOnHold = "mostlyDoneOnHold",
//     onHold = "onHold",
//     notTouchingAgain = "notTouchingAgain",
// }

interface ProjectViewProps {
    userData: CurrentUserDataModel;
    setNewUserData: React.Dispatch<React.SetStateAction<CurrentUserDataModel>>;
    toggleNewProjectItemForm: (item: ProjectExpereinceModel, isBeingUpdated: boolean) => void;
}

const ResetProjectModel : ProjectExpereinceModel = {
    projectID: "",
    projectName: "",
    ideaInEightyCharacters : "",
    summaryOfIdea: "",
    websiteOrLink: "",
    stateOfProject: StateOfProject.inprogress, 
}

const ProjectListView = ({ userData, setNewUserData, toggleNewProjectItemForm }: ProjectViewProps) => {
    return (
        <>
            <div className="large-field-container" style={{ width: "100%", marginTop: "34px" }}>
                <div className="project-add-and-title">
                    <label className="purpose-label">Your Project History</label>
                    <button className="add" onClick={() => { toggleNewProjectItemForm(ResetProjectModel, false); }}>add</button>
                </div>
                <div className="professional-and-project-history-container-scrollview hideScrollIndicator">
                    <div className="professional-and-project-history-container-content-view">
                        {
                            userData.projectExpience.map((expItem, index) => {
                                return (
                                    <div className="project-and-experience-item">
                                        <div className="action-buttons">
                                            <button className="edit" onClick={() => {
                                                toggleNewProjectItemForm(expItem, true);
                                            }}>edit</button>
                                            <button className="remove" onClick={() => {
                                                let list = userData.projectExpience;
                                                list = list.filter(item => item.projectID !== expItem.projectID );
                                                setNewUserData( obj => {
                                                    let object = {...obj, projectExpience: list };
                                                    return object;
                                                });
                                            }}>remove</button>
                                        </div>
                                        <h3 className="project-name">{ expItem.projectName }</h3>
                                        <h4 className="eighty-characters">{ expItem.ideaInEightyCharacters }</h4>
                                        <h5 className="summary">{ expItem.summaryOfIdea }</h5>
                                        <h6 className="state">{ getProjectState(expItem.stateOfProject) }</h6>
                                        <a className="link-refs" href={ expItem.websiteOrLink } target="_blank" >link</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

interface NewProjectItemFormProps {
    setShowNewProjectItemForm: React.Dispatch<React.SetStateAction<boolean>>
    animateView: boolean;
    newUserData: CurrentUserDataModel;
    dataPassed: ProjectExpereinceModel;
    isAnUpdate: boolean;
    setNewUserData: React.Dispatch<React.SetStateAction<CurrentUserDataModel>>;
}

const NewProjectItemForm = ({ setShowNewProjectItemForm , animateView, newUserData, dataPassed, isAnUpdate, setNewUserData } : NewProjectItemFormProps ) => {
    const [ projectName, setProjectName ] = useState<string>( dataPassed.projectName );
    const [ ideaInEightyCharacters, setIdeaInEightyCharacters] = useState<string>( dataPassed.ideaInEightyCharacters );
    const [ summaryOfIdea, setSummaryOfIdea ] = useState<string>( dataPassed.summaryOfIdea );
    const [ websiteOrLink, setWebsiteOrLink ] = useState<string>( dataPassed.websiteOrLink );
    const [ statofProject, setStateOfProject ] = useState<StateOfProject>( dataPassed.stateOfProject );
    return (
        <div className="new-project-form-container" style={{ opacity: animateView ? "100%" : "0%" }}>
            <button className="close-view-button" onClick={() => { setShowNewProjectItemForm(false); }}>x</button>
            <div className="new-project-form" style={{ transform: animateView ? 'translateY("0")' : 'translateY("100vw")' }}>
                {/* Project Name */}
                <div className="vStack-form-item">
                    <label>Project Title</label>
                    <input className="title" type={ "text" } placeholder={"Project Title..."} value={ projectName } onChange={(e) => { setProjectName(_=> { return e.target.value; })}}/>
                </div>
                {/* Brief Articulation Of the idea */}
                <div className="vStack-form-item">
                    <label>Idea In Eighty Characters</label>
                    <input placeholder={"Idea In Eighty Characters..."} type={ "text" } value={ ideaInEightyCharacters } onChange={(e) => { setIdeaInEightyCharacters(_=> { return e.target.value; })}} maxLength={ 80 }/>
                </div>
                {/* Summary */}
                <div className="vStack-form-item">
                    <label>Project Summary</label>
                    <textarea placeholder={"Project Summary..."} value={ summaryOfIdea } maxLength={ 200 }  onChange={(e) => { setSummaryOfIdea(_=> { return e.target.value; })}}/>
                </div>
                {/* Link */}
                <div className="vStack-form-item">
                    <label>Link To Project</label>
                    <input className="link" placeholder={"Link To Project..."} type={ "text" } value={ websiteOrLink } onChange={(e) => { setWebsiteOrLink(_=> { return e.target.value; })}}/>
                </div>
                {/* State Of Project */}
                <div className="vStack-form-item">
                    <label>State Of Project</label>
                    <select defaultValue={ statofProject } onChange={ e => { setStateOfProject( _=> { return e.target.value as StateOfProject; }); }}>
                        <option value={ StateOfProject.done }>Done</option>
                        <option value={ StateOfProject.mostlyDone }>Mostly Done</option>
                        <option value={ StateOfProject.inprogress }>In Progress</option>
                        <option value={ StateOfProject.mostlyDoneOnHold }>Mostly Done On Hold</option>
                        <option value={ StateOfProject.onHold }>On Hold</option>
                        <option value={ StateOfProject.notTouchingAgain }>Not Touching Again</option>
                    </select>
                </div>
                {/* Add Button */}
                <button className="submit" onClick={() => {
                    if (isAnUpdate) {
                        let list = newUserData.projectExpience;
                        list.forEach( (item) => {
                            if (item.projectID === dataPassed.projectID) {
                                item.projectName = projectName;
                                item.ideaInEightyCharacters = ideaInEightyCharacters;
                                item.summaryOfIdea = summaryOfIdea;
                                item.websiteOrLink = websiteOrLink;
                                item.stateOfProject = statofProject;
                                setNewUserData( obj => {
                                    let object = {...obj, projectExpience: list };
                                    return object;
                                });
                            }
                        })
                        setShowNewProjectItemForm(false)
                    } else {
                        let list = newUserData.projectExpience;
                        const NewProjectModel : ProjectExpereinceModel = {
                            projectID: uuidGenerator(),
                            projectName: projectName,
                            ideaInEightyCharacters : ideaInEightyCharacters,
                            summaryOfIdea: summaryOfIdea,
                            websiteOrLink: websiteOrLink,
                            stateOfProject: statofProject, 
                        }
                        list.push(NewProjectModel);
                        setNewUserData( obj => {
                            let object = {...obj, projectExpience: list };
                            return object;
                        });
                        setShowNewProjectItemForm(false)
                    }
                }}>{ isAnUpdate ? "update" : "add" }</button>
            </div>
        </div>
    );
}

function getProjectState(state: StateOfProject):string {
    let retString: string = "";
    switch (state) {
        case StateOfProject.done:
            retString = "Done"
            break;
        case StateOfProject.mostlyDone:
            retString = "Mostly Done"
            break;
        case StateOfProject.inprogress:
            retString = "In Progress"
            break;
        case StateOfProject.mostlyDoneOnHold:
            retString = "Mostly Done But On Hold"
            break;
        case StateOfProject.onHold:
            retString = "On Hold"
            break;
        case StateOfProject.notTouchingAgain:
                retString = "Not Touching Again"
            break;
    }

    return retString;
}


const _data_ = {
    "userID": "dTIJA53ZwdUos2TIab6xVAsEmXF2",
    "DunbarColonyID": "test_colony",
    "customUserName": "superman",
    "TopicsOfInterest": [
        "Business",
        "Economics",
        "Physics",
        "Astronomy",
        "Earth science",
        "Neuroscience",
        "Psychology",
        "Technology",
        "Engineering",
        "Software Engineering",
        "Civil Engineering",
        "Chemical Engineering",
        "Mechanical Engineering",
        "Electrical Engineering",
        "Industrial Engineering",
        "Aerospace engineering",
        "Systems engineering",
        "Materials Engineering",
        "Agricultural engineering",
        "Biological engineering",
        "History",
        "Archeology",
        "Art",
        "Mathematics",
        "Artificial Intelligence",
        "Architecture",
        "Music",
        "Philosophy",
        "Electrical",
        "Plumbing",
        "Carpentry",
        "Construction",
        "Wood Working",
        "Theology ",
        "Christianity",
        "Islam",
        "Judaism",
        "Law",
        "Comics",
        "Marvel Comics",
        "DC Comics",
        "Anime",
        "Manga",
        "Books",
        "Classic Literature",
        "Animals",
        "Pets",
        "Big Cats",
        "Language",
        "Funny",
        "Dark Humor"
    ],
    "firstName": "Clark",
    "communities": [
        {
            "communityID": "1",
            "communityLevel": "expert",
            "communityTitle": "physics"
        },
        {
            "communityID": "2",
            "communityTitle": "Chemical Engineering",
            "communityLevel": "wise"
        },
        {
            "communityLevel": "expert",
            "communityTitle": "math",
            "communityID": "3"
        }
    ],
    "allowDirectMessage": true,
    "professionalExperience": [
        {
            "company": "Dunder Miflin",
            "website": "",
            "role": "Frontend Engineer",
            "timeThere": {
                "beginning": "Jan",
                "end": "M"
            }
        }
    ],
    "followingCount": 10,
    "prioritizeExperience": false,
    "userName": "superman",
    "lastName": "Kent",
    "profilePrivate": false,
    "posts": [
        {
            "postID": "yTJUbcNcPecbOaAXeCaYdsXWrKtTwsWjTkjbHQavqSzCpIAGmcfVDSIDVMtHLFnJKFePJjuPgjgMUGwv",
            "community": "1"
        },
        {
            "community": "default",
            "postID": "VBwsFtSsWUGXJhjVMhbFBGGFfECNDcmSJarLRWPHuhYcrWMbPUbVpVkHeytdxrFwkXkHzvaupUAibDqh"
        }
    ],
    "peopleYouFollow": [
        "dTIJA53ZwdUos2TIab6xVAsEmXF2",
        "mp699DW1KWOM0yMWbQETcATb8cJ3",
        "thKKL88Lljau28V1iZ2RTiI2OUx1"
    ],
    "peopleOfCare": [],
    "contentColonies": [],
    "projectExpience": [
        {
            "stateOfProject": "onHold",
            "ideaInEightyCharacters": "Teaching Kids Complex Ideas via story.",
            "projectID": "1",
            "projectName": "Toddlit",
            "summaryOfIdea": "The project was a cross-platform application where artist accross the world to share complex concepts, ideas, and morals via story.",
            "websiteOrLink": "https://www.toddlit.com/"
        },
        {
            "ideaInEightyCharacters": "An attempt to fix the human epistomology problem generally.",
            "projectName": "WikiRandom",
            "projectID": "2",
            "websiteOrLink": "https://github.com/rabusmccaleb/WikiRandom-2.0",
            "stateOfProject": "done",
            "summaryOfIdea": "This project was built to solve the problem of filling in the blind spots in our lack of knowledge. It often is not clear that we can't even search what we don't know."
        },
        {
            "stateOfProject": "inprogress",
            "summaryOfIdea": "It was meant to allow real-time feedback from all your students even the shy ones, enable collaboration outside the classroom , complex digital note-taking (even for math), and reduce friction between teacher and student.",
            "projectName": "Auessey.",
            "projectID": "3",
            "ideaInEightyCharacters": "Making education more data-driven, collaborative, and easy on all temperaments in the classroom.",
            "websiteOrLink": "https://auessey.com/"
        }
    ],
    "bio": "I'm from a small town in Kansas called Smallville. I was adopted by loving parents, and I have a love for journalism. People compare me to Ercle, but I like to believe I look like Superman. 😅",
    "messagesRefferences": [
        {
            "user_one_lastName": "Wayne",
            "user_one_ID": "mp699DW1KWOM0yMWbQETcATb8cJ3",
            "user_one_firstName": "Bruce",
            "user_two_ID": "dTIJA53ZwdUos2TIab6xVAsEmXF2",
            "user_two_firstName": "Clark",
            "user_one_username": "Batman",
            "user_two_lastName": "Kent",
            "user_two_username": "rabmcc",
            "current_doc_index": 0,
            "mostRecentInteractionDate": {
                "seconds": 1690837121,
                "nanoseconds": 94000000
            },
            "documentID": "YUpDxVhGQgRoDoivzOoSNGimxjApmNYOHRcfLohFsrvLUmUKfuEJECyoCAIBOGpyxrDHQvAPpSonAvtN"
        }
    ],
    "currentProjectItems": [
        {
            "checkPoints": [
                {
                    "checkPointTitle": "",
                    "checkPointDescription": "Close first sale",
                    "completed": false
                },
                {
                    "checkPointTitle": "",
                    "checkPointDescription": "Reach out to potential clients",
                    "completed": false
                },
                {
                    "completed": false,
                    "checkPointTitle": "",
                    "checkPointDescription": "Wrap up on the collab view"
                },
                {
                    "completed": false,
                    "checkPointDescription": "Wrap up on the notes view",
                    "checkPointTitle": ""
                },
                {
                    "checkPointTitle": "",
                    "completed": false,
                    "checkPointDescription": "Wrap up on the lecture playback view"
                },
                {
                    "completed": false,
                    "checkPointDescription": "Build an elaborate note taking system (mostly done).",
                    "checkPointTitle": ""
                },
                {
                    "checkPointTitle": "",
                    "completed": true,
                    "checkPointDescription": "Build out the real-time session view"
                },
                {
                    "checkPointDescription": "Build out the landing page",
                    "checkPointTitle": "",
                    "completed": true
                },
                {
                    "completed": true,
                    "checkPointTitle": "",
                    "checkPointDescription": "Build out the pricing page"
                },
                {
                    "completed": true,
                    "checkPointTitle": "",
                    "checkPointDescription": "Build out the careers page"
                },
                {
                    "checkPointDescription": "Build out the distributed cognition page",
                    "completed": true,
                    "checkPointTitle": ""
                },
                {
                    "checkPointDescription": "Host the site",
                    "checkPointTitle": "",
                    "completed": true
                }
            ],
            "projectInEightyCharacters": "Bringing helpful technology to students and teachers in real-time",
            "projectID": "1234567",
            "projectTitle": "Auessey",
            "projectDescription": "This is a simple project. The major purpose is to solve classroom hangups, by offering realtime software, along with a suite of other software to allow students to participate in distributed learning."
        }
    ],
    "followerCount": 20
}