import React, { useState, useEffect, useContext } from "react";
import './SideMenu.css'
import { auth } from "../../../fb/fbSetup";
import { signOut } from "firebase/auth";
// Side Menu Icons
import Home_icon from '../../../../Resources/SideMenuIcons/home.png';
import Message_icon from '../../../../Resources/SideMenuIcons/message.png';
import DunbarColony_icon from '../../../../Resources/SideMenuIcons/colony.png';
import Profile_icon from '../../../../Resources/SideMenuIcons/user.png';
import History_icon from '../../../../Resources/SideMenuIcons/history.png';
import ViewLater_icon from '../../../../Resources/SideMenuIcons/viewlater.png';
import Favorites_icon from '../../../../Resources/SideMenuIcons/favorites.png';
import LiveChat_icon from "../../../../Resources/SideMenuIcons/livechat.png";
import ControllerContext from "../../../Contexts/ControllerContexts";
import { PagePaths } from "../../../Models/Models";
import UserContext from "../../../Contexts/UserContext";

interface SideMenuProps {
	selectedItem : SideMenuOptions;
	setSelectedItem: (value: React.SetStateAction<SideMenuOptions>) => void;
	setShowSetting: React.Dispatch<React.SetStateAction<boolean>>
}
const SideMenuContainer = ({ selectedItem, setSelectedItem, setShowSetting }: SideMenuProps) => {
	return (
		<div className='side-menu'>
			<SideMenu selectedItem={ selectedItem } setSelectedItem={ setSelectedItem } setShowSetting={ setShowSetting }/>
		</div>
	);
}

export default SideMenuContainer;

interface Props {
    selectedItem : SideMenuOptions;
    setSelectedItem: (value: React.SetStateAction<SideMenuOptions>) => void;
    setShowSetting: React.Dispatch<React.SetStateAction<boolean>>
}

export enum SideMenuOptions {
    BecomeMemberOfNewCommunity = "BecomeMemberOfNewCommunity",
    Home = "Home",
    DunbarColony = "DunbarColony",
    LiveChat = "LiveChatSession",
    Messages = "Messages",
    Profile = "Profile",
    History = "VideoHistory",
    Favorites = "Favorites",
    ViewLater = "ViewLater",
    Settings = "Settings",
    Report = "Report",
    DesireFeatures = "DesireFeatures",
}

const SideMenu = ({ 
    selectedItem, setSelectedItem, setShowSetting
}: Props ) => {
    const reportingAndSafteyTitle = "Reporting / Safety";
    const desiredFeaturesTitle = "Desired Features";
    const { userData } = useContext(UserContext);
    const { toggleDesiredFeaturesView, toggleJumpInSignUp, toggleSettings } = useContext(ControllerContext);
    const sideMenuItems = [
        { label: "Home", icon: Home_icon, option: SideMenuOptions.Home, path: PagePaths.Home },
        { label : "Dunbar Colony", icon : DunbarColony_icon, option: SideMenuOptions.DunbarColony, path: PagePaths.DunbarColony },
        { label : "Live Chat", icon : LiveChat_icon, option: SideMenuOptions.LiveChat, path: PagePaths.LiveChatSession  },
        { label: "Messages", icon: Message_icon, option: SideMenuOptions.Messages, path: PagePaths.Messages },
        { label: "Profile", icon: Profile_icon, option: SideMenuOptions.Profile, path: PagePaths.Profile },
        { label: "Video History", icon: History_icon, option: SideMenuOptions.History, path: PagePaths.VideoHistory },
        { label: "View Later", icon: ViewLater_icon, option: SideMenuOptions.ViewLater, path: PagePaths.ViewLater },
        { label: "Favorites", icon: Favorites_icon, option: SideMenuOptions.Favorites, path: PagePaths.Favorites  }
    ];

    function stopCamera() {
        // @ts-ignore
        if (window.localStream) {
            // @ts-ignore
            let tracks = window.localStream.getTracks();
            tracks.forEach(track => { track.stop(); });
            // @ts-ignore
            window.localStream = null;
        }
    }

    

    return (
        <div className="dashbord-side-menu-item-container" key={0}>
            <ul className="dashboard-side-menu-list-conatainer">
                    <li className={`join-another-community`}  onClick={() => {
                        // @ts-ignore
                            if (userData.NOT_AN_AUTHENTICATED_USER ) {
                                toggleJumpInSignUp(true);
                            } else {
                                window.location.href = "#"
                            }
                        }}>
                        <div className="label-conatainer">
                            <label>{ "Add Another Community" }</label>
                        </div>                         
                    </li>
                { 
                    sideMenuItems.map( SideMenuItem => {
                        return (
                            <li className={`dashboard-side-menu-item view-item ${ selectedItem == SideMenuItem.option ? ' active': ''}`}
                                onClick={() => {
                                    if (SideMenuItem.option !== SideMenuOptions.LiveChat) { // Handling Ending Call
                                        setSelectedItem(item => { return SideMenuItem.option; })
                                        window.history.pushState(null, '', SideMenuItem.path);
                                        stopCamera();
                                    } else {
                                        if (SideMenuItem.option !== SideMenuOptions.LiveChat) stopCamera();
                                        window.location.href = SideMenuItem.path
                                    }
                                }}
                            >
                                <div className="icon-container">
                                    <img loading={"lazy"} alt="A House Icon" src={ SideMenuItem.icon }/>
                                    <div className="image-cover"/>
                                </div>
                                <div className="label-conatainer">
                                    <label>{ SideMenuItem.label }</label>
                                </div>
                            </li>
                        )
                    })
                }
                {/* <li className="dashboard-side-menu-item defered-items first" onClick={() => { window.location.href = "/about"}}>
                    <div className="label-conatainer">
                        <label>About</label>
                    </div>
                </li> */}

                <li className="dashboard-side-menu-item defered-items d-features first"  onClick={() => { toggleSettings(true); }} >
                    <div className="label-conatainer">
                    <label style={{ color: 'black' }}>You & Us</label>
                    </div>
                </li>   
                <li className="dashboard-side-menu-item defered-items d-features"  onClick={() => { toggleDesiredFeaturesView(true); }} >
                    <div className="label-conatainer">
                        <label style={{ color: 'black' }}>{ desiredFeaturesTitle }</label>
                    </div>
                </li>                
                <li className="dashboard-side-menu-item defered-items" onClick={() => { 
                    signOut(auth).then(_ => {
                        window.location.reload();
                    }).catch(_=> {
                        window.location.reload();
                    }); 
                }} >
                    <div className="label-conatainer">
                        <label style={{ color: 'var(--app-red)' }}>Sign Out</label>
                    </div>
                </li> 
            </ul>
        </div>
    );
}
