import React, { useState, useEffect, useContext } from "react";
import ControllerContext from "../../../Contexts/ControllerContexts";
import Lottie from "lottie-react";
import loading_Lottie from "../../../../Resources/Lottie/loading.json";
import { ContentModel, Posts_Coll_And_Doc_Path, searchPath } from "../../../fb/dbMethods";
import { collection, documentId, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../fb/fbSetup";
import { ContentCard, ContentItemsComp } from "../../../Pages/Home/ContentView/ContentView";
import FilterView from "../FilterView";
import "./FilterPopUp.css"

const FilterPopUp = () => {
    const inDevMode = true;
    const {
        showFilterView,
        toggleFilterView,
    } = useContext(ControllerContext);
    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView, setAnimateView ] = useState<boolean>(false);
    useEffect(() => {
        if(showFilterView || inDevMode) {
            setRenderView(showFilterView);
            setTimeout(() => {
                setAnimateView(showFilterView);
            }, 150);
        } else {
            setAnimateView(showFilterView);
            setTimeout(() => {
                setRenderView(showFilterView);
            }, 400);
        }
    }, [ showFilterView ]);

    return (
        <>
            { renderView &&
                <div className="FilterPopUp" style={{ opacity: animateView ? "100%" :  "0%" }}>
                    <FilterView/>
                </div>
            }
        </>
    );
}

export default FilterPopUp;