import React, { useState, useContext, useEffect } from "react";
import ControllerContext from "../../Contexts/ControllerContexts";
import UserContext from "../../Contexts/UserContext";
import "./DesiredFeaturesMobile.css";
import {
    // Model:
    FeatureRequestModel,
    FeatureRequestType, 
    // Methods:
    addFeatureRequest, 
} from "./DesiredFeaturesMobileController";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../fb/fbSetup";

const DesiredFeaturesMobile = () => {
    // Popup Logic
    const [ animateView , setAnimateView ] = useState<boolean>(true);
	const [ userID, setUserID ] = useState<string | undefined>(undefined);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tkn = urlParams.get('t');  
        const ud = urlParams.get('ud');     
        if (tkn) {
            signInWithCustomToken(auth, tkn)
            .then(sucess => {
                if (sucess.user.uid) { setUserID(`${ud}`); }
            })
            .catch(error => {
            });
        }
    }, []);
    //
    //
    // Component Logic
    const [ requestType, setRequestType ] = useState<FeatureRequestType>(FeatureRequestType.feature);
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ reason, setReason ] = useState("");

    // Button
    const [ buttonDisabled, setButtonDisabled ] = useState<boolean>(false);
    return (
        <>
            { userID &&
                <div className="DesiredFeatures_MobileView DesiredFeaturesView mobileApp" style={{ opacity: animateView ? "100%" :  "0%" }}>
                    <div className="disired-features-container" style={{ transform: animateView ? "translateX(0)" : "translateX(150vh)",}}>
                        <div className="field-items-container">
                            <div className="field-title-description">
                                <h4 className="title">*Request Type</h4>
                                <label className="description">Select a the type of request you are making.</label>
                                <select className="request-type" defaultValue={ requestType } onChange={e => {
                                    console.log("- Feature request type has changed: ", e.target.value);
                                    setRequestType( (curr) => {
                                        const type = e.target.value as FeatureRequestType;
                                        const returnType = type ? type : curr;
                                        console.log("1 Feature request type has changed: ", returnType);
                                        return returnType;
                                    })
                                }}>
                                    <option value={ FeatureRequestType.feature }>Feature</option>
                                    <option value={ FeatureRequestType.bug }>Bug Fix</option>
                                </select>
                            </div>
                        </div>
                        <div className="field-items-container">
                            <div className="field-title-description">
                                <h4 className="title">Title</h4>
                                <label className="description">{"(option) please give a title to your request"}</label>
                                <input 
                                    className="field title-field" 
                                    type={ "text" }
                                    value={ title }
                                    placeholder="Title"
                                    onChange={e => {
                                        setTitle(_=> { return e.target.value; })}
                                    }
                                />
                            </div>
                        </div>
                        <div className="field-items-container">
                            <div className="field-title-description">
                                <h4 className="title">*Request Description</h4>
                                <label className="description">{
                                    (requestType === FeatureRequestType.feature) ?
                                    "Allows you to describe your feature request." :
                                    "Allows you to describe the bug in question and tell us where to find it."
                                }</label>
                                <textarea 
                                    className="field request"
                                    value={ description }
                                    onChange={e => {setDescription(_=> { return e.target.value; })}} 
                                    placeholder={ (requestType === FeatureRequestType.feature) ? "Describe your request..." : "Describe the bug..."}
                                />
                            </div>
                        </div>
                        { (requestType === FeatureRequestType.feature) && 
                            <div className="field-items-container">
                                <div className="field-title-description">
                                    <h4 className="title">Reason For Feature Request</h4>
                                    <label className="description">The helps us determine the urgeny or the utilitu of the feature to the user experience</label>
                                    <textarea 
                                        className="field reason"
                                        value={ reason }
                                        onChange={e => {setReason(_=> { return e.target.value; })}} 
                                        placeholder="Explain your reasoning for the request..."
                                    />
                                </div>
                            </div>
                        }

                        <button 
                            className="submit-button"
                            disabled={ buttonDisabled }
                            onClick={() => {
                                if (description.trim() !== "") {
                                    const requestField = document.querySelector(".DesiredFeaturesView .disired-features-container .field-items-container .field.request");
                                    // @ts-ignore
                                    requestField.style.border = "none";
                                    //
                                    //
                                    setButtonDisabled(_=> { return true; });
                                    const obj: FeatureRequestModel = {
                                        userID: userID,
                                        requestType: requestType,
                                        title: title ,
                                        description: description,
                                        reason: (requestType === FeatureRequestType.feature) ? reason : "" ,
                                        requestID: undefined,
                                    }
                                    addFeatureRequest(obj, (completedWithoutError) => {
                                        if (completedWithoutError) {
                                            setButtonDisabled(_=> { return true; });
                                            // Until We Build Out Natively On Mobile We Will Use This Log To Close The View
                                            console.log("task_for_nav_complete__navigate_back");
                                        }
                                    })
                                } else {
                                    const requestField = document.querySelector(".DesiredFeaturesView .disired-features-container .field-items-container .field.request");
                                    // @ts-ignore
                                    requestField.style.border = "2px solid var(--app-red-80)"
                                        setButtonDisabled(_=> { return false; });
                                }
                            }}
                            >{ (requestType === FeatureRequestType.feature) ? "request" : "inform"}</button>

                    </div>
                </div>
            }
        </>
   )
}

export default DesiredFeaturesMobile;