import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../fb/fbSetup";
import { v } from "./v";
import ProductView from "../../Pages/Product/ProductView";

const GoogleAdSiteAcessCrawler = () => {
    const [ isAuth, setIsAuth ] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const u = urlParams.get('u');  
        const p = urlParams.get('p');
        const t = `${urlParams.get('t')}`;
        if (t === v) {
            signInWithEmailAndPassword(auth, u, p)
            .then(sucess => {
                if (sucess.user.uid) {
                    setIsAuth(true);
                }
            })
            .catch(error => {
                setIsAuth(false);
            });
        }
    }, []);

    useEffect(()=> {

    }, [])

    return (
        <>
        { isAuth && 
            <ProductView/>
        }
        </>
    )
}

export default GoogleAdSiteAcessCrawler;