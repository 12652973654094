import { signInWithCustomToken, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState, useContext } from "react";
import { auth, db } from "../../fb/fbSetup";
import { ArrayOfComm } from "../../Models/Models";
import { CommunityInterestDataModel, saveFilterData, SelectTopicsOfInterestModel } from "./FilterBackendController";
import Lottie from "lottie-react";
import loading_Lottie from "../../../Resources/Lottie/loading.json";
import JumpInView, { CloseJumpInPopup } from "../../Components/AuthCard/AuthCard";
import "./FilterView.css";
import { doc, getDoc } from "firebase/firestore";
import ControllerContext from "../../Contexts/ControllerContexts";

interface ReccommendModel {
    recommendations: {
        userID: string,
        itemsOfInterest: CommunityInterestDataModel[],
        customInterestPrompt: string[],
        sentenceTopics: string[],
        dateAdded: Date
    }[]
}

const ArrayOfCommunityData: CommunityInterestDataModel[] = ArrayOfComm.map(item => { return { title: item, selected: false } });
const FilterView = () => {
    const { showFilterView, toggleFilterView } = useContext(ControllerContext)
    const [ userID, setUserID ] = useState(undefined);
    const [ isUploading, setIsUploading ] = useState(false);
    const [ askToSignIn, setAskToSignIn ] = useState(false);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tkn = urlParams.get('t');  
        const ud = urlParams.get('ud');     
        if (tkn && ud) {
            signInWithCustomToken(auth, tkn)
            .then(sucess => {
                if (sucess.user.uid) {
                    setUserID(_=> { return  `${ud}`; }); 
                    getRecommendations(ud);
                } else {
                    onAuthStateChanged(auth, (user) => {
                        if (user && user.uid) {
                            setUserID(_=> { return user.uid });
                            getRecommendations(user.uid);
                        } else {
                            setShowFirstLoadingView(_=> { return false; });
                            setAskToSignIn(_=> { return true; });
                        }
                    });
                }
            })
            .catch(error => {
                onAuthStateChanged(auth, (user) => {
                    if (user && user.uid) {
                        setUserID(_=> { return user.uid });
                        getRecommendations(user.uid);
                    } else {
                        setShowFirstLoadingView(_=> { return false; });
                        setAskToSignIn(_=> { return true; });
                    }
                });
            });
        } else {
            onAuthStateChanged(auth, (user) => {
                if (user && user.uid) {
                    setUserID(_=> { return user.uid });
                    getRecommendations(user.uid);
                } else {
                    setShowFirstLoadingView(_=> { return false; });
                    setAskToSignIn(_=> { return true; });
                }
            });
        }
    }, []);
    //
    //
    //
    const [ itemsOfInterest, setItemsOfInterests ] = useState<CommunityInterestDataModel[]>(ArrayOfCommunityData);
    const [ customInterestPrompt, setCustomInterestPrompt ] = useState<string[]>([]);
    const [ topicOne, setTopicOne] = useState("");
    const [ topicTwo, setTopicTwo] = useState("");
    const [ topicThree, setTopicThree] = useState("");
    const [ topicFour, setTopicFour] = useState("");
    const [ topicFive, setTopicFive] = useState("");
    //
    const [ reccomendationsDoc, setReccomendationsDoc ] = useState<ReccommendModel | undefined >(undefined);
    const [ showFirstLoadingView, setShowFirstLoadingView] = useState(true);
    function getRecommendations(userID: string) {
        const emptyDoc : ReccommendModel = { recommendations: [] };
        const ref = `user_generated_recommendation_controlls/${userID}`;
        getDoc(doc(db, ref))
        .then(document => {
            const reccommendations_filters = document.exists() ? document.data() as ReccommendModel : undefined;
            if (reccommendations_filters && reccommendations_filters.recommendations.length) {
                const size = (reccommendations_filters.recommendations.length - 1);
                setReccomendationsDoc(_=> { return reccommendations_filters; });
                setCustomInterestPrompt(_=> { return reccommendations_filters.recommendations[size].customInterestPrompt; });
                setItemsOfInterests(_=> { return reccommendations_filters.recommendations[size].itemsOfInterest; });
                if (reccommendations_filters.recommendations[size].sentenceTopics[0]) setTopicOne(_=> { return reccommendations_filters.recommendations[size].sentenceTopics[0]; });
                if (reccommendations_filters.recommendations[size].sentenceTopics[1]) setTopicTwo(_=> { return reccommendations_filters.recommendations[size].sentenceTopics[1]; });
                if (reccommendations_filters.recommendations[size].sentenceTopics[2]) setTopicThree(_=> { return reccommendations_filters.recommendations[size].sentenceTopics[2]; });
                if (reccommendations_filters.recommendations[size].sentenceTopics[3]) setTopicFour(_=> { return reccommendations_filters.recommendations[size].sentenceTopics[3]; });
                if (reccommendations_filters.recommendations[size].sentenceTopics[4]) setTopicFive(_=> { return reccommendations_filters.recommendations[size].sentenceTopics[4]; });
                setShowFirstLoadingView(_=> { return false; });
            } else {
                setShowFirstLoadingView(_=> { return false; });
            }
        }).catch(() => {
            setShowFirstLoadingView(_=> { return false; });
        })
    }
    //
    //
    // View Animation Controller:
    const [ showJumpInSignUp, setShowJumpInSignUp ] = useState<boolean>(false);
    const [ animateView, setAnimateView ] = useState<boolean>(false);
    const [ renderView, setRenderView ] = useState<boolean>(false);
    useEffect(() => {
        if(showJumpInSignUp) {
            setRenderView(showJumpInSignUp);
            setTimeout(() => {
                setAnimateView(showJumpInSignUp);
            }, 150);
        } else {
            setAnimateView(showJumpInSignUp);
            setTimeout(() => {
                setRenderView(showJumpInSignUp);
            }, 400);
        }
    }, [ showJumpInSignUp ]);
    //
    //
    //
    return (
        <div className={`FilterView${ showFilterView ? " is-app-mobile" : "" }`}>
            { showFilterView &&
                <div className="cancel-container" onClick={_=>{toggleFilterView(false)}}>
                    <label className="cancel-label">cancel</label>
                </div>
            }
            { !showFirstLoadingView &&
                <div className="scrollview">
                    <div className="contentview">
                        {/* <h1>Filter View:</h1>
                        <h3>Big World Project's "Control What You See"</h3> */}
                        <div className="sentence-prompt-container">
                            <div className="oneSentence-content-view">
                                <div className="oneSentence-field-and-items">
                                    <h1 className="content-title">One Sentence Explaination:</h1>
                                    <label className="content-description">We give you the opportunity to give an explaination of your interests in a sentence.</label>
                                    <input className="oneSentence-field" type={"text"} placeholder={"Add a sentence..."} value={ topicOne } onChange={e => { setTopicOne(_=> { return e.target.value; })}}/>
                                    <input className="oneSentence-field" type={"text"} placeholder={"Add a sentence..."} value={ topicTwo } onChange={e => { setTopicTwo(_=> { return e.target.value; })}}/>
                                    <input className="oneSentence-field" type={"text"} placeholder={"Add a sentence..."} value={ topicThree } onChange={e => { setTopicThree(_=> { return e.target.value; })}}/>
                                    <input className="oneSentence-field" type={"text"} placeholder={"Add a sentence..."} value={ topicFour } onChange={e => { setTopicFour(_=> { return e.target.value; })}}/>
                                    <input className="oneSentence-field" type={"text"} placeholder={"Add a sentence..."} value={ topicFive } onChange={e => { setTopicFive(_=> { return e.target.value; })}}/>
                                </div>
                            </div>
                        </div>

                        <div className="custom-intersts">
                            <CustomTopicsOfInterest customInterestPrompt={ customInterestPrompt } setCustomInterestPrompt={ setCustomInterestPrompt }/>
                        </div>
                        <div className="top-categories">
                            <SelectTopicsOfInterest itemsOfInterest={itemsOfInterest} setItemsOfInterests={setItemsOfInterests}/>
                        </div>
                        <div className="disposition"></div>
                        <div className="weight-of-recommendations"></div>

                        <button className="save-button" style={{ 
                            opacity: isUploading ? "0" : "1",
                            pointerEvents: isUploading ? "none" : "all",
                            transform: `scale(${isUploading ? 0 : 1 })`,
                        }} onClick={() => {
                            if (askToSignIn) {
                                setShowJumpInSignUp(_=> { return true; });
                            } else {
                                setIsUploading(_=> { return true; });
                                if (userID) {
                                    saveFilterData(userID, itemsOfInterest, customInterestPrompt, [ topicOne, topicTwo, topicThree, topicFour, topicFive ], (sucess) => {
                                        if (!sucess) setIsUploading(_=> { return false; });
                                        if (sucess && !showFilterView) window.location.href = "/"
                                        if (showFilterView) toggleFilterView(false);
                                    });
                                }
                            }
                        }}>save</button>
                        { isUploading &&
                            <div className="loading-card" style={{ width: "50px", height: "50px" }}>
                                <Lottie animationData={ loading_Lottie } loop={true}/>
                            </div>
                        }
                        {/* <button className="save-button" onClick={() => { testMethod(); }}>test</button> */}
                    </div>
                </div>
            }
            { (renderView) &&
                <div className="auth-jump-in-pop-out" style={ { opacity: animateView ? "100%" : "0%"} }>
                    <CloseJumpInPopup setShowJumpInSignUp={ setShowJumpInSignUp }/>
                    <JumpInView animateView={ animateView }/>
                </div>
            }
            { (showFirstLoadingView) &&
                <div className="loading-card" style={{
                    height: "80px",
                    width: "80px",
                    zIndex: "1000000"
                }}>
                    <Lottie animationData={ loading_Lottie } loop={true}/>
                </div>
            }
        </div>
    )
}

export default FilterView;


interface SentencePrompModel {
    promptID: string;
    scentence: string;
    dateAdded: string;
    promptCategory: PromptCategories;
}

enum PromptCategories {
    interest = "interest",
    humor = "Humor",
    couldBeInterest = "couldBeInterest",
    meaning = "meaning",
}

const sentencePropmItem = () => {
    return (
        <div></div>
    );
}


//
//
const SelectTopicsOfInterest = ({itemsOfInterest, setItemsOfInterests }:SelectTopicsOfInterestModel) => {
    return (
        <div className="topics-of-inteterst-scrollview-view">
            <div className="topics-of-interets-content-view">
                <h2 className="topics-of-interest-title">Select Topics That Interest You</h2>
                <p className="topics-of-interest-description">The selections below will help us recommend content to you.</p>
                <div className="topics-of-interest-container">
                    {
                        itemsOfInterest.map((item, index) => {
                            return (
                                <div key={ index } className={`topic-item${ item.selected ? " active" : ""}`} onClick={() => {
                                    let list = itemsOfInterest.map((elem, i) => {
                                        if (i === index) { return { title: elem.title, selected: !elem.selected };
                                        } else { return elem; }
                                    })
                                    setItemsOfInterests(list);
                                }}>
                                    <label className="topic-title">{ item.title }</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}


//
// customInterestPrompt
interface CustomTopicsProps {
    customInterestPrompt: string[];
    setCustomInterestPrompt: React.Dispatch<React.SetStateAction<string[]>>;

}
const CustomTopicsOfInterest = ({ customInterestPrompt, setCustomInterestPrompt }: CustomTopicsProps) => {
    const [ interest, setInterest] = useState("");
    return (
        <div className="topics-of-inteterst-scrollview-view interests">
            <div className="topics-of-interets-content-view">
                <h2 className="topics-of-interest-title">Custom Categories of Interests</h2>
                <p className="topics-of-interest-description">Please attempt to keep these interest to one word.</p>


                <div className="interest-field-and-items">
                    <input className="interest-field" type={"text"} placeholder={"Add a topic of interest..."} value={ interest } onChange={e => { setInterest(_=> { return e.target.value; })}}/>
                    <button type="button" className="add-interest" onClick={() => {
                        if (interest && interest.trim() !== "") {
                            setCustomInterestPrompt(list => {
                                let newList = [...list, interest]
                                return newList;
                            });
                            setInterest(_=> { return ""; });
                        }
                    }}>add</button>
                </div>


                <div className="topics-of-interest-container remove">
                    {
                        customInterestPrompt.map((item, index) => {
                            return (
                                <div key={ index } className={`topic-item`}>
                                    <label className="topic-title">{ item }</label>
                                    <button className="remove-item" onClick={() => {
                                        let items = customInterestPrompt.filter(i => i !== item );
                                        setCustomInterestPrompt(_=>{ return items; });
                                    }}>x</button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}