import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../Contexts/UserContext";
import { ApplicationName } from "../../Models/Models";
import logo from "../../../Resources/AppIcon.png"// "../../../Resources/AppIcon.png"
import ControllerContext from "../../Contexts/ControllerContexts";
interface Type {
    fullView?: boolean;
    not_visble_view_name: string;
    view_purpose: string;
    custom_message?: string;
}

export default function NotSignedInView({ fullView, not_visble_view_name, view_purpose, custom_message } : Type) {
    const { userData } = useContext(UserContext);
    const { toggleJumpInSignUp } = useContext(ControllerContext);
    
    useEffect(() => {
        // @ts-ignore  
        if (userData.NOT_AN_AUTHENTICATED_USER) {
            // @ts-ignore  
            import('./NotSignedInView.css');
        }
    }, [])
    return (
        <>
            { fullView && <span className="NotSignedInView-full-view-cover" /> }

            {/* @ts-ignore   */}
            { (userData.NOT_AN_AUTHENTICATED_USER) &&
                <div className="NotSignedInView"> 
                    <h1 className='not-signed-in-text'>{ 
                        (custom_message && custom_message.trim() !== "") ?
                        custom_message
                        :
                        `Ready to dive in? Sign in now to enjoy ${not_visble_view_name}.`
                     }</h1>
                     {(view_purpose && view_purpose.trim() !== "" ) && <div className="description" dangerouslySetInnerHTML={
                        { __html:
                        `<span style="font-size: 19px; font-weight: 700;">What is ${not_visble_view_name}?</span> ${ view_purpose }`
                        }
                    }/>}
                    <button className="call-to-action" onClick={ _ => { toggleJumpInSignUp(true) } }>Junp In</button>
                </div>
            }
        </>
    )
}