import { UrlObjectModel } from "../fb/dbMethods";
import { UserContentFilterModel, UserPersonalModel } from "../Recomendations/Recomendations";

export const appNameList = [ "RIGORE", "Rigueur", "Riggs", "Søren", "Sørenk", "Clago", "Smrtville", "Auessey", "Apallo Proj.", "Newtopos", "Plaetoe", "Kryptopia", "Newtopia", "Newtopos.", "Big World Project" ,"Codename" ];
export const ApplicationName = appNameList[ appNameList.length - 2 ];
const fakeDescrip = "XYZ Corporation is a cutting-edge technology firm that specializes in developing AI-driven solutions for businesses, revolutionizing industries with its innovative products.";
const realDescrop = "Open for everyone but built for nerds. Join here to laugh, connect, and share ideas."
// const realDescrop = "Built for smart people. Join here to laugh, connect, and share ideas."
// const realDescrop = "Built for bright people to laugh, connect, and share ideas."
// "A corner of the web for bright people to have a good time, share ideas, and make friends."
// "A corner of the web here for the good, bright, and skillful people of the world."
// "A corner of the web built for bright and excited people in the world."
export const ApplicationDescription = realDescrop; // fakeDescrip;

export const ArrayOfComm = [
    "Business",
    "Economics",
    "Makeup",
    "Science",
    "Biology",
    "Biochemistry",
    "Chemistry",
    "Physics",
    "Astronomy",
    "Geology",
    "Earth science",
    "Oceanography",
    "Botany",
    "Neuroscience",
    "Zoology",
    "Social Science",
    "Psychology",
    "Technology",
    "Engineering",
    "Software Engineering",
    "Civil Engineering",
    "Chemical Engineering",
    "Mechanical Engineering",
    "Electrical Engineering",
    "Industrial Engineering",
    "Aerospace engineering",
    "Systems engineering",
    "Materials Engineering",
    "Agricultural engineering",
    "Biological engineering",
    "History",
    "Archeology",
    "Art",
    "Mathematics",
    "Film",
    "Artificial Intelligence",
    "Automative",
    "Aviation",
    "Architecture",
    "Music",
    "Music Production",
    "Chaos Theory",
    "Metaphysics",
    "Philosophy",
    "Television",
    "Do It Yourself",
    "Electrical",
    "Plumbing",
    "Carpentry",
    "Construction",
    "Wood Working",
    "HVAC",
    "Theology ",
    "Christianity",
    "Islam",
    "Hinduism",
    "Buddhism",
    "Shinto",
    "Taoism",
    "Judaism",
    "Confucianism",
    "Law",
    "Guns",
    "Comics",
    "Marvel Comics",
    "DC Comics",
    "Anime",
    "Manga",
    "Books",
    "Classic Literature",
    "Writing",
    "Medicine",
    "Games",
    "Chess",
    "Go",
    "Gardening",
    "Travel",
    "Animals",
    "Pets",
    "Dogs",
    "Cats",
    "Birds",
    "Big Cats",
    "Language",
    "Funny",
    "Dark Humor",
    "Diet",
    "Exercise",
    "Meteorology",
    "Paleontology",
    "Genetics",
    "Animation",
    "Cosplay",
    "Knitting & Crochet",
    "Fashion",
]

export const ArrayOfEmojis = [ "👍", "👎", "😂", "🎓", "😢", "🤯", "🥹", "🤙", "🤞", "✈️", "❤️"];



export const PagePaths = {
    Home: "/",
    DunbarColony: "/dunbar-colony",
    Messages: "/messages",
    Profile: "/me",
    LiveChatSession: "/live-chat-session",
    VideoHistory: "/video-history",
    ViewLater: "/view-later",
    Favorites: "/favorites",






    newUser : "/new-user",
    filterControls: "/filter-controls",
    Authenticate: "/auth",
    Test: "/exam",
    MobileLiveSessiion: "/mobile-live-sessions",


    careers: "/careers",
    distributed: "/distributed",
    shared: "/shared-post",
    authMobile: "/auth-mobile",
    mobile: "/mobile",
    mobileDunbarColony: "/mobile-dunbar-colony",


    mobileConstitution: "/mobile-constitution",
    editProfile: "/edit-profile",
    desiredFeatures: "/desired-features",
    mobileMessageThread: "/mobile-message-thread",


    mobileVideoPlayerMVP: "/mobile-video-player-mvp",
    newUserMobile: "/new-user-mobile",
    newUserMobileIOS: "/new-user-mobile-ios",
    commentSectionViewMobile: "/comment-section-view-mobile",
    startDirectMessageChain: "/start-direct-message-chain",


    termsOfService: "/terms-of-service-page",
    about: "/about",
    privacyPolicy: "/privacy-policy",
    deleteAccountRequest: "/delete-account-request",
    eulaAgreement: "/eula-aggreement",
    poem: "/poem",
}

export const addEmailToWaitList_val = "https://us-central1-newtopia-a4327.cloudfunctions.net/addEmailToWaitList";
export const addDeleteAccountRequestt_val = "https://us-central1-newtopia-a4327.cloudfunctions.net/addDeleteAccountRequest";

export interface CurrentUserDataModel {
    userID: string;
    firstName: string;
    lastName: string;
    customUserName: string;
    bio: string;
    posts: LowLevelPostRefferenceModel[]; // Should be used to pull in all posts
    contentColonies: ContentColonyModel[];
    communities: Community[];
    peopleOfCare: string[];
    peopleYouFollow: string[];
    allowDirectMessage: boolean;
    followerCount: number;
    followingCount: number;
    // Will in the future determine if I want the profile of the user to be private
    profilePrivate: boolean;
    //
    prioritizeExperience: boolean; // This is for determinging what to show first project history or professional history
    professionalExperience: ProfessionalExpereinceModel[];
    projectExpience: ProjectExpereinceModel[];
    currentProjectItems: CurrentProjectItems[];
    messagesRefferences: MessageRelationModel[];
    // Self Disclosed Demographic and Psych Type For Content Prefference
    age?: number;
    disclosedUserPsycheType? : UserPersonalModel;
    userContentFilterModel?: UserContentFilterModel;
    //
    TopicsOfInterest?: string[];
    // Dunbar Colony ID Very Important For Know What Community A User Was In..
    DunbarColonyID: string;
    //
    imageProfileImageURLs?: UrlObjectModel;
    //
    feedImages?: FeedImageModel[];
    customUserImages?: string[];
}

export interface FeedImageModel {
    imageID: string;
    fullImageURL : string;
}

export interface ProfessionalExpereinceModel {
    role: string;
    company: string;
    website: string;
    timeThere: { beginning:string, end: string }
}

export interface MessageRelationModel {
    documentID: string;// Should refference the document it is stored under
    // One of these ids below should be the current user
    user_one_ID: string;
    user_one_username: string;
    user_one_firstName: string;
    user_one_lastName: string;
    // User Two
    user_two_ID: string;
    user_two_firstName: string;
    user_two_lastName: string;
    user_two_username: string;
    // Up to date items
    mostRecentMessage: string;
    mostRecentInteractionDate?: Date;
    current_doc_index: number; // If the number of messages in the current index is greater than 100,000 you increment the current doc index by one and begin having messages load in from that session
    //
}

export interface ProjectExpereinceModel {
    projectID: string;
    projectName: string;
    ideaInEightyCharacters: string;
    summaryOfIdea: string;
    websiteOrLink: string;
    stateOfProject: StateOfProject;
}

export interface CurrentProjectItems { 
    projectID: string;
    projectTitle: string;
    projectInEightyCharacters: string;
    projectDescription: string;
    checkPoints: { checkPointTitle: string; completed : boolean; checkPointDescription: string; }[]
}

// The major point of existence... Not to be liked, but loved. That includes a very few exceptional in spirit.
export enum StateOfProject {
    done = "done",
    mostlyDone = "mostlyDone",
    inprogress = "inprogress",
    mostlyDoneOnHold = "mostlyDoneOnHold",
    onHold = "onHold",
    notTouchingAgain = "notTouchingAgain",
}

/**  
 * This is given its name due to the fact that the model is not all that complicated but should be able to reffrence the content in question
 * @community: string -> The community that the post was added to even if it is in multiple communities
 * @postID:string; string -> This includes the post ID to get a refference to a document of interest
 */
export interface LowLevelPostRefferenceModel {
    community: string; 
    postID:string; 
}

/**
 * This model is used in order to create a cluster of posts that one finds meaningful and certainly meaningful enough to share
 * @clusterID : string;
 * @clusterTitle : string;
 * @publicStatus : boolean; 
 */
export interface ContentColonyModel {
    clusterID : string;
    clusterTitle : string;
    publicStatus : boolean; // If set to true then the cluster is public else it is a private cluster... should be able to be turned on simply with a toggle
}

/**
 * Communities the user is in:
 *  @communityID : string -> ID Of The Community
 *  @communityTitle : string -> Community Title
 *  @communityLevel : string -> Level of skill one has reached on this topic
 */

export interface Community {
    communityID: string;
    communityTitle: string;
    communityLevel: SkillLevelOptions;
}

/**
 * @solid -> Post View
 * @knowledgeable -> Post Drafts View
 * @wise -> Content Colony Groups Assembled
 * @expert -> Interactions
 */
export enum SkillLevelOptions {
    solid = "solid",
    knowledgeable = "knowledgeable",
    wise = "wise",
    expert = "expert",
}

/**
 * @Posts -> Post View
 * @Drafts -> Post Drafts View
 * @ContentColony -> Content Colony Groups Assembled
 * @Interactions -> Interactions
 */
export enum ContentViewOptions {
    Posts,
    Drafts,
    ContentColony,
    Interactions,
}








export interface InvitationDocModel {
    invitedBy: string;
    invitationTo: string;
    inviteCreatedOn: Date;
    //
    inviteAccepted: boolean;
    inviteAcceptedOn?: Date;
    //
    invitationCode: string;
    invitationID: string;
}



export interface ExamScoreDocModel {
    userID: string;
    dateOfExam: Date;
    score: number;
    didPass: boolean;
}
/**
 * Model For Testing:
*/
export interface TestModel {
    multipleChoiceQuestion: MultpleChoice[];
    multiSelectQuestions: MultiSelect[]; 
    trueOrFalseQuestions: TrueOrFalse[]; 
}

export enum QuestionType {
    MultpleChoice = "MultpleChoice",
    MultiSelect = "MultiSelect",
    TrueOrFalse = "TrueOrFalse",
}

/**
 * question: string
 * exampleImages: { imageID: string, imageTitle: string; imageURL: string }[]
 * correctAnswer: boolean
 * a
 * b
 * c
 * d
 * e
 * f
 * questionType: QuestionType.TrueOrFalse
 * randomIndexer: number
 */
export interface MultpleChoice {
    question: string;
    exampleImages: { imageID: string, imageTitle: string; imageURL: string }[]; // A series of image Id's this is for denoting things like, image a or image b etc.
    correctAnswer: string; // Setting the correct value in the code should only be a condition that can be selected from the options of choices
    a: string;
    b: string;
    c?: string;
    d?: string;
    // There should only be a few options as the default, but the option to add 6 options is availble
    e?: string;
    f?: string;
    questionType: QuestionType.MultpleChoice;
    // Random Value In Data To Sort Items
    randomIndexer?: number;
}

//
// Chilling by myself. I get attention I don't need.
//

/**
 * question: string
 * exampleImages: { imageID: string, imageTitle: string; imageURL: string }[]
 * correctAnswer: boolean
 * a
 * b
 * c
 * d
 * e
 * f
 * questionType: QuestionType.TrueOrFalse
 * randomIndexer: number
 */
export interface MultiSelect {
    question: string;
    exampleImages: { imageID: string, imageTitle: string; imageURL: string }[]; // A series of image Id's this is for denoting things like, image a or image b etc.
    correctAnswer: string[]; // Should allow a list of image IDs
    a: string;
    b: string;
    c?: string;
    d?: string;
    // There should only be a few options as the default, but the option to add 6 options is availble
    e?: string;
    f?: string;
    questionType: QuestionType.MultiSelect;
    // Random Value In Data To Sort Items
    randomIndexer: number;
}

/**
 * TrueOrFalse:
 * question: string
 * exampleImages: { imageID: string, imageTitle: string; imageURL: string }[]
 * correctAnswer: boolean
 * questionType: QuestionType.TrueOrFalse
 * randomIndexer: number
 */
export interface TrueOrFalse {
    question: string;
    exampleImages: { imageID: string, imageTitle: string; imageURL: string }[]; // A series of image Id's this is for denoting things like, image a or image b etc.
    correctAnswer: boolean;
    questionType: QuestionType.TrueOrFalse;
    // Random Value In Data To Sort Items
    randomIndexer: number;
}



export const exampleTrueFalse: TrueOrFalse[] = [
    {
        question: "True of false? The Earth is flat.",
        exampleImages: [],
        correctAnswer: false,
        questionType: QuestionType.TrueOrFalse,
        randomIndexer: 0,
    },
    {
        question: "True of false? 'Die Lit' by PlaBoi Carti was a good album?",
        exampleImages: [],
        correctAnswer: true,
        questionType: QuestionType.TrueOrFalse,
        randomIndexer: 0,
    },
];

export const exampleMultpleChoice: MultpleChoice[] = [
    {
        question: "What types of graph transformation are shown below?",
        exampleImages: [
            { imageID: "string", imageTitle: "1a", imageURL: "string", },
        ],
        correctAnswer: "aaaaa",
        a: "translation",
        b: "reflection",
        c: "renumeration",
        d: "denominator",
        e: undefined,
        f: undefined,
        questionType: QuestionType.MultpleChoice,
        randomIndexer: 0,
    },
    {
        question: "Which image below best aligns with the idea of a star gear?",
        exampleImages: [
            { imageID: "string", imageTitle: "1", imageURL: "string", },
            { imageID: "string", imageTitle: "2", imageURL: "string", },
            { imageID: "string", imageTitle: "3", imageURL: "string", },
            { imageID: "string", imageTitle: "4", imageURL: "string", },
        ],
        correctAnswer: "1",
        a: "1",
        b: "2",
        c: "3",
        d: "4",
        e: undefined,
        f: undefined,
        questionType: QuestionType.MultpleChoice,
        randomIndexer: 0,
    },
];

export const exampleMultiSelect: MultiSelect[] = [
    {
        question: "Example 1 Multi Select",
        exampleImages: [],
        correctAnswer: ["aaaaa", "bbbbb"],
        a: "aaaaa",
        b: "bbbbb",
        c: "ccccc",
        d: "ddddd",
        e: undefined,
        f: undefined,
        questionType: QuestionType.MultiSelect,
        randomIndexer: 0,
    },
    {
        question: "Example 2 Multi Select",
        exampleImages: [],
        correctAnswer: ["aaaaa", "ccccc"],
        a: "aaaaa",
        b: "bbbbb",
        c: "ccccc",
        d: "ddddd",
        e: undefined,
        f: undefined,
        questionType: QuestionType.MultiSelect,
        randomIndexer: 0,
    },
    {
        question: "Example 2 Multi Select",
        exampleImages: [],
        correctAnswer: ["aaaaa", "ddddd"],
        a: "aaaaa",
        b: "bbbbb",
        c: "ccccc",
        d: "ddddd",
        e: undefined,
        f: undefined,
        questionType: QuestionType.MultiSelect,
        randomIndexer: 0,
    },
];




export const CommunityObjectArray = {
    0 : { title: 'Business', active: false },
    1 : { title: 'Economics', active: false },
    2 : { title: 'Makeup', active: false },
    3 : { title: 'Scinece', active: false },
    4 : { title: 'Biology', active: false },
    5 : { title: 'Biochemistry', active: false },
    6 : { title: 'Chemistry', active: false },
    7 : { title: 'Physics', active: false },
    8 : { title: 'Astronomy', active: false },
    9 : { title: 'Geology', active: false },
    10 : { title: 'Earth science', active: false },
    11 : { title: 'Oceanography', active: false },
    12 : { title: 'Botany', active: false },
    13 : { title: 'Neuroscience', active: false },
    14 : { title: 'Zoology', active: false },
    15 : { title: 'Social Science', active: false },
    16 : { title: 'Psychology', active: false },
    17 : { title: 'Technology', active: false },
    18 : { title: 'Engineering', active: false },
    19 : { title: 'Software Engineering', active: false },
    20 : { title: 'Civil Engineering', active: false },
    21 : { title: 'Chemical Engineering', active: false },
    22 : { title: 'Mechanical Engineering', active: false },
    23 : { title: 'Electrical Engineering', active: false },
    24 : { title: 'Industrial Engineering', active: false },
    25 : { title: 'Aerospace engineering', active: false },
    26 : { title: 'Systems engineering', active: false },
    27 : { title: 'Materials Engineering', active: false },
    28 : { title: 'Agricultural engineering', active: false },
    29 : { title: 'Biological engineering', active: false },
    30 : { title: 'History', active: false },
    31 : { title: 'Archeology', active: false },
    32 : { title: 'Art', active: false },
    33 : { title: 'Mathematics', active: false },
    34 : { title: 'Film', active: false },
    35 : { title: 'Artificial Intelligence', active: false },
    36 : { title: 'Automative', active: false },
    37 : { title: 'Aviation', active: false },
    38 : { title: 'Architecture', active: false },
    39 : { title: 'Music', active: false },
    40 : { title: 'Music Production', active: false },
    41 : { title: 'Chaos Theory', active: false },
    42 : { title: 'Metaphysics', active: false },
    43 : { title: 'Philosophy', active: false },
    44 : { title: 'Do It Yourself', active: false },
    45 : { title: 'Electrical', active: false },
    46 : { title: 'Plumbing', active: false },
    47 : { title: 'Carpentry', active: false },
    48 : { title: 'Construction', active: false },
    49 : { title: 'Wood Working', active: false },
    50 : { title: 'HVAC', active: false },
    51 : { title: 'Theology ', active: false },
    52 : { title: 'Christianity', active: false },
    53 : { title: 'Islam', active: false },
    54 : { title: 'Hinduism', active: false },
    55 : { title: 'Buddhism', active: false },
    56 : { title: 'Shinto', active: false },
    57 : { title: 'Taoism', active: false },
    58 : { title: 'Judaism', active: false },
    59 : { title: 'Confucianism', active: false },
    60 : { title: 'Law', active: false },
    61 : { title: 'Guns', active: false },
    62 : { title: 'Comics', active: false },
    63 : { title: 'Marvel Comics', active: false },
    64 : { title: 'DC Comics', active: false },
    65 : { title: 'Anime', active: false },
    66 : { title: 'Manga', active: false },
    67 : { title: 'Books', active: false },
    68 : { title: 'Classic Literature', active: false },
    69 : { title: 'Writing', active: false },
    70 : { title: 'Medicine', active: false },
    71 : { title: 'Games', active: false },
    72 : { title: 'Chess', active: false },
    73 : { title: 'Go', active: false },
    74 : { title: 'Gardening', active: false },
    75 : { title: 'Travel', active: false },
    76 : { title: 'Animals', active: false },
    77 : { title: 'Pets', active: false },
    78 : { title: 'Dogs', active: false },
    79 : { title: 'Cats', active: false },
    80 : { title: 'Birds', active: false },
    81 : { title: 'Zoology', active: false },
    82 : { title: 'Language', active: false },
    83 : { title: 'Funny', active: false },
    84 : { title: 'Dark Humor', active: false },
    85 : { title: 'Diet', active: false },
    86 : { title: 'Exercise', active: false },
    87 : { title: "Television", active: false },
    88 : { title: "Meteorology", active: false },
    89 : { title: "Paleontology", active: false },
    90 : { title: "Genetics", active: false },
    91 : { title: "Animation", active: false },
    92: { title: "Cosplay", active: false },
    95: { title: "Knitting & Crochet", active: false },
    96: { title: "Fashion", active: false }
}



// <a href="https://www.flaticon.com/free-icons/plus" title="plus icons">Plus icons created by Freepik - Flaticon</a>
// Doing full text search:
// https://artem.krylysov.com/blog/2020/07/28/lets-build-a-full-text-search-engine/
// Scroll Icon
// <a href="https://www.flaticon.com/free-icons/history" title="history icons">History icons created by Freepik - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/app" title="app icons">App icons created by Freepik - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/app" title="app icons">App icons created by Freepik - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/brands-and-logotypes" title="brands and logotypes icons">Brands and logotypes icons created by Freepik - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/save" title="save icons">Save icons created by Freepik - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/save" title="save icons">Save icons created by Freepik - Flaticon</a>
// eyeIcon: <a href="https://www.flaticon.com/free-icons/eye" title="eye icons">Eye icons created by Gregor Cresnar - Flaticon</a>
// send icon <a href="https://www.flaticon.com/free-icons/send" title="send icons">Send icons created by Freepik - Flaticon</a>
// image icon <a href="https://www.flaticon.com/free-icons/photo" title="photo icons">Photo icons created by Pixel perfect - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/settings" title="settings icons">Settings icons created by Ilham Fitrotul Hayat - Flaticon</a>


// Home : <a href="https://www.flaticon.com/free-icons/house" title="house icons">House icons created by vectorsmarket15 - Flaticon</a>
// Messages : <a href="https://www.flaticon.com/free-icons/message" title="message icons">Message icons created by Graphics Plazza - Flaticon</a>
// Colony: <a href="https://www.flaticon.com/free-icons/colony" title="colony icons">Colony icons created by Eucalyp - Flaticon</a>
// Profile:  <a href="https://www.flaticon.com/free-icons/user" title="user icons">User icons created by Freepik - Flaticon</a>
// History: <a href="https://www.flaticon.com/free-icons/user" title="user icons">User icons created by Freepik - Flaticon</a>
// Watch Later: <a href="https://www.flaticon.com/free-icons/clock" title="clock icons">Clock icons created by Ilham Fitrotul Hayat - Flaticon</a>
// Favorites: <a href="https://www.flaticon.com/free-icons/later" title="later icons">Later icons created by SeyfDesigner - Flaticon</a>
// <a href="https://www.freepik.com/free-vector/abstract-horizontal-grid-lines-graph-style-graphic-design_32237069.htm#query=grid%20texture&position=0&from_view=keyword&track=ais">Image by starline</a> on Freepik

// <a href="https://www.flaticon.com/free-icons/add" title="add icons">Add icons created by Ilham Fitrotul Hayat - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/plus-sign" title="plus sign icons">Plus sign icons created by riajulislam - Flaticon</a>
// <a href="https://www.flaticon.com/free-icons/add-image" title="add image icons">Add image icons created by SeyfDesigner - Flaticon</a>

// Photo by Porapak Apichodilok: https://www.pexels.com/photo/person-holding-world-globe-facing-mountain-346885/
// Photo by Julia Volk: https://www.pexels.com/photo/old-stone-cathedral-located-in-countryside-5272983/

// Good
// Photo by Diana Dorčáková: https://www.pexels.com/photo/landscape-photography-of-water-falls-1262803/
// Photo by Pixabay: https://www.pexels.com/photo/adventure-countryside-daylight-environment-301395/
// Photo by Simon Berger: https://www.pexels.com/photo/photo-of-waterfalls-1385474/


/* https://artem.krylysov.com/blog/2020/07/28/lets-build-a-full-text-search-engine/  */
// Ordering by milestones complete at bottom:
// https://codingbeautydev.com/blog/javascript-sort-object-array-by-boolean-property/

// Notifications
// <a href="https://www.flaticon.com/free-icons/notification" title="notification icons">Notification icons created by ghufronagustian - Flaticon</a>

// Not Signed In Icon
// <a href="https://www.flaticon.com/free-icons/user" title="user icons">User icons created by Becris - Flaticon</a>

// Live Sessions
// <a href="https://www.flaticon.com/free-icons/video-call" title="video-call icons">Video-call icons created by Hilmy Abiyyu A. - Flaticon</a>