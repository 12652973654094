import { db, storage, auth } from "../../../fb/fbSetup";
import { doc, collection ,updateDoc, setDoc, getDoc, query, limit, orderBy, getDocs, arrayUnion, runTransaction } from "firebase/firestore";
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";
import { CurrentUserDataModel } from "../../../Models/Models";
import { sizePostPendObj, userprofile_storage_path } from "../../../fb/dbMethods";

export const Dunabar_CommunityStoragePath = "_D_U_N_B_A_R__C_O_M_M_U_N_I_T_Y";
export const Dunbar_ChatStoragePath = "_D_U_N_B_A_R__C_H_A_T";
export const Dunbar_SubthreadStoragePath = "_S_U_B_T_H_R_E_A_D_S"
export const Dunbar_Subthread_Chat_StoragePath = "_S_U_B__T_H_E_A_D__C_H_A_T";
const briefExplaination = "dunbar-colony / colony-id / subthreads / subthread-doc / sub thread chat collection";
// Don't be kind, don't be talkative, be an agent of force. Stay to yourself, work really hard and build really hard things.
/**
 * DunabrChatDocModel:
 * @param date_doc_created: Date -> This is the date the document was created
 * @param colonyMessageThread: DunbarThreadItemModel[] -> This is the array of messages for this doc that users have sent to each other
 */
export interface DunabrChatDocModel {
    date_doc_created: Date;
    colonyMessageThread: DunbarThreadItemModel[];
}

/**
 * DunbarThreadItemModel:
 * @param messageID : string -> this is a message to store each message as something very unique and allow user to reply to particular messages and jump into new threads with someone from a message thread.
 * @param message : string -> this is the content of the message each message should have this or a list of images in an array.
 * @param imageURLs : string[] -> this is the array of images sent by a user in this one particular message.
 * @param from : string -> this is the user id of the user who sent the message.
 * @param messageTime : date -> this is the time and date the user sent out the message.
 * @param ats : string -> this is the list of users this message refers to in the thread. They will be notified that a message directly refers to them.
 * @param like : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param dislike : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param funny : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param sad : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param mindBlown : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param proudOfYou : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param smart : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param yert : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param fingersCrossed : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 * @param love : string[] -> this is a list of ids of users that responded to this in the thread with an emoju response
 */
export interface DunbarThreadItemModel {
    messageID: string;
    message: string; 
    imageURLs: string[]; 
    from: string; 
    messageTime: Date;
    ats? : string[];
    like: string[];
    dislike: string[];
    funny: string[];
    sad: string[];
    mindBlown: string[];
    proudOfYou: string[];
    smart: string[];
    yert: string[];
    fingersCrossed: string[];
    love: string[];
}

export const MaxNumberOfMessagesInADoc = 50000; // Currently set to 50,000
export async function AddANewMessage(
    colonyDocID: string, 
    message: DunbarThreadItemModel, 
    callBack: (sucess: boolean) => void, 
    isSubThread?: boolean, 
    subthreadID?: string
) {
    const defualtChatPath = `${ Dunabar_CommunityStoragePath }/${ colonyDocID }/${ Dunbar_ChatStoragePath }`;
    const subthreadChatPath = `${ Dunabar_CommunityStoragePath}/${ colonyDocID }/${ Dunbar_SubthreadStoragePath }/${ subthreadID }/${ Dunbar_Subthread_Chat_StoragePath }`;
    const threadPath = isSubThread ? subthreadChatPath : defualtChatPath; 
    //
    const ColonyChatRef = collection(db, threadPath);// Stay focused my friend stay in your own space... let them judge... ease up on the gas
    const mostRecentChatDoc = query(ColonyChatRef, orderBy("date_doc_created","desc"), limit(1));
    const getMostRecentChatDoc = await getDocs(mostRecentChatDoc);
    if (getMostRecentChatDoc.size > 0) {
        getMostRecentChatDoc.forEach( async document => {
            const favoritesDoc = document.data() as DunabrChatDocModel;
            if (favoritesDoc.colonyMessageThread.length < MaxNumberOfMessagesInADoc) {
                const firstFavoritesDocRef = `${ threadPath }/${ document.id }`
                const firstTimeFavoritesRef = await updateDoc(doc(db, firstFavoritesDocRef), {
                    colonyMessageThread : arrayUnion( message ),
                })
                .then(status => {
                    console.log("New Dunbar Doc Added");
                    callBack(true);
                })
                .catch(err => {
                    console.log("Issue Uploading Doc")
                    callBack(false);
                })


            } else {
                // Adding A New Document Because Favorites Has Reached It's Max Size
                const docID = uuidGenerator();
                const firstFavoritesDocRef = `${ threadPath }/${ uuidGenerator() }`
                const favoritesDocObject: DunabrChatDocModel = {
                    date_doc_created: new Date(),
                    colonyMessageThread: [ message ],
                }
                const firstTimeFavoritesRef = setDoc(doc(db, firstFavoritesDocRef), favoritesDocObject)
                .then(status => {
                    console.log("New Dunbar Chat Doc Added");
                    callBack(true);
                })
                .catch(err => {
                    console.log("Issue Uploading Doc")
                    callBack(false);
                });
            }// End of if else for item reaching its max count
        }) // End of forEach loop of all the 1 document tha that should be being called for getting the most recent favorites doc
    } else {
        // This is for the favorites list that is created if the user doesn't / hasn't stored any favorites on their user model yet.
        const docID = uuidGenerator();
        const firstFavoritesDocRef = `${ threadPath }/${ docID }`
        const favoritesDocObject: DunabrChatDocModel = {
            date_doc_created: new Date(),
            colonyMessageThread: [ message ],
        }
        const firstTimeFavoritesRef = setDoc(doc(db, firstFavoritesDocRef), favoritesDocObject)
        .then(status => {
            console.log("New Dunbar Chat Doc Added");
            callBack(true);
        })
        .catch(err => {
            console.log("Issue Uploading Doc")
            callBack(false);
        })
    }// End of else statment for the condition where the user is saving an item to favorites for the first time
}


function getRecenteMessages() {
}

interface FilterCritera {
    userToIgnore : string[];
    ranking: {
        usersToPrioritize: string[]; 
    }
}

export function filterCommunityContent(colonyFilters: FilterCritera, messages: DunbarThreadItemModel[]): DunbarThreadItemModel[] {
    let dunbarThreadList: DunbarThreadItemModel[] = messages;
    // All filter happens here
    dunbarThreadList.filter(item => {
        const isMessagesFromIgnoredUsers = colonyFilters.userToIgnore.includes(item.from)
        if (!isMessagesFromIgnoredUsers) {
            return item;
        }
    });// Filters Out User By Their ID.
    
    return dunbarThreadList;
}

/*
 Dunbar Community Backend Setup:
   ( `${ _D_U_N_B_A_R__C_O_M_M_U_N_I_T_Y }/${ colony.id } `)  --> { returns  colony_doc_model }
   ( `${ _D_U_N_B_A_R__C_O_M_M_U_N_I_T_Y }/${ colony.id }/${ _D_U_N_B_A_R__C_H_A_T}` )  --> { returns colony_chat_model }

*/

//  Functions To Be Created Later:
/*_____________________________________*/
// - Remove User From Colony, There By Leaving Space For A New Colony Member, They Have To Be Voted Out By A Large Majority 65% - 75% of the group has to agree.
    // The Above Declaration is for the purpose of offsetting the issue sensitive idiots and remove people that are overwhelming trouble makers... Keep in mind you also can ignore them by filtering them out of the thread. In the thread filters above.
    // To Add on to this if a user posts something and the larger part of the colony has this user ignored... we will find a different colony for them... Keep in mind the point is to find a group that your tempermant fits well with not one that just exists... The small existential few.
// Voting For The Community Leader


export function getImagePathRef() {
    return `DUNBARCOMMUNITYIMAGES/${uuidGenerator()}.jpg`
}

export function getProfileImageRef(userID: string){
    return `${ userprofile_storage_path }/${userID}${ sizePostPendObj.small }.jpg`
}


export function getMessageObject(message: string, userID: string, imageURLs?: string[], ats? : string[]) {
    const messageItem: DunbarThreadItemModel = {
        messageID: uuidGenerator(),
        message: message, 
        imageURLs: imageURLs ? imageURLs : [], 
        from: userID, 
        messageTime: new Date(),
        ats: ats? ats : [], // For now this will always be empty, but later it will be full...
        like: [],
        dislike: [],
        funny: [],
        sad: [],
        mindBlown: [],
        proudOfYou: [],
        smart: [],
        yert: [],
        fingersCrossed: [],
        love: [],
    }

    return messageItem;
}

