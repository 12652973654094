import Compressor from "compressorjs";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useState, useEffect, useContext } from "react";
import uuidGenerator from "../../../../../SharedItems/UUIDGenerator";
import ColorUI from "../../../../Components/ColorUI/ColorUI";
import ControllerContext from "../../../../Contexts/ControllerContexts";
import UserContext from "../../../../Contexts/UserContext";
import { db, storage } from "../../../../fb/fbSetup";
import { Dunabar_CommunityStoragePath } from "../../DunbarBackend/DunbarBackend";
import { DunbarSubThreadModel, dunbarSubThreadStoragePath } from "../../DunbarBackend/DunbarColonyMangament";
import DunbarContext from "../../DunbarContext/DunbarContext";
import './DunbarSubThreadPopUp.css';

interface Props {
    toggleSubThread: boolean;
    setToggleSubThread: React.Dispatch<React.SetStateAction<boolean>>;
}

const DunbarSubThreadPopUp = ({ toggleSubThread , setToggleSubThread }: Props) => {
    const { showErrorPopup } = useContext(ControllerContext)
    const [ subthreadPopUpTitle, setSubthreadPopUpTitle ] = useState<string>("");
    const [ subthreadPopUpDescription, setSubthreadPopUpDescription ] = useState<string>("");
    const [ subthreadImageFile, setSubthreadImageFile ] = useState<File | Blob | undefined>(undefined);
    const [ subthreadSrc, setSubthreadSrc ] = useState<string>("");

    const { userData } = useContext(UserContext);
    //
    async function AddPotentialThread () {
        const subThreadID = uuidGenerator();
        if (subthreadImageFile) {
            uploadImage(subthreadImageFile , subThreadID);
        } else {
            showErrorPopup(
                true, 
                `You're missing a sub-thread image.`, 
                "Error: ",
                "var(--app-red-90)", 
                undefined, 
                true, 
                undefined, 
                0);
        }
    }

    async function uploadImage(imageFile:(File | Blob), subThreadID: string) {
        const storageRef = ref(storage, `${Dunabar_CommunityStoragePath}/${ dunbarSubThreadStoragePath }/${ subThreadID }.jpg`);
        const uploadImage = await uploadBytes(storageRef, imageFile)
        .then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                addPotentialThread(url, subThreadID);
            })
            .catch(err => {
            })
        }).catch(err => {
        });
    }
    
    async function addPotentialThread(subThreadImageURL: string, subThreadID: string) {
        const thisUser = userData.userID;
        const modelObj: DunbarSubThreadModel = { 
            title: subthreadPopUpTitle,
            purpose: subthreadPopUpDescription,
            colonyFounder: thisUser,
            thread_doc_id: subThreadID,
            votes: [ { userID: thisUser, shouldExist: true } ], 
            imagURL: subThreadImageURL,
            date_created : new Date(), // Why am I responsible for everyone. I think people think I owe them something.
        }

        const colonyRef_str: string = `${ Dunabar_CommunityStoragePath }/${ userData.DunbarColonyID }`;
        const colonyRef_doc = doc(db, colonyRef_str);
        const updateColonyDoc = await updateDoc(colonyRef_doc, {
            subthreads: arrayUnion(modelObj),
        })
        .then(status => {
            clearPotentialItem();
        })
        .catch( err => {
            console.log("Error Uploading Message Image: ");
        })
    }

    function clearPotentialItem() {
        setToggleSubThread(_=> { return false; });
        setSubthreadImageFile(_=> { return undefined; });
        setSubthreadSrc(_=> { return ""; });
        setSubthreadPopUpDescription(_=> { return ""; });
        setSubthreadPopUpTitle(_=> { return ""; });
    }

    function SelectMessageImage () {
        const input = document.querySelector('.DunbarSubThreadPopUp input#new-subthread-image-input.new-subthread-image-input');
        if (input) {
            // @ts-ignore
            input.click();
        }
    }

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files: File[] = Array.from(e.target.files)
        const file: File = files[0];
        const fileType = files[0].type;
        const compressImageFile = new Compressor(file, 
            { quality: 0.008, 
                success: async (compressedFile) => {
                    const src = URL.createObjectURL(compressedFile);
                    // setSubthreadSrc(_=> { return src_test; });
                    setSubthreadSrc(_=> { return src; });
                    setSubthreadImageFile(_=> { return compressedFile; });
                }
            })
    }
    

    return (
        <div className="DunbarSubThreadPopUp" style={{ top: toggleSubThread ? "calc(50%)" : "300vh" }}>
            <div className="dunbar-subthread-popup-content-container">
                {/*  */}
                <div className="pop-up-title-description">
                    <h3>Start A New Sub-Thread</h3>
                    <p className="pop-up-purpose">
                        It takes two to talk, but only one to start a conversation. Dunbar Sub-Threads work the same—start one with members of your Dunbar Colony. The Sub-Thread is formed only when at least one other member agrees to participate.
                    </p>
                    {/* <p className="pop-up-purpose">
                        It takes two to talk, but only one to start a conversation. Dunbar Sub-Threads work the same. Start a Sub-Thread with members of your Dunbar Colony.
                        <br/>
                        <br/>
                        After initiating the interest in starting a Sub-Thread the full formation of the Sub-Thread only exist after at least one other colony member agrees that they would like to participate in this sub-thread.
                    </p> */}
                </div>
                {/*  */}
                <div className="sub-thread-pop-up-form-content">
                    {/*  */}
                    <div className="sub-thread-form">
                        <div className="sub-thread-image-and-title-container">
                            <div className="sub-thread-image" onClick={ SelectMessageImage }>
                                {/*  */}
                                { subthreadSrc && <img loading={"lazy"} className="image" src={ subthreadSrc }/> }
                                {/*  */}
                                <div className="image-cover"/>
                                {/*  */}
                                <div className="plus-icon"><span className="v"/><span className="h"/></div>
                                <input className="new-subthread-image-input" id="new-subthread-image-input" type="file" onChange={ handleFileSelected } accept="image/x-png,image/jpeg" style={{ display: "none" }}/>
                            </div>
                            <div className="title-field">
                                <label htmlFor="title">Sub-thread Title:</label>
                                {/* <p className="explaination">This field is meant simply to title the sub-thread you are founding.</p> */}
                                <input 
                                    type={ "text" } 
                                    id="title" 
                                    className="title" 
                                    placeholder="Sub-Thread Title..."
                                    value={ subthreadPopUpTitle }
                                    onChange={e=> { setSubthreadPopUpTitle(_=> { return e.target.value; }) }} 
                                />
                            </div>
                        </div>
                        <div className="decription-field">
                            <label htmlFor="decription">Sub-thread Decription:</label>
                            {/* <p className="explaination">This field is simply for describing the general purpose of the sub thread. 😅 No, pressure. It serves simply to communicate the purpose to potential participators.</p> */}
                            <textarea 
                                id="decription" 
                                className="decription" 
                                maxLength={ 250 } 
                                placeholder="Describe the purpose of the sub-thread... (max character length: 200 )"
                                value={ subthreadPopUpDescription }
                                onChange={e=> { setSubthreadPopUpDescription(_=> { return e.target.value; }) }}
                            />
                        </div>
                    </div>
                    {/*  */}
                    <div className="subthread-itiation-buttons">
                        <button className="cancel" onClick={()=> { clearPotentialItem(); }}>cancel</button>
                        <button className="initiate" onClick={()=> { AddPotentialThread(); }}>initiate</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DunbarSubThreadPopUp;