import { db } from "../../../fb/fbSetup";
import { setDoc, doc, collection, query, orderBy, getDocs, limit } from "firebase/firestore";
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";

const p: string = "_A_N_O_N_Y_M_O_U_S";

export interface AnonymousPostModel {
    postID: string; 
    title: string;
    date_of_post: Date;
    content: string;
    backgroundImageURL?: string;
    backgroundImageID?: string;
    userID?: String; // This is for the user to have the option to associate this post with them if they would like to... we can warn them of the risk. 
    // Response Model
    like: number;
    dislike: number;
    funny: number;
    smart: number;
    sad: number;
    mindBlown: number;
    proudOfYou: number;
    yert: number;
    fingersCrossed: number;
    love: number;

}

export default async function ShareAnonymousContent(
    post: AnonymousPostModel,
    callback: (complete: boolean) => void
) {
    const id = uuidGenerator();
    const ref = `${ p }/${ post.postID }`;
    const _doc = doc(db, ref);
    let d = {
        postID: post.postID ,
        title: post.title,
        content: post.content,
        date_of_post: new Date(),
        // Response Model
        like: 0,
        dislike: 0,
        funny: 0,
        smart: 0,
        sad: 0,
        mindBlown: 0,
        proudOfYou: 0,
        yert: 0,
        fingersCrossed: 0,
        love: 0,
    }
    if (post.backgroundImageURL) d["backgroundImageURL"] = post.backgroundImageURL;
    if (post.backgroundImageID ) d["backgroundImageID"] = post.backgroundImageID;
    if (post.userID) d["userID"] = post.userID;
    await setDoc(_doc, d)
    .then(async (val) => {
        callback( true );
    })
    .catch(err => {  
        callback( false );
    });

}

/**
 
Argument On Behalf Of Capitalism

Capitalism fosters innovation and competition. In a free market, individuals and businesses are incentivized to create better products and services, as they strive to meet consumer demands and outperform rivals. This dynamic environment has led to remarkable technological advancements and increased efficiency. It also allows individuals to pursue entrepreneurship and wealth creation. Capitalism has historically lifted countless people out of poverty, providing opportunities for upward mobility. Additionally, the profit motive can drive businesses to operate efficiently, which benefits consumers through lower prices and better quality products. While not without flaws, capitalism, when regulated appropriately, remains a powerful engine for economic growth, individual empowerment, and societal progress.

*/

export async function _GetAnonymousContent(callback: (posts?: AnonymousPostModel[]) => void) {
    const _coll = collection(db, p);
    const _collQuery = query(_coll, limit(7));
    const docs = await getDocs(_collQuery);
    let list: AnonymousPostModel[] = [];
    docs.forEach(async (document) => {
        let _post = document.data()
        let mDate : any = _post["date_of_post"];
        mDate = mDate.toDate();
        const mDateTime = new Date(mDate);
        _post.date_of_post = mDateTime;
        let post = _post as AnonymousPostModel;
        list.push(post);
        if (docs.size === list.length) {
            callback(list);
        }
    });
    if (docs.empty) {
        callback(undefined);
    }

}