import React, { useState, useEffect, useContext } from "react";
import './PostController.css';
import uuidGenerator, { AddingEnters } from "../../../SharedItems/UUIDGenerator";
import { auth, db } from '../../fb/fbSetup';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { doc , query, serverTimestamp } from "firebase/firestore";
import { 
	emojiResponsesDocTitle,
	postsDocTitle,
	ContentModel,
	TranscriptionModeType,
	PostType,
	postContent,
	InteractionObjectModel,
	uploadImagesForBasedPost,
} from '../../fb/dbMethods';
import MuxUploader, { MuxUploaderFileSelect, MuxUploaderProgress, MuxUploaderDrop } from "@mux/mux-uploader-react";
import { MathParser_New, ChemistryModeParse, CodeModeParse, DefaultModeParse } from "../../Components/TextTransformers/MathModeTransformer";
import UserContext from "../../Contexts/UserContext";
import { Community, SkillLevelOptions } from "../../Models/Models";
import Compressor from "compressorjs";
import ControllerContext from "../../Contexts/ControllerContexts";

interface TemporaryPostImageModel {
	file: ( File | Blob ),
	blob_src: string,
	id: number,
}
//
interface PostControllerProps { showMe : boolean;  setCallPostController: React.Dispatch<React.SetStateAction<boolean>>; }
const PostController = ({ showMe, setCallPostController }: PostControllerProps)  => {
	const { userData } = useContext(UserContext);
	const { showErrorPopup } = useContext(ControllerContext);
	const [ community, setCommunity] = useState<Community | undefined>(undefined);
	const userID  = userData.userID;
	const [ title, setTitle ] = useState('');
	const [ postType, setPostType ] = useState<PostType>( PostType.textOnly );
	const [ transcriptionModeType, setTranscriptionModeType ] = useState<TranscriptionModeType>(TranscriptionModeType.Default);
	const [ postTextContent, setPostTextContent ] = useState<string>("");
	const [ ephimeralText, setEphimeralText ] = useState("");
	// Video Post:
	const [ baseURL, setBaseURL ] = useState< string | undefined >(undefined);
	const [ videoID, setVideoID ] = useState< string | undefined >(undefined);
	const [video_assetID, setVideo_assetID ] = useState< string | undefined >(undefined);
	const [ videoPlaybackID, setVideoPlaybackID ] = useState<string | undefined>(undefined);
	const defaultCommunity: Community = {
		communityID: "default",
		communityTitle: "default",
		communityLevel: SkillLevelOptions.knowledgeable,
	}
	//
	const API_URL = "https://us-central1-newtopia-a4327.cloudfunctions.net/app/upload-url";
	const options = { method: 'GET', mode: 'cors', headers:{ "Access-Control-Allow-Origin": "*", "Content-Type": "application/json",} };
	useEffect(()=> {
		if (postType === PostType.longFormVideo || postType === PostType.shortFormVideo) {
			// @ts-ignore
			fetch( API_URL , { options })
			.then(res => {
				return res.json();
			}).then( data => {
				console.log(data);
				//@ts-ignore
				if (data && data.url) setBaseURL(_=> { return data.url;} );
				if (data && data.id) setVideoID(_=> { return data.id;} );
			}).catch( err => {
				console.log('err: ', err);
			})
		}
	}, [ postType ]);
	// Image Post State Vaibles:
	// Image Post State Vaibles:
	// Image Post State Vaibles:
	const [ imageFiles, setImageFiles ] = useState<TemporaryPostImageModel[]>([]); // Should upload an image

	function PostTypeVal( type : PostType) : string {
		let str = "";
		switch (type) {
			case PostType.imagePosts:
				str = "Image Based Post";
			break;
				case PostType.shortFormVideo:
				str = "Short Form Video Post";
			break;
				case PostType.longFormVideo:
				str = "Long Form Video Post";
			break;
			default:
				str = "Text Only";
			break;
		}
		return str;
	}

	function showError(sucess: boolean) {
		if (sucess) {
			showErrorPopup(true, "Your post was added!", "Congradulation!", "var(--app-green-90)", undefined, true, undefined, undefined);
			setCallPostController(_=> { return false; });
		} else {
			showErrorPopup(true, "😕 Something went wrong uploading your post!", "Error", "var(--app-red-90)", undefined, true, undefined, undefined);
			setCallPostController(_=> { return false; });
		}
	}

	return (
		<div className='post-controller-container' style={{ top: showMe ? "0" : "105vh" }}>
			{/* Instruction Controller */}
			<div className='post-controller-content-instructions-view'>
				<h2>Post Controller Instructions</h2>
				<div className="post-ctrlr-instuction-content-view">
					{ transcriptionModeType === TranscriptionModeType.Math && <MathModeInstructions/>}
				</div>
			</div>

			{/* Post Controller */}
			<div className='post-controller-content-scroll-view'>
				<div className='post-controller-content-view'>
					{/* This will have to be re-built as something custom, this is because the styling is not set by us in css it is styled by the browser and device... I will have to do that also for t video speed controller */}
					<div className='close-post-controller' onClick={() => { setCallPostController(false); }}><h1>x</h1></div>

					{/* Post Type */}
					<div className='post-title-container'>
						<label htmlFor="title-label">{ "* Select Your Type Of Post" }</label>
						<label htmlFor="postTextContent" className="description">{"Here for you to select a type of post for your content (Required)"}</label>
						<div className='post-type'>
							<div className='post-type-select'>
								<div className={`post-type-option${ postType === PostType.textOnly ? ' active': ''}`} onClick={()=>{setPostType(_=> {return PostType.textOnly})}}><p>{ PostTypeVal(PostType.textOnly) }</p></div>
								<div className={`post-type-option${ postType === PostType.imagePosts ? ' active': ''}`} onClick={()=>{setPostType(_=> {return PostType.imagePosts})}}><p>{ PostTypeVal(PostType.imagePosts) }</p></div>
								{/* <div className={`post-type-option${ postType === PostType.shortFormVideo ? ' active': ''}`} onClick={()=>{setPostType(_=> {return PostType.shortFormVideo})}}><p>{ PostTypeVal(PostType.shortFormVideo) }</p></div> */}
								<div className={`post-type-option${ postType === PostType.longFormVideo ? ' active': ''}`} onClick={()=>{setPostType(_=> {return PostType.longFormVideo})}}><p>{ PostTypeVal(PostType.longFormVideo) }</p></div>
							</div>
						</div>
					</div>

					{/* Community */}
					<div className="community-options-container">
						<label className="title-label">{"* Select The Post Community"}</label>
						<label htmlFor="postTextContent" className="description">{"select the community you prefer to share this with (Required)"}</label>
						<div className="community-items-container">
							<div key={ "0ab6" } className={`community-option-item ${ (community && defaultCommunity.communityTitle === community.communityTitle) ? " active" : ""}`} onClick={()=>{ setCommunity(_=> { return defaultCommunity; }); }}>
								<label>{ defaultCommunity.communityTitle }</label>
							</div>
							{ (userData && userData.communities) &&
								userData.communities.map((item, index) => {
									return (
										<div key={ index } className={`community-option-item ${ (community && item.communityTitle === community.communityTitle) ? " active" : ""}`} onClick={()=>{ setCommunity(_=> { return item; }); }}>
											<label>{ item.communityTitle }</label>
										</div>
									)
								})
							}
						</div>
					</div>

					{/* Title */}
					<div className='post-title-container'>
						<label htmlFor="title-label">Title</label>
						<label htmlFor="postTextContent" className="description">Here for you to create a title for this post</label>
						<input placeholder="Title..." id='tile-field' type={"text"} onChange={ e => { setTitle(_=>{ return e.target.value; }); } }/>
					</div>

					
					{/* Text Content */}
					<div className='post-text-content'>
						<div className="mode-items-container">
							<div className="mode-items-v-stack-grid">
								{/* Default */}
								<div className={`mode-item ${ transcriptionModeType === TranscriptionModeType.Default ? ' active' : '' }`} onClick={() => { setTranscriptionModeType(_=> { return TranscriptionModeType.Default }) }}>
									<label>Default</label>
								</div>
								{/* Chemistry Mode */}
								<div className={`mode-item ${ transcriptionModeType === TranscriptionModeType.Chemistry ? ' active' : '' }`} onClick={() => { setTranscriptionModeType(_=> { return TranscriptionModeType.Chemistry }) }}>
									<label>Chemistry <br/>Mode</label>
								</div>
								{/* Code Mode */}
								<div className={`mode-item ${ transcriptionModeType === TranscriptionModeType.Code ? ' active' : '' }`}  onClick={() => { setTranscriptionModeType(_=> { return TranscriptionModeType.Code }) }}>
									<label>Code <br/>Mode</label>
								</div>
								{/* Math Mode: A mode that turns math notes in real time into math notes by typing in the text area below > same thing for chemistry but slightly altered*/}
								<div className={`mode-item ${ transcriptionModeType === TranscriptionModeType.Math ? ' active' : '' }`}  onClick={() => { setTranscriptionModeType(_=> { return TranscriptionModeType.Math}) }}>
									<label>Math <br/>Mode</label>
								</div>
							</div>
						</div>
						<div className="post-text-content-field-container">
							<label htmlFor="postTextContent" className="title">Share Something Meaningful </label>
							<label htmlFor="postTextContent" className="description">
								Here for you to write or share something deeply meaningful in short or long form. You can write complex math, chemistry, and code explanations with no problem.
							</label>
							{(transcriptionModeType !== TranscriptionModeType.Default) && <div className="emphmeral-box" dangerouslySetInnerHTML={ { __html: ephimeralText } }/>}
							<textarea 
							id="postTextContent" 
							className="postTextContent"
							placeholder='Write Something...'
							//value={ postTextContent }
							onChange={ e => { 
                                let textAreaVal = e.target.value;
								// Should print the content of the string if I get a truth value
								switch (transcriptionModeType) {
									case TranscriptionModeType.Math:
										MathParser_New(textAreaVal, (transformedText) => {
											setEphimeralText(_=>{ 
												return transformedText; 
											});// Updating state method in order to be able to store that note
										});
										break;
									case TranscriptionModeType.Chemistry:
										ChemistryModeParse(textAreaVal, (transformedText) => {
											setEphimeralText(_=>{ 
												return transformedText; 
											});// Updating state method in order to be able to store that note
										});
									break;

									case TranscriptionModeType.Code:
										CodeModeParse(textAreaVal, (transformedText) => {
											setEphimeralText(_=>{ 
												return transformedText; 
											});// Updating state method in order to be able to store that note
										});
									break;
								
									default:
										DefaultModeParse(textAreaVal, (transformedText) => {
											setEphimeralText(_=>{ 
												return transformedText; 
											});// Updating state method in order to be able to store that note
										});
									break;
								}
								setPostTextContent(_=>{ return e.target.value; });
							 } }
							/>
						</div>
					</div>

					{ (postType === PostType.imagePosts) && 
						<div className="video-post-uploader">
							<h1>Image Post</h1> 
							<ImageUploaderView imageFiles={ imageFiles } setImageFiles={ setImageFiles }/>
						</div>
					}
					{/* { (postType === PostType.shortFormVideo) && <h1>Short Form Video</h1> } */}
					{ ((postType === PostType.longFormVideo) && baseURL) && 
						<div className="image-post-uploader">
							<h1>Long Form Video</h1> 
							<PostLongFormVideos videoID={videoID ? videoID : ""} baseURL={ baseURL } setVideo_assetID={ setVideo_assetID } setVideoPlaybackID={ setVideoPlaybackID }/>
						</div>
					}

					<button id="postContent" className="post-button" onClick={(e) => {
						let qeryContentPost = [ ...title.split(" "), ...postTextContent.split(" "), userData.firstName, userData.lastName, userData.customUserName ]; 
						qeryContentPost = qeryContentPost.map(item => { 
							let newItem = item.toLowerCase();
							return newItem;
						})

						if((postType === PostType.longFormVideo || postType === PostType.shortFormVideo) && baseURL && videoID && video_assetID && videoPlaybackID && community) {
							const postData : ContentModel = {
								documentID: uuidGenerator(),
								postType: postType,
								userID: userData.userID,
								title: title,
								textContent : postTextContent,
								textTranscriptionType: transcriptionModeType,
								videoType: "mux",// in the event I migrate away
								videoID: videoID,// returns full path
								videoPlaybackID: videoPlaybackID,
								video_assetID: video_assetID,
								imageURLs: [],
								dateOfPost: serverTimestamp(),
								community: community.communityID,
								userDisclosedModel: userData.disclosedUserPsycheType,
								userIntersts: userData.TopicsOfInterest,
								//interactions:
								like: 0,
								dislike: 0,
								funny: 0,
								smart: 0,
								sad: 0,
								mindBlown: 0,
								proudOfYou: 0,
								yert: 0,
								fingersCrossed: 0,
								love: 0,
								// queryable string list for search
								postQueryableContent: qeryContentPost,
							}
							postContent(userID, community.communityTitle.toLowerCase(), postData, userData, (status) => {
								showError(status);
							});
						} else if (postType === PostType.imagePosts && community) {
							const justImageList : (File | Blob)[] = imageFiles.map(item => { return item.file; });
							uploadImagesForBasedPost(justImageList, 0, [], (sucess, imageUrls) => {
								if (sucess) {
									console.log("I'm being called");
									// Posting images after I get the image object url back from the recurssive function... once the function is wrapped up then we send it back
									// I have to check if a recurrsive function actually calls the function back
									const postData : ContentModel = {
										documentID: uuidGenerator(),
										postType: postType,
										userID: userData.userID,
										title: title,
										textContent : postTextContent,
										textTranscriptionType: transcriptionModeType,
										videoType: "",// in the event I migrate away
										videoID: "",// returns full path
										videoPlaybackID: "",
										video_assetID: "",
										imageURLs: [],
										dateOfPost: serverTimestamp(),
										community: community.communityID,
										userDisclosedModel: userData.disclosedUserPsycheType,
										userIntersts: userData.TopicsOfInterest,
										//interactions:
										like: 0,
										dislike: 0,
										funny: 0,
										smart: 0,
										sad: 0,
										mindBlown: 0,
										proudOfYou: 0,
										yert: 0,
										fingersCrossed: 0,
										love: 0,
										// queryable string list for search
										postQueryableContent: qeryContentPost,
									}
									postContent(userID, community.communityTitle.toLowerCase(), postData, userData, (status) => {
										showError(status);
									});

								}
							});
						} else if ( postType === PostType.textOnly && community) {
							// The idea here is to pass in the ideal set of items
							const postData : ContentModel = {
								documentID: uuidGenerator(),
								postType: postType,
								userID: userData.userID,
								title: title,
								textContent : postTextContent,
								textTranscriptionType: transcriptionModeType,
								videoType: "",// in the event I migrate away
								videoID: "",// returns full path
								videoPlaybackID: "",
								video_assetID: "",
								imageURLs: [],
								dateOfPost: serverTimestamp(),
								community: community.communityID,
								userDisclosedModel: userData.disclosedUserPsycheType,
								userIntersts: userData.TopicsOfInterest,
								//interactions:
								like: 0,
								dislike: 0,
								funny: 0,
								smart: 0,
								sad: 0,
								mindBlown: 0,
								proudOfYou: 0,
								yert: 0,
								fingersCrossed: 0,
								love: 0,
								// queryable string list for search
								postQueryableContent: qeryContentPost,
							}
							postContent(userID, community.communityTitle.toLowerCase(), postData, userData, (status) => {
								showError(status);
							});
						}
					}}>Post</button>

				</div>
			</div>
		</div>
	)
}

export default PostController;


interface PostLongFormVideosModel { 
	videoID: string; 
	baseURL: string; 
	setVideo_assetID: React.Dispatch<React.SetStateAction<string | undefined>>;
	setVideoPlaybackID: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const PostLongFormVideos = ({ videoID, baseURL, setVideo_assetID, setVideoPlaybackID }: PostLongFormVideosModel) => {
	function getAssetID() {
		const API_URL = `https://us-central1-newtopia-a4327.cloudfunctions.net/app/get-upload-url?id=${ videoID }`;
		const options = { method: 'GET', mode: 'cors', headers:{ "Access-Control-Allow-Origin": "*", "Content-Type": "application/json",} };
		// @ts-ignore
		fetch( API_URL , { options })
		.then(res => {
			return res.json();
		}).then( data => {
			console.log(data);
			//@ts-ignore
			if (data && data.asset_id) { 
				setVideo_assetID(_=> { return data.asset_id });
				getPlaybackID(data.asset_id);
			 }
		}).catch( err => {
			console.log('err: ', err);
		})
	}

	function getPlaybackID(id: string) {
		const API_URL = `https://us-central1-newtopia-a4327.cloudfunctions.net/app/get-plb-url?id=${ id }`;
		const options = { method: 'GET', mode: 'cors', headers:{ "Access-Control-Allow-Origin": "*", "Content-Type": "application/json",} };
		// @ts-ignore
		fetch( API_URL , { options })
		.then(res => {
			return res.json();
		}).then( data => {
			console.log(data);
			//@ts-ignore
			if (data && data.playback_ids && data.playback_ids.length) setVideoPlaybackID(_=> { return data.playback_ids[0].id;} );
		}).catch( err => {
			console.log('err: ', err);
		})
	}

	return (
		<>
			<div className="p-4">
				<MuxUploader id="my-uploader" className="hidden" endpoint={ baseURL } onSuccess={ (e) => { getAssetID();}}>
					{/* @ts-ignore */}
						<button className="btn" type="button" slot="file-select">Upload A Video</button>
				</MuxUploader>
			</div>
		</>
	);
}

const MathModeInstructions = () => {
	const Mode = "Math Mode";
	let transformedText = "";
	const transTextTextMethod: (t: string) => string = (t: string) => { 
		MathParser_New(t, (thisTransText) => { transformedText = thisTransText; })
		return transformedText;
	};

	const InstuctionsItems: { title: string, implimentaionExample: string, symbolRepresentationStringFormat: string, symbolRepresentation: string, descriptionText: string }[] = [
		{
			title: "express",
			implimentaionExample: "express[[ value,,, value,,, etc. ]]",
			symbolRepresentationStringFormat: "express[[ summ[[ 1 ,,, 20 ]] integ[[ 1 ,,, 2 ]] 2x + 7 ]]",
			symbolRepresentation: `${ transTextTextMethod("express[[ summ[[ 1 ,,, 20 ]] integ[[ 1 ,,, 2 ]] 2x + 7 ]]") }`,
			descriptionText: "The express key word allows you to write out full expressions such that its clear that they are related."
		},
		{
			title: "integ",
			implimentaionExample: "integ[[ value ]]",
			symbolRepresentationStringFormat: "integ[[ 1 ,,, 2 ]]",
			symbolRepresentation: `${ transTextTextMethod("integ[[1,,,2]]") }`,
			descriptionText: "The integ key is used to build integrals."
		},

		{
			title: "limit",
			implimentaionExample: "limit[[ value ,,, value ]]",
			symbolRepresentationStringFormat: "limit[[ 1 ,,, 2 ]]",
			symbolRepresentation: `${ transTextTextMethod("limit[[1,,,2]]") }`,
			descriptionText: "The limit key is used to build limits."
		},
	]
	const Instuctions = `
	<br/>
	<h3>Math Mode</h3>
	<br/>
	<h5 style="text-decoration:underline;">Key Words:</h5>

	<p style="color:black; font-size: 16px; font-weight:600; margin-top:8px;">express: <label style="color:var(--app-blue); font-weight:500;">express[[ value ]]</label></p>
	<p style="color:gray; font-size:14px;">The express key word allows you to write out full expressions such that its clear that they are related.</p>
	<br/>
	<p style="color:black; font-size: 16px; font-weight:600;">integ: <label style="color:var(--app-blue); font-weight:500;">integ[[ value ,,, value ]] </label></p>
	<p style="color:gray; font-size:14px;">Integral signs.</p>	
	`;
	return (
		<div>
			<h3>{ Mode }</h3>
			<h5 style={{ textDecoration: "underline" }}>Key Words:</h5>
			{ InstuctionsItems.map((item, index) => {
				return (
					<div key={ index } style={{ marginTop: "30px"}}>
						<p style={{color: "black", fontSize: "16px", fontWeight:"600", marginTop: "8px"}}>
							{`"${item.title}" : `}
							<label style={{ color:"var(--app-blue)", fontWeight: "500" }}>{`${ item.implimentaionExample }`}</label>
						</p>
						<div style={{ padding:"4px", backgroundColor:"rgba(0,0,0, 0.1)", borderRadius: "4px", display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", marginTop: "8px" }}>
							<p style={{ fontSize:"14px", marginTop: "12px", fontWeight:"600", }}>{`Example:`}</p>
							<p style={{ fontSize:"12px", marginTop: "8px"}}>{`"${item.symbolRepresentationStringFormat}"`}</p>
							<p dangerouslySetInnerHTML={{ __html: item.symbolRepresentation }}/>
						</div>
						<p style={{ color:"gray", fontSize:"14px", marginTop: "12px"}}>{ item.descriptionText }</p>
					</div>
				);
			})}
		</div>
	);
}


interface PostImagesModel {
	imageFiles: TemporaryPostImageModel[];
	setImageFiles: React.Dispatch<React.SetStateAction<TemporaryPostImageModel[]>>;
}
const ImageUploaderView = ({imageFiles, setImageFiles}: PostImagesModel) => {
	const li_ = [1,2,3,4,5];
	return (
		<div className="image-post-upload-container hideScrollIndicator">
			<div className="image-post-upload-content-view">
				{
					li_.map((i) => {
						return ( <ImageUploderItem imageFiles={ imageFiles } setImageFiles={ setImageFiles } key={ i }/>)
					})
				}
			</div>
		</div>
	);
}

interface PostImagesItemModel {
	imageFiles: TemporaryPostImageModel[];
	setImageFiles: React.Dispatch<React.SetStateAction<TemporaryPostImageModel[]>>;
	key: number;
}
const ImageUploderItem = ({ imageFiles, setImageFiles, key}: PostImagesItemModel) => {
	const [ imageFile, setImageFile ] = useState<File | Blob | undefined>(undefined);
    const [ imageSrc, setImageSrc ] = useState<string>("");
	function SelectMessageImage () {
        const input = document.querySelector(`.image-post-upload-container .post-image-button-option input#new-image-input-${ key }.new-image-input-${key}`);
        if (input) {
            // @ts-ignore
            input.click();
        }
    }

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files: File[] = Array.from(e.target.files)
        const file: File = files[0];
        const fileType = files[0].type;
        const compressImageFile = new Compressor(file, 
			{ quality: 0.2, // 20% of it's original qulity 
				success: async (compressedFile) => {
					console.log("File Compression complete.");
					const src = URL.createObjectURL(compressedFile);
					setImageFiles(curr => {
						let list = curr;
						list.filter(item => {
							if (item.id != key) {
								return item;
							}
						});
						// Adding file item to list
						list.push({ blob_src: src, file: compressedFile, id: key });
						console.log(list);
						return list;
					})
				}
			})
			// I'm acting like I owe people things. I do not
    }
	return (
		<div className="post-image-button-option" onClick={ SelectMessageImage } key={ key } style={{
			width: imageFiles.filter( item => { if (item.id === key) return item }).length ? "fit-content" : "300px",
		}}>
			{ (imageFiles.filter( item => { if (item.id === key) return item }).length > 0) && <img className="image" src={ imageFiles.filter( item => { if (item.id === key) return item })[0].blob_src }/> }
			<div className="image-cover"/>
			<div className="plus-icon"><span className="v"/><span className="h"/></div>
			<input className={`new-image-input-${ key }`} id={`new-image-input-${ key }`}  type="file" onChange={ handleFileSelected } accept="image/x-png,image/jpeg" style={{ display: "none" }}/>
		</div>
	)
}