import React, { useState, useEffect, useContext } from "react";
import { db, storage, auth, functions } from "../../../fb/fbSetup";
import { doc, collection , getDoc, setDoc, getDocs, serverTimestamp, Query, OrderByDirection, updateDoc, increment, arrayUnion, FieldPath } from "firebase/firestore";
import { query, where, orderBy, limit, or, and, documentId } from "firebase/firestore";  
import uuidGenerator, { AddingEnters } from "../../../../SharedItems/UUIDGenerator";
import MoviePlayer from "../../../Components/ContentComponents/MoviePlayer";
import ShortFormVideoPlayer from "../../../Components/ContentComponents/ShortFormVideo"; 
import favIcon from '../../../../Resources/Favorites/fav.png';
import favIcon_filled from '../../../../Resources/Favorites/fav_filled.png';
import EyeIcon from '../../../../Resources/eye.png';
// 
import { 
    ContentModel,
    postsDocTitle,
    PostType,
    emojiResponsesDocTitle,
	Posts_Coll_And_Doc_Path,
	InteractionObjectModel,
	userprofile_storage_path,
	recommendationPath,
	communityPath,
	DEFAULTCATEGORY,
} from "../../../fb/dbMethods";
import HeaderItemAndAppBigCom, { HeaderItemAndAppBigComModel } from "../../../Components/HomeHeader/HomeHeader";
import FilterViewButton from "../../FilterView/FilterViewButton/FilterViewButton";
import ControllerContext from "../../../Contexts/ControllerContexts";
import { AddComment } from "../../../Components/CommentSection/Comments_DB_Methods";
import UserContext from "../../../Contexts/UserContext";
import { AddItemToYourFavorites, RemoveFavoritesFromFavoritesList } from "../../../Controller/favorites_db_controller";
import { AddInteraction, AddItemToYourInteraction, InteractionsModel, InteractionTypeOptions, intereraction_DecrementField, intereraction_IncrementField } from "../../../Controller/like_db_controller";
import { NotifyUser } from "../../../Controller/notify_user_db_controller";
import { CurrentUserDataModel, PagePaths } from "../../../Models/Models";
import { getDownloadURL, ref } from "firebase/storage";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ImageItemsView from "../../../Components/ImageItemsView/ImageItemsView";
import "./ContentView.css";
import StoriesView from "../../../Components/Stories/Stories";
import AnonymousPosts from "../../../Components/Anonymous/Anonymous";
import { AddItemToYourViewlater } from "../../../Controller/ViewLater_db_controller";
import LiveSessionEmbeded from "../../../Components/LiveSession/LiveSessionEmbeded/LiveSessionEmbeded";
import { PlayIcon } from "../../../Components/PlayIcon/PlayIcon";
import { AddItemToYourVideoHistory } from "../../../Controller/VideoHistory";
import VideoToGifConverter from "../../../Components/GifComponent/GifComponent";
import { Adsense } from "@ctrl/react-adsense";
import { NOT_AN_AUTHENTICATED_USER } from "../../../Contexts/unsignedUserDataModle";
import { getFunctions, httpsCallable } from "firebase/functions";
import Lottie from "lottie-react";
import loading_Lottie from "../../../../Resources/Lottie/loading.json";

export interface ContentViewProps {
	setScrolled: React.Dispatch<React.SetStateAction<boolean>>
	isMobile: boolean;
	selectedCommunityItem: string;
	setSelectedCommunityItem: React.Dispatch<React.SetStateAction<string>>;
}

export const ResponseTypesList = [
	{ responseType: "like", repsonseIcon : "👍", text: "Like", type: InteractionTypeOptions.like, db_name: "like" },
	{ responseType: "dislike", repsonseIcon : "👎", text: "Dislike", type: InteractionTypeOptions.dislike, db_name: "dislike" },
	{ responseType: "funny", repsonseIcon : "😂", text: "Funny", type: InteractionTypeOptions.funny, db_name: "funny" },

	{ responseType: "smart", repsonseIcon : "🎓", text: "Smart", type: InteractionTypeOptions.smart, db_name: "smart" },
	{ responseType: "sad", repsonseIcon : "😢", text: "Sad", type: InteractionTypeOptions.sad, db_name: "sad" },
	{ responseType: "mind-blown", repsonseIcon: "🤯", text: "Mind Blown", type: InteractionTypeOptions.mindBlown, db_name: "mindBlown" },

	{ responseType: "proud-of-you", repsonseIcon: "🥹", text: "I'm proud of you", type: InteractionTypeOptions.proudOfYou, db_name: "proudOfYou" },
	{ responseType: "yert", repsonseIcon : "🤙", text: "Yert", type: InteractionTypeOptions.yert, db_name: "yert" },
	{ responseType: "fingers-crossed", repsonseIcon: "🤞", text: "Fingers Crossed", type: InteractionTypeOptions.fingersCrossed, db_name: "fingersCrossed" },

	{ responseType: "love", repsonseIcon : "❤️", text: "Love", type: InteractionTypeOptions.love, db_name: "love" },
]

const ContentView = ({ setScrolled, isMobile, selectedCommunityItem, setSelectedCommunityItem }: ContentViewProps) => {
	const { userData } = useContext(UserContext);
	const [ isLoading, setIsLoading ] = useState<boolean>(true);
	const [ ContentItems, setContentItems ] = useState<ContentModel[]>([]);
	const [ recommendationsHaveBeenCalled, setRecommendationsHaveBeenCalled ] = useState<boolean>(false);
	useEffect(() => {
		setTimeout(() => {
			if (!recommendationsHaveBeenCalled) getDocuments();
		}, 5000)
	}, [])

	useEffect(() => {
		if (selectedCommunityItem.trim().toLowerCase() !== DEFAULTCATEGORY) getCommunityDocuments(selectedCommunityItem);
	}, [ selectedCommunityItem ])


	async function getDocuments() {
			setRecommendationsHaveBeenCalled(_=> { return true; });
			const remcmmend = httpsCallable(functions, `getRecommendedPosts?userid=${(auth && auth.currentUser && auth.currentUser.uid) ? (auth.currentUser.uid) : userData.userID}`)// .call
			// fetch(`${recommendationPath}userid=${(auth && auth.currentUser && auth.currentUser.uid) ? (auth.currentUser.uid) : userData.userID}`)
			// .then(response => response.json())
			remcmmend({}).then(async (result) => {
				console.log("getDocuments results: ", result)
				const data = result;
				if (data && Array.isArray(data) && data.length) {
					const postList = data.filter((x,i) => i < 28);
					const collectionRef = collection(db, `/${ Posts_Coll_And_Doc_Path }`);
					const multiDocumentQuery = query(collectionRef, 
						and(
							where(documentId(), "in", postList),
							or(
								where('postType', '==', "imagePosts"),
                                where('postType', '==', "textOnly"),
                                // where("postType", "==", "shortFormVideo"),
                                // where('postType', '==', "shortsOnly"),
                                // where('postType', '==', "longFormVideo")
							 )
						),
						// orderBy("dateOfPost", orderDirectionDesc ? "desc" : "asc"),
					);
					await getDocs(multiDocumentQuery)
					.then(documents => {
						let list : ContentModel[] = [];
						documents.forEach(doc => {
							const content = doc.data() as ContentModel;
							list.push(content);
							setIsLoading(_=>{ return false;});
							setContentItems(_=>{ return list;});
						})
					})
					.catch(error => {
						old_getDocuments();
					})
				}				
			})
			.catch((error) => {
				const code = error.code;
				const message = error.message;
				const details = error.details;
			});
	}

	async function getCommunityDocuments(category: string) {
		fetch(`${communityPath}category=${category}`)
		.then(response => response.json())
		.then(async (result) => {
			const data = result;
			if (data.data && data.data.length) {
				const postList = data.data.filter((x,i) => i < 29);
				const collectionRef = collection(db, `/${ Posts_Coll_And_Doc_Path }`);
				const multiDocumentQuery = query(collectionRef, 
					where(documentId(), "in", postList),
					orderBy("dateOfPost", orderDirectionDesc ? "desc" : "asc"),
				);
				await getDocs(multiDocumentQuery)
				.then(documents => {
					let list : ContentModel[] = [];
					documents.forEach(doc => {
						const content = doc.data() as ContentModel;
						list.push(content);
						setIsLoading(_=>{ return false;});
						setContentItems(_=>{ return list;});
					})
				})
				.catch(error => {
					old_getDocuments();
				})
			}				
		})
		.catch((error) => {
			const code = error.code;
			const message = error.message;
			const details = error.details;
		});
}
	
	let orderDirectionDesc = true;
	async function old_getDocuments() {
		const collectionRef = collection(db, `/${ Posts_Coll_And_Doc_Path }`);
		const multiDocumentQuery = query(collectionRef, or(
			where("postType", "==" ,"imagePosts"),
			where("postType", "==" ,"textOnly"),
			// where("postType", "==" ,"longFormVideo"), 
		), orderBy("dateOfPost", orderDirectionDesc ? "desc" : "asc"))
		const notAuthQuery = query(collectionRef, or(
			where("postType", "==" ,"imagePosts"),
			where("postType", "==" ,"textOnly"),
			// where("postType", "==" ,"longFormVideo"), 
		), orderBy("dateOfPost", orderDirectionDesc ? "desc" : "asc"),
			limit(20)
		)
		// @ts-ignore
		const queryiedDocs = await getDocs( userData.NOT_AN_AUTHENTICATED_USER ? notAuthQuery : multiDocumentQuery);
		let list : ContentModel[] = [];
		let count = 0;
		queryiedDocs.forEach(doc => {
			const content = doc.data() as ContentModel;
			list.push(content);
			count = count + 1;
			if(count === 2) {
			}
			setContentItems(_=>{ return list;});
		})
	}

	return (
		<ContentItemsComp isLoading={ isLoading } ContentItems={ ContentItems } setScrolled={ setScrolled } showEmojiResponse={ true } showHomeHeder={ true } showFilterButton={ true } isMobile={ isMobile }/>
	);
}

const BingAdUnit = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const tkn = urlParams.get('t');  
	const ud = urlParams.get('ud');

	useEffect(() => {
        try {
            // @ts-ignore
			(adsbygoogle = window.adsbygoogle || []).push({});
        }catch (e) {
        }
    },[]);
	return (
		<div className="ad-my-friend" style={{
			position: "relative",
			width: (tkn && ud) ? "calc(100%)" : "calc(100% - 20px)",
			height: "fit-content",
			minHeight: (tkn && ud) ? "400px": "200px",
			// @ts-ignore
			// "aspect-ratio" : "16 / 6",
			boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
			background: "white",
			borderRadius: (tkn && ud) ? "0px" : "20px",
			marginLeft: (tkn && ud) ? "0px" : "12px",
			marginTop: (tkn && ud) ? "0px" : "8px",
			marginBottom: (tkn && ud) ? "0px" : "0px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			overflow:"hidden"
		}}>
			<h2
			style={{ 
				position: "absolute", 
				top: "50%",
				left: "50%",
				zIndex: "0",
				transform: "translate(-50%, calc(-50% - 10px) )",
				color: "var(--app-blue)", 
				fontWeight: "600", 
				fontFamily: "Cabin Sketch !important",
				padding: "20px",
				borderRadius: "20px",
				background: "linear-gradient(var(--app-green-5), var(--app-blue-5) )",
				border: "2px solid rgba(0,0,0,0.01)",
				opacity: "0.5",
			}}
			>An Ad Will Be Place Here</h2>

			{/* <ins className="adsbygoogle"
				style={{ 
					position: "relative",
					top: 0,
					left: 0,
					minWidth: "200px",
					zIndex: "1000",
					display: "block",
				}}
				data-ad-format="fluid"
				data-ad-layout-key="-64+e6+1k-5q+9s"
				data-ad-client="ca-pub-5619224767870187"
				data-ad-slot="4120903050"/> */}

					{/* <Adsense
					client="ca-pub-5619224767870187"
					slot="4120903050"
					// layoutKey="-64+e6+1k-5q+9s"
					responsive="true"
					style={{ 
						// position: "relative",
						// top: 0,
						// left: 0,
						// minWidth: "200px",
						// zIndex: "1000",
						display: "block",
					}}
					format="fluid"
					// layout="in-feed"		
					/> */}
				{ (tkn && ud) ?
					// mobile_app_inArticle_for_home 
					<Adsense 
						className="adsbygoogle"
						style={{display:"block", width:"100%", height:"fit-content", zIndex: "1", backgroundColor: "white"}}
						layout="in-article"
						format="fluid"
						client="ca-pub-5619224767870187"
						slot="5201846167"
					/>
					// <Adsense 
					// 	className="adsbygoogle"
					// 	style={{display:"block", width:"100%", height:"fit-content", zIndex: "1", backgroundColor: "white"}}
					// 	format="fluid"
					// 	layoutKey="-6t+ed+2i-1n-4w"
					// 	client="ca-pub-5619224767870187"
					// 	slot="7145483229"
					// />
					// style="display:block"
					// data-ad-client="ca-pub-5619224767870187"
					// data-ad-slot="5323035598"
					// data-ad-format="auto"
					// data-full-width-responsive="true"
					:
					<Adsense 
						className="adsbygoogle"
						style={{display:"block", width:"100%", height:"fit-content", zIndex: "1",}}
						format="fluid"
						layoutKey="-fb+5w+4e-db+86"
						client="ca-pub-5619224767870187"
						slot="7220178455"
						
					/>
				}

					{/* <Adsense
						className="adsbygoogle"
						style={{display:"block", width:"300px", height:"200px"}}
						client="ca-pub-5619224767870187"
						slot="7348170968"
					/> */}

					{/* <Adsense className="adsbygoogle"
					style={{display:"block", width:"300px", height:"200px", zIndex: "1",}}
					layout="in-article"
					format="fluid"
					client="ca-pub-5619224767870187"
					slot="2301009794"
					/> */}

					{/* <Adsense className="adsbygoogle"
					style={{display:"block", width:"300px", height:"200px", zIndex: "1",}}
					layout="in-article"
					format="fluid"
					client="ca-pub-5619224767870187"
					slot="2301009794"
					/> */}

			<label style={{ 
				position: "absolute", 
				top: "12px",
				zIndex: "10000",
				right: "12px", 
				color: "var(--app-blue)", 
				fontWeight: "600", 
				fontFamily: "Cabin Sketch !important",
				padding: "4px",
				borderRadius: "8px",
				background: "linear-gradient(var(--app-green-20), var(--app-blue-20) )",
				boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
			}}>ad</label>
		</div>
	)
}

const AdUnit = () => {
	useEffect(() => {
        try {
            // @ts-ignore
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }catch (e) {
        }
    },[]);
	let ad = `
	<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5619224767870187" crossorigin="anonymous"></script>
	<ins 
		class="adsbygoogle" 
		style="display:block" 
		data-ad-format="fluid" 
		data-ad-layout-key="-64+e6+1k-5q+9s" 
		data-ad-client="ca-pub-5619224767870187" 
		data-ad-slot="4120903050"
	>
	</ins>
	<script> (adsbygoogle = window.adsbygoogle || []).push({});</script>
	`;
	return(
		<>
			{/* <span dangerouslySetInnerHTML={{__html: ad}}/> */}
			{/* @ts-ignore */}
			<ins 
				className="adsbygoogle" 
				style={{display: "block"}} 
				data-ad-format="fluid" 
				data-ad-layout-key="-64+e6+1k-5q+9s" 
				data-ad-client="ca-pub-5619224767870187" 
				data-ad-slot="4120903050"
			>
			</ins>
		</>
	)
}

export default ContentView;

interface ContentItemsCompModel {
	isLoading: boolean;
	ContentItems : ContentModel[];
	setScrolled?: React.Dispatch<React.SetStateAction<boolean>>;
	showEmojiResponse : boolean;
	showHomeHeder?: boolean;
	showFilterButton?: boolean;
	isMobile: boolean;
}

export const ContentItemsComp = ({
	isLoading,
	ContentItems,
	setScrolled,
	showEmojiResponse,
	showHomeHeder,
	showFilterButton,
	isMobile,
}: ContentItemsCompModel) => {
	const [ currentValue , setCurrentValue ] = useState<number>(0);
	const { userData } = useContext(UserContext);
	// @ts-ignore
	function handleScroll (e) {
		if (setScrolled && e && e.currentTarget && e.currentTarget.scrollTop) {
			const val = e.currentTarget.scrollTop ? e.currentTarget.scrollTop : 0;
			if (e.currentTarget.scrollTop <= 50) {
				setCurrentValue(_=> { return val });
				setScrolled(b => { return false; });
			} else if (e.currentTarget.scrollTop >= currentValue) {
				setCurrentValue(_=> { return val; });
				setScrolled(b => { return true; });
			} else {
				setCurrentValue(_=> { return val });
				setScrolled(b => { return false; });
			}
		}
	};

	const speakButtonText = "speak"; //"Yell It At Me";
	function SpeakContent(postContent : string) {
		let msg = new SpeechSynthesisUtterance();
		//
		let voices = window.speechSynthesis.getVoices();
		let goodVoiceOptions = [/* 
		67 good, 76 okay, 81 better, 85 robot singing lol, 88 okay, 90 decent
		91 pretty dang good, 98 okay, 100 good, 101 good, 109 classic computer good, 112 okay,
		144 best */];
		// msg.voice = voices[ 67 ];
		// msg.voice = voices[ 144 ];
		// msg.volume = 100.0;
		// msg.rate = 0.85; // From 0.1 to 10
		// msg.pitch = 0.9; // From 0 to 2
		// msg.lang = 'en-US';
		msg.text = postContent;
		// Restarting the Speech
		// window.speechSynthesis.cancel();
		// speechSynthesis.getVoices().forEach(function(voice) {
		// 	let preventVal = false
		// 	if (voice.name === "Google US English " && preventVal) { msg.voice = voice; }
		// });
		// msg.voice = voices[ 144 ];
		window.speechSynthesis.speak(msg);
	}
	
	return (
		<div className='content-scrollview' onScroll={ handleScroll } /*style={{ background: `url("${ bckg_img_test }")` }}*/>
			{ !isLoading ?
				<div className='content-scrollview-content-view'>
					{/* @ts-ignore */}
					{/* { !userData.NOT_AN_AUTHENTICATED_USER && <StoriesView /> } */}
					{/* @ts-ignore */}
					{ (showHomeHeder && userData.NOT_AN_AUTHENTICATED_USER ) && 
						<HeaderItemAndAppBigCom 
							title={"Content Filtering"}
							description={"Controller What You See: Take a look at some of the amazing content filtering options at your fingertips to control what you see and enjoy."}
							callToActionTitle={"navigate"}
							callToActionLink={PagePaths.filterControls}
						/> 
					}
					{/* { <VideoToGifConverter/>} */}
					{ ContentItems.map((item, index) => {
						return (
							<>
								{ (index > 0 && (index === 2  || ((index % 7) === 0) || ((index % 15) === 0))) &&  <LiveSessionEmbeded/> }
								{ (index === 11) && <AnonymousPosts/> }
								<ContentCard item={ item } index={ index } showEmojiResponse={ true } isMobile={ isMobile }/>
							</>
						);
					})}
				</div>
				:
				<div style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					width: "130px",
					height: "130px",
					transform: "translate(-50%, -50%)",
					zIndex: "10000",
				}}>
					<Lottie animationData={ loading_Lottie } loop={true}/>
				</div>
			}
		</div>
	);
}

interface ContentCard { 
	item: ContentModel; 
	index? : number;
	showEmojiResponse : boolean;
	showFilterButton?: boolean;
	isMobile?: boolean;
}
export const ContentCard = ({ 
	item,
	index,
	showEmojiResponse,
	showFilterButton,
	isMobile
}: ContentCard) => {
	const { userData, userInteractions, userFavorites, userVideoHistory } = useContext(UserContext)
	const { showCommentSectionPopOut, toggleTheaterView, toggleStartDirectMessageView, toggleJumpInSignUp } = useContext(ControllerContext);
	const [ comment, setComment ] = useState<string>("");
	const [ showMoreOptions, setShowMoreOptions ] = useState<boolean>(false);
	const [ responded, setResponded ] = useState<InteractionTypeOptions | undefined>(undefined);
	const speakButtonText = "speak"; //"Yell It At Me";
	
	function GetContentDate(content: ContentModel): string {
		let dateString: string = "";
		// Do Work With Date
		let mDate : any = content.dateOfPost;
		mDate = mDate.toDate();
		const mDateTime = new Date(mDate);
		let dayjs_ = dayjs.extend(relativeTime);
		dateString = dayjs(mDateTime).fromNow();//.format('ddd, MMM D, YYYY h:mm A');
		//const english = new Intl.RelativeTimeFormat("en-us");
		// Return String
		return dateString;
	}

	function SpeakContent(postContent : string) {
		let msg = new SpeechSynthesisUtterance();
		//
		let voices = window.speechSynthesis.getVoices();
		let goodVoiceOptions = [/* 
		67 good, 76 okay, 81 better, 85 robot singing lol, 88 okay, 90 decent
		91 pretty dang good, 98 okay, 100 good, 101 good, 109 classic computer good, 112 okay,
		144 best */];
		msg.voice = voices[ 67 ];
		msg.voice = voices[ 144 ];
		msg.volume = 100.0;
		msg.rate = 0.85; // From 0.1 to 10
		msg.pitch = 0.9; // From 0 to 2
		msg.lang = 'en-US';
		msg.text = postContent;
		// Restarting the Speech
		window.speechSynthesis.cancel();
		speechSynthesis.getVoices().forEach(function(voice) {
			let preventVal = false
			if (voice.name === "Google US English " && preventVal) { msg.voice = voice; }
		});
		msg.voice = voices[ 144 ];
		window.speechSynthesis.speak(msg);
	}

	function hasInteracted(): (InteractionTypeOptions | undefined) {
		let type : InteractionTypeOptions | undefined = undefined;
		userInteractions.forEach(inter => {
			if (inter.postID === item.documentID) { type = inter.type; }
		})
		return type;
	}

	function getInteractionObject(): (InteractionsModel | undefined)  {
		let interaction : InteractionsModel | undefined = undefined;
		userInteractions.forEach(inter => {
			if (inter.postID === item.documentID) { interaction = inter; }
		})
		return interaction;
	}


	const [ ContentUser, setContentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    useEffect(()=>{
        getCommentUserData();
        getCommentUserProfileImage();
    },[]);
    async function getCommentUserData(){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ item.userID }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                setContentUser(_=> { return data; });
            }
        })
        .catch( err => {
        });
    }

    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${item.userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }
	
	return (
		<>
			<div 
				className={`content-items${(item.postType === PostType.imagePosts && item.imageURLs && item.imageURLs.length < 2 && window.innerWidth > 600) ? " horizontal" : ""}`} 
				key={ index }
			>
				{ item.postType !== PostType.shortFormVideo &&
					<div className='long-form-video v-stack-item'>
						<div className='postedby-speech-and-more-button'>
							<div className='content-post-by-container'>
								<div className='userProfile-img-content-container img-container'>
									{(imageSrc && ContentUser) && <img loading={"lazy"} alt={ `${ ContentUser.firstName } ${ ContentUser.lastName } Profile Image` } src={ imageSrc } /> }
									<div className='cover'></div>
								</div>
								<div className="profile-name-container">
									{ ContentUser && <label className='userProfile-name'>{ `${ ContentUser.firstName } ${ ContentUser.lastName }` }</label> }
									{ ContentUser && <label className='userProfile-nick-name'>{ `@${ ContentUser.customUserName }` }</label> }
								</div>
							</div>

							<div className="date-of-post-container">
									<p>{ `${ GetContentDate(item) }` }</p>
							</div>
							{ (isMobile === false || isMobile === undefined ) &&

								<div className='speak-content-button' onClick={_=>{SpeakContent(item.textContent!); }}>
									<label className='speak-content-label'>{ speakButtonText }</label>
								</div>
							}
							{
								(isMobile === false || isMobile === undefined ) &&
								<div className='favorites-button-container' onClick={()=> {
																			// @ts-ignore
										if (userData.NOT_AN_AUTHENTICATED_USER) {
											toggleJumpInSignUp(true)
										} else { 
											const favoriteItem = userFavorites.filter(favItem => { 
												if (favItem.postID === item.documentID) {
													return favItem;
												}
											})
											if (favoriteItem.length) {
												RemoveFavoritesFromFavoritesList(userData.userID, favoriteItem[0], (sucess)=> {
												})
											} else {
												AddItemToYourFavorites(userData.userID, item.documentID, (status) => {
													if (status) { 
													}
												});
											}
										}
									}}>
										<img loading={"lazy"}
											style={{
												transform: "scale(0.80)",
												opacity: "0.85"
											}}
											src={
												userFavorites.filter(favItem => { if (favItem.postID === item.documentID) { return favItem; }}).length > 0 ? favIcon_filled : favIcon
											}
										/>
									<div className='favorites-button'></div>
								</div>
							}
							<button className="more-button" onClick={() => {
								// @ts-ignore
								if (userData.NOT_AN_AUTHENTICATED_USER) {
									toggleJumpInSignUp(true)
								} else { 
									setShowMoreOptions(b => { return !b; }); 
								}
							}}>
								<span className="dot"/><span className="dot"/><span className="dot"/>
								{ showMoreOptions &&
									<>
										<div className="focus-view"><div className="control-focus-view"/></div>
										<div className="more-button-pop-up">
											<div className="more-button-comtent">
												<button className="options" onClick={() => { if (ContentUser) { toggleStartDirectMessageView(true, ContentUser, item.documentID, ); } }}>Direct Messege</button>
												<button className="options" onClick={() => {
													AddItemToYourViewlater(userData.userID, item.documentID, (_) => {
														//
													})
												}}>Add To View Later</button>
												<button className="options">Add To A Content Colony</button>
												<button className="options">Copyrights</button>
												<button className="options red">Report</button>
											</div>
										</div>
									</>
								}
							</button>
						</div>

						{/* <h3 className='content-title'>{ item.documentID + " " + item.title }</h3> */}
						<h3 className='content-title'>{ item.title }</h3>
						<div dangerouslySetInnerHTML={{ __html: `<p class="content-post">${ AddingEnters(item.textContent!, item.textTranscriptionType) }</p>` } }></div>
						{ 
							(item.postType === PostType.longFormVideo && item.videoPlaybackID ) && 
							<div className="MoviePlayerThumbnailView" onClick={() => {
								// @ts-ignore
								if (userData.NOT_AN_AUTHENTICATED_USER) {
									toggleJumpInSignUp(true)
								} else { 
									toggleTheaterView(item, true);
									const favoriteItem = userVideoHistory.filter(videoItem => { 
										if (videoItem.postID === item.documentID) return videoItem;
									})
									if (favoriteItem.length) { /* Don't do anything */ } else {
										AddItemToYourVideoHistory(userData.userID, item.documentID, (status) => {});
									}
								}
							}}>
								<img loading={"lazy"} className="thumbnail" src={ `https://image.mux.com/${ item.videoPlaybackID }/animated.gif?animated.gif?width=550&height=461&time=10&time=10` }/>
								<PlayIcon dimension={50} iconColor={"white"} zIndex={1}/>
								<div className="thumbnail-cover"/>
							</div>
						}
						{/* { 
							(item.postType === PostType.longFormVideo && item.videoPlaybackID ) && 
							<MoviePlayer id={ uuidGenerator() } playbackId={item.videoPlaybackID ? item.videoPlaybackID : ""}/>
						} */}
						{
							(item.postType === PostType.imagePosts && item.imageURLs && item.imageURLs.length > 1) && <ImageItemsView imageList={ item.imageURLs }/>
						}
						{
						(item.postType === PostType.imagePosts && item.imageURLs && item.imageURLs.length < 2 && window.innerWidth <= 600) && <ImageItemsView imageList={ item.imageURLs }/>
						}
						<div className='like-and-emoji-responses-container hideScrollIndicator'>
							<div className='like-and-emoji-responses-hstack'>
								{
									ResponseTypesList.map((resp, index) => {
										function getInteractionCount(type: InteractionTypeOptions): ( number | undefined ) {
											switch(type){
												case InteractionTypeOptions.like:
													// @ts-ignore 
													return item.like
													break;
												case InteractionTypeOptions.dislike:
													// @ts-ignore 
													return item.dislike
													break;
												case InteractionTypeOptions.funny:
													// @ts-ignore 
													return item.funny
													break;
												case InteractionTypeOptions.smart:
													// @ts-ignore 
													return item.smart
													break;
												case InteractionTypeOptions.sad:
													// @ts-ignore 
													return item.sad
													break;
												case InteractionTypeOptions.mindBlown:
													// @ts-ignore 
													return item.mindBlown
													break;
												case InteractionTypeOptions.proudOfYou:
													// @ts-ignore 
													return item.proudOfYou
													break;
												case InteractionTypeOptions.yert:
													// @ts-ignore 
													return item.yert
													break;
												case InteractionTypeOptions.fingersCrossed:
													// @ts-ignore 
													return item.fingersCrossed
													break;
												case InteractionTypeOptions.love:
													// @ts-ignore 
													return item.love
													break;
											}
										}
										return (
											<div 
												key={ index } 
												className={`emoji-response-options ${ ((hasInteracted() && hasInteracted() === resp.type)) ? " active" : "" }`}
												title={ resp.text }
												onClick={() => {
													// @ts-ignore
													if (userData.NOT_AN_AUTHENTICATED_USER) {
														toggleJumpInSignUp(true)
													} else {
														if (showEmojiResponse) {
															// Adding a value to user interactions
															const interactionStatus = hasInteracted();
															if (interactionStatus && interactionStatus === resp.type) {
																// Removing the interaction behavoir all together
																const dec_obj = intereraction_DecrementField(resp.type);
																AddInteraction(
																	userData.userID,// User ID
																	item, // Post
																	undefined, // Object To Update
																	dec_obj, // Object To Remove
																	getInteractionObject() // Interaction Object
																);
																// Updating the interaction UI
																setResponded(_=> { return undefined });
															} else if(interactionStatus && interactionStatus !== resp.type){
																// Updating the interaction to be the changed interaction value
																const dec_obj = intereraction_DecrementField(interactionStatus);
																const inc_obj = intereraction_IncrementField(resp.type);
																AddInteraction(
																	userData.userID,// User ID
																	item, // Post
																	inc_obj, // Object To Update
																	dec_obj, // Object To Remove
																	getInteractionObject(), // Interaction Object
																	(status) => {
																		if (status) {
																			AddItemToYourInteraction(userData.userID, item.documentID, resp.type, (sucess) => {
																				if (sucess) {
																					// Notifying the user once we have interacted with their content
																					const notusText = `👋 Hi, You recieved a ${ resp.repsonseIcon } (${ resp.text }) from ${ userData.firstName } ${ userData.lastName }.`;
																					NotifyUser( item.userID, userData.userID, item, resp.type,notusText, 
																						(sucess) => {
																							if (sucess) {
																							}
																					});
																				}
																			});
																			// Updating the UI for the interaction
																			setResponded(_=> { return resp.type });
																		}
																	}
																);
															} else {
																// Adding interaction for an item a user has not interacted with
																const inc_obj = intereraction_IncrementField(resp.type);
																AddInteraction( userData.userID, item, inc_obj, undefined);
																AddItemToYourInteraction(userData.userID, item.documentID, resp.type, (sucess) => {
																	if (sucess) {
																		// Notifying the user once we have interacted with their content
																		const notusText = `👋 Hi, You recieved a ${ resp.repsonseIcon } (${ resp.text }) from ${ userData.firstName } ${ userData.lastName }.`;
																		NotifyUser( item.userID, userData.userID, item, resp.type,notusText, 
																			(sucess) => {
																				if (sucess) {
																				}
																		});
																	}
																});
																// Updating the UI for the interaction
																setResponded(_=> { return resp.type });
															}
															// Last thing to note is that a user should be notified that someone has like their item... this should be simple and opperate simply in the same way that a an item is added to favorites... only that another user is doing the update.... to do this I would go ahead and copy this setup added above.
														}
													}
												}}
											>
													<label>{ resp.repsonseIcon }</label>
													{/* @ts-ignore */}
													{/* { getInteractionCount(resp.type) && <label>{ getInteractionCount(resp.type) }</label> } */}
											</div>
										)
									})
								}
								<div key={ 124 } className="emoji-response-options metrics">
									<span className="material-symbols-outlined">insert_chart</span>
								</div>
							</div>
						</div>

						{/* For Likes greater than 100 should be popping out in a { userUID , like-type } and popping them back out and updating the like count.
							What I'm saying is
							Like model
							{
							likeCount : number,
							contentLikers : { userUID: string, likeType: string, likerName: string }[] // Max length of array is 100, where new likes are pushed in while the other is pushed out.
							}
						*/}
						<div className='comments-controlls-container'>
							{/* https://stackoverflow.com/questions/46554091/cloud-firestore-collection-count */}
							
							{/* 
								<div className='comment-send-button-container'>
									<textarea className='comment-area' placeholder='Comment...' value={ comment } onChange={e=> { setComment(_=> { return e.target.value; })}} />
									<button className='comment-area-send-button' onClick={()=> { 
										AddComment(item, comment, userData);
										setComment(_=> { return ""; })
									}}>send</button>
								</div> 
							*/}

							<div className='see-comments-button' onClick={() => {
								// @ts-ignore
								if (userData.NOT_AN_AUTHENTICATED_USER) {
									toggleJumpInSignUp(true)
								} else {
									showCommentSectionPopOut(true, item);
								}
							}}>
								<div className="eye-icon-container">
									<img loading={"lazy"} src={ EyeIcon }/>
									<div className="image-cover"/>
								</div>
								<label>comments</label>
							</div>
						</div>
					</div>
				}
				{ 
					item.postType === PostType.shortFormVideo && <ShortFormVideoPlayer id={ uuidGenerator() } autoPlay={ false } title={ item.title ? item.title : "" }  textPost={ item.textContent ? item.textContent : "" }/>
				}
				{
					(item.postType === PostType.imagePosts && item.imageURLs && item.imageURLs.length < 2 && window.innerWidth > 600) && <ImageItemsView imageList={ item.imageURLs }/>
				}
			</div>
			{ ( (index > 1) && (((index + 1)) % 2) === 0) && <BingAdUnit/>
			/* <AdUnit/> */ }
		</>
	)
}































// This Content Card Makes It's Own Individual Document Request Rather Then Being Passed Data And Making A Request
interface ContentCard_indv_REQ { 
	postID: string; 
	index? : number;
	showEmojiResponse : boolean;
}
export const ContentCard_IndividualDataRequest = ({ postID, index, showEmojiResponse }: ContentCard_indv_REQ) => {
	const [ item, setItem ] = useState<ContentModel | undefined>(undefined);

	const speakButtonText = "speak"; //"Yell It At Me";
	function SpeakContent(postContent : string) {
		let msg = new SpeechSynthesisUtterance();
		//
		let voices = window.speechSynthesis.getVoices();
		let goodVoiceOptions = [/* 
		67 good, 76 okay, 81 better, 85 robot singing lol, 88 okay, 90 decent
		91 pretty dang good, 98 okay, 100 good, 101 good, 109 classic computer good, 112 okay,
		144 best */];
		msg.voice = voices[ 67 ];
		msg.voice = voices[ 144 ];
		msg.volume = 100.0;
		msg.rate = 0.85; // From 0.1 to 10
		msg.pitch = 0.9; // From 0 to 2
		msg.lang = 'en-US';
		msg.text = postContent;
		// Restarting the Speech
		window.speechSynthesis.cancel();
		speechSynthesis.getVoices().forEach(function(voice) {
			let preventVal = false
			if (voice.name === "Google US English " && preventVal) { msg.voice = voice; }
		});
		msg.voice = voices[ 144 ];
		window.speechSynthesis.speak(msg);
	}
	
	async function getDocument() {
		const documentRef = doc(db, `${ Posts_Coll_And_Doc_Path }/${ postID }`);        
		await getDoc(documentRef)
		.then((item) => {
			if (item.exists()) {
				const content = item.data() as ContentModel;
				if (content) {
					setItem(_=> { return content; });
				}
			}
		}).catch((err) => {
		})
    }

	useEffect(()=> {
		getDocument();
	}, [])
	
	return (
		<>
			{ item &&
				<div className='content-items' key={ index }>
					{ item.postType !== PostType.shortFormVideo &&
						<div className='long-form-video v-stack-item'>
							<div className='postedby-speech-and-more-button'>
								<div className='content-post-by-container'>
									<div className='userProfile-img-content-container img-container'>
										<img loading={"lazy"} alt="" />
										<div className='cover'></div>
									</div>
									<label className='userProfile-name'>Username</label>
								</div>

								<div className='speak-content-button' onClick={_=>{SpeakContent(item.textContent!); }}>
									<label className='speak-content-label'>{ speakButtonText }</label>
								</div>

								<div className='favorites-button-container'>
									<div className='favorites-button'></div>
								</div>
							</div>

							<h3 className='content-title'>{ item.title }</h3>
							<div dangerouslySetInnerHTML={{ __html: `<p class="content-post">${ AddingEnters(item.textContent!, item.textTranscriptionType) }</p>` } }></div>
							{ 
								item.postType === PostType.longFormVideo && <MoviePlayer id={ uuidGenerator() } playbackId={ item.videoPlaybackID ? item.videoPlaybackID : ""}/>
							}
							{
								item.postType === PostType.imagePosts && <ImageItemsView imageList={ item.imageURLs }/>
							}
							{ showEmojiResponse &&
								<div className='like-and-emoji-responses-container'>
									<div className='like-and-emoji-responses-hstack'>
										{
											ResponseTypesList.map((resp, index) => {
												return (
													<div 
														key={ index } 
														className='emoji-response-options' 
														title={ resp.text }
														onClick={() => {
															const emojiInteractionref = `${item.community}/${item.community}/${postsDocTitle}/${item.documentID}/${emojiResponsesDocTitle}/${"userID_1234"}`;
															setDoc(doc(db, emojiInteractionref), {
																userID: "userID_1234",
																likeType: resp.responseType
															})
														}}
													>
															<label>{ resp.repsonseIcon }</label>
													</div>
												)
											})
										}
									</div>
								</div>
							}

							{/* For Likes greater than 100 should be popping out in a { userUID , like-type } and popping them back out and updating the like count.
								What I'm saying is
								Like model
								{
								likeCount : number,
								contentLikers : { userUID: string, likeType: string, likerName: string }[] // Max length of array is 100, where new likes are pushed in while the other is pushed out.
								}
							*/}
							<div className='comments-controlls-container'>
								{/* https://stackoverflow.com/questions/46554091/cloud-firestore-collection-count */}
								<div className='comment-send-button-container'>
									<textarea className='comment-area' placeholder='Comment...' />
									<button className='comment-area-send-button'>send</button>
								</div>

								<div className='see-comments-button'>
									<label>👁️ comments</label>
								</div>
							</div>
						</div>
					}
					{ 
						item.postType === PostType.shortFormVideo && <ShortFormVideoPlayer id={ uuidGenerator() } autoPlay={ false } title={ item.title ? item.title : "" }  textPost={ item.textContent ? item.textContent : "" }/>
					}
					{ index === 2 && <AdUnit/> }
					
				</div>
			}
		</>
	)
}
