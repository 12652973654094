import React, { useState, useEffect, useContext } from "react";
import Compressor from "compressorjs";
import ColorUI from "../../Components/ColorUI/ColorUI";
import UserContext from "../../Contexts/UserContext";
import { AddNewUser, Exam_Score_Coll_Doc_Path, uploadUserProfileImage, UrlObjectModel, userprofile_storage_path, User_Invitaion_Coll_Doc_Path } from "../../fb/dbMethods";
import { auth, db, storage } from "../../fb/fbSetup";
import { ref, uploadBytes } from "firebase/storage";
import { signOut, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { ArrayOfComm, CommunityObjectArray, CurrentUserDataModel, ExamScoreDocModel, PagePaths } from "../../Models/Models";
import "./NewUserPage.css";
import { ApplicationName } from "../../Models/Models";
import { 
    Region, EastWestRegion, NorthSouthRegion, // Region
    DisclosedPsychType, Openess, Conscientiousness, Extraversion, Agreeableness, Neuroticism, // Personality Type
    PsychTypeGradient_default, OpenessPsychType_default, ConscientiousnessPsychType_default, ExtraversionPsychType_default, AgreeablenessPsychType_default, NeuroticismPsychType_default, UserPersonalModel,
 } from "../../Recomendations/Recomendations";
import { collection, doc, getDoc, getDocs, limit, query, updateDoc, where } from "firebase/firestore";
import ControllerContext from "../../Contexts/ControllerContexts";
import logo from "../../../Resources/AppIcon.png";
import { NewUser_AddToDunbarColony } from "../DunbarColony/DunbarBackend/DunbarColonyMangament";
import Lottie from "lottie-react";
import loading_Lottie from "../../../Resources/Lottie/loading.json";

const NewUserViewMobile_IOS = () => {
    const [ userData, setUserData ] = useState<CurrentUserDataModel | undefined>(undefined);
	const [ userID, setUserID ] = useState<string | undefined>(undefined);
	//
	//
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const tkn = urlParams.get('t');  
		const ud = urlParams.get('ud');   
		if (tkn) {
			signInWithCustomToken(auth, tkn)
			.then(sucess => {
				setUserID(`${ud}`);
			})
			.catch(error => {
			});
		}
	}, []);

    return (
        <>
            { userID &&
                <NewUserView userID={ userID }/>
            }
        </>
    );
}

// Topics Of Intersts :
interface CommunityInterestDataModel { title: string; selected: boolean; }
const ArrayOfCommunityData: CommunityInterestDataModel[] = ArrayOfComm.map(item => { return { title: item, selected: false } })
//
interface NewUserMobileProps { userID: string }
const NewUserView = ({ userID }: NewUserMobileProps) => {
    const [ newUserData, setNewUserData ] = useState<CurrentUserDataModel | undefined >(undefined);
    // disclosedUserPsycheType
    // Personality Types: 
    const [ openess, setOpenness ] = useState<Openess>(OpenessPsychType_default);
    const [ conscientiousness, setConscientiousness ] = useState<Conscientiousness>(ConscientiousnessPsychType_default);
    const [ extraversion, setExtraversion ] = useState<Extraversion>(ExtraversionPsychType_default);
    const [ agreeableness, setAgreeableness ] = useState<Agreeableness>(AgreeablenessPsychType_default);
    const [ neuroticism, setNeuroticism ] = useState<Neuroticism>(NeuroticismPsychType_default);
    // Community Items Of Interest
    const [ itemsOfInterest, setItemsOfInterests ] = useState<CommunityInterestDataModel[]>(ArrayOfCommunityData);
    const [ allowSave, setAllowSave ] = useState<boolean>(true);
    const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
    const [ fake, setFake ] = useState(false);

    useEffect(() => {
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
        console.log("console.log in IOS");
    }, [])
    return (
        <div className="newUserView">
            <div className="new-user-bckg-view">
                <ColorUI colonyCount={ 300 }/>
            </div>
            <div className="new-user-main-content-scrollview">
                <div className="new-user-content-view">
                    {/* <button onClick={()=> { signOut(auth); }}>sign out</button> */}
                    <div className="logo-title-container">
                        <div className="logo-container">
                            <img src={ logo }/>
                            <div className="image-cover"/>
                        </div>
                        <h1 className="auth-app-title">{ ApplicationName }</h1>
                    </div>
                    <h1 className="view-title">Setup Profile</h1>
                    {/* Basic User Data: */}
                    <AddBasicUserDataField setUserData={ setNewUserData } userID={ userID }/>
                    {/* Personality Types:  */}
                    <PersonalityThumbSlider_Openess type={ OpenessPsychType_default } options={ PsychTypeGradient_default } setValue={ setOpenness }/>
                    <PersonalityThumbSlider_Conscientiousness type={ ConscientiousnessPsychType_default } options={ PsychTypeGradient_default } setValue={ setConscientiousness }/>
                    <PersonalityThumbSlider_Extraversion type={ ExtraversionPsychType_default } options={ PsychTypeGradient_default } setValue={ setExtraversion }/>
                    <PersonalityThumbSlider_Agreeableness type={ AgreeablenessPsychType_default } options={ PsychTypeGradient_default } setValue={ setAgreeableness }/>
                    <PersonalityThumbSlider_Neuroticism type={ NeuroticismPsychType_default } options={ PsychTypeGradient_default } setValue={ setNeuroticism }/>
                    {/* Selecting A Set Of Intersts */}
                    <SelectTopicsOfInterest itemsOfInterest={ itemsOfInterest } setItemsOfInterests={ setItemsOfInterests }/>
                    {/* Save Button And Moving On To Testing Step */}
                        { errorMessage &&
                            <p
                            style={{
                                fontWeight: "600",
                                color: "var(--app-red)",
                                marginTop: "30px"
                            }}
                            >{ errorMessage }</p>
                        }
                        { allowSave && 
                            <button className="save-user-data-button" onClick={() => {
                                if (allowSave) {
                                    let missingList = []
                                    var _newUserData = {
                                        firstName: "",
                                        lastName: "",
                                        customUserName: "",
                                        age: 0
                                    };
                                    
                                    var FirstName = document.querySelector(".first-name-field");
                                    // @ts-ignore
                                    const fN = FirstName.value as string;
                                    // @ts-ignore
                                    if (FirstName && FirstName.value && fN && fN.trim() != "") {
                                        // @ts-ignore
                                        _newUserData.firstName = FirstName.value;
                                    } else {
                                        missingList.push("First Name")
                                    }


                                    var LastName = document.querySelector(".last-name-field");
                                    // @ts-ignore
                                    const lN = LastName.value as string;
                                    // @ts-ignore
                                    if (LastName && LastName.value && lN && lN.trim() != "") {
                                        // @ts-ignore
                                        _newUserData.lastName = LastName.value;
                                    } else {
                                        // missingList.push("Last Name")
                                    }

                                    var UserName = document.querySelector(".user-name-field");
                                    // @ts-ignore
                                    const uN = UserName.value as string;
                                    // @ts-ignore
                                    if (UserName && UserName.value && uN && uN.trim() != "") {
                                        // @ts-ignore
                                        _newUserData.customUserName = UserName.value;
                                    } else {
                                        missingList.push("Username")
                                    }


                                    var Age = document.querySelector(".age-field");
                                    // @ts-ignore
                                    if (Age && Age.value) {
                                        // @ts-ignore
                                        _newUserData.age = Number(Age.value);
                                    }
                                    const cleanTopics: string[] = itemsOfInterest.filter(i => i.selected === true ).map(item => { return item.title });
                                    if (cleanTopics.length > 6 && missingList.length < 1) {
                                        setErrorMessage(_=> { return null; });
                                        setAllowSave(_=> { return false; });
                                        // Purifying topics which is just turning array of objects into an array of strings
                                        const userPersonalModel: UserPersonalModel = {
                                            age: _newUserData.age ? _newUserData.age : 0,
                                            country: "United States",
                                            region: { ew: EastWestRegion.east, ns: NorthSouthRegion.south },
                                            disclosedPsychType: {
                                                openess: openess,
                                                conscientiousness: conscientiousness,
                                                extraversion: extraversion,
                                                agreeableness: agreeableness,
                                                neuroticism: neuroticism,
                                            }
                                        }
                                        AddNewUser(
                                            userID, 
                                            _newUserData.firstName, 
                                            _newUserData.lastName, 
                                            _newUserData.customUserName,
                                            userPersonalModel,
                                            cleanTopics,
                                        (complete, newUserModel) => {
                                            // if (bool) { window.location.href = PagePaths.Test; }
                                            if (complete && newUserModel) {
                                                updateDoc(doc(db, `_U_S_E_R/${ userID }`), { 
                                                    disclosedUserPsycheType: userPersonalModel,
                                                    TopicsOfInterest: cleanTopics
                                                }).then(status=> {
                                                    NewUser_AddToDunbarColony(newUserModel, (completed) => {
                                                        console.log("mobile_finished_adding_new_user_data");
                                                    });
                                                }).catch(err => {
                                                    /* Do something if everything return zero */
                                                    setAllowSave(_ => { return true; }); // Under the condition that the user can save their data due to error re-show save button
                                                })
                                            } else { 
                                                /* Do something if everything return zero */
                                                setAllowSave(_ => { return true; }); // Under the condition that the user can save their data due to error re-show save button
                                            }

                                        });
                                    } else if (missingList.length > 0) {
                                        let message = "You are missing information in the"
                                        missingList.forEach((val, index) => {
                                            if (index == 0) {
                                                message = message + ` ${val} field`
                                            } else {
                                                message = message + ` ,${val} field`
                                            }
                                        })
                                        message = message + "."
                                        setErrorMessage(message);
                                    } else if (cleanTopics.length < 7) {
                                        setErrorMessage("You must select at least 7 topics of interests.")
                                    } else {
                                        setErrorMessage("Unknown error.")
                                    }
                                }
                            }}>save</button>
                        }
                        { !allowSave &&
                            <div className="loading-lottie" style={{
                                position: "relative",
                                width: "100px",
                                height: "100px",
                                display: "grid",
                                placeItems: "center"
                            }}>
                                <Lottie animationData={ loading_Lottie } loop={true}/>
                            </div>
                        }
                </div>
            </div>
        </div>
    );
}

export default NewUserViewMobile_IOS;

interface AddBasicUserDataModel { setUserData: React.Dispatch<React.SetStateAction<CurrentUserDataModel>>; userID: string};
const AddBasicUserDataField = ({setUserData, userID }: AddBasicUserDataModel) => {
    const { renderErrorMessagePopUp, showErrorPopup } = useContext(ControllerContext);
    const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
    const [ age, setAge ] = useState<number>(0);
    const [ shouldBeUploadingImage, setShouldBeUploadingImage ] = useState(false);

    function SelectMessageImage () {
        const input = document.querySelector('.newUserView input#image-input-id.image-input');
        if (input) {
            // @ts-ignore
            input.click();
        }
    }

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files: File[] = Array.from(e.target.files)
        const file: File = files[0];
        const fileType = files[0].type;
        const src = URL.createObjectURL(files[0]);
        // setImgSrc(_=> { return src; });
        setShouldBeUploadingImage(_=> { return true; });
        uploadUserProfileImage(file, "jpg", userID, (sucess, urlsObject) => {
            if (sucess) { 
                console.log(`{ s: ${ urlsObject.smallImageUrl }, m: ${ urlsObject.mediumImageUrl }, l: ${ urlsObject.largeImageUrl } }`);
                setShouldBeUploadingImage(_=> { return false; });
                setImgSrc(_=> { return urlsObject.mediumImageUrl; });
            }
        }, undefined)
    }

    async function updateUserProfileImageObject(userID: string, imageURLs : UrlObjectModel) {
        const ref_str = `${ userprofile_storage_path }/${ userID }`;
        const ref_doc = doc(db, ref_str);
        const update = await updateDoc(ref_doc, { imageProfileImageURLs: imageURLs } )
        .then(sucess => {
            console.log(sucess);
            showErrorPopup( true, "Your profile image photo has been uploaded", "Sucess!", "var(--app-green-85)", undefined, undefined, undefined, undefined)
        })
        .catch(err => {
            console.log(err);
        })
    }
    
    return (
        <div className="add-basic-user-data-view-and-profile-image">
            {/* Add Profile Image */}
            <div className="proflie-image-container" onClick={()=>{ SelectMessageImage() }}>
                { imgSrc && <img loading={"lazy"} src={ imgSrc } /> }
                { !imgSrc && <span className="plusSign"><span className="hdash"/><span className="vdash"/></span> }
                <input className="image-input" id="image-input-id" type="file" onChange={ handleFileSelected } accept="image/x-png,image/jpeg" style={{ display: "none" }}/>
                { (shouldBeUploadingImage) &&
                    <div style={{
                        position: "absolute",
                        width: "calc(100% - 40px)",
                        height: "calc(100% - 40px)",
                        padding: "40px",
                        zIndex: 100000,
                        display: "grid",
                        placeItems: "center"
                    }}>
                        <Lottie animationData={ loading_Lottie } loop={true}/>
                    </div>
                }
            </div>
            {/* Add Basic User Data */}
            <div className="add-basic-user-data-view">
                {/* First & Last Name */}
                {/* First & Last Name */}
                {/* First & Last Name */}
                <div className="first-last-name field-container">
                    <div className="text-field-cont">
                        <label className="field-label" htmlFor="firstName">First Name</label>
                        <input className="first-name-field" name="firstName" type={"text"} placeholder="first name" onChange={e=> { 
                            // setUserData(curr => { return {...curr, firstName: e.target.value }; }) 
                        }}/>
                    </div>
                    <div className="text-field-cont">
                        <label className="field-label" htmlFor="lastName">Last Name</label>
                        <input className="field-input last-name-field" placeholder="last name" name="lastName" type={"text"}  onChange={e=> { 
                            // setUserData(curr => { return {...curr, lastName: e.target.value } }) 
                        }}/>
                    </div>
                </div>
                {/* User Name */}
                {/* User Name */}
                {/* User Name */}
                <div className="username-age field-container">
                    <div className="text-field-cont">
                        <label className="field-label" htmlFor="username">Username</label>
                        <input className="field-input user-name-field" placeholder="username" name="username" type={"text"}   onChange={e=> { 
                            // setUserData(curr => { return {...curr, customUserName: e.target.value } }) 
                        }}/>
                    </div>
                    
                    <div className="text-field-cont">
                        <label className="field-label" htmlFor="age">Age</label>
                        <input className="field-input age-field" placeholder="24" name="age" type={"number"} onChange={e=> {
                             // setUserData(curr => { return {...curr, age: Number(e.target.value) } }) 
                        }}/>
                    </div>
                </div>
                {/* Demographics Data & Setup */}
                {/* Demographics Data & Setup */}
                {/* Demographics Data & Setup */}
                <div></div>
                {/* Bio */}
                {/* Bio */}
                {/* Bio */}
            </div>
        </div>
    );
}


// Disclosed Personality Assesments:
// Disclosed Personality Assesments:
// Disclosed Personality Assesments: 
interface PersonalityThumbSliderModel_Openess {
    type: Openess;
    options: string[];
    setValue: React.Dispatch<React.SetStateAction<Openess>>
}
const PersonalityThumbSlider_Openess = ({type, options, setValue}: PersonalityThumbSliderModel_Openess) => {
    const [ selectedIndex, setSelectedIndex] = useState<{0: number; 1: number;}>({ "0": 2, "1": 2}); // Default value set to the middle
    return (
        <>
            {
                type.spectrum.map((spectrum, mainIndex) => {
                    return (
                        <div key={ mainIndex } className="thumb-slider-view">
                            <div className="thumb-slider-track">
                                {
                                    options.map((option, index) => {
                                        return (
                                            <div key={ index } className="thumb-slider-option" style={{left : `${( (25 * index) ) }%` }}>
                                                <div 
                                                    className={`thumb-select ${ selectedIndex[`${ mainIndex }`] === index ? " active" : ""}`}
                                                    onClick={() => {
                                                        if (spectrum["one"] === "inventive") {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => {
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });
                                                            setValue(curr => { return { ...curr, inventive_v_consistent: newValue, avgValue: ((curr.curious_v_cautious + newValue) / 2) } });
                                                        } else {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });
                                                            setValue(curr => { return { ...curr, inventive_v_consistent: newValue, avgValue: ((curr.curious_v_cautious + newValue) / 2) } });
                                                        }
                                                    }} 
                                                />

                                                <div className="thumb-label-options-container">
                                                    <label className="thumb-option-item-label">{ option }</label>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="slider-poles">
                                <div className="pole-item left">
                                <label className="pole-item-label">{ spectrum["one"] }</label>
                                </div>
                                <div className="pole-item right">
                                    <label className="pole-item-label">{ spectrum["two"] }</label>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

interface PersonalityThumbSliderModel_Conscientiousness {
    type:  Conscientiousness ;
    options: string[];
    setValue: React.Dispatch<React.SetStateAction<Conscientiousness>>
}
const PersonalityThumbSlider_Conscientiousness = ({type, options, setValue}: PersonalityThumbSliderModel_Conscientiousness) => {
    const [ selectedIndex, setSelectedIndex] = useState<{0: number; 1: number;}>({ "0": 2, "1": 2}); // Default value set to the middle
    return (
        <>
            {
                type.spectrum.map((spectrum, mainIndex) => {
                    return (
                        <div key={ mainIndex } className="thumb-slider-view">
                            <div className="thumb-slider-track">
                                {
                                    options.map((option, index) => {
                                        return (
                                            <div key={ index } className="thumb-slider-option" style={{left : `${( (25 * index) ) }%` }}>
                                                <div 
                                                    className={`thumb-select ${ selectedIndex[`${ mainIndex }`] === index ? " active" : ""}`}
                                                    onClick={() => {
                                                        if (spectrum["one"] === "efficient") {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });
                                                            setValue(curr => { return { ...curr, efficient_v_easyGoing: newValue, avgValue: ((curr.organized_v_careless + newValue) / 2) } });
                                                        } else {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });

                                                            setValue(curr => { return { ...curr, organized_v_careless: newValue, avgValue: ((curr.efficient_v_easyGoing + newValue) / 2) } });
                                                        }
                                                    }} 
                                                />

                                                <div className="thumb-label-options-container">
                                                    <label className="thumb-option-item-label">{ option }</label>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="slider-poles">
                                <div className="pole-item left">
                                <label className="pole-item-label">{ spectrum["one"] }</label>
                                </div>
                                <div className="pole-item right">
                                <label className="pole-item-label">{ spectrum["two"] }</label>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}


interface PersonalityThumbSliderModel_Extraversion {
    type:  Extraversion;
    options: string[];
    setValue: React.Dispatch<React.SetStateAction<Extraversion>>
}
const PersonalityThumbSlider_Extraversion = ({type, options, setValue}: PersonalityThumbSliderModel_Extraversion) => {
    const [ selectedIndex, setSelectedIndex] = useState<{0: number; 1: number;}>({ "0": 2, "1": 2}); // Default value set to the middle
    return (
        <>
            {
                type.spectrum.map((spectrum, mainIndex) => {
                    return (
                        <div key={ mainIndex } className="thumb-slider-view">
                            <div className="thumb-slider-track">
                                {
                                    options.map((option, index) => {
                                        return (
                                            <div key={ index } className="thumb-slider-option" style={{left : `${( (25 * index) ) }%` }}>
                                                <div 
                                                    className={`thumb-select ${ selectedIndex[`${ mainIndex }`] === index ? " active" : ""}`}
                                                    onClick={() => {
                                                        if (spectrum["one"] === "outgoing") {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });
                                                            setValue(curr => { return { ...curr, outgoing_v_solitary: newValue, avgValue: ((curr.energetic_v_reserved + newValue) / 2) } });
                                                        } else {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });

                                                            setValue(curr => { return { ...curr, energetic_v_reserved: newValue, avgValue: ((curr.outgoing_v_solitary + newValue) / 2) } });
                                                        }
                                                    }} 
                                                />

                                                <div className="thumb-label-options-container">
                                                    <label className="thumb-option-item-label">{ option }</label>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="slider-poles">
                                <div className="pole-item left">
                                <label className="pole-item-label">{ spectrum["one"] }</label>
                                </div>
                                <div className="pole-item right">
                                <label className="pole-item-label">{ spectrum["two"] }</label>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

interface PersonalityThumbSliderModel_Agreeableness {
    type: Agreeableness;
    options: string[];
    setValue: React.Dispatch<React.SetStateAction<Agreeableness>>
}
const PersonalityThumbSlider_Agreeableness = ({type, options, setValue}: PersonalityThumbSliderModel_Agreeableness) => {
    const [ selectedIndex, setSelectedIndex] = useState<{0: number; 1: number;}>({ "0": 2, "1": 2}); // Default value set to the middle
    return (
        <>
            {
                type.spectrum.map((spectrum, mainIndex) => {
                    return (
                        <div key={ mainIndex } className="thumb-slider-view">
                            <div className="thumb-slider-track">
                                {
                                    options.map((option, index) => {
                                        return (
                                            <div key={ index } className="thumb-slider-option" style={{left : `${( (25 * index) ) }%` }}>
                                                <div 
                                                    className={`thumb-select ${ selectedIndex[`${ mainIndex }`] === index ? " active" : ""}`}
                                                    onClick={() => {
                                                        if (spectrum["one"] === "friendly") {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });
                                                            setValue(curr => { return { ...curr, friendly_v_challenging: newValue, avgValue: ((curr.compassionate_v_detached + newValue) / 2) } });
                                                        } else {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });

                                                            setValue(curr => { return { ...curr, compassionate_v_detached: newValue, avgValue: ((curr.friendly_v_challenging + newValue) / 2) } });
                                                        }
                                                    }} 
                                                />

                                                <div className="thumb-label-options-container">
                                                    <label className="thumb-option-item-label">{ option }</label>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="slider-poles">
                                <div className="pole-item left">
                                <label className="pole-item-label">{ spectrum["one"] }</label>
                                </div>
                                <div className="pole-item right">
                                    <label className="pole-item-label">{ spectrum["two"] }</label>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}


interface PersonalityThumbSliderModel_Neuroticism {
    type: Neuroticism ;
    options: string[];
    setValue: React.Dispatch<React.SetStateAction<Neuroticism>>
}
const PersonalityThumbSlider_Neuroticism = ({type, options, setValue}: PersonalityThumbSliderModel_Neuroticism) => {
    const [ selectedIndex, setSelectedIndex] = useState<{0: number; 1: number;}>({ "0": 2, "1": 2}); // Default value set to the middle
    return (
        <>
            {
                type.spectrum.map((spectrum, mainIndex) => {
                    return (
                        <div key={ mainIndex } className="thumb-slider-view">
                            <div className="thumb-slider-track">
                                {
                                    options.map((option, index) => {
                                        return (
                                            <div key={ index } className="thumb-slider-option" style={{left : `${( (25 * index) ) }%` }}>
                                                <div 
                                                    className={`thumb-select ${ selectedIndex[`${ mainIndex }`] === index ? " active" : ""}`}
                                                    onClick={() => {
                                                        if (spectrum["one"] === "sensitive") {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });
                                                            setValue(curr => { return { ...curr, sensitive_v_secure: newValue, avgValue: ((curr.nervous_v_confident + newValue) / 2) } });
                                                        } else {
                                                            const newValue = ((25 * index) / 100);
                                                            setSelectedIndex(curr => { 
                                                                let obj = curr;
                                                                obj[`${mainIndex}`] = index;
                                                                return obj; 
                                                            });

                                                            setValue(curr => { return { ...curr, nervous_v_confident: newValue, avgValue: ((curr.sensitive_v_secure + newValue) / 2) } });
                                                        }
                                                    }} 
                                                />

                                                <div className="thumb-label-options-container">
                                                    <label className="thumb-option-item-label">{ option }</label>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="slider-poles">
                                <div className="pole-item left">
                                    <label className="pole-item-label">{ spectrum["one"] }</label>
                                </div>
                                <div className="pole-item right">
                                    <label className="pole-item-label">{ spectrum["two"] }</label>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}


interface SelectTopicsOfInterestModel { 
    itemsOfInterest : CommunityInterestDataModel[]; 
    setItemsOfInterests : React.Dispatch<React.SetStateAction<CommunityInterestDataModel[]>>
}
const SelectTopicsOfInterest = ({itemsOfInterest, setItemsOfInterests }:SelectTopicsOfInterestModel) => {
    return (
        <div className="topics-of-inteterst-scrollview-view">
            <div className="topics-of-interets-content-view">
                <h2 className="topics-of-interest-title">Select Topics That Interest You</h2>
                <p className="topics-of-interest-description">The selections below will help us recommend content to you.</p>
                <h2 className="topics-of-interest-title small" style={{ color: "rgba(0,0,0,0.55)"}}>{`(select a minimum of 7 topics)`}</h2>
                <div className="topics-of-interest-container">
                    {
                        itemsOfInterest.map((item, index) => {
                            return (
                                <div key={ index } className={`topic-item${ item.selected ? " active" : ""}`} onClick={() => {
                                    let list = itemsOfInterest.map((elem, i) => {
                                        if (i === index) { return { title: elem.title, selected: !elem.selected };
                                        } else { return elem; }
                                    })
                                    setItemsOfInterests(list);
                                }}>
                                    <label className="topic-title">{ item.title }</label>
                                </div>
                            )
                        })
                    }
                </div>
                <h2 className="topics-of-interest-title" style={{ marginTop: "40px", marginBottom: "30px" }}>{`${ itemsOfInterest.filter(_ => _.selected ).length } of 7`}</h2>
            </div>
        </div>
    );
}

// Look at the content field in the post array of JSON objects below. 
// Sort the post list based on rather the content field is more or less inline with this sentence: 
// "I'm looking for posts that are positive, about AI technology, and story.".
// Do not return code. Do not return a sentence. Only return a JSON object back of the newly sorted list.
// const posts = [
//     {
//         id: 1,
//         content: "Look At This Incredible Comic Book Art!: I use to want to be a comic book artist growing up so I’m a huge fan of seeing work like this! (A post with images)"
//     },
//     {
//         id: 2,
//         content: "Judgment As It Relates To Dasein (and now AI): Judgment in a technical sense is the production of potential action models with a selection of one that is both likely probable( “truthy” ) and aligned with the system care that prompts the system to search for a set of possible options “care-cares-for”. Care itself is a function. That comes from not so intelligent objectives, but is used to send a directive to the the entire chain of actions from the model."
//     },
//     { 
//         id: 3,
//         content: "A Horror Film From The 1940's: I can't lie. The visual language in this film has aged pretty well. It has many of the elements that are still used today."
//     },
//     {
//         id: 4,
//         content: "Beautiful Interior Design: The design makes me feel warm and homely. Anyone else feel thy way with that when these images?"
//     }
// ]