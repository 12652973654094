import { CommunityObjectArray } from "../Models/Models";
export const NOT_AN_AUTHENTICATED_USER = "NOT_AN_AUTHENTICATED_USER";
export const unsignedUserDataModel = {
    "currentProjectItems": [
    ],
    "imageProfileImageURLs": {
        "largeImageUrl": "",
        "smallImageUrl": "",
        "mediumImageUrl": ""
    },
    "contentColonies": [],
    "followerCount": 0,
    "peopleYouFollow": [],
    "projectExpience": [],
    "professionalExperience": [],
    "customUserName": "Sign Up / In",
    "allowDirectMessage": false,
    "feedImages": [],
    "posts": [],
    "firstName": "Sign",
    "lastName": "Up / In",
    "DunbarColonyID": NOT_AN_AUTHENTICATED_USER,
    "messagesRefferences": [],
    "bio": "",
    "userID": "no_auth_user",
    "prioritizeExperience": false,
    "profilePrivate": false,
    "TopicsOfInterest": Object.keys(CommunityObjectArray).map(key => CommunityObjectArray[`${key}`].title),
    "followingCount": 0,
    "customUserImages": [],
    "communities": [],
    "userName": "",
    "peopleOfCare": [],
    //
    //
    NOT_AN_AUTHENTICATED_USER : "NOT_AN_AUTHENTICATED_USER" // THIS IS EXCLUSIVELY FOR ALLOWING USERS TO NOT HAVE TO SIGN IN TO GET AN APPREACIATION FOR THE APP
};

