import { signInWithCustomToken } from "firebase/auth";
import React, { useState, useEffect, useContext } from "react";
import { auth } from "../../../fb/fbSetup";
import "../LiveSessionMobile/LiveSessionMobile.css";
import { ApplicationName, CurrentUserDataModel } from "../../../Models/Models";
import { userprofile_storage_path } from "../../../fb/dbMethods";
import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../../../fb/fbSetup";
import { doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import {
    freeTurnServers, 
    stunServers,
    LiveSessionModel,
    // Methods
    ____CheckForAnyOpenChatSessoions,
    // Models
    _CallerModel,
    LiveSessionRole,
    _AnswererModel,
    customRTCSessionDescription,
    customRTCSessionDescription_Type,
    // consts
    Live_Session_Storage_Ref,
    nestedOfferCandidatePath,
    nestedAnswerCandidatePath,
} from "./LiveSessionController/LiveSessionController";
import UserContext from "../../../Contexts/UserContext";
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";

interface PromptsListItemModel {
    id: string;
    prompt: string;
    used: boolean;
}

const LiveSessionMobile = () => {
    const [ showLiveSessionView, setShowLiveSessionView ] = useState<boolean>(false);
    const [ userData, setUserData ] = useState<{ userID: string } | undefined>(undefined);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tkn = urlParams.get('t');  
        const ud = urlParams.get('ud');     
        if (tkn) {
            signInWithCustomToken(auth, tkn)
            .then(sucess => {
                if (sucess.user.uid) {
                    console.log("loaded data: ",  ud)//.user.uid)
                    setUserData(_=> { return { userID: `${ud}` }; });
                    setShowLiveSessionView(true);
                }
                // sucess sign in with token.
            })
            .catch(error => {
                console.log("LiveSessionMobile: ", error)
                setShowLiveSessionView(false);
            });
        }
    }, []);

    const [ otherUserID, setOtherUserID ] = useState<string>("");
    // Test Video Call Setup:
    // Test Video Call Setup:
    // Test Video Call Setup:
    // Test Video Call Setup:
    const servers = {
        'iceServers': [
            {
                'urls': 'stun:stun.l.google.com:19302'
            },
            ...dataExamples,
        ]
    };
    // global state
    const [ pc, set_pc ] = useState<RTCPeerConnection>(new RTCPeerConnection(servers));
    const [ statHasChanged, setStateHasChanged ] = useState(false);
    const [ remoteVideo, setRemoteVideo ] = useState(document.querySelector(".LiveSession .user-container.user-two video"));
    //
    const [ webCamVideo, setWebCamVideo ] = useState(document.querySelector(".LiveSession .user-container.user-two video"));
    //
    let metered_TempAPIKey = "00adb3ed4f498719f183f34f6bb9d33cde46"
    let metered_app_name = "bwp"
    useEffect(() => {
        if (showLiveSessionView === true) {
            console.log("WITH NEW ROUTING APIS AYYYYYYYY! >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
            // fetch(`https://${metered_app_name}.metered.live/api/v1/turn/credentials?apiKey=${metered_TempAPIKey}`)
            // .then( sucess => {
                setTimeout(() => {
                    set_pc(_=> { return new RTCPeerConnection(servers); });
                    setWebCamVideo(_=> { return document.querySelector(".LiveSession .user-container.user-two video"); });
                    setRemoteVideo(_=> { return document.querySelector(".LiveSession .user-container.user-one video"); });
                    setStateHasChanged( _=> { return true; }); 
                }, 2000);                    
            // })
        }
    }, [ showLiveSessionView ]);

    useEffect(() => {
        if (statHasChanged && showLiveSessionView) {
            getUserMedia()
        }
    }, [ statHasChanged, showLiveSessionView ]);

    const [ localStream, setLocalStream ] = useState<MediaStream | undefined>(undefined);
    const [ remoteStream, setRemoteStream ] = useState<MediaStream | undefined>(undefined);
    function getUserMedia() {
        const urlParams = new URLSearchParams(window.location.search);
        const vdo = urlParams.get('v');
        navigator.mediaDevices.getUserMedia({ video: (`${vdo}` === "t") ? true : false, audio: true })
        .then( stream => {
            setRemoteStream(() => { return new MediaStream(); });
            setLocalStream(() => { return stream; });
        })
        .catch(error => {
            console.log("ERROR: getUserMedia() => ", error);
        })
    }

    useEffect(() => {
        makeStreamsAvaibleOnPeerConnection();
    }, [ localStream ])

    function makeStreamsAvaibleOnPeerConnection() {
        console.log(`
            makeStreamsAvaibleOnPeerConnection method called:
            {
                localStream: ${localStream },
                webCamVideo: ${ remoteVideo },
            }
        `)
        if (localStream) {
            localStream.getTracks().forEach(track => {
                pc.addTrack(track, localStream);
            })
            
            pc.ontrack = event => {
                if (event.streams.length > 0) {
                    event.streams[0].getTracks().forEach(track => {
                        remoteStream.addTrack(track);
                    })
                }
            }
            // @ts-ignore
            webCamVideo.srcObject = localStream;
            // @ts-ignore
            remoteVideo.srcObject = remoteStream ;
            //
            checkForAvailbleSessions();
        }
    }
    // Check if there are any avaible session open
    function checkForAvailbleSessions() {
        const userID =  auth.currentUser.uid;
        ____CheckForAnyOpenChatSessoions(
            userID,
        (returnedErrorFree, foundASession, session) => {
            if (returnedErrorFree) {
                if (foundASession && session) {
                    answerCall(session);
                } else {
                    createCall();
                }
            }
        })
    }
    //
    //time preparing for an answer 8:50
    let answerListener;
    let answerICEListener;
    async function createCall() {
        const documentID = uuidGenerator(); 
        const callDoc = doc(db, `${Live_Session_Storage_Ref}/${documentID}`);

        // Listen for any ice candidates:
        pc.onicecandidate = event => {
            if (event.candidate) {
                const offerCandidates = doc(db, `${Live_Session_Storage_Ref}/${documentID}/${ nestedOfferCandidatePath}/${nestedOfferCandidatePath}`);
                setDoc(offerCandidates, event.candidate.toJSON())
                .then(_ => {
                    console.log("Offer Ice Candidate Added Sucessfully");
                })
                .catch(error => {
                    console.log("Error: createCall() => Offer ICE Candidate Upload Failed => ", error);
                })
            }
        }
        // create offer
        const offerDescription = await pc.createOffer();
        // set local descript on peer connection to the offer description
        await pc.setLocalDescription(offerDescription);
        // store offer description on db
        const offer : customRTCSessionDescription = {
            sdp: offerDescription.sdp,
            type: offerDescription.type as customRTCSessionDescription_Type
        };
        const sessionObj: LiveSessionModel = {
            createdBy: userData.userID,
            timeOfSessionCreation: new Date(),
            SessionID: uuidGenerator(),
            documentID: documentID,
            Caller: {
                userID: userData.userID,
                //
                sessionDescriptionProtocol: offer,
                //
                role: LiveSessionRole.caller,
            },
            // Answerer: undefined,
            isPaired: false,
            isActive: true,
        }
    
        await setDoc(callDoc, sessionObj)
        .then(() => {
            console.log("Storing Offer SDP Suceeded");
            const ICE_answer_str_ref = `${ Live_Session_Storage_Ref }/${documentID}/${ nestedAnswerCandidatePath }/${ nestedAnswerCandidatePath }`;
            const ICE_AnswerDoc = doc(db, ICE_answer_str_ref);
            //Add A temporary answer ICE Doc
            setDoc(ICE_AnswerDoc, { _obj_holder_item_shouldnt_exist_when_updated_by_answerer : true })
            .then(() => {
                console.log("Sucessfully Added the temporary Nested Answer Data");
                // Listen for an answer
                answerListener = onSnapshot(callDoc, (snapshot) => {
                    let data = snapshot.data() as LiveSessionModel;
                    if (!pc.currentRemoteDescription && data.Answerer && data.Answerer.sessionDescriptionProtocol) {
                        const sdp = data.Answerer.sessionDescriptionProtocol;
                        pc.setRemoteDescription(sdp)
                        setOtherUserID(_=> { return data.Answerer.userID; })
                    }
                });

                answerICEListener = onSnapshot(ICE_AnswerDoc, (snapshot) => {
                    if (snapshot.data()._obj_holder_item_shouldnt_exist_when_updated_by_answerer) {
                        // Do no work  because this is a temorary object...
                    } else {
                        let data = snapshot.data() as RTCIceCandidateInit;
                        if (data) {
                            let candidate = new RTCIceCandidate(data);
                            pc.addIceCandidate(candidate);
                        } else {
                            let candidate = new RTCIceCandidate(snapshot.data());
                            pc.addIceCandidate(candidate);
                        }
                    }
                })
            })
            .catch(error => { console.log("Answer str_ref called 3: ", error); })
        })
        .catch(error => {
            console.log("Error: createCall() => Storing Offer SDP Failed => ", error);
        });
    }

    let offerICEListener;
    async function answerCall(session: LiveSessionModel) {
        const docID = session.documentID;
        const callDoc = doc(db, `${Live_Session_Storage_Ref}/${docID}`);
        const answerCandidates = doc(db,`${ Live_Session_Storage_Ref }/${docID}/${ nestedAnswerCandidatePath }/${ nestedAnswerCandidatePath }`);
        pc.onicecandidate = event => {
            if (event.candidate) {
                const offerCandidates = doc(db, `${Live_Session_Storage_Ref}/${docID}/${nestedAnswerCandidatePath}/${nestedAnswerCandidatePath}`);
                setDoc(offerCandidates, event.candidate.toJSON())
                .then(_ => {
                    console.log("Answer Ice Candidate Added Sucessfully");
                })
                .catch(error => {
                    console.log("Error: createCall() => Answer ICE Candidate Upload Failed => ", error);
                })
            }
        }

        await getDoc(callDoc)
        .then(async (document) => {
            let data = document.data() as LiveSessionModel;
            if (data) {
                let sessionDescription = new RTCSessionDescription(data.Caller.sessionDescriptionProtocol);
                pc.setRemoteDescription(sessionDescription);
                setOtherUserID(_=> { return data.Caller.userID; })
                const answerDescription = await pc.createAnswer();
                await pc.setLocalDescription(answerDescription);

                const answer : customRTCSessionDescription = {
                    sdp: answerDescription.sdp,
                    type: answerDescription.type as customRTCSessionDescription_Type
                };

                const answererObj = { Answerer: { "userID": userData.userID, "sessionDescriptionProtocol": answer, "role": LiveSessionRole.answerer, }, isPaired: true, }
                await updateDoc(callDoc, answererObj)
                .then(() => {
                    const ICE_offer_str_ref = `${ Live_Session_Storage_Ref }/${session.documentID}/${ nestedOfferCandidatePath }/${ nestedOfferCandidatePath }`;
                    const ICE_OfferDoc = doc(db, ICE_offer_str_ref);
                    offerICEListener = onSnapshot(ICE_OfferDoc, (snapshot) => {
                        let candidate = new RTCIceCandidate(snapshot.data());
                        pc.addIceCandidate(candidate);
                    })
                })
                .catch(error => {
                }) 
            }
        })
        .catch(error => {
            console.log("Error: Faild to grab session data");
        })
    }

    
    return (
        <>
            { userData &&
                <div className="LiveSession Mobile">
                    <VideoUserOneView userID={ otherUserID } />
                    <VideoUserTwoView userID={ userData.userID } />
                </div>
            }
        </>
    );
}

export default LiveSessionMobile;

interface VideViewProps { userID: string; }
const VideoUserOneView = ( { userID }: VideViewProps ) => {
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    useEffect(()=>{ 
        if (userID.trim() !== "") {
            getCommentUserProfileImage();
        }
     },[ userID ]);
     
    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    return (
        <div className="user-container user-one">
            {/*  */}
            <div className="video-profile-image-container">
                { imageSrc && <img loading={ "lazy" } src={ imageSrc }/>}
                <div className="image-cover"/>
            </div>
            {/*  */}
            <video id="video" autoPlay={ true } playsInline={ true }/>
            <UserInfoContainer userID={ userID } imageSrc={ imageSrc }/>
        </div>
    )
}

const VideoUserTwoView = ( { userID }: VideViewProps ) => {
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    useEffect(()=>{  getCommentUserProfileImage(); },[]);
    async function getCommentUserProfileImage() {
        console.log("getCommentUserProfileImage method called", userID);
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    return (
        <div className="user-container user-two">
            {/*  */}
            <div className="video-profile-image-container">
                { imageSrc && <img loading={ "lazy" } src={ imageSrc }/>}
                <div className="image-cover"/>
            </div>
            {/*  */}
            <video id="video" autoPlay={ true } playsInline={ true } muted={ true }/>
            {/* <UserInfoContainer userID={ userID } imageSrc={ imageSrc }/> */}
        </div>
    )
}

interface UserInfoContainerProps { userID: string; imageSrc: string | undefined }
const UserInfoContainer = ( { userID, imageSrc }: UserInfoContainerProps ) => {
	const [ ContentUser, setContentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    useEffect(()=>{
        if (userID.trim() !== "") {
            getCommentUserData();
        }
    },[ userID ]);
    async function getCommentUserData(){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ userID }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                setContentUser(_=> { return data; });
            }
        })
        .catch( err => {
            console.log("Error grabbing comment user");
        });
    }

    return (
        <div className="user-info-container">
            <div className="user-profile-image-container">
                { imageSrc && <img loading={ "lazy" } src={ imageSrc }/>}
                <div className="image-cover"/>
            </div>
            { ContentUser &&
                <div className="username-nickname">
                    <label className="first-last">{`${ ContentUser.firstName } ${ ContentUser.lastName }`}</label>
                    { ContentUser.customUserName && <label className="nickname" >{ `@${ ContentUser.customUserName }`}</label>}
                </div>
            }
        </div>
    )
}

const dataExamples = [
    {"urls":"stun:stun.relay.metered.ca:80"},
    {"urls":"turn:a.relay.metered.ca:80","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"},
    {"urls":"turn:a.relay.metered.ca:80?transport=tcp","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"},
    {"urls":"turn:a.relay.metered.ca:443","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"},
    {"urls":"turn:a.relay.metered.ca:443?transport=tcp","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"}
]


{/* 
    <div className="user-container user-two" style={{
        position: "absolute", bottom: "0", right: "0",
        height: "0px", width: "0px", overflow: "hidden"
    }}><video id="video" autoPlay={ true } playsInline={ true } muted={ true }/></div>
*/}