import { 
    signInWithEmailAndPassword, 
    onAuthStateChanged, 
    createUserWithEmailAndPassword, 
    getAuth, 
    signInWithPopup, 
    GoogleAuthProvider,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    ConfirmationResult,
    signInWithCredential,
    signInWithCustomToken,
    AuthCredential,
} from "firebase/auth";
import React, { useState, useEffect, useContext } from "react";
import ColorUI from "../../Components/ColorUI/ColorUI";
import { AddNewUser, AuthContentExamplePosts, ContentModel, Posts_Coll_And_Doc_Path, PostType } from "../../fb/dbMethods";
import { auth, db } from "../../fb/fbSetup";
import { ApplicationName, ApplicationDescription, ArrayOfComm, ArrayOfEmojis, PagePaths } from "../../Models/Models";
import "./AuthViewMobile.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import GoogleLogo from "../../../Resources/googleLogo.jpeg";
import "../Product/ProductView.css";
import { ContentCard, ResponseTypesList } from "../Home/ContentView/ContentView";
import EyeIcon from "../../../Resources/eye.png";
import ImageItemsView from "../../Components/ImageItemsView/ImageItemsView";
import MoviePlayer from "../../Components/ContentComponents/MoviePlayer";
import uuidGenerator, { AddingEnters } from "../../../SharedItems/UUIDGenerator";
import AppIcon from "../../../Resources/AppIcon.png"
import BWPEarth from "../../../Resources/BWPEarth.png"
import ex_1 from "../../../Resources/ExamplePostItems/1.jpg";
import ex_2 from "../../../Resources/ExamplePostItems/2.jpg";
import ex_3 from "../../../Resources/ExamplePostItems/3.jpg";
import ex_4 from "../../../Resources/ExamplePostItems/4.jpg";
import gridImg from "../../../Resources/grid.jpg"
// https://www.npmjs.com/package/react-phone-number-input
import Lottie from "lottie-react";
import animationOne from "../../../Resources/Lottie/1.json";
import animationTwo from "../../../Resources/Lottie/1.json";
import { collection, doc, getDoc, getDocs, limit, orderBy, query } from "firebase/firestore";
import AppStore from "../../../Resources/AppLocation/app-store.png";
import GooglePlay from "../../../Resources/AppLocation/google-play.png";

const AppName = `${ ApplicationName }`
const AuthView_Mobile = () => {
    let bubbleItemList = [
        ...ArrayOfComm,
        ...ArrayOfEmojis, 
        ...ArrayOfComm, 
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        ...ArrayOfComm,
        ...ArrayOfEmojis,
        
    ].sort(_=>{ return Math.random() - 0.5; });
    
    const [ scrolled , setScrolled ] = useState(false);
    useEffect(function(){
        onAuthStateChanged(auth, (user) => {
            if (user) {
            //   window.location.href = PagePaths.newUser;
            }
        });
    }, []);

    useEffect(()=> {
        // signInWithCustomToken(auth, "")
        // signInWithCustomToken(auth, "eyJhbGciOiJSUzI1NiIsImtpZCI6IjUzZWFiMDBhNzc5MTk3Yzc0MWQ2NjJmY2EzODE1OGJkN2JlNGEyY2MiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoicmFidXMgbWNjYWxlYiIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NLcEp6ejBUUWZOWmdiT1NiRzJNNWhEbVRwcXU2VWhRcElDMFNUVXIwTV9JdHc9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbmV3dG9waWEtYTQzMjciLCJhdWQiOiJuZXd0b3BpYS1hNDMyNyIsImF1dGhfdGltZSI6MTcwNzM4MDk3NiwidXNlcl9pZCI6IkNibzlQdkExUFJOblNqQkxaMnpTTDdzMTl0ejEiLCJzdWIiOiJDYm85UHZBMVBSTm5TakJMWjJ6U0w3czE5dHoxIiwiaWF0IjoxNzA3MzgwOTc2LCJleHAiOjE3MDczODQ1NzYsImVtYWlsIjoibWNjYWxlYnJhYnVzQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTAyNDY3NjY4NjY5NzM5NzcyOTgxIl0sImVtYWlsIjpbIm1jY2FsZWJyYWJ1c0BnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.Upnb7ug2ONrAbVkKtGosHIlZchbvMnbATO0QAp30bGHQL-XW0KkIV6MsiP7-sJEn4UKT79Y8jiIY6SLHo0o5zcglGhTEP_JMecWUoHPE2gMZQnXIeIk-8v3fJ67ynkjPqRwjePqt_dSfKGVqR7CeXfVvm_yShpSx74m0qFJiNBx6PhK_VP5oAHHBtp2ULn-jYXjpklxnjEZ7E3DmU0P1JEOToNcqpJ7KPGfXNzTxdmLye0W3seHwzbbaEedSiqotUxSW0dSkC1Zt58wN69GJs3LVFhEMK9UgqOWaHWxuRpS2WC-JeIoThmdUttUOYCKY4PVQhbJhwqARHwkdfaRt7g").then(s => { console.log(s); }).catch(e => { console.log(e); });
        // signInWithCredential(auth, cred).then(s => { console.log(s); }).catch(e => { console.log(e); });
    },[]);

    // View Animation Controller:
    return (
        <>
        <div className="auth-view">
            <div className={`auth-view-scrollview hideScrollIndicator`}>
                <div className="auth-view-content-view">
                    <div className="auth-and-content-ex-container">
                        <AuthCard/>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default AuthView_Mobile;

interface LandingViewProps { setShowJumpInSignUp: React.Dispatch<React.SetStateAction<boolean>>; }

const AuthView_LandingView = ({ setShowJumpInSignUp }: LandingViewProps) => {
    let bubbleItemList = [
            ...ArrayOfComm,
            ...ArrayOfEmojis, 
            ...ArrayOfComm, 
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            ...ArrayOfComm,
            ...ArrayOfEmojis,
            
        ].sort(_=>{ return Math.random() - 0.5; });

    async function scrollToBottom(
        time: number // Number in milliseconds
    ) {
        const MainView = document.querySelector(".auth-view-scrollview");
        const PageElement = document.querySelector(".auth-view");
        if (MainView && PageElement) {
            const start = performance.now()
            while (performance.now() < start + time) {
                const progress = (performance.now() - start) / time
                MainView.scrollTo(0, (MainView.scrollHeight - window.innerHeight - 150) * progress)
                await new Promise(requestAnimationFrame)
            }
            MainView.scrollTo(0, MainView.scrollHeight - window.innerHeight - 150)
        }
    }


    return (
        <div className="auth-view-landing-container">
            <div className="landing-background">
                <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: "1000", opacity: "0.75"}}><ColorUI colonyCount={ 200 }/></div>
                <div className="landing-bckg-ui-items">
                    {
                        bubbleItemList.map((item, index) => {
                            return (
                                <>
                                    <div className="background-items" key={ index }>
                                        <label className="bckg-label">{ item }</label>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
            <div className="landing-app-title-description">
                <div className="landing-icon">
                    <img src={ AppIcon }/>
                    <div className="image-cover"/>
                </div>
                <h1 className="app-name-landing">{ AppName }</h1>
                <h2 className="app-type-landing">{ "The Social App" }</h2>
                <h5 className="app-description-landing">{ ApplicationDescription }</h5>
                <button className="app-call-to-action-landing" onClick={() => { scrollToBottom(400); }}>Jump In</button>
            </div>
        </div>
    );
}


const AuthCard = () => {
    async function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            const t = result.user.getIdToken().then(res => { 
                // res
                console.log("[__$%^_-_-_user_sucess_fully_signed_in] ", res);
            })
            .catch(e => {
                console.log("Auth Token From Phone Sign In: ", e);
            });
            // Passing In Empty Values Once A New User Attempts to Sign In... They can 
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }
    // Spend sometime jumping back into the zone...
    return (
        <div className="auth-card">
            <div className="auth-card-container">
                <h1 style={{marginBottom: "8px"}} dangerouslySetInnerHTML={{__html: `
                    <span>
                        Sign Up / In
                    </span>`}}/>
                    <p style={{
                        margin: "20px 0 20px 0",
                        maxWidth: "calc(100% - 100px)",
                        fontSize: "20px",
                        color: "rgba(0,0,0,0.6)",
                        textAlign: "center",
                        fontWeight: "600",
                    }}>Built for people to celebrate everything bright & nerdy.</p>
                
                <PhoneNumberSignIn/>
                <label style={{ marginTop: "10px" }}>- or- </label>
                <button className="google-auth-button" onClick={()=> { signInWithGoogle(); }}>
                    <img src={ GoogleLogo }/>
                    <label>Google</label>
                </button>
            </div>
        </div>
    );
}


const PhoneNumberSignIn = () => {
    const [ phoneNumber, setPhoneNumber ] = useState<string>("");
    const [ confirmationNumber, setConfirmationNumber ] = useState<string>("");
    const [ confirmationResult, setConfirmationResult ] = useState<ConfirmationResult | undefined>(undefined);

    useEffect(()=> { 
        // @ts-ignore
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
        // appVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {'callback': (response) => { console.log("recaptchaVerifier response: ", response); } });
    }, []);

    async function signInViaPhoneNumber() {
        console.log("phoneNumber: ", phoneNumber);
        // @ts-ignore
        signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier )
            .then((confirmationRes) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            // @ts-ignore
            window.confirmationResult = confirmationRes;
            setConfirmationResult(_=> { return confirmationRes; });
            // ...
            }).catch((error) => {
                console.log("Error: ", error);
            // Error; SMS not sent
            // ...
        });
    }
    
    function ConfirmCode() {
        // @ts-ignore
        if(confirmationResult) {
            confirmationResult.confirm(confirmationNumber).then((result) => {
                // User signed in successfully.
                const user = result.user;
                const t = result.user.getIdToken().then(res => { 
                    // res
                    console.log("Auth Token From Phone Sign In: ", res);
                })
                .catch(e => {
                    console.log("Auth Token From Phone Sign In: ", e);
                });
                
                // ...
                // Now That We Have A New User We Have To Take Them To The New User Page
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
            });
        }
    }

    return (
        <div className="phone-sign-in-up" style={{ display:"flex", flexDirection: "column"}}>
            { (!confirmationResult) &&
                <span className="phone-number-code-container">
                    <label className="phone-number-label" htmlFor="phone-number">Sign In / Up via Phone Number</label>
                    {/*  +1 7062482304 */}
                    {/* <input placeholder="Phone Number..." type={ "text" } value={phoneNumber} onChange={ e => { setPhoneNumber( _=> { return e.target.value }) } }/> */}
                    <PhoneInput placeholder="Phone #" value={phoneNumber} onChange={setPhoneNumber}/>
                    <button className="phone-sign-in-up-submit-button"  onClick={()=> { signInViaPhoneNumber(); }}>Next</button>
                </span>
            }
            { confirmationResult &&
                <span className="confirmation-code-container">
                    <label htmlFor="confirmation-code">Enter The Confirmation Number</label>
                    <input id="confirmation-code" placeholder="000000" type={ "text" } value={ confirmationNumber } onChange={ e => { setConfirmationNumber( _=> { return e.target.value }) } }/>
                    <button className="phone-sign-in-up-submit-button" onClick={()=> { ConfirmCode(); }}>Done</button>
                </span>
            }
            <div id="recaptcha-container" className="justify-center flex" style={{ marginTop: "20px",}}/>
        </div>
    );
}

