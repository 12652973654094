import { db, storage, auth } from "../fb/fbSetup";
import { doc, collection ,updateDoc, setDoc, getDoc, query, limit, orderBy, getDocs, arrayUnion, runTransaction } from "firebase/firestore";
import { userprofile_storage_path } from "../fb/dbMethods";
import uuidGenerator from "../../SharedItems/UUIDGenerator";

export interface ViewlaterDocModel {
    date_doc_created: Date;
    posts: ViewlaterModel[];
}

export interface ViewlaterModel {
    dateAdded : Date;
    postID: string;
    post?: string; // For A User Later To Store Something As Custom Viewlater Category...
    stored_document: string;
}

export const ViewLaterCollectionRef = "_V_I_E_W__L_A_T_E_R";
export const MaxNumberOfViewLatersInADoc = 50000; // Currently set to 50,000
/**
 * AddItemToYourViewlater: * This method adds a new item to a users Viewlater
 * @param userID : string
 * @param postID : string
 * @param callBack : (sucess: boolean) => void
 */
export async function AddItemToYourViewlater(userID: string, postID: string, callBack: (sucess: boolean) => void) {
    console.log("AddItemToYourViewlater method being called =>>>>>>>>");
    const userViewlaterPath = `${ userprofile_storage_path }/${ userID }/${ ViewLaterCollectionRef }`;
    const userViewlaterRef = collection(db, userViewlaterPath);
    const mostRecentViewlaterDoc = query(userViewlaterRef, orderBy("date_doc_created","desc"), limit(1));
    const getMostRecentViewlaterDoc = await getDocs(mostRecentViewlaterDoc);
    if (getMostRecentViewlaterDoc.size > 0) {
        getMostRecentViewlaterDoc.forEach( async document => {
            const ViewlaterDoc = document.data() as ViewlaterDocModel;
            if (ViewlaterDoc.posts.length < MaxNumberOfViewLatersInADoc) {
                // This is for a document that both exist and hasn't reached it's maximum array size for Viewlater.                
                const firstViewlaterDocRef = `${ userViewlaterPath }/${ document.id }`
                const firstTimeViewlaterRef = await updateDoc(doc(db, firstViewlaterDocRef), {
                    posts: arrayUnion({ dateAdded: new Date(), postID: postID, favorite_stored_document: document.id })
                })
                .then(status => {
                    console.log("New Viewlater Doc Added");
                    callBack(true);
                })
                .catch(err => {
                    console.log("Issue Uploading Doc")
                    callBack(false);
                })


            } else {
                // Adding A New Document Because Viewlater Has Reached It's Max Size
                const docID = uuidGenerator();
                const firstViewlaterDocRef = `${ userViewlaterPath }/${ uuidGenerator() }`
                const ViewlaterDocObject: ViewlaterDocModel = {
                    date_doc_created: new Date(),
                    posts: [ 
                        { dateAdded: new Date(), postID: postID, stored_document: docID } 
                    ]
                }
                const firstTimeViewlaterRef = setDoc(doc(db, firstViewlaterDocRef), ViewlaterDocObject)
                .then(status => {
                    console.log("New Viewlater Doc Added");
                    callBack(true);
                })
                .catch(err => {
                    console.log("Issue Uploading Doc")
                    callBack(false);
                });
            }// End of if else for item reaching its max count
        }) // End of forEach loop of all the 1 document tha that should be being called for getting the most recent Viewlater doc
    } else {
        // This is for the Viewlater list that is created if the user doesn't / hasn't stored any Viewlater on their user model yet.
        const docID = uuidGenerator();
        const firstViewlaterDocRef = `${ userViewlaterPath }/${ docID }`
        const ViewlaterDocObject: ViewlaterDocModel = {
            date_doc_created: new Date(),
            posts: [ 
                { dateAdded: new Date(), postID: postID, stored_document: docID } 
            ]
        }
        const firstTimeViewlaterRef = setDoc(doc(db, firstViewlaterDocRef), ViewlaterDocObject)
        .then(status => {
            console.log("New Viewlater Doc Added");
            callBack(true);
        })
        .catch(err => {
            console.log("Issue Uploading Doc")
            callBack(false);
        })
    }// End of else statment for the condition where the user is saving an item to Viewlater for the first time
}

export async function _GetAllOfUsersViewlater(userID: string, callBack: (sucess: boolean, Viewlater: ViewlaterModel[]) => void) {
    const userViewlaterRef = `${ userprofile_storage_path }/${ userID }/${ ViewLaterCollectionRef }`;
    const userViewlaterCollection = collection(db, userViewlaterRef);
    const ViewlaterQuery = query(userViewlaterCollection, orderBy("date_doc_created","desc"));
    const getUserViewlaterDoc = await getDocs(ViewlaterQuery);
    // Need Items for loop
    let favoriteDocCounter: number = 0;
    let ViewlaterList: ViewlaterModel[] = [];
    if (getUserViewlaterDoc.size > 0) {
        getUserViewlaterDoc.forEach(document => {
            const data: ViewlaterDocModel = document.data() as ViewlaterDocModel;
            console.log("ViewlaterDocModel: ", data);
            const dateToJSDateList = data.posts.map(item => {
                let cleanDate : any = item.dateAdded;
                cleanDate = cleanDate.toDate();
                const favoriteDateTime = new Date(cleanDate);
                const newFavorite: ViewlaterModel = { 
                    dateAdded: favoriteDateTime,
                    postID: item.postID,
                    post: item.post,
                    stored_document: item.stored_document
                }
                return newFavorite;
            });

            favoriteDocCounter = favoriteDocCounter + 1;
            if (favoriteDocCounter >= getUserViewlaterDoc.size) {
                let newList : ViewlaterModel[] = [ ...ViewlaterList, ...dateToJSDateList ];
                ViewlaterList = newList;
                //@ts-ignore
                ViewlaterList = ViewlaterList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                callBack( true, ViewlaterList );
            } else {
                let newList : ViewlaterModel[] = [ ...ViewlaterList, ...dateToJSDateList ];
                ViewlaterList = newList;
            }
        });
    }
}

export async function RemoveViewlaterFromViewlaterList(
    userID: string, 
    favoriteItem: ViewlaterModel, 
    callBack : (status: boolean) => void
) {
    const ViewlaterStringRef = `${ userprofile_storage_path }/${ userID }/${ ViewLaterCollectionRef }/${ favoriteItem.stored_document }`;
    const ViewlaterDocRef = doc(db, ViewlaterStringRef);
        try {
            await runTransaction(db, async (transaction) => {
                const ViewlaterDoc = await transaction.get(ViewlaterDocRef);
                if (!ViewlaterDoc.exists()) {
                    throw "Document does not exist!";
                }

                let data = ViewlaterDoc.data() as ViewlaterDocModel;
                let newViewlaterModel = { ...data }; 
                let ViewlaterList = data.posts.filter(item => {
                    if((favoriteItem.postID !== item.postID)) {
                        return item;
                    }
                });
                newViewlaterModel = { ...newViewlaterModel, posts: ViewlaterList };
                transaction.update(ViewlaterDocRef, newViewlaterModel);
            });
            console.log("Transaction successfully committed!");
            callBack(true);
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
}
