import { Adsense } from "@ctrl/react-adsense";
import React, { useState, useEffect, useContext } from "react";
import ColorUI from "../../../Components/ColorUI/ColorUI";
import ControllerContext from "../../../Contexts/ControllerContexts";
import { NOT_AN_AUTHENTICATED_USER } from "../../../Contexts/unsignedUserDataModle";
import UserContext from "../../../Contexts/UserContext";
import { ApplicationName } from "../../../Models/Models";
import './RightSideOptions.css'

export interface RightSideOptionsProps { setCallPostController: React.Dispatch<React.SetStateAction<boolean>> }

const RightSideOptions = ({ setCallPostController }: RightSideOptionsProps ) => {
	return (
        <div className='other-options hideScrollIndicator'>
			<div className="other-options-content-view">
				<PostContentButtonOption setCallPostController={ setCallPostController }/>
				<LiveChatSession/>
				<InArticleAd/>
				<GamesCard/>
				<InstructionBounty/>
			</div>
        </div>
    );
};

export default RightSideOptions

const styleObject: React.CSSProperties = {
	position: "absolute",
	bottom: "34px",
	right: "-28px",
	padding: "2px 28px",
	backgroundColor: "var(--app-yellow-80)",
	background: "linearGradient(-90deg, rgba(0,0,0, 0.0), rgba(0,0,0, 0.15) )",
	transform: "rotate(-45deg)",
	fontSize: "12px",
	zIndex: "10000",
}

const InstructionBounty = () => {
	const cardTitle = "💰 Instruction Bounty";
	const desciption = `Get Paid or Ask a question you've always wanted the answer to from the braud ${ ApplicationName } Community`;
	return (
		<div className='live-chat-session-container'>
			<div className="live-chat-card-container" onClick={() => { }}>
				<div className="background-container"><ColorUI/></div>

				<div className="live-chat-content">
					<div className="card-content">
						<h2 className="live-chat-title">{ cardTitle }</h2>
						<h6 className="live-chat-desciption">{ desciption }</h6>
					</div>
				</div>
				<div style={ styleObject }>⏰ coming soon!</div>
			</div>
		</div>
	);
}

const LiveChatSession = () => {
	const {userData} = useContext(UserContext)
	const { toggleLiveSessionView, toggleJumpInSignUp } = useContext(ControllerContext);
	const cardTitle = "💬 Live Chat Session";
	const desciption = "Jump into a live chat with bright people from around the world.";
	return (
		<div className='live-chat-session-container'>
			<div className="live-chat-card-container" onClick={() => { 
				// @ts-ignore
				if (userData.NOT_AN_AUTHENTICATED_USER) {
					toggleJumpInSignUp(true)
				} else {
					toggleLiveSessionView(true);
				}
			}}>
				<div className="background-container"><ColorUI/></div>
				<div className="live-chat-content">
					<div className="card-content">
						<h2 className="live-chat-title">{ cardTitle }</h2>
						<h6 className="live-chat-desciption">{ desciption }</h6>
					</div>
				</div>
			</div>
		</div>
	);
}

const GamesCard = () => {
	const cardTitle = "🎮 GAMES";
	const desciption = `Jump into a session for "World Builder", Model UN, Chess, Dungeons And Dragons, and more`;
	return (
		<div className='live-chat-session-container'>
			<div className="live-chat-card-container" onClick={() => {}}>
				<div className="background-container"><ColorUI/></div>
				<div className="live-chat-content">
					<div className="card-content">
						<h2 className="live-chat-title">{ cardTitle }</h2>
						<h6 className="live-chat-desciption">{ desciption }</h6>
					</div>
				</div>
				<div style={ styleObject }>⏰ coming soon!</div>
			</div>
		</div>
	);
}

const PostContentButtonOption = ({ setCallPostController }: RightSideOptionsProps ) => {
	const {userData} = useContext(UserContext)
	const {toggleJumpInSignUp} = useContext(ControllerContext)
	const postText = "post";
	return (
		<div className='post-content-option-container' onClick={() => {
			// @ts-ignore
			if (userData.NOT_AN_AUTHENTICATED_USER) {
				toggleJumpInSignUp(true)
			} else {
				setCallPostController(b => { return !b; });
			}
		}}>
			<div className='post-content-option'>
				<label className="post-content-icon">+</label>
				<label>{ postText }</label>
			</div>
		</div>
	);
};

const InArticleAd = () => {
	return (
		<div className='live-chat-session-container isAd'>
			<div className="live-chat-card-container isAd" onClick={() => {}}>
				<Adsense className="adsbygoogle"
				style={{ display:"block", width:"250px", height:"fit-content", zIndex: "1", }}
				layout="in-article"
				format="fluid"
				client="ca-pub-5619224767870187"
				slot="2301009794"
				/>
			</div>
		</div>
	)
}