import React, { useState } from "react";
import { ApplicationName } from "../../Models/Models";
import { AuthFooter, AuthViewHeader } from "../../Pages/Auth/AuthView";
import ColorUI from "../ColorUI/ColorUI";
// import { AuthFooter, AuthViewHeader } from "/Auth/AuthView";

const About = () => {
    const [ scrolled , setScrolled ] = useState(true);
    const [ showJumpInSignUp, setShowJumpInSignUp] = useState<boolean>(false);
    return (
        <>
            <ColorUI/>
            <div className="AboutPage" style={
                { 
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(255,255,255, 0.75)",
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    padding: "0"
                }
            }>
                <AuthViewHeader scrolled={ scrolled } setShowJumpInSignUp={ setShowJumpInSignUp }/>
                <div style={
                        { 
                            position: "relative",
                            width: "100vw",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "100px",
                            minHeight: "100vh",
                        }
                }>
                    <Content/>
                    <div style={{
                    position: "relative",
                    width: "100vw",
                    height: "150px",
                    bottom: "0",
                    zIndex: "1",
                    }}>
                    <AuthFooter/>
                    </div>
                </div>
            </div>
        </>
        );
      }

export default About;

const Content = () => {
    return (
        <div style={
            { 
                position: "relative",
                width: "100%",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                marginBottom: "auto",
                padding: "0 20px"
            }
        }>
            <h2 style={{ fontSize: "40px", maxWidth: "90%"} } >{`About The Mission of` } <span style={{ color: "var(--app-blue)"}}>{`${ ApplicationName }`}</span> 🌎</h2>
            <br/>
            <br/>
            <br/>
            <p style={ { fontSize: "20px", maxWidth: "90%", fontWeight: "700"} } dangerouslySetInnerHTML={ { __html: AppTelos } }/>
            <br/>
            <br/>
            <p style={ { fontSize: "20px", maxWidth: "90%", fontWeight: "700"} } dangerouslySetInnerHTML={ { __html: AppTelos_2 } }/>
            <br/>
            <br/>
            <p style={ { fontSize: "20px", maxWidth: "90%", fontWeight: "700"}  } dangerouslySetInnerHTML={ { __html: AppTelos_3 } }/>
            <br/>
            <br/>
            <p style={ { fontSize: "20px", maxWidth: "90%", fontWeight: "700"}  } dangerouslySetInnerHTML={ { __html: AppTelos_4 } }/>
            <br/>
            <br/>
            <p style={ { fontSize: "20px", maxWidth: "90%", fontWeight: "700"}  } dangerouslySetInnerHTML={ { __html: `- Big World Project ❤️` } }/>
            <br/>
            {/* <a style={{
                color: "var(--app-blue)", 
                fontWeight: "700",
            }} href="https://x.com/thebigworldproj">Check Us Out On X</a> */}
            {/* <h2>Credits</h2>
            <p>...</p> */}
        </div>
    )
}

const AppTelos = `
Big World Project is a social app open to everyone but built for nerds. The application will specialize in content related to engineering, medicine, comics, art, architecture, science, anime, gaming, and more. We plan to be the largest community and content delivery system for everything nerdy.
<br/>
We think this type of content lends itself to a good and exciting world indirectly, because bright and nerdy things tend toward arbitrary care, appreciation for myth, a love of wisdom, and a pursuit of epistemology.
`


const AppTelos_2 = `
Applications have not taken their demographics seriously. Who is included has been an afterthought. In every social context outside the social/entertainment space, this is a very important consideration. A bias toward what is usually considered intelligent has a general bias toward what is good, exciting, important, or, at minimum, ironic and absurd. This sets the table for what is implicitly or explicitly expected from this community. We believe this would lead to unregretted user minutes and content that doesn't center around people but instead centers around things. As an example, "content centered around people" is influencer culture and "content centered around things" is engineering.
`
// dia-logos
const AppTelos_3 = `
We plan to build an application with dia-logos and democracy in mind from the beginning. Your insight, your passion, your skills, your love, and your ideas will matter. We hope to see heavy truths and deep play here. We want to see a love for comics and a love for transformative research papers. This, to us, would be a good world.
`

const AppTelos_4 = `
We have so many things planned with the <span style="color: var(--app-green); font-weight: 700;">${ ApplicationName }</span>, and we are excited for you to join us.
`

// `
// We think building a good world requires a celebration and biased propencities toward things in the world not people. A good
// <br/>
// We think this type of content lends itself to a good and exciting world indirectly, because bright and nerdy things tend toward arbitrary care, appreciation for myth, a love of wisdom, and a pursuit of epistemology.
// `