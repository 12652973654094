import { db, storage, auth } from "../fb/fbSetup";
import { doc, collection ,updateDoc, setDoc, getDoc, query, limit, orderBy, getDocs, arrayUnion, runTransaction } from "firebase/firestore";
import { userprofile_storage_path } from "../fb/dbMethods";
import uuidGenerator from "../../SharedItems/UUIDGenerator";

export interface FavoritesDocModel {
    date_doc_created: Date;
    favorites: FavoritesModel[];
}

export interface FavoritesModel {
    dateAdded : Date;
    postID: string;
    favorites?: string; // For A User Later To Store Something As Custom Favorites Category...
    favorite_stored_document: string;
}

export const FavoritesCollectionRef = "_F_A_V_O_R_I_T_E_S";
export const MaxNumberOfFavoritesInADoc = 50000; // Currently set to 50,000
/**
 * AddItemToYourFavorites: * This method adds a new item to a users favorites
 * @param userID : string
 * @param postID : string
 * @param callBack : (sucess: boolean) => void
 */
export async function AddItemToYourFavorites(userID: string, postID: string, callBack: (sucess: boolean) => void) {
    console.log("AddItemToYourFavorites method being called =>>>>>>>>");
    const userFavoritesPath = `${ userprofile_storage_path }/${ userID }/${ FavoritesCollectionRef }`;
    const userFavoritesRef = collection(db, userFavoritesPath);
    const mostRecentFavoritesDoc = query(userFavoritesRef, orderBy("date_doc_created","desc"), limit(1));
    const getMostRecentFavoritesDoc = await getDocs(mostRecentFavoritesDoc);
    if (getMostRecentFavoritesDoc.size > 0) {
        getMostRecentFavoritesDoc.forEach( async document => {
            const favoritesDoc = document.data() as FavoritesDocModel;
            if (favoritesDoc.favorites.length < MaxNumberOfFavoritesInADoc) {
                // This is for a document that both exist and hasn't reached it's maximum array size for favorites.                
                const firstFavoritesDocRef = `${ userFavoritesPath }/${ document.id }`
                const firstTimeFavoritesRef = await updateDoc(doc(db, firstFavoritesDocRef), {
                    favorites: arrayUnion({ dateAdded: new Date(), postID: postID, favorite_stored_document: document.id })
                })
                .then(status => {
                    console.log("New Favorites Doc Added");
                    callBack(true);
                })
                .catch(err => {
                    console.log("Issue Uploading Doc")
                    callBack(false);
                })


            } else {
                // Adding A New Document Because Favorites Has Reached It's Max Size
                const docID = uuidGenerator();
                const firstFavoritesDocRef = `${ userFavoritesPath }/${ uuidGenerator() }`
                const favoritesDocObject: FavoritesDocModel = {
                    date_doc_created: new Date(),
                    favorites: [ 
                        { dateAdded: new Date(), postID: postID, favorite_stored_document: docID } 
                    ]
                }
                const firstTimeFavoritesRef = setDoc(doc(db, firstFavoritesDocRef), favoritesDocObject)
                .then(status => {
                    console.log("New Favorites Doc Added");
                    callBack(true);
                })
                .catch(err => {
                    console.log("Issue Uploading Doc")
                    callBack(false);
                });
            }// End of if else for item reaching its max count
        }) // End of forEach loop of all the 1 document tha that should be being called for getting the most recent favorites doc
    } else {
        // This is for the favorites list that is created if the user doesn't / hasn't stored any favorites on their user model yet.
        const docID = uuidGenerator();
        const firstFavoritesDocRef = `${ userFavoritesPath }/${ docID }`
        const favoritesDocObject: FavoritesDocModel = {
            date_doc_created: new Date(),
            favorites: [ 
                { dateAdded: new Date(), postID: postID, favorite_stored_document: docID } 
            ]
        }
        const firstTimeFavoritesRef = setDoc(doc(db, firstFavoritesDocRef), favoritesDocObject)
        .then(status => {
            console.log("New Favorites Doc Added");
            callBack(true);
        })
        .catch(err => {
            console.log("Issue Uploading Doc")
            callBack(false);
        })
    }// End of else statment for the condition where the user is saving an item to favorites for the first time
}

export async function _GetAllOfUsersFavorites(userID: string, callBack: (sucess: boolean, favorites: FavoritesModel[]) => void) {
    const userFavoritesRef = `${ userprofile_storage_path }/${ userID }/${ FavoritesCollectionRef }`;
    const userFavoritesCollection = collection(db, userFavoritesRef);
    const FavoritesQuery = query(userFavoritesCollection, orderBy("date_doc_created","desc"));
    const getUserFavoritesDoc = await getDocs(FavoritesQuery);
    // Need Items for loop
    let favoriteDocCounter: number = 0;
    let favoritesList: FavoritesModel[] = [];
    if (getUserFavoritesDoc.size > 0) {
        getUserFavoritesDoc.forEach(document => {
            const data: FavoritesDocModel = document.data() as FavoritesDocModel;
            console.log("FavoritesDocModel: ", data);
            const dateToJSDateList = data.favorites.map(item => {
                let cleanDate : any = item.dateAdded;
                cleanDate = cleanDate.toDate();
                const favoriteDateTime = new Date(cleanDate);
                const newFavorite: FavoritesModel = { 
                    dateAdded: favoriteDateTime,
                    postID: item.postID,
                    favorites: item.favorites,
                    favorite_stored_document: item.favorite_stored_document
                }
                return newFavorite;
            });

            favoriteDocCounter = favoriteDocCounter + 1;
            if (favoriteDocCounter >= getUserFavoritesDoc.size) {
                let newList : FavoritesModel[] = [ ...favoritesList, ...dateToJSDateList ];
                favoritesList = newList;
                //@ts-ignore
                favoritesList = favoritesList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                callBack( true, favoritesList );
            } else {
                let newList : FavoritesModel[] = [ ...favoritesList, ...dateToJSDateList ];
                favoritesList = newList;
            }
        });
    }
}

export async function RemoveFavoritesFromFavoritesList(
    userID: string, 
    favoriteItem: FavoritesModel, 
    callBack : (status: boolean) => void
) {
    const favoritesStringRef = `${ userprofile_storage_path }/${ userID }/${ FavoritesCollectionRef }/${ favoriteItem.favorite_stored_document }`;
    const FavoritesDocRef = doc(db, favoritesStringRef);
        try {
            await runTransaction(db, async (transaction) => {
                const favoritesDoc = await transaction.get(FavoritesDocRef);
                if (!favoritesDoc.exists()) {
                    throw "Document does not exist!";
                }

                let data = favoritesDoc.data() as FavoritesDocModel;
                let newFavoritesModel = { ...data }; 
                let favoritesList = data.favorites.filter(item => {
                    if((favoriteItem.postID !== item.postID)) {
                        return item;
                    }
                });
                newFavoritesModel = { ...newFavoritesModel, favorites: favoritesList };
                transaction.update(FavoritesDocRef, newFavoritesModel);
            });
            console.log("Transaction successfully committed!");
            callBack(true);
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
}
