import React, { useState, useEffect, useContext } from "react";
import { AddingEnters } from "../../../SharedItems/UUIDGenerator";

export interface ShortPlayerProps {
	id: string;
	videoSrc?: string;
	autoPlay? : boolean;
	muted? : boolean;
	refresh? : boolean;
	showBufferIndicator? : boolean
	userPlaybackSpeedDefault?: number;
	//
	title: string;
	textPost: string;
}

const ShortFormVideoPlayer = (
	{ 
		id,
		videoSrc,
		autoPlay,
		muted,
		userPlaybackSpeedDefault,
		// refresh,
		// showBufferIndicator,
		title,
		textPost,
	}: ShortPlayerProps 
) => {

	const speakButtonText = "speak"; //"Yell It At Me";
	function SpeakContent(postContent : string) {
		let msg = new SpeechSynthesisUtterance();
		//
		let voices = window.speechSynthesis.getVoices();
		let goodVoiceOptions = [/* 
		67 good, 76 okay, 81 better, 85 robot singing lol, 88 okay, 90 decent
		91 pretty dang good, 98 okay, 100 good, 101 good, 109 classic computer good, 112 okay,
		144 best */];
		msg.voice = voices[ 67 ];
		msg.voice = voices[ 144 ];
		msg.volume = 100.0; // From 0 to 1
		msg.rate = 0.85; // From 0.1 to 10
		msg.pitch = 0.9; // From 0 to 2
		msg.lang = 'en-US';
		msg.text = postContent;
		// Restarting the Speech
		window.speechSynthesis.cancel();
		speechSynthesis.getVoices().forEach(function(voice) {
		let preventVal = false
		if (voice.name === "Google US English " && preventVal) { msg.voice = voice; }
		console.log(voice.name, voice.default ? voice.default :'');
		});
		msg.voice = voices[ 67 ];
		window.speechSynthesis.speak(msg);
	}

return (
<div className='short-form-video-player-container'>



	<div className='short-form-video-player-hstack'>
		<div className='title-comments-responses-text-content'></div>
		<div className='video-player'>
			<div className='long-form-video v-stack-item'>
				<div className='postedby-speech-and-more-button'>
					{/* Post by container */}
					<div className='content-post-by-container'>
						<div className='userProfile-img-content-container img-container'>
							<img loading={"lazy"} alt="" />
							<div className='cover'></div>
						</div>
						<label className='userProfile-name'>Username</label>
					</div>
					{/* speak */}
					<div className='speak-content-button' onClick={_=>{ SpeakContent( textPost ); }}>
						<label className='speak-content-label'>{ speakButtonText }</label>
					</div>
					{/* Favorites */}
					<div className='favorites-button-container'>
						<div className='favorites-button'></div>
					</div>
				</div>
				{/* Title */}
				<h3 className='content-title'>{ title }</h3>
				{/* Text Content */}
				<div dangerouslySetInnerHTML={{ __html: `<p class="content-post">${ AddingEnters( textPost ) }</p>` } }></div>
				{/* Emoji Responses */}
				<div className='like-and-emoji-responses-container'>
					<div className='like-and-emoji-responses-hstack'>
						{/* thumbs up */}
						<div className='emoji-response-options' title="thumbs up"><label>👍</label></div>
						{/* thumbs down */}
						<div className='emoji-response-options' title="thumbs down" ><label>👎</label></div>
						{/* Funny */}
						<div className='emoji-response-options' title="Funny" ><label>😂</label></div>
						{/* Smart */}
						<div className='emoji-response-options' title="Smart" ><label>🎓</label></div>
						{/* Sad */}
						<div className='emoji-response-options' title="Sad" ><label>😢</label></div>
						{/* Mind Blown */}
						<div className='emoji-response-options' title="Mind Blown" ><label>🤯</label></div>
						{/* I'm proud of you */}
						<div className='emoji-response-options' title="I'm proud of you" ><label>🥹</label></div>
						{/* Yert */}
						<div className='emoji-response-options' title="Yert" ><label>🤙</label></div>
						{/* fingers crossed */}
						<div className='emoji-response-options' title="fingers crossed" ><label>🤞</label></div>
					</div>
				</div>
			</div>
		</div>
	</div>




</div>
);
};

export default ShortFormVideoPlayer;