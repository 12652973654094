import React, { useState, useEffect, useContext } from "react";
import ControllerContext from "../../../Contexts/ControllerContexts";
import UserContext from "../../../Contexts/UserContext";
import { ApplicationName } from "../../../Models/Models";
import ColorUI from "../../ColorUI/ColorUI";
import "./LiveSessionEmbeded.css";

const LiveSessionEmbeded = () => {
    const { toggleLiveSessionView, toggleJumpInSignUp } = useContext(ControllerContext);
    const {userData} = useContext(UserContext)

    return (
        <div className="liveSessionEmbededView">
            <div className="background-view">
                {/* <ColorUI/> */}
            </div>
            <div className="liversess-card-content-view">
                <h1 className="live-sess-card-title">Live Chat Session</h1>
                <h3 className="live-sess-explainaion">{`Jump into an incredible conversation with someone from the ${ ApplicationName } community.`} </h3>
                <h5 className="live-sess-secondary-explainaion">{`Don't know what you would even say? No worries we have tons of ice breakers to get the conversation going, lean on, and keep it on track.`}</h5>
                <button className="live-sess-call-to-action" onClick={() => { 
                    // @ts-ignore
                    if (userData.NOT_AN_AUTHENTICATED_USER) {
                        toggleJumpInSignUp(true)
                    } else {
                        toggleLiveSessionView(true);
                    }
                }}>Jump In</button>
            </div>
        </div>
    );
}

export default LiveSessionEmbeded;