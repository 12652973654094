import React, { useContext, useState, useEffect } from 'react';
import ControllerContext from '../../Contexts/ControllerContexts';
import "./SettingsView.css"

const SettingsView = () => {
    const { showSettiings, toggleSettings } = useContext(ControllerContext);
    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView , setAnimateView ] = useState<boolean>(false);
    useEffect(() => {
        if(showSettiings) {
            setRenderView(showSettiings);
            setTimeout(() => {
                setAnimateView(showSettiings);
            }, 150);
        } else {
            setAnimateView(showSettiings);
            setTimeout(() => {
                setRenderView(showSettiings);
            }, 400);
        }
    }, [ showSettiings ]);
    return (
        <>
            { renderView &&
                <div className="SettingsView" style={{ opacity: animateView ? "100%" :  "0%" }}>
                    <button 
                        className="close-button"
                        onClick={() => { toggleSettings(false); }}
                    >x</button>
                    <div className="settings-view-container" style={{ transform: animateView ? "translateX(0)" : "translateX(150vh)",}}>
                            <a className="setting-option" href="/about">About</a>
                            <a className="setting-option" href="/eula-aggreement">EULA</a>
                            <a className="setting-option" href="/privacy-policy">Privacy Policy</a>
                            <a className="setting-option" href="/terms-of-service-page">Terms Of Service</a>
                            <a className="setting-option" href="/distributed">Distributed Cognition</a>
                            <a className="setting-option" href="/poem">Our Favorite Poem</a>
                            <a className="setting-option" href="/careers">Careers</a>
                    </div>
                </div>
            }
        </>
    );
};

export default SettingsView;
