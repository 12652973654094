import { useContext } from "react";
import UserContext from "../../Contexts/UserContext";
import scroll from "../../../Resources/scroll.png";
import ControllerContext from "../../Contexts/ControllerContexts";
import "./ProductHeader.css"
import bell from "../../../Resources/bell.png";
import logo from "../../../Resources/AppIcon.png";
import unauth_user from "../../../Resources/unauth_user.png";
import AppIcon3d from "../../../Resources/AppIcon3d.png"
export interface ProductHeader_Type {
	appName: string;
	username?: string;
	userID?: string;
}

const ProductHeader = ({ appName }: ProductHeader_Type) => {
	const { showConstitution, setShowConstitutionView, showNotificationView, toggleNotificationView, toggleSearchView, toggleMobileSettingsView } = useContext(ControllerContext);
	const { userProfileImageURL, userData } = useContext(UserContext)
	const { toggleJumpInSignUp } = useContext(ControllerContext)
	const urlParams = new URLSearchParams(window.location.search);
	const tkn = urlParams.get('t');  
	const ud = urlParams.get('ud');
	const devMode = true;
	return (
		<div className={`header${ ((tkn && ud) || devMode) ? " mobile-app" : " not-mobile-app" }`}>
			{/* title and logo */}
			<div className='titolo-and-logo' onClick={ () => { window.location.reload(); } }>
				<div className='logo-container img-container'>
					<img src={ AppIcon3d } />
					<div className='cover'></div>
				</div>
				<label>{ appName }</label>
			</div>
			<h6 style={{ marginLeft: "8px" }}>A great place on the internet for nerds.</h6>
			{/* Constution */}
			{ ((tkn && ud) || window.innerWidth > 1200) &&
				<div className="app-constitution-button" onClick={() => { setShowConstitutionView(!showConstitution); }}>
				<img src={ scroll }/>
				<div className="image-cover"/>
				</div>
			}
			{/* Search Bar */}
			<div className='search' onClick={_=> {
				// @ts-ignore
				if (userData.NOT_AN_AUTHENTICATED_USER ) {
					toggleJumpInSignUp(true);
				} else {
					toggleSearchView(true);
				}
			}}>
				{/* Will be it's own component to reduce re-rendering */}
				<div className='product-search-container'>
					<input 
						id="search-input" 
						type={"search"} 
						autoComplete="off" 
						autoCorrect="off" 
						autoCapitalize="off"
						spellCheck="false"
						placeholder="Search..."
						style={{
							pointerEvents: "none"
						}}
					/>
					<button id='search-button'>
						<span className="material-symbols-outlined">search</span>
					</button> 
				</div>
			</div>
			{/* Notifications */}
			<div className='profile-img-username-notifications'
			style={{
				//@ts-ignore 
				backgroundColor: userData.NOT_AN_AUTHENTICATED_USER ?  "var(--app-blue-10)": "transparent"
			}}
			onClick={() => {
				// @ts-ignore
				if (userData.NOT_AN_AUTHENTICATED_USER) {
					toggleJumpInSignUp(true);
				} 
				else if ((tkn && ud) || window.innerWidth > 1200) {
					toggleMobileSettingsView(true)
				}
				else {
					toggleNotificationView(true);
				}
			}}>
				<div className='userProfile-img-container img-container'>
					<img loading={"lazy"} className={``} alt="" src={ userProfileImageURL ? userProfileImageURL : unauth_user } />
					<div className='cover'></div>
				</div>

				<label className='userproile-name' style={{ 
					//@ts-ignore 
					fontWeight: userData.NOT_AN_AUTHENTICATED_USER ? "700" : "600", 
					//@ts-ignore 
					color: userData.NOT_AN_AUTHENTICATED_USER ?  "var(--app-blue)": "black"
				}}>{ `${ userData.firstName } ${ userData.lastName }` }</label>
				<div className='notifications'>
					<img src={ bell }/>
					<div className="image-cover"/>
				</div>
			</div>
		</div>
	)
}

export default ProductHeader;
