import React, { useState, useEffect, useContext } from "react";
import "./Notification.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ControllerContext from "../../Contexts/ControllerContexts";
import { NotusModel, _GetAllOfUsersNotifications } from "../../Controller/notify_user_db_controller";
import UserContext from "../../Contexts/UserContext";
import { CurrentUserDataModel } from "../../Models/Models";
import { doc, getDoc } from "firebase/firestore";
import { db, storage } from "../../fb/fbSetup";
import { userprofile_storage_path } from "../../fb/dbMethods";
import { getDownloadURL, ref } from "firebase/storage";
dayjs.extend( relativeTime );

const NotificationView = () => {
    const { userData } = useContext(UserContext);
    const { showNotificationView , toggleNotificationView } = useContext(ControllerContext);
    const [ notifications, setNotifications ] = useState<NotusModel[]>([]);
    const [ showNoNotificationsError, setShowNoNotificationsError ] = useState<boolean>(false);
    // Animations
    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView, setAnimateView ] = useState<boolean>(true);
    useEffect(() => {
        if(showNotificationView) {
            setRenderView(showNotificationView);
            setTimeout(() => {
                setAnimateView(showNotificationView);
            }, 150);
        } else {
            setAnimateView(showNotificationView);
            setTimeout(() => {
                setRenderView(showNotificationView);
            }, 400);
        }
    }, [ showNotificationView ]);

    useEffect(() => {
        if (showNotificationView) {
            _GetAllOfUsersNotifications(
                userData.userID, 10,
                (sucess, notifications) => {
                    if (sucess) {
                        setNotifications(notifications);
                    } else {
                        setShowNoNotificationsError(_=> { return true; });
                    }
                }
            )
        }
    }, [ showNotificationView ])
    return (
        <>
            { (renderView) &&
                <div className="NotificationView" style={{ 
                    opacity: animateView ? "100%" : "0%", 
                    pointerEvents: animateView ? "all" : "none",
                }}>
                    <div className="collapse-view" onClick={() => { toggleNotificationView(false); }}/>
                    <div className="notus-content-view" style={{ transform: animateView ? "translateX(0%)" : "translateX(110%)" }}>
                        <div className="notification-content-container">
                            <div className="notification-v-stack">
                                <h1 className="pop-out-title">Notifications</h1>
                                <div className="notifications-items-container">
                                    { showNoNotificationsError ?
                                        <h3>😕 It looks like you have zero notifications</h3>
                                        :
                                        <>
                                            { (notifications && notifications.length > 0) ?
                                                notifications.map((item, index) => {
                                                    return <NotificationItem item={ item } index={ index }/>
                                                })
                                                :
                                                <h3>😕 It looks like you have zero notifications</h3>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default NotificationView;

interface NotificationItemProps {
    item: NotusModel;
    index: number;
}
const NotificationItem = ({ item, index }: NotificationItemProps) => {
    useEffect(()=>{ 
        getPostUserData();
        getCommentUserProfileImage();
    }, [])
    const [ ContentUser, setContentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    async function getPostUserData() {
        const docRef = doc(db, `${userprofile_storage_path}/${item.from_userid}`);
        await getDoc(docRef)
        .then(document => {
            const notificationUserData = document.data() as CurrentUserDataModel;
            if (notificationUserData) {
                setContentUser(_=> { return notificationUserData; });
            }
        }).catch(error=> {})
    }

    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${item.from_userid}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    return (
        <div className="NotificationItem">
            <div className="date-from-container">
                <div className="notiification-from-container">
                    { imageSrc &&
                        <div className="profile-image-container">
                            <img src={ imageSrc }/>
                            <div className="image-cover"/>
                        </div>
                    }
                    { ContentUser &&
                        <div className="username-nickname-conatiner">
                            <h5 className="first-name">{`${ ContentUser.firstName } ${ ContentUser.lastName }`}</h5>
                            <h5 className="nickname">{`@${ ContentUser.customUserName }`}</h5>
                        </div>
                    }
                </div>
                <label className="date-time">{ `${ dayjs(item.dateAdded).fromNow() }` }</label>
            </div>
            <div>
                <p className="notification-content">{ item.notification_message }</p>
            </div>
        </div>
    )
}