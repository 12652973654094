import React, { useState, useEffect, useContext } from "react";
import MuxPlayer from '@mux/mux-player-react';
import TempAdUnderneathVideo from "../Ads/Temp_Ad_underneath_video";

export interface MoviePlayerProps {
	id: string;
	playbackId: string;
}

const MoviePlayer = ( { id, playbackId }: MoviePlayerProps ) => {
	useEffect(() => { 	console.log("playbackId: ", playbackId); }, []);
	return (
		//  Adding cusomt uniquer identifier to the element to be better able to get the video id
		<div className='movie-player' id={ id }>
			{/* MuxPlayer */}
			{
				playbackId && 
				<MuxPlayer
					streamType="on-demand"
					playbackId={ playbackId } //"00OBS1fZ59Ps02M00UDIOLsN1HBIygw2i7vq84Jb6pgGiE"}
					autoPlay={true}
					metadataVideoTitle="Placeholder (optional)"
					metadataViewerUserId="Placeholder (optional)" 
					primaryColor="#FFFFFF"
					secondaryColor="#000000"
					playsInline={ true }
					
				/>
			}
			<TempAdUnderneathVideo/>
		</div>
	);
};

export default MoviePlayer;