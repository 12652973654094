import React, { useState, useEffect, useContext } from "react";
import "./SearchView.css"
import ControllerContext from "../../Contexts/ControllerContexts";
import Lottie from "lottie-react";
import loading_Lottie from "../../../Resources/Lottie/loading.json";
import { ContentModel, Posts_Coll_And_Doc_Path, searchPath } from "../../fb/dbMethods";
import { collection, documentId, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../fb/fbSetup";
import { ContentCard, ContentItemsComp } from "../../Pages/Home/ContentView/ContentView";

const SearchView = () => {
    const inDevMode = true;
    const { showSearchView, toggleSearchView } = useContext(ControllerContext);
    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView, setAnimateView ] = useState<boolean>(false);
    useEffect(() => {
        if(showSearchView || inDevMode) {
            setRenderView(showSearchView);
            setTimeout(() => {
                setAnimateView(showSearchView);
            }, 150);
        } else {
            setAnimateView(showSearchView);
            setTimeout(() => {
                setRenderView(showSearchView);
            }, 400);
        }
    }, [ showSearchView ]);

    const [ searchQuery, setSearchQuery ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const [ contentItems, setContentItems ] = useState<ContentModel[] | undefined>(undefined);
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);
    const [ isScrolled, setIsScrolled ] = useState<boolean>(false);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') search();
    };
    
    const handleInputChange = (e) => {
    setSearchQuery(_=> { return `${e.target.value}`; });
    };
    
    const search = () => {
    // Implement your search functionality here
    // alert(searchQuery);
    getDocuments();
    setIsLoading(_ => true);
    setErrorMessage(_=>{ return undefined;});
    setContentItems(_=> { return undefined;});
    };

    async function getDocuments() {
        fetch(`${searchPath}val=${searchQuery}`)
        .then(response => response.json())
        .then(async (result) => {
            const data = result;
            if (data.data && data.data.length) {
                const postList = data.data.filter((x,i) => i < 29);
                const collectionRef = collection(db, `/${ Posts_Coll_And_Doc_Path }`);
                const multiDocumentQuery = query(collectionRef, 
                    where(documentId(), "in", postList),
                );
                await getDocs(multiDocumentQuery)
                .then(documents => {
                    let list : ContentModel[] = [];
                    documents.forEach(doc => {
                        const content = doc.data() as ContentModel;
                        list.push(content);
                        setIsLoading(_=>{ return false;});
                        setContentItems(_=>{ return list;});
                    })
                })
                .catch(error => {
                    setErrorMessage("🙁 Error encountered performing search.");
                })
            } else {
                setErrorMessage("🙁 No posts found for this search.");
            }			
        })
        .catch((error) => {
            const code = error.code;
            const message = error.message;
            const details = error.details;
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
	const tkn = urlParams.get('t');  
	const ud = urlParams.get('ud');

    return (
        <>
            { renderView &&
                <div className={`SearchView${(tkn && ud) ? " isMobileApp": ""}`} style={{ opacity: animateView ? "100%" :  "0%" }}>
                    <button className="close-button" onClick={() => { toggleSearchView(false); }} >x</button>
                    <div className="search-view-container" style={{ transform: animateView ? "translateX(0)" : "translateY(150vh)",}}>
                        <div className="search-field-and-title-container">
                            <div className='search-container'>
                                { (!tkn && !ud) && <h3 className="search-title">Search:</h3>}
                                <input 
                                    id="search-input" 
                                    type={"search"} 
                                    autoComplete="off" 
                                    autoCorrect="off" 
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    placeholder="Search..."
                                    value={ searchQuery }
                                    onKeyDown={handleKeyDown}
                                    onChange={handleInputChange}
                                    style={{
                                        margin: (tkn && ud) ? "0px !important" : "inherit",
                                        width: (tkn && ud) ? "100% !important" : "inherit",
                                    }}
                                />
                                { (!isLoading && (!tkn && !ud)) ?
                                    <button id='search-button' onClick={search}>
                                        <span className="material-symbols-outlined">search</span>
                                    </button> 
                                    :
                                    (!tkn && !ud) && 
                                    <div className="search-loading-card">
                                        <div style={{ width: "calc(100% - 8px)", height: "calc(100% - 8px)", }}>
                                            <Lottie animationData={ loading_Lottie } loop={true}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="search-query-container">
                            <h2 className="search-query">{ searchQuery }</h2>
                        </div>
                        {(isLoading && (tkn && ud)) &&
                            <div className="search-loading-card" style={{marginLeft: "auto", marginRight: "auto"}}>
                                <div style={{ width: "calc(100px)", height: "calc(100px)", }}>
                                    <Lottie animationData={ loading_Lottie } loop={true}/>
                                </div>
                            </div>
                        }
                        { (contentItems && contentItems.length) &&
                        	<ContentItemsComp isLoading={ isLoading } ContentItems={ contentItems } setScrolled={ setIsScrolled } showEmojiResponse={ true } showHomeHeder={ true } showFilterButton={ true } isMobile={ false }/>
                            // contentItems.map((item, index) => {
                            //     return <ContentCard item={ item } index={ index } showEmojiResponse={ true } isMobile={ false }/>

                            // })
                        }
                        {/* <div className=""></div> */}
                        { (errorMessage) &&
                            <h4>{ errorMessage }</h4>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default SearchView;