import { db, storage, auth } from "../fb/fbSetup";
import { doc, collection ,updateDoc, setDoc, getDoc, query, limit, orderBy, getDocs, arrayUnion, runTransaction, deleteDoc } from "firebase/firestore";
import { ContentModel, userprofile_storage_path } from "../fb/dbMethods";
import uuidGenerator from "../../SharedItems/UUIDGenerator";
import { InteractionTypeOptions } from "./like_db_controller";

export interface NotusDocModel {
    date_doc_created: Date;
    notifications: NotusModel[];
}

export interface NotusModel {
    dateAdded: Date;
    postID: string;
    postTitle: string;
    interactionType: InteractionTypeOptions;
    notification_message: string;
    from_userid : string;
    notusDocumentID : string;
}

export const NotificationsCollectionRef = "_N_O_T_U_S";
/**
 * NotifyUser:
 * @param postUser: string -> It is the user who posted something
 * @param interactingUser: string -> This is the user who is liking, disliking, etc.
 * @param post: ContentModel -> This is the post it's self
 * @param interactionType: InteractionTypeOptions -> this is the interactio type
 * @param notification_message: string -> this is the message the user recieves for the interaction
 * @param callBack: (sucess: boolean) => void -> This is a method called after the notification has been attempted to be added. It returns a boolean. The boolean lets the developer know if the notification attempt was sucessful. From that they do as the please.
 */
export async function NotifyUser(
    postUser: string,
    interactingUser: string,
    post: ContentModel,
    interactionType: InteractionTypeOptions,
    notification_message: string,
    callBack: (sucess: boolean) => void
) {
    /**
     * The Reason for this is that the notication model now becomes far more reliable. This also enables us to paginate through notifications.
     * We pull in as many as the user cares about. In fact the user should mostly bias likes from people who he follows. Those are far more valuble notifications.
     * This model can be transported across other territory also.
     */
    const documentID = `${post.documentID}-${interactingUser}`; // This is esentially is the interacting userid. This makes interactions hyper specific
    const userNotificationsPath = `${userprofile_storage_path}/${postUser}/${NotificationsCollectionRef}/${documentID}`;
    const docRef = doc(db, userNotificationsPath);
    await getDoc(docRef)
    .then(document => {
        if(document.exists()) {
            console.log("NotifyUser: nofication document exists")
            // Remove or update document depending on the interaction type:
            console.log("NotifyUser Doc Data: ", document.data());
            const data = document.data() as NotusModel;
            if (data) {
                if (data.interactionType === interactionType) {
                    // This is a removal if the interaction type matches the interaction that one currently has
                    deleteDoc(docRef).then(() => { callBack(true); }).catch(()=> { callBack(false); });
                    console.log("NotifyUser: delete doc worked")
                } else {
                    const mod: NotusModel = {dateAdded: new Date(),from_userid: interactingUser,notification_message: notification_message,notusDocumentID: documentID,postID: post.documentID,postTitle: post.title, interactionType: interactionType};
                    const objData = {dateAdded:mod.dateAdded,from_userid:mod.from_userid,notification_message:mod.notification_message,notusDocumentID:mod.notusDocumentID,postID:mod.postID,postTitle:mod.postTitle, interactionType:mod.interactionType};        
                    updateDoc(docRef, objData)
                    .then(() => {
                        // In this case the user sucessfully notified the user. The user can look at that notification now at any time
                        callBack(true)
                        console.log("NotifyUser: update doc worked")
                    }).catch(() => { callBack(false); });
                }
            } else {
                console.log("NotifyUser: error casting");
                callBack(false); 
            }
        } else {
            // Three is no document to remove so instead store a notification document:
            const mod: NotusModel = {dateAdded: new Date(),from_userid: interactingUser,notification_message: notification_message,notusDocumentID: documentID,postID: post.documentID,postTitle: post.title, interactionType: interactionType};
            const objData = {dateAdded:mod.dateAdded,from_userid:mod.from_userid,notification_message:mod.notification_message,notusDocumentID:mod.notusDocumentID,postID:mod.postID,postTitle:mod.postTitle, interactionType:mod.interactionType};
            setDoc(docRef, objData)
            .then(() => { 
                console.log("NotifyUser: Added brand new notification")
                callBack(true)/* Notified user of your interaction  */;}).catch(() => { callBack(false) /* Error Updating the notification check */})
        }
    })
    .catch(error => {  
        console.log("NotifyUser: error on notificatio check: ", error);
        callBack(false);/* Error Performing check */ });
}

// export async function old_NotifyUser(
//     otherUserID: string,
//     post: ContentModel, 
//     notification_message: string, 
//     callBack: (sucess: boolean) => void
// ) {
//     const userNotificationsPath = `${ userprofile_storage_path }/${ otherUserID }/${ NotificationsCollectionRef }`;
//     const userNotificationsRef = collection(db, userNotificationsPath);
//     const mostRecentNotificationsDoc = query(userNotificationsRef, orderBy("date_doc_created","desc"), limit(1));
//     const getMostRecentNotificationsDoc = await getDocs(mostRecentNotificationsDoc);
//     if (getMostRecentNotificationsDoc.size > 0) {
//         getMostRecentNotificationsDoc.forEach( async document => {
//             const notificationsDoc = document.data() as NotusDocModel;
//             if (notificationsDoc.notifications.length < MaxNumberOfNotifcationsInADoc) {
//                 // This is for a document that both exist and hasn't reached it's maximum array size for notifications.                
//                 const firstNotificationsDocRef = `${ userNotificationsPath }/${ document.id }`
//                 const firstTimeNotificationsRef = await updateDoc(doc(db, firstNotificationsDocRef), {
//                     notifications: arrayUnion({ dateAdded: new Date(), postID: post.documentID, postTitle: post.title, notification_message: notification_message, from_userid: otherUserID  })
//                 })
//                 .then(status => {
//                     console.log("New Notifcation Doc Added");
//                     callBack(true);
//                 })
//                 .catch(err => {
//                     console.log("Issue Uploading Doc")
//                     callBack(false);
//                 })


//             } else {
//                 // Adding A New Document Because Notifications Has Reached It's Max Size
//                 const firstNotificationsDocRef = `${ userNotificationsPath }/${ uuidGenerator() }`
//                 const notificationsDocObject: NotusDocModel = {
//                     date_doc_created: new Date(),
//                     notifications: [ 
//                         { dateAdded: new Date(), postID: post.documentID, postTitle: post.title, notification_message: notification_message, from_userid: otherUserID } 
//                     ]
//                 }
//                 const firstTimeNotificationsRef = setDoc(doc(db, firstNotificationsDocRef), notificationsDocObject)
//                 .then(status => {
//                     console.log("New Notifications Doc Added");
//                     callBack(true);
//                 })
//                 .catch(err => {
//                     console.log("Issue Uploading Doc")
//                     callBack(false);
//                 });
//             }// End of if else for item reaching its max count
//         }) // End of forEach loop of all the 1 document tha that should be being called for getting the most recent notifications doc
//     } else {
//         // This is for the notifications list that is created if the user doesn't / hasn't stored any notifications on their user model yet.
//         const firstNotificationsRef = `${ userNotificationsPath }/${ uuidGenerator() }`
//         const notificationsDocObject: NotusDocModel = {
//             date_doc_created: new Date(),
//             notifications: [ 
//                 { dateAdded: new Date(), postID: post.documentID, postTitle: post.title, notification_message: notification_message, from_userid: otherUserID } 
//             ]
//         }
//         const firstTimeNotificationsRef = setDoc(doc(db, firstNotificationsRef), notificationsDocObject)
//         .then(status => {
//             console.log("New Notifications Doc Added");
//             callBack(true);
//         })
//         .catch(err => {
//             console.log("Issue Uploading Doc")
//             callBack(false);
//         })
//     }// End of else statment for the condition where the user is saving an item to notifications for the first time
// }






export async function _GetAllOfUsersNotifications(
    userID: string,
    numberOfNotificationsToShow: number,
    callBack: (sucess: boolean, notifications: NotusModel[]) => void
) {
    const userNotificationsRef = `${ userprofile_storage_path }/${ userID }/${ NotificationsCollectionRef }`;
    const userNotificationsCollection = collection(db, userNotificationsRef);
    const NotificationsQuery = query(userNotificationsCollection, orderBy("dateAdded","desc"), limit(numberOfNotificationsToShow));
    const getUserNotificationsDoc = await getDocs(NotificationsQuery);
    // Need Items for loop
    let notificationsDocCounter: number = 0;
    let notificationsList: NotusModel[] = [];
    if (getUserNotificationsDoc.size > 0) {
        getUserNotificationsDoc.forEach(document => {
            const item: NotusModel = document.data() as NotusModel;
            let cleanDate : any = item.dateAdded;
            cleanDate = cleanDate.toDate();
            const notificationsDateTime = new Date(cleanDate);
            const newNotification: NotusModel = { 
                dateAdded: notificationsDateTime,
                postID: item.postID,
                from_userid: item.from_userid,
                notification_message: item.notification_message,
                postTitle: item.postTitle,
                interactionType: item.interactionType,
                notusDocumentID: item.notusDocumentID
            }

            notificationsDocCounter = notificationsDocCounter + 1;
            if (notificationsDocCounter >= getUserNotificationsDoc.size) {
                notificationsList.push(newNotification);
                //@ts-ignore
                notificationsList = notificationsList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                callBack( true, notificationsList );
            } else {
                notificationsList.push(newNotification);
            }
        });
    }
}
