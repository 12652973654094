function MathParser_New(text: string, callback?: (textVal : string) => void ) {
    const mathModeClass = "mathMode";
    let transformedText = text;
    for(let x = 0; x < transformedText.length; x++) {
        let foundTheClosingBracketInQuestion = false;
        // Memory of the previous and next string
        const currentCharacter = transformedText[x];
        const nextCharacter = transformedText[x + 1] ? transformedText[x + 1] : undefined;
        const previous = x - 1 >= 0 ? transformedText[x - 1] : undefined;

        // Sanatizing
        let src = /src/g;
        transformedText = transformedText.replace(src, '');
        let dataSrc = /dataSrc/g;
        transformedText = transformedText.replace(dataSrc, '');
        let http = /http/g;
        transformedText = transformedText.replace(http, '');
        let https = /https/g;
        transformedText = transformedText.replace(https, '');
        let script = /scrip/g;
        transformedText = transformedText.replace(script, '');
        let www = /www/g;
        transformedText = transformedText.replace(www, '');
        let iframe = /iframe/g;
        transformedText = transformedText.replace(iframe, '');
        

        // Default and always true rules: Should be global replacements
        let enter = /\n/g;
        transformedText = transformedText.replace(enter, '<br>');
        // Integral with no upper and lower bound replace
        let integral = "integ[[]]";
        transformedText = transformedText.replace(integral, '<integral style="font-size: 30px;">∫</integral>');

        let infinity = /infint/g;
        transformedText = transformedText.replace(infinity, '∞')

        let piSymbol = /pi /g;
        transformedText = transformedText.replace(piSymbol, '𝜋 ')

        let rightArrow = /->/g;
        transformedText = transformedText.replace(rightArrow, '→')

        let leftArrow = /<-/g;
        transformedText = transformedText.replace(leftArrow, '←')

        let delta = /delta/g;
        transformedText = transformedText.replace(delta, '∆')

        //
        if(currentCharacter === "[" && nextCharacter === "[" ) {
            // Get Ready For Transformation
            // Look for closing brackets to get insight into the expression
            interface BrackModel {
                firstBracketPosition : number;
                secondBracketPositon: number;
            }
            let listOfNestedBrackets: string[] = [];
            let newIndexOffset = (x + 2);
            let NestedOpeningBrackets: BrackModel[] = [
                // Starting the array out with initial item found.
                // { firstBracketPosition: x, secondBracketPositon: x + 1 },
            ];
            let ClosingBrackets: BrackModel[] = [];

            // Looping on from there to find the closing brackets
            for(let transformationIndex = x; transformationIndex < transformedText.length; transformationIndex++) {
                const closing_currentCharacter = transformedText[transformationIndex];
                const closing_nextCharacter = transformedText[transformationIndex + 1] ? transformedText[transformationIndex + 1] : undefined;
                const closing_previous = transformationIndex - 1 >= 0 ? transformedText[transformationIndex - 1] : undefined;

                // Incremeting Opening Bracket Counter
                if (closing_currentCharacter === "[" && closing_nextCharacter === "[") {
                    // Found another opening and closing bracket 
                    // They both should be true because they are both inheriting their index from the same bracket.
                    NestedOpeningBrackets.push(
                        {
                            firstBracketPosition: transformationIndex,
                            secondBracketPositon: transformationIndex + 1,
                        }
                    );

                }
                
                // Has to both closing brackets
                if(closing_currentCharacter === "]" && closing_previous === "]") {
                    // Add to count if there was another
                    ClosingBrackets.push(
                        {
                            firstBracketPosition: transformationIndex,
                            secondBracketPositon: transformationIndex + 1,
                        }
                    ); 


                    if (
                        (ClosingBrackets.length === NestedOpeningBrackets.length) &&
                        foundTheClosingBracketInQuestion === false
                        // || (NestedOpeningBrackets.length === 0 && ClosingBrackets.length === 1)
                    ) {
                        // We have found the end of the expression if this is true
                        //
                        // Looping through the items from the index that caused a search for the closing bracket of an index through the beiging of the closing bracket, and concatinating a string with its content to return the content of that string
                        let content = "";
                        const beginingIndex = x + 2;
                        const endingIndex = ClosingBrackets[ClosingBrackets.length - 1].firstBracketPosition - 2;
                        foundTheClosingBracketInQuestion = true;

                        // Go Back Three to get the transformation
                        const transformationTypePotentialValues = [ 
                            transformedText[x - 1], transformedText[x - 2], transformedText[x - 3],
                            transformedText[x - 4], transformedText[x - 5], transformedText[x - 6], transformedText[x - 7],
                        ]; // Transformation Text should max be 7 characters long
                        const transformationType = ReturnTransformationType(transformationTypePotentialValues);
                        // console.log("transformationType: ", transformationType);
                        // Cleaning Up Brackets By Checking if they are indeed brackets, by checking a limited space (aka a standard de)
                        for(let contentIndex = beginingIndex; contentIndex <= endingIndex; contentIndex ++) {
                            content = content + transformedText[contentIndex];
                        }
                        if (transformationType) {
                            let transformedContent = MathPerformTransformation(content,transformationType);
                            // Removing the transformation text length
                            let expectedTransformedTextPosition = x - (transformationType.length);
                            let transformationLength = transformedText[expectedTransformedTextPosition] ? expectedTransformedTextPosition : x;
                            // Removing brackets and transformation text
                            let removingBracketsString = transformedText.slice(0, transformationLength) + transformedContent + transformedText.slice(endingIndex + 3) //+ transformedText.slice((x + 1));
                            transformedText = removingBracketsString;
                        }
                    }
                }
            }
        }
    }
    if (callback) {
        callback(transformedText);  
    }
}

// Euler's Number
// Euler's number is one of the most important numbers  along with pi in physics and mathematics.
// express[[e =  summ[[ 1 ,,, infint]]  fract[[ 1 ,,, n!]]   ]] 


enum MathTransformationOptions {
    Integral = "integ", // integral with bounds
    Fraction = "fract", // Fraction
    Exponent = "^",// Exponent
    Parenthesis = "paran",// Parenthesis
    Limit = "limit", // "limit with bounds"
    Matrix = "matrix", // This is for linerar algerbra and having dimensions and matrix mathematic notation... This should also have be able to be nested in a fraction object and have exponets etc.
    Summation = "summ",
    // This is here to cluster a representation together.
    UniformExpressionDenotaion = "express"
}

const transformationValueSplitter = ",,,";
function MathPerformTransformation(content: string, transformationType: MathTransformationOptions) {
    const mathModeClass = "mathMode";
    switch (transformationType) {
        case MathTransformationOptions.Integral:
            let integ_values = content.split(transformationValueSplitter);
            let integLowerBound: string | number = integ_values[0] ? integ_values[0] : 0;
            let integUpperBound: string | number = integ_values[1] ? integ_values[1] : 'n';
            const integralText = `<span class="${mathModeClass} calc-limit"><span style="font-size:12px;">${integUpperBound}</span><span style="font-size:32px;">∫</span><span style="font-size:12px;">${integLowerBound}</span></span>`
            return integralText;
            break;
        case MathTransformationOptions.Fraction:
            let frac_values = content.split(transformationValueSplitter);
            let frac_numerator = frac_values[0];
            let frac_denominator = frac_values[1];
            let fractionElem = `<span class="${mathModeClass} math-fraction"><numerator>${frac_numerator}</numerator><denominator>${frac_denominator}</denominator></span>`  
            return fractionElem;
            break;
        case MathTransformationOptions.Exponent:
            let transformation = `<sup>${content}</sup>`;
            return transformation;
            break;
        case MathTransformationOptions.Parenthesis:
            //let values = content.split(transformationValueSplitter);
            // will have to do some sizing here I am not completely sure of the approach
            return `(${content})`;
            break;
        case MathTransformationOptions.Limit:
            let lim_values = content.split(transformationValueSplitter);
            let limitLowerBound: string | number = lim_values[0] ? lim_values[0] : 0;
            let limitUpperBound: string | number = lim_values[1] ? lim_values[1] : 'n';
            const limitText = `<span class="${mathModeClass} calc-limit"><span style="font-size:20px;">lim</span><span>${limitLowerBound} -> ${limitUpperBound}</span></span>`
            return limitText;
            break;
        case MathTransformationOptions.Matrix:
            let matrix_values = content.split(transformationValueSplitter);
            return content;
            break;
        case MathTransformationOptions.Summation:
            let sum_values = content.split(transformationValueSplitter);
            let summLowerBound: string | number = sum_values[0] ? sum_values[0] : 0;
            let sumUpperBound: string | number = sum_values[1] ? sum_values[1] : 'n';
            const summationText = `
                <span class="${mathModeClass} calc-summation"><span style="font-size:12px;">${sumUpperBound}</span><span style="font-size:32px;">∑</span><span style="font-size:12px;">i =${summLowerBound}</span></span>`
            return summationText;
        case MathTransformationOptions.UniformExpressionDenotaion: 
            return `<span class="${mathModeClass} epression-denotation">${content}<span>`;
        default:
            return content;
        break;
    }
}

function ReturnTransformationType( transformationTypePotentialValues: string[]): MathTransformationOptions | undefined {
    let transformationType = "";
    let transFormTypeValue: MathTransformationOptions | undefined = undefined;
    for (let typeIndex = 0; typeIndex < transformationTypePotentialValues.length; typeIndex++) {
        let reverseIndex = ((transformationTypePotentialValues.length - 1) - typeIndex);
        if (transformationTypePotentialValues[typeIndex] !== undefined && transFormTypeValue === undefined) {
            transformationType = transformationTypePotentialValues[typeIndex] + transformationType; //transformationType + transformationTypePotentialValues[reverseIndex];
            switch (transformationType) {
                case MathTransformationOptions.Integral:
                        if (
                            transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                            transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                            transformationTypePotentialValues[reverseIndex - 1] !== " "
                        ) {
                            transFormTypeValue = MathTransformationOptions.Integral;
                        }
                    break;
                case MathTransformationOptions.Fraction:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Fraction;
                    }
                break;
                case MathTransformationOptions.Exponent:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" ||
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Exponent;
                    }
                break;
                case MathTransformationOptions.Parenthesis:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Parenthesis;
                    }
                break;
                case MathTransformationOptions.Limit:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Limit;
                    }
                break;
                case MathTransformationOptions.Matrix:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Matrix;
                    }
                break;
                case MathTransformationOptions.Summation:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Summation;
                    }
                break;
                case MathTransformationOptions.UniformExpressionDenotaion:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.UniformExpressionDenotaion;
                    }
                break;

                default:
                    // Worry Less get high accuracy 
                    // Should not transform the transFormTypeValue because if an option is found it should not be reset to undefined
                break;
            }
        }
    }
    return transFormTypeValue;
    // e = summ[[1  ,,,  n]]  fract[[1 ,,, n]] 
}


function ChemistryModeParse(text: string, callback: (textVal : string) => void ) {
    let transformedText = text;
    //
    // Chemistry Parsing Code
    let enter = /\n/g;
    transformedText = transformedText.replace(enter, '<br>');
    //
    callback(transformedText);
}


function CodeModeParse(text: string, callback: (textVal : string) => void ) {
    let transformedText = text;
    //
    // Code Parsing Code
    let enter = /\n/g;
    transformedText = transformedText.replace(enter, '<br>');
    //
    callback(transformedText);
}

function DefaultModeParse(text: string, callback: (textVal : string) => void ) {
    let transformedText = text;
    //
    // Default Parsing Code
    let enter = /\n/g;
    transformedText = transformedText.replace(enter, '<br>');
    //
    callback(transformedText);
}

export { MathParser_New, ChemistryModeParse, CodeModeParse, DefaultModeParse };