import { Adsense } from "@ctrl/react-adsense";
import React, { useState, useEffect, useContext, useRef } from "react";
import './Dunbar_Ad.css'; // Grabbing the Ad Model

interface DunbarAdModel { showAd?: boolean, index: number; }
const Dunbar_Ad = ({ showAd, index }: DunbarAdModel ) => {
    return (
        <>
            { ((  (index >= 5 && ((index % 5) === 0)) || (index >= 7 && ((index % 7) === 0))  ) || showAd) &&  
             <div className="d-c-a-d-item">
                <span style={{ position: "absolute", color: "var(--app-green-80)", padding: "2px", fontSize: "10px", left: "8px", top: "8px", zIndex: "10000", backgroundColor: "white"}}>Ad</span>
                <ActualAd/>
            </div>
            }
        </>
    )
}

export default Dunbar_Ad;

const ActualAd = () => {
    useEffect(()=> {
        // @ts-ignore
        // (adsbygoogle = window.adsbygoogle || []).push({});
    }, []);
    return (
        <>
        {/* <ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-gw-3+1f-3d+2z"
     data-ad-client="ca-pub-5619224767870187"
     data-ad-slot="3943611565"></ins> */}

            <Adsense
                className="adsbygoogle"
                style={{display:"block", width:"100%", height:"fit-content", zIndex: "1",}}
                format="fluid"
                layoutKey="-gw-3+1f-3d+2z"
                client="ca-pub-5619224767870187"
                slot="3943611565"
            />
            {/* @ts-ignore */}
        {/* <ins className="adsbygoogle"
            style={{display:"block"}}
            data-ad-format="fluid"
            data-ad-layout-key="-fh+5x+48-cv+7p"
            data-ad-client="ca-pub-5619224767870187"
            data-ad-slot="8393163868"
            data-adbreak-test="on"
            ></ins> */}
        </>
    )
}