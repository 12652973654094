import { arrayUnion, collection, doc, getDocs, increment, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";
import { userprofile_Landing_storage_path } from "../../../fb/dbMethods";
import { db } from "../../../fb/fbSetup";
import { CurrentUserDataModel } from "../../../Models/Models";
import DunbarColony from "../DunbarColony";
import { Dunabar_CommunityStoragePath } from "./DunbarBackend";

export interface DunbarColonyDocModel {
    colonyName: string; // Will be set through voting a series of voting
    colonyMembers: CurrentUserDataModel[]; 
    colonyMember_count: number; // This is to be updated when a new member is added or removed from a colony... the process should be rather simple
    /* 
        When ever a user updates their profile we will also simultaniosly update their dunbar colony model... updates shouldn't happen all that frequently.
        And this means that when interacting with the dunbar community which will be more frequent than what will be the case with what will happen with updating a user's profile.
        this will reduce reads by magnitudes.... a good example is the fact that I haven't updated my twitter profile sense I had it. I said what I wanted to say and have kept it the same sense.
    */
    colonyLeader: string;
    colonyLastElectionCycle: Date;
    //
    subthreads: DunbarSubThreadModel[];
    //
    colonyID: string;
    //
    date_of_colony_creation: Date;
}

export async function NewUser_AddToDunbarColony(user: CurrentUserDataModel, complete: (complete: boolean) => void) {
    const colonyCollectionRef_str = `${Dunabar_CommunityStoragePath}`;
    const colonyCollectionRef_coll = collection(db, colonyCollectionRef_str);
    const collectionQuery = query(colonyCollectionRef_coll, 
        where("colonyMember_count", "<", 150), 
        // orderBy("date_of_colony_creation", "asc"), 
        limit(1)
    );
    // Grab documents where dunbar colony has less than 150
    const getDunbarColony = await getDocs(collectionQuery)// test_colony
    if (getDunbarColony.size > 0) {
        getDunbarColony.forEach(document => {
            if (document.exists()) {
                const data = document.data() as DunbarColonyDocModel;
                AddNewUserToDunbarColony(document.id, user, complete);
            }
        });
    } else {
        CreateDunbarNewColony(user, complete);
    }
}

export function AddNewUserToDunbarColony(colonyID: string, user: CurrentUserDataModel, complete: (complete) => void) {
    const dunbarStringRef = `${Dunabar_CommunityStoragePath}/${ colonyID }`;
    const dunbarCommunityDocRef = doc(db, dunbarStringRef);
    updateDoc(dunbarCommunityDocRef, { 
        colonyMembers: arrayUnion(user), // Adding that user's info to the array and 
        colonyMember_count: increment(1),
     })// Passing that users data to array
    .then(status => {
        console.log("Doc Updated Successfully");
        addUserColonyData(colonyID, user, complete)
    })
    .catch(err => {
        console.log("Error Updating Document: ", err);
        // Error updating doc.
        complete(false);
    })
}

function addUserColonyData(colonyID: string, user: CurrentUserDataModel, complete: (complete) => void) {
    const userRef = `_U_S_E_R/${ user.userID }`;
    const userCommunityRef = doc(db, userRef);
    updateDoc(userCommunityRef, { 
        DunbarColonyID: colonyID,
    })
    .then(stat => {
        complete(true)
    })
    .catch( err => {
        complete(false);
    });
}

// export function AddUserToDunbarColony(user: CurrentUserDataModel) {
//     const dunbarStringRef = `${Dunabar_CommunityStoragePath}/${ user.DunbarColonyID }`;
//     const dunbarCommunityDocRef = doc(db, dunbarStringRef);
//     updateDoc(dunbarCommunityDocRef, { colonyMembers: arrayUnion(user), })
//     .then(status => {
//         console.log("Doc Updated Successfully");
//     })
//     .catch(err => {
//         console.log("Error Updating Document: ", err);
//         // Error updating doc.
//     })
// }

/*
    Colony Addition And Creation:
    ________________________________
    Check the db for any colonies with less than 150 people, you should be quering and sorting the list by date, the oldest colony without 150 members will be the colony that gets a new memnber first then everything down the list
    If all colonies are full then create a colony for a member. Let them know as new members join then this will not be an empty space... the ideal context is to check if their colony has more than one person before showing the side menu option.
    Will need to create a dunbare colony. when there are no options availble, else I will have to query for 
*/
export async function CreateDunbarNewColony(user: CurrentUserDataModel, complete: (complete) => void) {
    const DunbarColonyID: string = uuidGenerator();
    const newDunbarColonyPath_str: string = `${ Dunabar_CommunityStoragePath }/${ DunbarColonyID }`;
    const newDunbareColonyPath_doc = doc(db, newDunbarColonyPath_str);
    const colonyObject: DunbarColonyDocModel = {
        colonyName:"" ,
        colonyMembers: [ user ], 
        colonyMember_count: 1, // currently the only user
        colonyLeader: "",
        colonyLastElectionCycle: new Date(),
        subthreads:[],
        colonyID : DunbarColonyID,
        date_of_colony_creation: new Date(),
    } 
    const CreateDoc = await setDoc(newDunbareColonyPath_doc, colonyObject)
    .then(status => {
        console.log("Coloby Created and User Added");
        addUserColonyData(DunbarColonyID, user, complete)
    })
    .catch( err => {
        console.log("Error Createing a Colony");
        complete(false);
    })
}

export async function test_CreateDunbarNewColony(user: CurrentUserDataModel) {
    const DunbarColonyID: string = uuidGenerator();
    const newDunbarColonyPath_str: string = `${ Dunabar_CommunityStoragePath }/${ user.DunbarColonyID }`;
    const newDunbareColonyPath_doc = doc(db, newDunbarColonyPath_str);
    const colonyObject: DunbarColonyDocModel = {
        colonyName:"" ,
        colonyMembers: [ user ], 
        colonyMember_count: 1, // currently the only user
        colonyLeader: "",
        colonyLastElectionCycle: new Date(),
        subthreads:[],
        colonyID : DunbarColonyID,
        date_of_colony_creation: new Date(),
    } 
    const CreateDoc = await setDoc(newDunbareColonyPath_doc, colonyObject)
    .then(status => {
        console.log("Coloby Created and User Added");
    })
    .catch( err => {
        console.log("Error Createing a Colony");
    })
}


export function UpdateUserData_forNewColony() {}

// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
// Sub Thread Controls
export const dunbarSubThreadStoragePath = "D_U_N_B_A_R__S_U_B__T_H_R_E_A_D_S"
export interface DunbarSubThreadModel {
    title : string;
    purpose: string;
    date_created: Date;
    thread_doc_id: string;
    votes: DunaberVoteThreadModel[];
    imagURL: string;
    colonyFounder: string; // just user id
}

export interface DunaberVoteThreadModel {
    userID: string;
    shouldExist: boolean; // True on yes means false on no, and vice versa
}

function CreateASubThread(thread: DunbarSubThreadModel) {
    const VotesForYesList = thread.votes.map(item => {
        if (item.shouldExist) {
            return item;
        }
    });
    if(VotesForYesList.length > 1) {
        // Create Thread...
    }
}