import { arrayUnion, doc, updateDoc, runTransaction } from "firebase/firestore";
import React, { useState, useEffect, useContext } from "react";
import ControllerContext from "../../../../../Contexts/ControllerContexts";
import UserContext from "../../../../../Contexts/UserContext";
import { auth, db } from "../../../../../fb/fbSetup";
import { Dunabar_CommunityStoragePath } from "../../../DunbarBackend/DunbarBackend";
import { DunaberVoteThreadModel, DunbarColonyDocModel, DunbarSubThreadModel } from "../../../DunbarBackend/DunbarColonyMangament";
import DunbarContext from "../../../DunbarContext/DunbarContext";
import './DunbarSubThreadVoting.css';

const DunbarSubThreadVoting = () => {
    const { showErrorPopup } = useContext(ControllerContext);
    const { userData } = useContext(UserContext);
    const { dunbarColonyObj } = useContext(DunbarContext);
    const [ subThreadModel, setSubThreadModel ] = useState<DunbarSubThreadModel | undefined>(undefined);
    useEffect(()=> {
        if (dunbarColonyObj && dunbarColonyObj.subthreads) {
            const needsToBeVotedOn = dunbarColonyObj.subthreads.filter((item, index) => {
                if (item.votes.length >= dunbarColonyObj.colonyMembers.length) { /* skip */ } 
                else {
                    let userHasVoted = false;
                    const needsVotesList = item.votes.filter(i => {
                        if (userData.userID === i.userID){ userHasVoted = true; };
                        if ((i.shouldExist) && (i.userID !== userData.userID)) { return i; }; 
                    });
                    if ( needsVotesList.length < 2  && !userHasVoted) { return item; }
                }
            });
            if (needsToBeVotedOn.length > 0) {
                setSubThreadModel(_=> { return needsToBeVotedOn[0]; });
            }
        }
    }, [ dunbarColonyObj ]);

    async function Vote(shouldExist: boolean, subThreadObj: DunbarSubThreadModel) {
        const dunbarVote: DunaberVoteThreadModel = { userID: userData.userID, shouldExist: shouldExist };
        const ref_str = `${ Dunabar_CommunityStoragePath }/${ userData.DunbarColonyID }`;
        const ref_doc = await doc(db, ref_str);
        try {
            await runTransaction(db, async (transaction) => {
              const document = await transaction.get(ref_doc);
              if (!document.exists()) { throw "Document does not exist!";  }
          
              let dunbarModel = document.data() as DunbarColonyDocModel;
              let subthreadList: DunbarSubThreadModel[] = dunbarModel.subthreads.map(item => {
                if (subThreadObj.thread_doc_id === item.thread_doc_id) {
                    item.votes.push(dunbarVote);
                    return item;
                } else {
                    return item;
                }
              })
              dunbarModel.subthreads = subthreadList;
              transaction.update(ref_doc, { ...dunbarModel });
            });
            console.log("Transaction successfully committed!");
            setSubThreadModel(_=> { return undefined; });
            showErrorPopup(true, "Your vote has sucessfully been cast", "Democracy Strikes Again!", "var(--app-green-90)", undefined, true);
          } catch (e) {
            console.log("Transaction failed: ", e);
            showErrorPopup(true, "There was an error casting vote for the existence of this sub-thread", "Error", "var(--app-red-90)", undefined, true);
          }
    }

    return (
        <div className="DunbarSubThreadVoting" style={{ top: subThreadModel ? "50%" : "300vh" }}>
            { subThreadModel &&
                <div className="dunbar-sub-thread-content">
                    <h4 className="static-view-title">Should this sub-thread exist?</h4>
                    <h5 className="sub-thread-title">{ subThreadModel.title }</h5>
                    <p className="sub-thread-description">{ subThreadModel.purpose }</p>
                    <div className="vote-buttons">
                        <button className="yes" onClick={()=>{ Vote(true, subThreadModel); }} >Yes</button>
                        <button className="no" onClick={()=>{ Vote(false, subThreadModel); }} >No</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default DunbarSubThreadVoting;


const currentData = {
    "date_of_colony_creation": {
        "seconds": 1692383065,
        "nanoseconds": 484000000
    },
    "colonyLeader": "",
    "colonyID": "WVEkFRXUnlAhReKxkwCdluAvLSlNBPTMFNolxCisJOwbDbhUHNjtxIzTESutrqzvbuNDrgetXcQoUCKO",
    "colonyName": "",
    "subthreads": [
        {
            "purpose": "This is a philosophical place of discussion about capital punishment and non-lethal methods of disengaging an assailant.",
            "imagURL": "https://firebasestorage.googleapis.com/v0/b/newtopia-a4327.appspot.com/o/_D_U_N_B_A_R__C_O_M_M_U_N_I_T_Y%2FD_U_N_B_A_R__S_U_B__T_H_R_E_A_D_S%2FPCxzxEeQQhLSChcogSshkgBgkltqIrEVVhKinNHPyEpkhIKKAdNtnOVVfoTAXVfwSpcWuSJSUJFuUwpO.jpg?alt=media&token=7977b27e-d76c-4d34-bbef-4d4379bd904e",
            "title": "Superheroes Who Don't kill",
            "votes": [
                {
                    "userID": "dTIJA53ZwdUos2TIab6xVAsEmXF2",
                    "shouldExist": true
                }
            ],
            "date_created": {
                "seconds": 1692821698,
                "nanoseconds": 780000000
            },
            "colonyFounder": "dTIJA53ZwdUos2TIab6xVAsEmXF2",
            "thread_doc_id": "PCxzxEeQQhLSChcogSshkgBgkltqIrEVVhKinNHPyEpkhIKKAdNtnOVVfoTAXVfwSpcWuSJSUJFuUwpO"
        }
    ],
    "colonyMembers": [
        {
            "disclosedUserPsycheType": {
                "country": "United States",
                "age": 25,
                "disclosedPsychType": {
                    "openess": {
                        "curious_v_cautious": 0.5,
                        "spectrum": [
                            {
                                "0": "inventive",
                                "1": "consistent"
                            },
                            {
                                "0": "curious",
                                "1": "cautious"
                            }
                        ],
                        "inventive_v_consistent": 0.75,
                        "avgValue": 0.625
                    },
                    "agreeableness": {
                        "spectrum": [
                            {
                                "0": "friendly",
                                "1": "challenging"
                            },
                            {
                                "0": "compassionate",
                                "1": "detached"
                            }
                        ],
                        "compassionate_v_detached": 0.25,
                        "friendly_v_challenging": 1,
                        "avgValue": 0.625
                    },
                    "extraversion": {
                        "outgoing_v_solitary": 1,
                        "energetic_v_reserved": 0.25,
                        "spectrum": [
                            {
                                "0": "outgoing",
                                "1": "solitary"
                            },
                            {
                                "0": "energetic",
                                "1": "reserved"
                            }
                        ],
                        "avgValue": 0.625
                    },
                    "conscientiousness": {
                        "organized_v_careless": 0.5,
                        "spectrum": [
                            {
                                "0": "efficient",
                                "1": "easy-going"
                            },
                            {
                                "0": "organized",
                                "1": "careless"
                            }
                        ],
                        "efficient_v_easyGoing": 0.25,
                        "avgValue": 0.375
                    },
                    "neuroticism": {
                        "nervous_v_confident": 0.25,
                        "avgValue": 0.5,
                        "sensitive_v_secure": 0.75,
                        "spectrum": [
                            {
                                "0": "sensitive",
                                "1": "secure"
                            },
                            {
                                "0": "nervous",
                                "1": "confident"
                            }
                        ]
                    }
                },
                "region": {
                    "ew": "east",
                    "ns": "south"
                }
            },
            "bio": "I'm from a small town in Kansas called Smallville. I was adopted by loving parents, and I have a love for journalism. People compare me to Ercle, but I like to believe I look like Superman. 😅",
            "projectExpience": [
                {
                    "ideaInEightyCharacters": "Teaching Kids Complex Ideas via story.",
                    "stateOfProject": "onHold",
                    "projectName": "Toddlit",
                    "websiteOrLink": "https://www.toddlit.com/",
                    "summaryOfIdea": "The project was a cross-platform application where artist accross the world to share complex concepts, ideas, and morals via story."
                },
                {
                    "websiteOrLink": "https://github.com/rabusmccaleb/WikiRandom-2.0",
                    "ideaInEightyCharacters": "An attempt to fix the human epistomology problem generally.",
                    "stateOfProject": "done",
                    "summaryOfIdea": "This project was built to solve the problem of filling in the blind spots in our lack of knowledge. It often is not clear that we can't even search what we don't know.",
                    "projectName": "WikiRandom"
                }
            ],
            "professionalExperience": [
                {
                    "timeThere": {
                        "end": "M",
                        "beginning": "Jan"
                    },
                    "role": "Frontend Engineer",
                    "company": "Dunder Miflin",
                    "website": ""
                }
            ],
            "allowDirectMessage": true,
            "messagesRefferences": [
                {
                    "user_one_ID": "mp699DW1KWOM0yMWbQETcATb8cJ3",
                    "user_two_lastName": "Kent",
                    "user_two_username": "rabmcc",
                    "user_two_firstName": "Clark",
                    "user_one_username": "Batman",
                    "documentID": "YUpDxVhGQgRoDoivzOoSNGimxjApmNYOHRcfLohFsrvLUmUKfuEJECyoCAIBOGpyxrDHQvAPpSonAvtN",
                    "mostRecentInteractionDate": {
                        "seconds": 1690837121,
                        "nanoseconds": 94000000
                    },
                    "user_two_ID": "dTIJA53ZwdUos2TIab6xVAsEmXF2",
                    "current_doc_index": 0,
                    "user_one_lastName": "Wayne",
                    "user_one_firstName": "Bruce"
                }
            ],
            "DunbarColonyID": "test_colony",
            "followerCount": 20,
            "customUserName": "superman",
            "firstName": "Clark",
            "currentProjectItems": [
                {
                    "checkPoints": [
                        {
                            "checkPointTitle": "",
                            "checkPointDescription": "Close first sale",
                            "completed": false
                        },
                        {
                            "checkPointTitle": "",
                            "completed": false,
                            "checkPointDescription": "Reach out to potential clients"
                        },
                        {
                            "checkPointDescription": "Wrap up on the collab view",
                            "checkPointTitle": "",
                            "completed": false
                        },
                        {
                            "checkPointDescription": "Wrap up on the notes view",
                            "checkPointTitle": "",
                            "completed": false
                        },
                        {
                            "completed": false,
                            "checkPointDescription": "Wrap up on the lecture playback view",
                            "checkPointTitle": ""
                        },
                        {
                            "checkPointTitle": "",
                            "checkPointDescription": "Build an elaborate note taking system (mostly done).",
                            "completed": false
                        },
                        {
                            "checkPointTitle": "",
                            "completed": true,
                            "checkPointDescription": "Build out the real-time session view"
                        },
                        {
                            "checkPointTitle": "",
                            "checkPointDescription": "Build out the landing page",
                            "completed": true
                        },
                        {
                            "checkPointDescription": "Build out the pricing page",
                            "checkPointTitle": "",
                            "completed": true
                        },
                        {
                            "checkPointTitle": "",
                            "checkPointDescription": "Build out the careers page",
                            "completed": true
                        },
                        {
                            "checkPointDescription": "Build out the distributed cognition page",
                            "completed": true,
                            "checkPointTitle": ""
                        },
                        {
                            "checkPointTitle": "",
                            "checkPointDescription": "Host the site",
                            "completed": true
                        }
                    ],
                    "projectInEightyCharacters": "Bringing helpful technology to students and teachers in real-time",
                    "projectID": "1234567",
                    "projectDescription": "This is a simple project. The major purpose is to solve classroom hangups, by offering realtime software, along with a suite of other software to allow students to participate in distributed learning.",
                    "projectTitle": "Auessey"
                }
            ],
            "contentColonies": [],
            "TopicsOfInterest": [
                "Business",
                "Economics",
                "Physics",
                "Astronomy",
                "Earth science",
                "Neuroscience",
                "Psychology",
                "Technology",
                "Engineering",
                "Software Engineering",
                "Civil Engineering",
                "Chemical Engineering",
                "Mechanical Engineering",
                "Electrical Engineering",
                "Industrial Engineering",
                "Aerospace engineering",
                "Systems engineering",
                "Materials Engineering",
                "Agricultural engineering",
                "Biological engineering",
                "History",
                "Archeology",
                "Art",
                "Mathematics",
                "Artificial Intelligence",
                "Architecture",
                "Music",
                "Philosophy",
                "Electrical",
                "Plumbing",
                "Carpentry",
                "Construction",
                "Wood Working",
                "Theology ",
                "Christianity",
                "Islam",
                "Judaism",
                "Law",
                "Comics",
                "Marvel Comics",
                "DC Comics",
                "Anime",
                "Manga",
                "Books",
                "Classic Literature",
                "Animals",
                "Pets",
                "Big Cats",
                "Language",
                "Funny",
                "Dark Humor"
            ],
            "prioritizeExperience": false,
            "lastName": "Kent",
            "posts": [
                {
                    "community": "physics",
                    "postID": "JYhNzjObrcuDEznXcKbngoxhcSSBtnpUALBdTtbSGPuQnKMtgEYAcCxVxQGXuHFiepHVwDRzxVOHVHRE"
                },
                {
                    "postID": "gJFUxYIovqmNWdDRgQnWHIjDSWbctPzdYURKzmAnGklsutaxJyRPtceWVkMjCjmHXhIsiNtyxSdayDCX",
                    "community": "physics"
                },
                {
                    "community": "physics",
                    "postID": "phtsrQKLkCdynlzoWgfEpGUJhXchcBAoXTKYgrHjdAhBUkPYzMdRfxaEPYefkHdpLhGHcmUdRnVHwMCM"
                },
                {
                    "postID": "sUHBdTQRlAxprqyVtgxfpXWTaUGYzqrkWvqJzdHdockeWumuNaepFPjwnfsoHwpQtflcJCQLTXlKoGdU",
                    "community": "physics"
                },
                {
                    "community": "physics",
                    "postID": "wznaUAEHetKWSAXYpDflBsRCjyQRUvTBDoYHHwrTVHvdxgdSCMWIfOgxdUOMpsUBoIidQLDvvGnEgpyT"
                }
            ],
            "peopleYouFollow": [],
            "peopleOfCare": [],
            "userName": "superman",
            "userID": "dTIJA53ZwdUos2TIab6xVAsEmXF2",
            "followingCount": 10,
            "communities": [
                {
                    "communityID": "1",
                    "communityLevel": "expert",
                    "communityTitle": "Physics"
                },
                {
                    "communityLevel": "wise",
                    "communityID": "2",
                    "communityTitle": "Chemical Engineering"
                },
                {
                    "communityID": "3",
                    "communityTitle": "Math",
                    "communityLevel": "expert"
                }
            ],
            "profilePrivate": false
        },
        {
            "bio": "",
            "profilePrivate": false,
            "contentColonies": [],
            "prioritizeExperience": false,
            "firstName": "Bruce",
            "projectExpience": [],
            "currentProjectItems": [],
            "communities": [
                {
                    "communityTitle": "Physics",
                    "communityID": "1",
                    "communityLevel": "expert"
                }
            ],
            "messagesRefferences": [
                {
                    "user_two_lastName": "Kent",
                    "user_one_lastName": "Wayne",
                    "user_two_username": "rabmcc",
                    "user_two_firstName": "Clark",
                    "user_one_firstName": "Bruce",
                    "mostRecentInteractionDate": {
                        "seconds": 1690837121,
                        "nanoseconds": 94000000
                    },
                    "user_two_ID": "dTIJA53ZwdUos2TIab6xVAsEmXF2",
                    "user_one_ID": "mp699DW1KWOM0yMWbQETcATb8cJ3",
                    "current_doc_index": 0,
                    "documentID": "YUpDxVhGQgRoDoivzOoSNGimxjApmNYOHRcfLohFsrvLUmUKfuEJECyoCAIBOGpyxrDHQvAPpSonAvtN",
                    "user_one_username": "Batman"
                }
            ],
            "peopleYouFollow": [],
            "allowDirectMessage": true,
            "followerCount": 0,
            "DunbarColonyID": "test_colony",
            "userID": "mp699DW1KWOM0yMWbQETcATb8cJ3",
            "lastName": "Wayne",
            "posts": [],
            "customUserName": "Batman",
            "professionalExperience": [],
            "peopleOfCare": [],
            "followingCount": 0
        },
        {
            "lastName": "Diana",
            "followerCount": 0,
            "DunbarColonyID": "test_colony",
            "messagesRefferences": [],
            "profilePrivate": false,
            "peopleYouFollow": [],
            "contentColonies": [],
            "communities": [],
            "followingCount": 0,
            "userID": "thKKL88Lljau28V1iZ2RTiI2OUx1",
            "firstName": "Princess",
            "projectExpience": [],
            "peopleOfCare": [],
            "professionalExperience": [],
            "customUserName": "Wonder Woman",
            "bio": "",
            "disclosedUserPsycheType": {
                "age": 30,
                "country": "United States",
                "disclosedPsychType": {
                    "agreeableness": {
                        "avgValue": 0.5,
                        "compassionate_v_detached": 0,
                        "friendly_v_challenging": 1,
                        "spectrum": [
                            {
                                "0": "friendly",
                                "1": "challenging"
                            },
                            {
                                "0": "compassionate",
                                "1": "detached"
                            }
                        ]
                    },
                    "neuroticism": {
                        "sensitive_v_secure": 1,
                        "nervous_v_confident": 1,
                        "avgValue": 1,
                        "spectrum": [
                            {
                                "0": "sensitive",
                                "1": "secure"
                            },
                            {
                                "0": "nervous",
                                "1": "confident"
                            }
                        ]
                    },
                    "openess": {
                        "avgValue": 0.375,
                        "curious_v_cautious": 0.5,
                        "inventive_v_consistent": 0.25,
                        "spectrum": [
                            {
                                "0": "inventive",
                                "1": "consistent"
                            },
                            {
                                "0": "curious",
                                "1": "cautious"
                            }
                        ]
                    },
                    "extraversion": {
                        "energetic_v_reserved": 0.75,
                        "avgValue": 0.5,
                        "outgoing_v_solitary": 0.25,
                        "spectrum": [
                            {
                                "0": "outgoing",
                                "1": "solitary"
                            },
                            {
                                "0": "energetic",
                                "1": "reserved"
                            }
                        ]
                    },
                    "conscientiousness": {
                        "organized_v_careless": 0.25,
                        "efficient_v_easyGoing": 0.75,
                        "avgValue": 0.5,
                        "spectrum": [
                            {
                                "0": "efficient",
                                "1": "easy-going"
                            },
                            {
                                "0": "organized",
                                "1": "careless"
                            }
                        ]
                    }
                },
                "region": {
                    "ew": "east",
                    "ns": "south"
                }
            },
            "TopicsOfInterest": [
                "Makeup",
                "Physics",
                "Astronomy",
                "Geology",
                "Earth science",
                "Oceanography",
                "Zoology",
                "Psychology",
                "History",
                "Archeology",
                "Art",
                "Film",
                "Aviation",
                "Architecture",
                "Philosophy",
                "Carpentry",
                "Construction",
                "Wood Working",
                "HVAC",
                "Theology ",
                "DC Comics",
                "Books",
                "Classic Literature",
                "Writing",
                "Pets",
                "Language",
                "Exercise"
            ],
            "allowDirectMessage": true,
            "currentProjectItems": [],
            "posts": [],
            "prioritizeExperience": false
        }
    ],
    "colonyMember_count": 1,
    "colonyLastElectionCycle": {
        "seconds": 1692303277,
        "nanoseconds": 56000000
    }
}