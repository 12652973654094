import React, { useState } from 'react';
import { AuthFooter, AuthViewHeader } from '../../Pages/Auth/AuthView';
import ColorUI from '../ColorUI/ColorUI';

function EulaAgreement() {
  const [ scrolled , setScrolled ] = useState(true);
  const [ showJumpInSignUp, setShowJumpInSignUp] = useState<boolean>(false);
  return (
    <>
      <ColorUI/>
      <div className="eula-container" style={
        { 
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(255,255,255, 0.8)",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          overflowY: "scroll",
          padding: "0"
      }
    }>
        <AuthViewHeader scrolled={ scrolled } setShowJumpInSignUp={ setShowJumpInSignUp }/>
        <div style={
                { 
                    position: "relative",
                    width: "100vw",
                    height: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "100px",
                    minHeight: "100vh",
                }
        }>
            <Content/>
            <div style={{
              position: "relative",
              width: "100vw",
              height: "150px",
              bottom: "0",
              zIndex: "1",
            }}>
              <AuthFooter/>
            </div>
        </div>
    </div>
  </>
  );
}

export default EulaAgreement;

const Content = () => {
  return (
    <div style={
      { 
          position: "relative",
          width: "90%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          marginBottom: "auto",
          padding: "0 20px",
          paddingBottom: "50px"
      }
    }>

    <h2>End-User License Agreement (EULA) for Big World Project</h2>
    <br/>
    <br/>
    <br/>
    <p>
      This End-User License Agreement ("Agreement") is a legal contract between you (either an individual or a single entity) and Big World Project, governing your use of the Big World Project social media application and any related services provided by Big World Project ("Application"). By installing, accessing, or otherwise using the Application, you agree to be bound by the terms of this Agreement. If you do not agree to the terms of this Agreement, do not install, access, or use the Application.
    </p>
    <br/>
    <br/>
    <h3>1. License Grant</h3>
    <p>
      Subject to the terms and conditions of this Agreement, Big World Project grants you a limited, non-exclusive, non-transferable, and revocable license to use the Application solely for your personal, non-commercial purposes. This license does not grant you any right to sublicense, distribute, or transfer the Application or any portion thereof.
    </p>
    <br/>
    <h3>2. Restrictions</h3>
    <p>
      You agree not to:
    </p>
    <ul>
      <li>Use the Application for any illegal purpose or in any manner inconsistent with this Agreement.</li>
      <li>Modify, adapt, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Application.</li>
      <li>Remove, alter, or obscure any copyright, trademark, or other proprietary rights notice on or in the Application.</li>
      <li>Use the Application in any way that could damage, disable, overburden, or impair the servers or networks connected to the Application.</li>
      <li>Attempt to gain unauthorized access to any portion of the Application or any systems or networks connected to the Application.</li>
      <li>Upload, post, transmit, or distribute any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
      <li>Engage in any activity that promotes objectionable content, including but not limited to hate speech, harassment, pornography, violence, or discrimination.</li>
      <li>Abuse or harass other users of the App in any way.</li>
    </ul>
    <br/>
    <h3>3. User Content</h3>
    <p>
      You are solely responsible for any content you upload, post, transmit, or distribute through the Application ("User Content"). By submitting User Content, you represent and warrant that you have all necessary rights and permissions to do so, and that your User Content does not violate any third-party rights or any applicable laws or regulations.
    </p>
    <br/>
    <h3>4. Monitoring and Enforcement</h3>
    <p>
      Big World Project reserves the right, but not the obligation, to monitor, review, and remove any User Content in its sole discretion, without notice or liability. Big World Project also reserves the right to terminate or suspend your access to the Application at any time, with or without cause, in its sole discretion. Big World Project may also cooperate with law enforcement authorities and third-party content providers to enforce this Agreement and applicable laws.
    </p>
    <br/>
    <h3>5. Intellectual Property</h3>
    <p>
      The Application, including all content, features, and functionality thereof, are owned by Big World Project or its licensors and are protected by copyright, trademark, and other intellectual property laws. Except as expressly provided herein, no license or right is granted to you under any patent, trademark, copyright, or other intellectual property right of Big World Project.
    </p>
    <br/>
    <h3>6. Privacy</h3>
    <p>
      Your use of the Application is also subject to Big World Project's Privacy Policy, which is hereby incorporated into this Agreement by reference. By using the Application, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.
    </p>
    <br/>
    <h3>7. No Warranty</h3>
    <p>
      THE APPLICATION IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY. TO THE FULLEST EXTENT PERMITTED BY LAW, BIG WORLD PROJECT DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. BIG WORLD PROJECT DOES NOT WARRANT THAT THE APPLICATION WILL BE UNINTERRUPTED, ERROR-FREE, OR FREE FROM HARMFUL COMPONENTS.
    </p>
    <br/>
    <h3>8. Limitation of Liability</h3>
    <p>
      IN NO EVENT SHALL BIG WORLD PROJECT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OR INABILITY TO USE THE APPLICATION, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, EVEN IF BIG WORLD PROJECT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL BIG WORLD PROJECT'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR THE USE OF THE APPLICATION.
    </p>
    <br/>
    <h3>9. Indemnification</h3>
    <p>
      You agree to indemnify, defend, and hold harmless Big World Project, its affiliates, officers, directors, employees, agents, licensors, and suppliers from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in connection with your use or misuse of the Application, your User Content, or your violation of this Agreement.
    </p>
    <br/>
    <h3>10. Termination</h3>
    <p>
      This Agreement is effective until terminated by either party. You may terminate this Agreement by uninstalling the Application and ceasing to use it. Big World Project may terminate this Agreement at any time, with or without cause, in its sole discretion.
    </p>
    <br/>
    <h3>11. Governing Law and Dispute Resolution</h3>
    <p>
      This Agreement shall be governed by and construed in accordance with the laws of [insert jurisdiction], without regard to its conflict of law principles. Any dispute arising out of or relating to this Agreement shall be resolved exclusively by arbitration administered by [insert arbitration institution] in accordance with its rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.
    </p>
    <br/>
    <h3>12. Entire Agreement</h3>
    <p>
      This Agreement constitutes the entire agreement between you and Big World Project regarding the subject matter hereof and supersedes all prior or contemporaneous agreements, understandings, and communications, whether oral or written.
    </p>
    <br/>
    <h3>13. Changes to Agreement</h3>
    <p>
      Big World Project reserves the right to modify or amend this Agreement at any time, in its sole discretion. Your continued use of the Application after any such modification or amendment constitutes your acceptance of the revised Agreement.
    </p>
    <br/>
    <h3>14. Contact Information</h3>
    <p>
      If you have any questions or concerns about this Agreement, please contact us at team@bigworldproject.com.
    </p>
    <p>
      By installing, accessing, or using the Application, you acknowledge that you have read and understood this Agreement and agree to be bound by its terms and conditions.
    </p>
  </div>
  )
}
