import { useContext } from "react";
import NotSignedInView from "../../Components/NotSignedInView/NotSignedInView";
import UserContext from "../../Contexts/UserContext";
import './VideoHistory.css'

const VideoHistory = () => {
    const { userData, userVideoHistory } = useContext(UserContext);
    return (
        <div className="ViewLaterView">
            <div className="ViewLater-content-view">
                <div className="view-title">
                    <h1>Video History</h1>
                    <h4>A log of all the videos you have watched</h4>
                </div>
                {/* { userVideoHistory.length > 0 ?
                        userVideoHistory.map((vl, index) => {
                            return ( <VideoHistoryItemView post={ vl } index={ index } />)
                        })
                        : */}
                        {/* @ts-ignore */}
                        { !userData.NOT_AN_AUTHENTICATED_USER && <h2 style={ { textAlign: "center", marginTop: "20px" } }>🙁 Sorry, it looks like you don't have any items in your video history.</h2> }
                {/* } */}
            </div>
            {/* @ts-ignore */}
            { userData.NOT_AN_AUTHENTICATED_USER &&<NotSignedInView not_visble_view_name={"Video History"} view_purpose={``} custom_message={ `Sign in for viewed videos to be added to your video history list. `}/> }
        </div>
    )
}

export default VideoHistory;