import React, { useState, useEffect } from "react";
import "./SharedPost.css"
//
import logo from "../../../Resources/AppIcon.png"
import AppStore from "../../../Resources/AppLocation/app-store.png";
import GooglePlay from "../../../Resources/AppLocation/google-play.png";
import favIcon from '../../../Resources/Favorites/fav.png';
import favIcon_filled from '../../../Resources/Favorites/fav_filled.png';
import EyeIcon from '../../../Resources/eye.png';
import { PlayIcon } from "../PlayIcon/PlayIcon";
//
import { ApplicationName, CurrentUserDataModel } from "../../Models/Models";
import { ContentCard_IndividualDataRequest } from "../../Pages/Home/ContentView/ContentView";
import { ContentModel, PostImageModel, PostType } from "../../fb/dbMethods";
import { InteractionTypeOptions } from "../../Controller/like_db_controller";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import uuidGenerator from "../../../SharedItems/UUIDGenerator";
import ShortFormVideoPlayer from "../ContentComponents/ShortFormVideo";
import Lottie from "lottie-react";
import loading_Lottie from "../../../Resources/Lottie/loading.json";
import { share_http_v } from "./a";




const SharedPost = () => {
    const [ item, setItem ] = useState<ContentModel | undefined>(undefined); 
    const [ userData, setUserData ] = useState<CurrentUserDataModel | undefined>(undefined);
    const [ userProfileImageURL, setUserProfileImageURL ] = useState<string | undefined>(undefined);

    function getPidFromUrl(): string | null {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('pid');
    }

    async function API_CALL_FOR_POST_AND_USER_DATA() {
        const pid = getPidFromUrl();
        if (pid) {
            const request = new Request(`${share_http_v}${pid}`);
            try {
                const response = await fetch(request);
                if (response.ok && response.status !== 204) {
                    const data = await response.json();
                    // @ts-ignore
                    const content = data.data as ContentModel;
                    if (content) {
                        setItem(_=> { return content; });
                    }
                    // @ts-ignore
                    const userD = data.user as CurrentUserDataModel;
                    if (userD) {
                        setUserData(_=> { return userD; });
                    }
                    const _userProfileURL = data.userProfileURL;
                    if (_userProfileURL.length) {
                        setUserProfileImageURL(_=> { return _userProfileURL[0]})
                    }
                }
                if (response.ok && response.status === 204) { }
            } catch (e) {
            }
        }
    }
    
    useEffect(() => {
        API_CALL_FOR_POST_AND_USER_DATA();
    }, []);

    const [ showCommentSectionPopOut, setShowCommentSectionPopOut ] = useState<boolean>(false);

    return (
        <div className="sharedPost">
            <SharedPostProductHeader appName={ ApplicationName }/>
            <div className="card-container">
                <ContentCard
                    item={ item } 
                    index={ 0 } 
                    isMobile={ false }
                    setShowCommentSectionPopOut={ setShowCommentSectionPopOut }
                    userData={ userData }
                    userProfileImageURL={ userProfileImageURL }
                />
            </div>
        </div>
    );
}


interface SharedPostProductHeader {appName: string}
const SharedPostProductHeader = ({ appName }: SharedPostProductHeader) => {
    return (
        <div className="header">
            <div className='titolo-and-logo' onClick={ () => { window.location.reload(); } }>
                <div className='logo-container img-container'>
                    <img src={ logo } />
                    <div className='cover'></div>
                </div>
                <label>{ appName }</label>
            </div>
            <h6 style={{ marginLeft: "4px", fontSize: "10px"}}>For everything bright & nerdy.</h6>
        </div>
    );
}

const SharePostsButtons = () => {
    return (

        <div className="location-to-find-app-small shared-post-item">
            <a className="location-button-small app-store-small shared-post-link" href="https://apps.apple.com/us/app/big-world-proj/id6499078499" >
                <h3 className="location-title">Apple App Store</h3>
                <div className="image-container">
                    <img src={ AppStore }/>
                    <div className="image-cover"/>
                </div>
            </a>

            <a className="location-button-small google-play-small shared-post-link" href="https://play.google.com/store/apps/details?id=com.bigworld.riggs_android">
            {/* href="#waitlist-container"> */}
                <h3 className="location-title">Google Play Store</h3>
                <div className="image-container">
                    <img src={ GooglePlay }/>
                    <div className="image-cover"/>
                </div>
            </a>
        </div>
    )
}
export default SharedPost;



interface ContentCard { 
	item: ContentModel; 
	index? : number;
	isMobile?: boolean;
    userData?: CurrentUserDataModel;
    userProfileImageURL?: string;
    setShowCommentSectionPopOut: (value: boolean) => void;
}
export const ContentCard = ({ 
	item,
	index,
	isMobile,
    userData,
    userProfileImageURL,
    setShowCommentSectionPopOut
}: ContentCard) => {
    const [ ContentUser, setContentUser ] = useState< CurrentUserDataModel | undefined >(undefined);

	useEffect(()=> {
        if (userData) {
            setContentUser(_=> { return userData; });
        }
    }, [ userData ])
	
	function GetContentDate(content: ContentModel): string {
		let dateString: string = "";
		let mDate : any = content.dateOfPost;
		const mDateTime = new Date(mDate);
		let dayjs_ = dayjs.extend(relativeTime);
		dateString = dayjs(mDateTime).fromNow();
		return dateString;
	}

	return (
		<>
            {(item) ?
                <div 
                    className={`content-items${(item.postType === PostType.imagePosts && item.imageURLs && item.imageURLs.length < 2) ? " horizontal" : ""} share`} 
                    key={ index }
                >
                    { item.postType !== PostType.shortFormVideo &&
                        <div className='long-form-video v-stack-item'>
                            <div className='postedby-speech-and-more-button'>
                                <div className='content-post-by-container'>
                                    <div className='userProfile-img-content-container img-container'>
                                        {(userProfileImageURL && ContentUser) && <img loading={"lazy"} alt={ `${ ContentUser.firstName } ${ ContentUser.lastName } Profile Image` } src={ userProfileImageURL } /> }
                                        <div className='cover'></div>
                                    </div>
                                    <div className="profile-name-container">
                                        { ContentUser && <label className='userProfile-name'>{ `${ ContentUser.firstName } ${ ContentUser.lastName }` }</label> }
                                        { ContentUser && <label className='userProfile-nick-name'>{ `@${ ContentUser.customUserName }` }</label> }
                                    </div>
                                </div>

                                <div className="date-of-post-container">
                                        <p>{ `${ GetContentDate(item) }` }</p>
                                </div>
                                <SharePostsButtons/>
                            </div>

                            <h3 className='content-title'>{ item.title }</h3>
                            <div style={{
                                height: (item.textContent && item.textContent.trim() === "") ? "0 !important" : "fit-content"
                            }} dangerouslySetInnerHTML={{ __html: `<p class="content-post">${ item.textContent }</p>` } }></div>
                            {
                                (item.postType === PostType.imagePosts && item.imageURLs && item.imageURLs.length > 1) && <ImageItemsView imageList={ item.imageURLs }/>
                            }
                            <div className='comments-controlls-container above-media-content'>
                                <div className='see-comments-button' onClick={() => {
                                    setShowCommentSectionPopOut(true);
                                }}>
                                    <div className="eye-icon-container">
                                        <img loading={"lazy"} src={ EyeIcon }/>
                                        <div className="image-cover"/>
                                    </div>
                                    <label>comments</label>
                                </div>
                            </div>
                        </div>
                    }
                    { 
                        item.postType === PostType.shortFormVideo && <ShortFormVideoPlayer id={ uuidGenerator() } autoPlay={ false } title={ item.title ? item.title : "" }  textPost={ item.textContent ? item.textContent : "" }/>
                    }
                    {
                        (item.postType === PostType.imagePosts && item.imageURLs && item.imageURLs.length < 2) && <ImageItemsView imageList={ item.imageURLs }/>
                    }

                <div className='comments-controlls-container below-media-content'>
                    <div className='see-comments-button' onClick={() => {
                        setShowCommentSectionPopOut(true);
                    }}>
                        <div className="eye-icon-container">
                            <img loading={"lazy"} src={ EyeIcon }/>
                            <div className="image-cover"/>
                        </div>
                        <label>comments</label>
                    </div>
                </div>
                </div>
                :
                <div className="loading-card"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "130px",
                        height: "130px",
                        transform: "translate(-50%, -50%)",
                        zIndex: "10000",
				    }}
                >
                    <Lottie animationData={ loading_Lottie } loop={true}/>
                </div>
            }
		</>
	)
}



interface imgProps {
    imageList? : PostImageModel[];
}
const ImageItemsView = ({ imageList }: imgProps) => {
    return (
        <>
            { imageList ?
                <div className={`image-view-scrollview hideScrollIndicator${ imageList.length === 1 ? " single" : "" }`}>
                   { imageList.length === 1 && <div className="imagview-single-bkg"><img loading={"lazy"} className="single-image-bkg" src={ imageList[ 0 ].url }/> </div> }
                    <div className={ `image-view-content-view${ imageList.length === 1 ? " single" : "" }` }>
                        { imageList.length === 1 ?
                            <img loading={"lazy"} className="single-image" src={ imageList[ 0 ].url }
                            onClick={() => {
                            }}
                            />
                            :
                            <>
                                {   
                                    imageList.map( (imageObj, index) => {
                                        return ( 
                                                    <img loading={"lazy"}
                                                        key={ index }
                                                        src={ imageObj.url }
                                                        onClick={() => {
                                                        }}
                                                    /> 
                                                )
                                    })
                                }
                            </>
                        }
                    </div>
                </div>
            :
            <></>
            }
        </>
    );
}