import React, { useState, useEffect, useContext } from "react";
import { db, auth } from "../../fb/fbSetup";
import './TestView.css';
import { ApplicationName, InvitationDocModel, PagePaths } from "../../Models/Models";
import logo from "../../../Resources/AppIcon.png";
import { collection, doc, getDocs, limit, query, updateDoc, where } from "firebase/firestore";
import Lottie from "lottie-react";
import loading from "../../../Resources/Lottie/loading.json";
import { Exam_Score_Coll_Doc_Path, User_Invitaion_Coll_Doc_Path } from "../../fb/dbMethods";

// ❌
export interface InvitationExamOptionViewProps {
    showInitationView : boolean;
    setStartExam: React.Dispatch<React.SetStateAction<boolean>>;
    setShowInitiaionView: React.Dispatch<React.SetStateAction<boolean>>;
}
const InvitationExamOptionView = ({ setStartExam, showInitationView, setShowInitiaionView }: InvitationExamOptionViewProps) => {
    const showFake = false;
    const [ verificationCode, setVerificationCode ] = useState<string>("");
    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView , setAnimateView ] = useState<boolean>(false);
    useEffect(() => {
        if(showInitationView) {
            setRenderView(showInitationView);
            setTimeout(() => {
                setAnimateView(showInitationView);
            }, 150);
        } else {
            setAnimateView(showInitationView);
            setTimeout(() => {
                setRenderView(showInitationView);
            }, 400);
        }
    }, [ showInitationView ]);
    //
    const [ userWasSuccessfullyInvited, setUserWasSuccessfullyInvited ] = useState<boolean>(false);
    const [ selectExam, setSelectExam ] = useState<boolean>(false);
    const [ timeElapsed, setTimeElapsed ] = useState<number>(3);

    const [ errorMessage, setErrorMessage ] = useState< string | undefined>(undefined);
    
    function createErrorMessage(message: string) {
        setErrorMessage(_=> { return message; });
    }

    async function __c() {
        const collRef = collection(db, User_Invitaion_Coll_Doc_Path);
        const q = query(collRef, limit(1), where("invitationCode", "==" , verificationCode.toLocaleLowerCase()))
        const documents = await getDocs(q);
        documents.forEach(async (document) => {
            const data = document.data() as InvitationDocModel;
            const curr = auth.currentUser;
            if (data && curr) {
                if (data.inviteAccepted === false) {
                    // If the invite was not accepted already then the user is now permited to be the person who accepts the invitation
                    await updateDoc(doc(db, `${User_Invitaion_Coll_Doc_Path}/${document.id}`), {
                        invitationTo: curr.uid,
                        inviteAccepted: true,
                        inviteAcceptedOn: new Date(),
                    })
                    .then(() => {
                        setUserWasSuccessfullyInvited(true);
                        setTimeout(() => { window.location.href = PagePaths.newUser; }, 3000);
                    })
                    .catch(()=> {
                        // error uploading you as the new user to be admited
                        createErrorMessage("There was an unexpexted error while attempting to confirm your inviatation. Please resubmit it.");
                    })
                } else if (data.inviteAccepted === true) {
                    createErrorMessage("This invitation was already accepted.");
                } else {
                    //
                    createErrorMessage("It doesn't seem that we have any invitation codes that match those values.");
                }
            }
        });
        if (documents.empty) {
            createErrorMessage("It doesn't seem that we have any invitation codes that match those values.");
        }
    }
    return (
         <>
            { renderView &&
                <div className="IntialOptionView" style={{
                    opacity: animateView ? "100%" : "0%",
                    transition: "0.2s ease-in-out",
                }}>
                    { !selectExam && !userWasSuccessfullyInvited && 
                        <div className="initial-option-container">
                            <div className="app-container">
                                <div className="logo-container">
                                    <img className="image" src={ logo }/>
                                    <div className="image-cover"/>
                                </div>
                                <h1 className="app-title">{ ApplicationName }</h1>
                            </div>
                            <div className="inition-options exam-option">
                                <h4 className="intial-option-title">{
                                showFake ?
                                "Those Items Under"
                                :
                                "Take Entrace Exam"
                                }</h4>
                                <h4 className="intial-option-description">{ 
                                showFake ?
                                "In a world ablaze with possibilities, where stars whisper secrets to the night, hope dances hand in hand with dreams, painting life's grand canvas."
                                :
                                "Entrance into this online community is done initially by an 7 minute entrance exam. It's certainly not traditional, but it's our best way to support a bright community."
                                }</h4>
                                <button className="take-exam-button" onClick={() => {
                                    setSelectExam(_ => { return true; });
                                    setTimeout( () => { setTimeElapsed(_=> { return _ - 1; }); }, 1000 );
                                    setTimeout( () => { setTimeElapsed(_=> { return _ - 1; }); }, 2000 );
                                    setTimeout( () => { setTimeElapsed(_=> { return _ - 1; }); }, 3000 );
                                    setTimeout( () => {
                                        console.log("this timer method is being called twice after button click");
                                        setShowInitiaionView(_=> { return false; });
                                    }, 3001 );
                                }}>
                                {
                                    showFake ?
                                    "Take One"
                                    :
                                    "Take Exam"
                                }</button>
                            </div>

                            <label className="or-splitter">- or -</label>

                            <div className="inition-options invite-option">
                                <h4 className="intial-option-title">{
                                    showFake ?
                                    "Enter Something Coam"
                                    :
                                    "Enter Invitation Code"
                                }</h4>
                                <h4 className="intial-option-description">
                                    { showFake ?
                                    "In the vast expanse of the cosmos, galaxies twinkle like diamonds, each telling a unique story of birth, evolution, and demise, while celestial bodies dance in an eternal cosmic ballet, painting the tapestry of the."
                                    :
                                    "'Bright people often know bright people.' Join the community through an invitation from a friend. If you have recieved an invitation, then you it should have come with a verification code. You may enter that below to join."
                                    }
                                </h4>
                                <input
                                    value={ verificationCode }
                                    className="invitation-field" 
                                    type={"text"} 
                                    placeholder={ "ABC12*-ABC" }
                                    maxLength={ 10 }
                                    onChange={e => {
                                        let transformString = "";
                                        for (let x = 0; x < e.target.value.length; x++) {
                                            if ((x === 6) && e.target.value.includes("-") === false) {
                                                transformString = `${transformString}-${e.target.value[x]}`
                                            } else {
                                                transformString = `${transformString}${e.target.value[x]}`
                                            }
                                        }
                                        setVerificationCode(_=> { return transformString; })
                                    }}
                                />
                                <button 
                                    className="invitation-submit-button"
                                    disabled={ (verificationCode.trim() !== "") ? false : true }
                                    style={{ 
                                        opacity: (verificationCode.trim() !== "") ? "100%" : "50%"
                                    }}
                                    onClick={() => { __c(); }}
                                >Okay</button>
                               { errorMessage && <label className="error-message">{ errorMessage }</label>}
                            </div>
                        </div>
                    }
                    { selectExam && 
                        <div className="get-exam-ready">
                            <h1 className="text">{ "Get Ready!"}</h1>
                            <h1 className="count-down">{ timeElapsed }</h1>
                        </div>
                    }
                    { userWasSuccessfullyInvited &&
                        <div className="get-exam-ready">
                            <h1 className="count-down" style={{ color: "black"}}>{ "🎉 Welcome," }</h1>
                            <h1 className="count-down">{ "You're all set!" }</h1>
                            <div className="lot-load-container">
                                <Lottie animationData={ loading } loop={true}/>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default InvitationExamOptionView;