
import React, { useState, useEffect  } from "react";
import { db, auth } from "../../fb/fbSetup";
import './TestView.css';
import { MultiSelect, MultpleChoice, TrueOrFalse, ApplicationName, ExamScoreDocModel } from "../../Models/Models";
import logo from "../../../Resources/AppIcon.png";
import { doc, setDoc } from "firebase/firestore";
import Lottie from "lottie-react";
import loading from "../../../Resources/Lottie/loading.json";
import { Exam_Score_Coll_Doc_Path } from "../../fb/dbMethods";

// ❌
export interface CompletedViewProps {
    // Subit Exam Results
    submitPressed: boolean;
    setSubmitPressed: React.Dispatch<React.SetStateAction<boolean>>;
    //
    assembledTestItems: {
        question: (MultpleChoice | TrueOrFalse | MultiSelect);
        answer: string;
    }[]
}
const CompletedView = ({
    submitPressed,
    setSubmitPressed,
    assembledTestItems,
}: CompletedViewProps) => {
    const [ score, setScore ] = useState<number | undefined>(undefined);
    const passingValue = 60;
    useEffect(()=>{
        if (submitPressed) {
            let correctAnswers = 0;
            assembledTestItems.forEach(item => {
                if (item.question.correctAnswer === item.answer) {
                    correctAnswers = correctAnswers + 1;
                }
            })

            const grade = (correctAnswers / assembledTestItems.length) * 100;
            const curr = auth.currentUser;
            if (curr && curr.uid) {
                const u = curr.uid;
                const docRef = doc(db, `${Exam_Score_Coll_Doc_Path}/${u}`);
                const mod: ExamScoreDocModel = { userID: u, dateOfExam: new Date(), score: grade, didPass: grade >= passingValue };
                const objectModel = { userID: mod.userID, dateOfExam: mod.dateOfExam, score: mod.score, didPass: mod.didPass };
                setDoc(docRef, objectModel)
                .then(() => {
                    setTimeout(()=> {
                        setScore(_=> { return grade; });
                    }, 3000)
                })
                .catch(() => {
                    // let user know there was an error
                })
            }

        } else {
            setScore(undefined);
        }
    }, [ submitPressed ]);

    return (
        <div className="CompletedView">
            { score ?
                <div className="completed-view-container" style={{
                    transform: score ? "translateY(-50px)" : "translateY(0px)"
                }}>
                    <div className="title-logo-container">
                        <div className="logo-container">
                            <img src={ logo }/>
                            <div className="image-cover"/>
                        </div>
                    </div>
                    <h1 className="completed-view-title">🎉 Completed! 🎉</h1>
                    <h1 className="score" style={{ color: (score >= passingValue) ? "var(--app-green)" : "var(--app-red)" }}>{ (score >= passingValue) ? 
                            "😃 You Passed!" : 
                            "😅 You scored below the mark." 
                    }</h1>
                    { 
                    <h4 className="next-step-explaination"
                        dangerouslySetInnerHTML={
                            { __html:   
                                (score < passingValue) ?
                                "You're welcome to retake the exam at anytime. You can even retake it right now right now if you'd like."
                                :
                                `You are ready to setup your profile and join the ${ ApplicationName } communty.`
                            }
                        }/>
                    }
                    { 
                        (score < passingValue) ? 
                        <button 
                            className="action-button retake-button" 
                            style={{ animation: "failAnimate 1.15s infinite"}}
                            onClick={() => {
                                console.log("button being clicked");
                                window.location.reload();
                            }}
                        >Retake</button> 
                        :
                        <button 
                            className="action-button sucess-button" 
                            style={{ animation: "nudge 0.85s infinite"}}
                            onClick={() => { window.location.href = "/new-user"; }}
                        >Jump In</button>
                    }
                </div>
                :
                <div className="lot-load-container">
                    <Lottie animationData={ loading } loop={true} />
                </div>
            }
        </div>
    );
}

export default CompletedView;