import React from 'react';
import './StartSessionPrompt.css'; // You'll need to create this CSS file

const StartSessionPrompt = ({ onConfirm, onCancel }) => {
  return (
    <div className="start-session-overlay">
      <div className="start-session-modal">
        <h2>Start Live Chat Session?</h2>
        <p>Are you ready to jump into a conversation with someone from the Big World Project community?</p>
        <div className="start-session-buttons">
          <button className="cancel-button" onClick={onCancel}>Cancel</button>
          <button className="confirm-button" onClick={onConfirm}>Jump In</button>
        </div>
      </div>
    </div>
  );
};

export default StartSessionPrompt;