import { deleteUser } from 'firebase/auth';
import React, { useState } from 'react';
import { auth } from '../../fb/fbSetup';
import { addDeleteAccountRequestt_val } from '../../Models/Models';
import './DeleteAccountRequest.css'; // Import CSS file for component-specific styles

const DeleteAccountRequest = () => {
  // State for storing form field values
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: ''
  });

  // Function to handle changes in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Reset form fields after submission (if needed)
    addDeleteAccountRequest(sucess => {
        console.log(formData);
        setErrorMessage(_=> { return null; });
        if (sucess) {
            setFormData({
                firstName: '',
                lastName: '',
                username: '',
                email: ''
              });
        } else {
            setErrorMessage("Unknown Error")
        }
    })
  };

  const [ buttonActive, setButtonActive ] = useState(false);
  let _____val = true;
  function addDeleteAccountRequest(callcback: (sucess: boolean) => void) {
    if (validateEmail(formData.email)) {
        setButtonActive(_=> { return true; });
        const url = `${addDeleteAccountRequestt_val}?first=${formData.firstName}&last=${formData.lastName}&username=${formData.username}&email=${formData.email}`;
        fetch(url,
            { method: 'GET', headers: {'Content-Type': 'application/json' },}).then(res =>res.json())
        .then(data => {
            callcback(true);
        })
        .catch(error => {
            callcback(true);
        });
    } else {
        if (navigator.onLine == false) {
            setErrorMessage("You are either not connected to internet.")
        }
        if (validateEmail(formData.email) === undefined || validateEmail(formData.email) === null) {
            setErrorMessage("Your email is invalid.")
        }
        callcback(false);
    }
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkInternetConnection = () => {
    return navigator.onLine;
  };

    // Function to show error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const showError = (message) => {
        setErrorMessage(message);
      };
    
      // Function to hide error message
      const hideError = () => {
        setErrorMessage(null);
      };


  return (
    <div className='delete-account-request-parent'>
        <div className="delete-account-request-container">
        <h2>Delete Account Request</h2>
        <form onSubmit={handleSubmit} className="delete-account-request-form">
            <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="form-control"
            />
            </div>
            <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="form-control"
            />
            </div>
            <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="form-control"
            />
            </div>
            <div className="form-group">
            <label htmlFor="email">Contact Email:</label>
            <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control"
            />
            </div>
            <button type="submit" disabled={buttonActive} className="btn btn-primary">Submit</button>
        </form>
        { errorMessage && <div className="error-message">{ errorMessage }</div> }
        <ol className="instructions">
            <li>Your Account Deletion Request will be sent to our team.</li>
            <li>We will verify with you that you intend to actually delete your account. This confirmation will happen via direct messages.</li>
            <li>Once the confirmation is done, we will proceed to delete your account and its profile data.</li>
            <li>Your Dunbar Colony messages, posts, and interactions will remain.</li>
        </ol>
        </div>
    </div>
  );
};

export default DeleteAccountRequest;

  