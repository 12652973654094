import { doc, getDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { ViewlaterModel } from "../../Controller/ViewLater_db_controller";
import { ContentModel, Posts_Coll_And_Doc_Path, PostType } from "../../fb/dbMethods";
import { db } from "../../fb/fbSetup";
import { ContentCard } from "../Home/ContentView/ContentView";

interface ViewItemModel {
    post : ViewlaterModel;
    index: number;
}
const ViewItemView = ({ post, index }: ViewItemModel) => {
    const [ contentObj, setContentObj ] = useState<ContentModel | undefined>(undefined);
    useEffect(()=>{
        GetViewLaterModel();
    }, []);

    async function GetViewLaterModel() {
        const postDocID = post.postID;
        await getDoc(doc(db, `${ Posts_Coll_And_Doc_Path }/${ postDocID }`))
        .then( document => {
            if (document.exists()) {
                const postDocumentModel = document.data() as ContentModel;
                if (postDocumentModel.postType !== PostType.longFormVideo && postDocumentModel.postType !== PostType.shortFormVideo) {
                    setContentObj(_=> { return postDocumentModel; });
                }
            }
        })
        .catch(err => {
            console.log("Error getting post : ");
        })
        
    }

    return (
        <>
            { contentObj &&
                <ContentCard item={ contentObj } showEmojiResponse={ true } index={ index }/>
            }
        </>
    )
}

export default ViewItemView;
