import { db, storage } from "./fbSetup";
import { getDoc, doc, addDoc, updateDoc, setDoc, FieldValue, serverTimestamp, arrayUnion } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { uploadBytes, ref } from "firebase/storage";
import uuidGenerator from "../../SharedItems/UUIDGenerator";
import { CurrentUserDataModel } from "../Models/Models";
import { async } from "@firebase/util";
import { Await } from "react-router-dom";
import { UserPersonalModel } from "../Recomendations/Recomendations";
import Compressor from "compressorjs";
import { useContext } from "react";
import ControllerContext from "../Contexts/ControllerContexts";
import { LowLevelPostRefferenceModel } from "../Models/Models";
import ex1 from "../../Resources/ExamplePostItems/ex1.jpg";
import ex2 from "../../Resources/ExamplePostItems/ex2.jpg";
import ex3 from "../../Resources/ExamplePostItems/ex3.jpg";
import ex4 from "../../Resources/ExamplePostItems/ex4.jpg";

export const DEFAULTCATEGORY = "Default"
export const recommendationPath = "https://us-central1-newtopia-a4327.cloudfunctions.net/getRecommendedPosts?";
export const communityPath = "https://us-central1-newtopia-a4327.cloudfunctions.net/getRecommendedForCategoryPosts?";
export const searchPath = "https://us-central1-newtopia-a4327.cloudfunctions.net/performSearch?";
export const Posts_Coll_And_Doc_Path = "_POSTS_";
export const Comments_Coll_And_Doc_Path = "_Comments_";
export const Comments_Replies_Coll_And_Doc_Path = "_Comments_Replies_";
export const UserInteractions_Coll_And_Doc_Path = "_UserInteractions_";
//
export const userprofile_storage_path = "_U_S_E_R";
export const userprofile_Landing_storage_path = "_U_S_E_R_Landing";
//
export const Exam_Score_Coll_Doc_Path = "_E_X_A_M__S_C_O_R_E";
export const User_Invitaion_Coll_Doc_Path = "_U_S_E_R__I_N_V_I_T_A_T_I_O_N";

function updateOtherUserProfileImage(otherID: string): string | undefined {
    let idExists = false;
    let urlInQuestion : string = '';
    if (idExists) {
        return urlInQuestion;
    } else {
        getDownloadURL(ref(storage, `${userprofile_storage_path}/${otherID}.jpg`))
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            return url;
        })
        .catch((error) => {
            return undefined;
        });
    }
}

async function getUserProfileImage(uid: string) {
    getDownloadURL(ref(storage, `${userprofile_storage_path}/${uid}.jpg`))
    .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();

        // Or inserted into an <img> element
        const userProfileImages = document.querySelectorAll('.this-user-profile-image');
        userProfileImages.forEach( imgElems => {
            imgElems.setAttribute('src', url);
        })
        // setProfileImageURL(url);
    })
    .catch((error) => {
        // Handle any errors
    });
}

/*
The Large Model Looks like this:

App {
    Communities: {
        *These communities should hold all of the community content. Even if we added machine learning to the mix, we could query all documents who had a value of something, which should just be a value between 0 and 1, and if it is higher then the activation threashold and the user hasn't seen it then we should just reshow it.,
    }
    Users:
    Admin:
}
*/

//

const communityCollection = "_COMMUNITY";
const chatCollection = "_CHAT";
//const chatCollection = "chat";
const ChatIndexCollection = "ChatIndex"


interface MessageRefType {
    community_collection : string;
    communityID_document: string;
    directMessage_collection: string;
    directMessageID_document: string;
}

interface ChatPathType {
    community : MessageRefType;
    index: number;
}

interface ChatModel {
    messages :  MessageModel[];
}

interface MessageModel {
    firstname?: string;
    lastname?: string;
    from?: string; //
    to?: string; // Direct Message or To course chat
    isCourse?: boolean;
    ats?: string[];
    message?: string;
    images?: MediaModel[]; // A Set Of ID's that I can use to get images from the backend: if there are images They Are Shown Before the text
    date?: string;
}

interface MediaModel {
    imageFullName: string;
    name: string;
    extension: string;
}

async function getChatDoc(chatRef: ChatPathType):  Promise<ChatModel | undefined> {
    let docPath = `${communityCollection}/${chatRef.community.communityID_document}/${chatCollection}/${chatRef.community.communityID_document}/${chatRef.index}`;
    let docObj = doc(db, docPath);
    const document = await getDoc(docObj);
    if (document.exists()) {
        const data = document.data() as ChatModel;
        return data;
    } else {
        return undefined;
    }
}

/**
    Transcription Mode Type Options:
    ---------------------------------
    *@Default: This mode is simply for text only.
    *@Math: This mode is for the text to get transcribed into the math mode.
    *@Chemistry: This mode is for the text to get transcribed into the chemistry mode.
    *@Code: This mode is for the text to get transcibed into the code mode.
*/
export enum TranscriptionModeType {
    Default = 'default',
    Math = 'Math',
    Chemistry = 'Chemistry',
    Code = 'code'
}

/**
    Post Type Options:
    -------------------
    *@shortFormVideo: This post type is for short form video.
    *@longFormVideo: This post type is for long form video.
    *@textOnly: This is for a text only post. This may include a title too.
    *@imagePosts: A single image or a series of images
*/
export enum PostType {
    shortFormVideo = "shortFormVideo",
    longFormVideo = "longFormVideo",
    textOnly = "textOnly",
    imagePosts = "imagePosts"
}

/**
    Content Model:
    -------------------
    *@postType: PostType
    *@userID: string
    *@community: string
    *@title: string
    *@textContent: string
    *@textTranscriptionType: TranscriptionModeType
    *@videoType: string
    *@videoID: string
    *@imageURLs: string[]
    *@dateOfPost: string
*/

export interface ContentModel {
    // Basics
    documentID: string;
    postType: PostType;
    userID: string;
    community?: string;
    // Title
    title?: string;
    // Text Related
    textContent?: string; // Text Content
    textTranscriptionType?: TranscriptionModeType; // Text Transciption
    // Media Related
    videoPlaybackID?: string;
    videoType?: string;
    videoID?: string; // This works for both short form and long form video 
    video_assetID?: string;
    imageURLs?: PostImageModel[];// This is for a context where the user posts a set of image... 
    // Date of post
    dateOfPost?: FieldValue;//string;
    //
    isDraft?: boolean;
    //
    userDisclosedModel?: UserPersonalModel;
    userIntersts?: string[];
    // This allows user to update the objects simply by increment or decrement... if a user likes and unlikes
    // interactions:
    like: number;
    dislike: number;
    funny: number;
    smart: number;
    sad: number;
    mindBlown: number;
    proudOfYou: number;
    yert: number;
    fingersCrossed: number;
    love: number;
    // This is for building the search engine... this will allow for complex queries
    postQueryableContent?: string[];
    // metaDescription & metaDescriptionQueryAbleContent
    metaDescription?: string;
    metaDescriptionQueryAbleContent?: string[];
}

export interface InteractionObjectModel {
    like: number;
    dislike: number;
    funny: number;
    smart: number;
    sad: number;
    mindBlown: number;
    proudOfYou: number;
    yert: number;
    fingersCrossed: number;
    love: number;
}

export interface ImageFileModel {
    file: Blob | Uint8Array | ArrayBuffer;
    fileExtension: string;
    fileName: string;
}


export const App_World_Collection = "world";
export const emojiResponsesDocTitle = "emojiResponses";
export const postsDocTitle = "posts";

export async function postContent(userID: string, community: string, content: ContentModel, user: CurrentUserDataModel, callBack?: (sucess: boolean) => void) {
    let post: ContentModel = {
        "documentID": content.documentID,
        "postType": content.postType,
        "userID": content.userID,
        "community": community,
        "title": content.title,
        "textContent": content.textContent,
        "textTranscriptionType": content.textTranscriptionType,
        // Video Stuff
        "videoType": "mux",
        "videoPlaybackID": content.videoPlaybackID,
        "videoID": content.videoID, // This works for both short form and long form video 
        "video_assetID": content.video_assetID,
        // Image Stuff
        "imageURLs": content.imageURLs,// This is for a context where the user posts a set of image... 
        // date of post
        "dateOfPost": content.dateOfPost,
        // emoji responses
        "like": content.love,
        "dislike": content.dislike,
        "funny": content.funny,
        "smart": content.smart,
        "sad": content.sad,
        "mindBlown": content.mindBlown,
        "proudOfYou": content.proudOfYou,
        "yert": content.yert,
        "fingersCrossed": content.fingersCrossed,
        "love": content.love,
        // quaryable string list for search
        "postQueryableContent": content.postQueryableContent ? content.postQueryableContent : [],
    };

    if ( user.disclosedUserPsycheType ) {
        const potentialItems_userModel = { "userDisclosedModel": user.disclosedUserPsycheType };
        post = { ...post, ...potentialItems_userModel };
    }
    if ( user.TopicsOfInterest) {
        const potentialItems_userIntersts = { "userIntersts": user.TopicsOfInterest };
        post = { ...post, ...potentialItems_userIntersts }
    }

    console.log("Attempted Post Model: ", post);
    const ref = `${ Posts_Coll_And_Doc_Path }/${ content.documentID }`
    await setDoc(doc(db, ref), post)
    .then(async (val) => {
        const commentRef = `${Posts_Coll_And_Doc_Path}/${content.documentID}/${Comments_Coll_And_Doc_Path}/${ uuidGenerator() }`;
        await setDoc(doc(db, commentRef), {
            date_doc_created: new Date(),
            comments: [],
        })
        .then(_=> { 
            UpdateUserPostList(user.userID, { community: content.community, postID: content.documentID }, callBack);
        })
        .catch(err => {
            callBack(false);
        });
    })
    .catch(err => {  callBack(false); });
}

async function UpdateUserPostList(userID: string, postModelObj: LowLevelPostRefferenceModel, callBack?: (sucess: boolean) => void) {
    const ref_str = `${ userprofile_storage_path }/${ userID }`;
    const ref_doc = doc(db, ref_str);
    const updateUserPostList = updateDoc(ref_doc, {  posts : arrayUnion(postModelObj) } )
    .then(sucess => { callBack(true); })
    .catch( err => { callBack(false); })
}


// function getTestDoc() {
//     const docRef = doc(db, "cities", "SF");
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//     console.log("Document data:", docSnap.data());
//     } else {
//     // docSnap.data() will be undefined in this case
//     console.log("No such document!");
//     }
// }


//The Pyrmids
//What an amazing structure. It's crazy to think that humans did this. If I weren't human I would think we were amazing creatures.




// OLD FB CONFIG FOR HOSTING
//___________________________
// {
//   "hosting": {
//     "public": "y",
//     "ignore": [
//       "firebase.json",
//       "**/.*",
//       "**/node_modules/**"
//     ],
//     "rewrites": [
//       {
//         "source": "**",
//         "destination": "/index.html"
//       }
//     ]
//   },
//   "functions": [
//     {
//       "source": "functions",
//       "codebase": "default",
//       "ignore": [
//         "node_modules",
//         ".git",
//         "firebase-debug.log",
//         "firebase-debug.*.log"
//       ],
//       "predeploy": [
//         "npm --prefix \"$RESOURCE_DIR\" run lint",
//         "npm --prefix \"$RESOURCE_DIR\" run build"
//       ]
//     }
//   ]
// }


/**
 * AddNewUser:
 * ______________
 * @param userID : string
 * @param firstName : string
 * @param lastName : string
 * @param customUserName : string
 * @param callBack : Optional: Call Back Function Deals with a parameter that returns the status of sucess or not
 */
export async function AddNewUser(
    userID: string,
    firstName: string,
    lastName: string,
    customUserName: string,
    userPersonalModel?: UserPersonalModel,
    topicsOfInterest?: string[],
    callBack? : (sucess: boolean, userModel?: CurrentUserDataModel) => void
    
) {
    const newUserModel : CurrentUserDataModel = {
        userID: userID,
        firstName: firstName,
        lastName: lastName,
        customUserName: customUserName,
        bio: "",
        posts: [], // Should be used to pull in all posts
        contentColonies: [],
        communities: [],
        peopleOfCare: [],
        peopleYouFollow: [],
        allowDirectMessage: true,
        followerCount: 0,
        followingCount: 0,
        // Will in the future determine if I want the profile of the user to be private
        profilePrivate: false,
        //
        prioritizeExperience: false, // This is for determinging what to show first project history or professional history
        professionalExperience: [],
        projectExpience: [],
        currentProjectItems: [],
        messagesRefferences: [],
        disclosedUserPsycheType: userPersonalModel,
        TopicsOfInterest: topicsOfInterest,
        DunbarColonyID : "",
    }

    const addUser = await setDoc(doc(db, `${userprofile_storage_path}/${userID}`), newUserModel)
    .then(status => {
        console.log("Doc Upload Sucess");
        callBack(true, newUserModel);
    })
    .catch(err => {
        console.log("Error: ", err);
        callBack(false, undefined);
    })
}


export enum UploadImageTypes {
    userProfile,
    userHeader,
    dunbarColonySubthread,
    post,
    message,
}

export interface UrlObjectModel {
    smallImageUrl: string;
    mediumImageUrl: string;
    largeImageUrl: string;
}

/*
 The Gist of this function is that we compress all of the images and label them small, medum, large,... then we upload them to the db, we can then pull the images as we need to much later
*/

/**
 * @enum uploadUserProfileImage() :
 * ------------------------
 * @param image : (File | Blob)
 * @param extension : string
 * @param userId : string
 * @callback callBack : callback to listen to the sucess of the image being uploaded and get a list of returned image urls for the different size images 
 * @enum imageUseCase : enum cases => userProfile, userHeader, dunbarColonySubthread,post, message, // abstraction will come later at the moment it is built specifically for user profile images
 */
export async function uploadUserProfileImage(
image : ( File | Blob), 
extension: string,
userId: string,
callBack: ( sucess: boolean, urlObject: UrlObjectModel ) => void,
imageUseCase : UploadImageTypes, // abstraction will come later at the moment it is built specifically for user profile images
) {
    const image_uncompressed_s = image;
    const image_uncompressed_m = image;
    const image_uncompressed_l = image;
    const ImageObject: ImageSizeOptionModel = {
        small: undefined,
        medium: undefined,
        large: undefined,
    }
    compressImage(image_uncompressed_s, 0.009, (imageFile_s) => { // less than 1 %
        // small
        ImageObject.small = imageFile_s;
        compressImage(image_uncompressed_m, 0.09, (imageFile_m) => { // 9%
            // Medium
            ImageObject.medium = imageFile_m
            compressImage(image_uncompressed_l, 1.0, (imageFile_l) => { // 20%
                // Large -> Compress final image and upload
                ImageObject.large = imageFile_l;
                uploadImages(ImageObject, userId, extension, (urls) => {
                    callBack(true, urls);
                });
            })
        })
    })
}

interface ImageSizeOptionModel { 
    small : File | Blob | undefined; 
    medium: File | Blob | undefined; 
    large: File | Blob | undefined; 
}
export const sizePostPendObj = { small: "_small", medium: "", large:"_large" };
async function uploadImages(
    imagesObj: ImageSizeOptionModel, 
    userID: string,
    extension: string,
    callBack: (imageURLs : UrlObjectModel | undefined ) => void,
) {
    const imageURLsObj: UrlObjectModel = { smallImageUrl: "", mediumImageUrl: "", largeImageUrl: "" };
    if ( imagesObj.small && imagesObj.medium && imagesObj.large )  {
        const smallImageRef = `${userprofile_storage_path}/${userID}${ sizePostPendObj.small }.${extension}`;
        const mediumImageRef = `${userprofile_storage_path}/${userID}${ sizePostPendObj.medium }.${extension}`;
        const largeImageRef = `${userprofile_storage_path}/${userID}${ sizePostPendObj.large }.${extension}`; 
        //const profileImagePathRef_small = ref(storage, smallImageRef);
        uploadImage(imagesObj.small, smallImageRef, (url_s) => {
            // small
            imageURLsObj.smallImageUrl = url_s;
            uploadImage(imagesObj.medium, mediumImageRef, (url_m) => {
                // medium
                imageURLsObj.mediumImageUrl = url_m;
                uploadImage(imagesObj.large, largeImageRef, (url_l) => {
                    // large
                    imageURLsObj.largeImageUrl = url_l;
                    console.log("imageURLsObj: ", imageURLsObj);
                    callBack(imageURLsObj);
                })
            })
        })
    }
}



async function uploadImage(imageFile:(File | Blob), stringRef: string, callBack: (url: string | undefined) => void) {
    const storageRef = ref(storage, stringRef);
    const uploadImage = await uploadBytes(storageRef, imageFile)
    .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
            callBack(url);
        })
        .catch(err => {
            console.log()
        })
    }).catch(err => {
        console.log("Error Uploading Message Image: ");
    });
}

async function compressImage( image: (File | Blob), compressionQuality : number, callBack: (returnImage : ( File | Blob | undefined )) => void ) {
    const compressImageToSmall = new Compressor(image, { quality: compressionQuality, success: async (compressedFile) => { callBack(compressedFile) } } )
}


// Uploading Post Images :
// Uploading Post Images :
// Uploading Post Images :
// Uploading Post Images :
// Uploading Post Images :
// Uploading Post Images :
export interface PostImageModel {
    imageName: string;
    url : string;
    extenstion : string;
    imageStorageRef : string;
}
/**
 * 
 * @param initialist 
 * @param counter : number -> Always pass in the starting value of zero because this is a recursive function that uses this counter to know the index position;
 * @param imaeUrlList 
 */
export async function uploadImagesForBasedPost(initialist: (File | Blob)[], counter: number, imageUrlList: PostImageModel[], callBack?: (sucess: boolean, imageModel: PostImageModel[]) => void) {
    const csListLength = initialist.length;
    if (initialist.length > 0) {
        const imageName : string = uuidGenerator();
        const extension : string = ".jpg";
        const postImageStorageRef_str = `${ Posts_Coll_And_Doc_Path }/${ imageName }.${ extension }`;
        const postImageStorageRef_ref = ref(storage, postImageStorageRef_str);
        uploadBytes(postImageStorageRef_ref, initialist[ counter ])
        .then(snapshot => {
            getDownloadURL(snapshot.ref)
            .then(url=> {
                if (csListLength === counter) {
                    // Don't recursively call method
                    const newImageObject: PostImageModel = {
                        imageName: imageName,
                        url: url,
                        extenstion: extension,
                        imageStorageRef: postImageStorageRef_str,
                    }
                    const newImageUrlList = [ ...imageUrlList, newImageObject ];
                    callBack(true, newImageUrlList);
                } else {
                    const newCounter = counter++;
                    const newImageObject: PostImageModel = {
                        imageName: imageName,
                        url: url,
                        extenstion: extension,
                        imageStorageRef: postImageStorageRef_str,
                    }
                    const newImageUrlList = [ ...imageUrlList, newImageObject ]; // adding the new image to the list
                    uploadImagesForBasedPost(initialist, newCounter, newImageUrlList, undefined);
                }
            })
            .catch(err => {
                console.log("Error: ", err);
            })
        })
    }
}














export const AuthContentExamplePosts: ContentModel[] =[ 
    {
        documentID: "",
        postType: PostType.textOnly,
        userID: "",
        community: undefined,
        title: "Euler's number",
        textContent: "<b>Euler's number</b> is an important constant that is found in many contexts and is the base for natural logarithms. An irrational number represented by the letter e, Euler's number is 2.71828..., where the digits go on forever in a series that never ends or repeats (similar to pi):This is it written in its formal mathematical expression:express[[ summ[[ 0 ,,, infint]]  fract[[  1 ,,, n!]]   ]]",
        textTranscriptionType: TranscriptionModeType.Math,
        videoPlaybackID: undefined,
        videoType: undefined,
        videoID: undefined, 
        video_assetID: undefined,
        imageURLs: undefined,
        dateOfPost: undefined,
        isDraft: false,
        userDisclosedModel: undefined,
        userIntersts: undefined,
        like: 0,
        dislike: 0,
        funny: 0,
        smart: 0,
        sad: 0,
        mindBlown: 0,
        proudOfYou: 0,
        yert: 0,
        fingersCrossed: 0,
        love: 0,
        postQueryableContent: undefined,
    },
    {
        documentID: "",
        postType: PostType.imagePosts,
        userID: "",
        community: undefined,
        title: "Check Out These Interior Designs",
        textContent: "They are so beautiful ❤️. I wish may home looked like any of these photos.",
        textTranscriptionType: TranscriptionModeType.Default,
        videoPlaybackID: undefined,
        videoType: undefined,
        videoID: undefined, 
        video_assetID: undefined,
        imageURLs: [
            { imageName: "", url : ex1, extenstion : "", imageStorageRef : "" },
            { imageName: "", url : ex2, extenstion : "", imageStorageRef : "" },
            { imageName: "", url : ex3, extenstion : "", imageStorageRef : "" },
            { imageName: "", url : ex4, extenstion : "", imageStorageRef : "" },
        ],
        dateOfPost: undefined,
        isDraft: false,
        userDisclosedModel: undefined,
        userIntersts: undefined,
        like: 0,
        dislike: 0,
        funny: 0,
        smart: 0,
        sad: 0,
        mindBlown: 0,
        proudOfYou: 0,
        yert: 0,
        fingersCrossed: 0,
        love: 0,
        postQueryableContent: undefined,
    },

    {
        documentID: "",
        postType: PostType.longFormVideo,
        userID: "",
        community: undefined,
        title: "Superman from 1940's",
        textContent: `I wasn't alive back then, but I feel so nostalgic. In this video, it seems like you can see a slightly older and dirtier definition of "hero". I have a feeling that we may see a revival of a scruffy in film in the coming future.`,
        textTranscriptionType: TranscriptionModeType.Math,
        videoPlaybackID: "cm4He02V7SyRrAeEJhCbOSMGqNzNoKN01ReW00zlPBNQsc",
        videoType: undefined,
        videoID: "wEz3WjJNa00zZSfw01LB02OoZcS8G00Lz006IvONIpDR9lL4",
        video_assetID: "FANnvlf9l00JIZth9nfCJQEIuBlxWWYzvpjbklV9LVno",
        imageURLs: undefined,
        dateOfPost: undefined,
        isDraft: false,
        userDisclosedModel: undefined,
        userIntersts: undefined,
        like: 0,
        dislike: 0,
        funny: 0,
        smart: 0,
        sad: 0,
        mindBlown: 0,
        proudOfYou: 0,
        yert: 0,
        fingersCrossed: 0,
        love: 0,
        postQueryableContent: undefined,
    },
]