import { db } from "../fb/fbSetup";
import { doc, collection, query, limit } from "firebase/firestore";
import { App_World_Collection, postsDocTitle } from "../fb/dbMethods";
import { Community } from "../Models/Models";
import ListOfAllCountries from "./CountryList";

const defaultQueryDocsToRequest: number = 3; // We will initally only look for about three documents that follows this pattern

/**
 * ContentGatherModel :
 * @usersOfInterst : Should include followers an users your frequently interact with
 * @contentInteractions : is simply the categories that the user selected in the begining and continues to modify via filer
 */
export interface ContentGatherModel {
    following? : [];
    contentInteractions : contentInteractionModel[];
    userCategoriesOfCare: string[];
    CommunitiesUserIsPartOf: Community[];
    userPersonalModel: UserPersonalModel;
    //
    userContentFilterModel: UserContentFilterModel;
}

export interface UserContentFilterModel {
    // This is last to be filled out it should associate witht the filters that will be visible on the fiter view page.
}

export interface contentInteractionModel {
    post_userID: string; // Is a User Of Interest
    tags: string[]; // To Recommend content with these tags
    dateOfInteraction: string; // To see if the user cares more about recency
    dateOfPost: string; // To see if the user cares more about recency
    interestsOfPoster: string[]; // To see if we can give you more content from a user with similar interest in the event that there is a cooralation
}

export interface UserPersonalModel {
    age: number;
    country: string;
    region: Region;
    disclosedPsychType: DisclosedPsychType;
}

export interface DisclosedPsychType {
    openess: Openess; // between 0 and 1
    conscientiousness: Conscientiousness; // between 0 and 1
    extraversion: Extraversion; // between 0 and 1
    agreeableness: Agreeableness; // between 0 and 1
    neuroticism: Neuroticism; // between 0 and 1
}

export interface Region {
    ew: EastWestRegion;
    ns: NorthSouthRegion;
}

export enum EastWestRegion {
    east = "east",
    west = "west"
}

export enum NorthSouthRegion {
    north = "north",
    south = "south"
}

function Elementary_Recommendations() {
    const collectionRef = collection(db, `/${ App_World_Collection }/${ App_World_Collection }/${ postsDocTitle }`);
    // const multiDocumentQuery = query(collectionRef, limit(10), orderBy("dateOfPost", orderDirectionDesc ? "desc" : "asc") );
}








export interface Openess {
    inventive_v_consistent: number; // between 0 and 1
    curious_v_cautious: number; // between 0 and 1
    avgValue: number; // should be the mean   => ( value_1 + value_2) / 2
    spectrum : [
        { "one": "inventive", "two": "consistent" },
        { "one": "curious", "two": "creative" },
    ]
}

export interface Conscientiousness {
    efficient_v_easyGoing: number; // between 0 and 1
    organized_v_careless: number; // between 0 and 1
    avgValue: number; // should be the mean   => ( value_1 + value_2) / 2
    spectrum : [
        { "one": "efficient", "two": "easy-going" },
        { "one": "organized", "two": "careless" },
    ]
}

export interface Extraversion {
    outgoing_v_solitary: number; // between 0 and 1
    energetic_v_reserved: number; // between 0 and 1
    avgValue: number; // should be the mean   => ( value_1 + value_2) / 2
    spectrum : [
        { "one": "outgoing", "two": "solitary" },
        { "one": "energetic", "two": "reserved" },
    ]
}


export interface Agreeableness {
    friendly_v_challenging: number; // between 0 and 1
    compassionate_v_detached: number; // between 0 and 1
    avgValue: number; // should be the mean   => ( value_1 + value_2) / 2
    spectrum : [
        { "one": "friendly", "two": "challenging" },
        { "one": "compassionate", "two": "detached" },
    ]
}


export interface Neuroticism {
    sensitive_v_secure: number; // between 0 and 1
    nervous_v_confident: number; // between 0 and 1
    avgValue: number; // should be the mean   => ( value_1 + value_2) / 2
    spectrum : [
        { "one": "sensitive", "two": "secure" },
        { "one": "nervous", "two": "confident" },
    ]
}


export const PsychTypeGradient_default : string[] = ["mostly", "more" ,"middle", "more" ,"mostly"];
export const OpenessPsychType_default : Openess = {
    curious_v_cautious : 0.5,
    inventive_v_consistent: 0.5,
    avgValue: 0.5,
    spectrum:  [{
        "one": "inventive",
        "two": "consistent",
    }, {
        "one": "curious",
        "two": "creative",
    }]
}

export const ConscientiousnessPsychType_default : Conscientiousness = {
    efficient_v_easyGoing : 0.5,
    organized_v_careless: 0.5,
    avgValue: 0.5,
    spectrum:  [{
        "one": "efficient",
        "two": "easy-going",
    }, {
        "one": "organized",
        "two": "careless",
    }]
}

export const ExtraversionPsychType_default : Extraversion = {
    energetic_v_reserved : 0.5,
    outgoing_v_solitary: 0.5,
    avgValue: 0.5,
    spectrum:  [{
        "one": "outgoing",
        "two": "solitary",
    }, {
        "one": "energetic",
        "two": "reserved",
    }]
}

export const AgreeablenessPsychType_default : Agreeableness = {
    compassionate_v_detached : 0.5,
    friendly_v_challenging: 0.5,
    avgValue: 0.5,
    spectrum:  [{
        "one": "friendly",
        "two": "challenging",
    }, {
        "one": "compassionate",
        "two": "detached",
    }]
}

export const NeuroticismPsychType_default : Neuroticism = {
    nervous_v_confident : 0.5,
    sensitive_v_secure: 0.5,
    avgValue: 0.5,
    spectrum:  [{
        "one": "sensitive",
        "two": "secure",
    }, {
        "one": "nervous",
        "two": "confident",
    }]
}