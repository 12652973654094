import React, { createContext, useState, useEffect, useContext } from "react";
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { storage, db, auth } from '../fb/fbSetup';
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { userprofile_storage_path , userprofile_Landing_storage_path, AddNewUser, InteractionObjectModel, uploadUserProfileImage, sizePostPendObj, UrlObjectModel, User_Invitaion_Coll_Doc_Path, Exam_Score_Coll_Doc_Path } from "../fb/dbMethods";
import Compressor from "compressorjs";
import { CurrentUserDataModel, ExamScoreDocModel, InvitationDocModel, PagePaths } from "../Models/Models";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { FavoritesCollectionRef, FavoritesDocModel, FavoritesModel } from "../Controller/favorites_db_controller";
import { InteractionCollectionRef, InteractionsDocModel, InteractionsModel } from "../Controller/like_db_controller";
import ControllerContext from "./ControllerContexts";
import { ViewLaterCollectionRef, ViewlaterDocModel, ViewlaterModel } from "../Controller/ViewLater_db_controller";
import { VideoHistoryCollectionRef, VideoHistoryModel, VideoHitoryDocModel } from "../Controller/VideoHistory";
import { NOT_AN_AUTHENTICATED_USER, unsignedUserDataModel } from "./unsignedUserDataModle";

interface UserContextModel {
    init: (userID: string) => void;
    tokenInit: (_userID: string) => void;
    userData: CurrentUserDataModel | undefined;
    ResetUserData: (data: CurrentUserDataModel) => void;
    userProfileImageURL: string | undefined;
    userLandingImageURL: string | undefined;
    SetUserProfileImage : (uid: string) => void;
    isUserProfileImageType: boolean;
    setIsUserProfileImageType?: React.Dispatch<React.SetStateAction<boolean>>;
    setUserData?: React.Dispatch<React.SetStateAction<CurrentUserDataModel>>;
    userFavorites: FavoritesModel[];
    userVideoHistory: VideoHistoryModel[];
    userInteractions: InteractionsModel[];
    userViewlaters: ViewlaterModel[];
}

const initObj: UserContextModel = {
    init: (userID: string) => {},
    tokenInit: (_userID: string) => {},
    userData: undefined,
    ResetUserData: (data: CurrentUserDataModel) => {},
    userProfileImageURL: undefined,
    userLandingImageURL: undefined,
    SetUserProfileImage: (uid: string) => {},
    isUserProfileImageType: true,
    setIsUserProfileImageType: undefined,
    setUserData: undefined,
    userFavorites: [],
    userVideoHistory: [],
    userInteractions: [],
    userViewlaters: [],
}

const UserContext = createContext<UserContextModel>(initObj);

interface UserContextProviderModel {
    children : any;
}

export const UserContextProvider = ({ children }: UserContextProviderModel ) => {
    const [ userID, setUserID ] = useState<string | undefined>((auth.currentUser && auth.currentUser.uid) ? auth.currentUser.uid : NOT_AN_AUTHENTICATED_USER);
    // Basic Userdata Storage And Grab
    const { renderErrorMessagePopUp, showErrorPopup } = useContext(ControllerContext);
    const [ userData, setUserData ] = useState< CurrentUserDataModel | undefined >(undefined);
    const [ userProfileImageURL, setUserProfileImageURL ] = useState< string | undefined >(undefined);
    const [ userLandingImageURL, setUserLandingImageURL ] = useState< string | undefined >(undefined);
    const [ userFavorites, setUserFavorites ] = useState<FavoritesModel[]>([]);
    const [ userVideoHistory, setUserVideoHistory ] = useState<VideoHistoryModel[]>([]);
    const [ userInteractions, setUserInteractions ] = useState<InteractionsModel[]>([]);
    const [ userViewlaters, setUserViewLaters ] = useState<ViewlaterModel[]>([]);
    //
    function init(userID? : string) {
        setUserData(_=> { return unsignedUserDataModel; });
        setUserID(_=> { return NOT_AN_AUTHENTICATED_USER; });
        //
        if (userID) {
            GetCurrentUserData(userID);
        } else {
            auth.signOut();
        }
    }

    function tokenInit(_userID? : string) {
        setUserData(_=> { return unsignedUserDataModel; });
        setUserID(_=> { return NOT_AN_AUTHENTICATED_USER; });
        //
        if (_userID && _userID.trim() !== "undefined") {
            setUserID(_userID);
            GetCurrentUserData(_userID);
        }
    }


    async function GetCurrentUserData(_userID : string) {
        const docRef = `${ userprofile_storage_path }/${_userID}`;
        const document = doc(db, docRef);
        if (_userID !== NOT_AN_AUTHENTICATED_USER) {
            await getDoc(document)
            .then( docObj => {
                if (docObj.exists()) {
                    const data = docObj.data() as CurrentUserDataModel;
                    if (data) {
                        setUserData(_=> { return data; });
                        // Grabbing User Images
                        getUserProfileImage(_userID);
                        getLandingProfileImage(_userID);
                        // Reducing costs 
                        // Creating the favorites listener... This is to listen to changes to items added to the favoites list, this allows the us to keep track of what is added to the favorites list and insure that we aren't added any duplicates
                        // And reduce reads because we never have to check if an item was added to favorites for any and all post we can just compare it to the this model, we only read on first load and when a user adds a new item to favorites...
                        listenToFavoritesDocuments(_userID);
                        listenToInteractionDocuments(_userID);
                        listenToViewLaterDocuments(_userID);
                        listenToVideoHistoryDocuments(_userID)
                    }
                } else {
                    // const curr = auth.currentUser;
                    // if (curr){
                    //     const collRef = collection(db, User_Invitaion_Coll_Doc_Path);
                    //     const q = query(collRef, limit(1), where("invitationTo", "==" , curr.uid))
                    //     getDocs(q)
                    //     .then((q) => {
                    //         if (q.size < 1) {
                    //             const docRef = doc(db, `${ Exam_Score_Coll_Doc_Path }/${ curr.uid }`);
                    //             getDoc(docRef)
                    //             .then(document => {
                    //                  const data = document.data() as ExamScoreDocModel;
                    //                  if (data && data.didPass === true) {
                    //                     window.location.href = PagePaths.newUser;
                    //                  } else {
                    //                     window.location.href = PagePaths.Test;
                    //                  }
                    //             })
                    //             .catch(() => {
                    //                 window.location.href = PagePaths.Test;
                    //             })
                    //         } else {
                    //             window.location.href = PagePaths.newUser;
                    //         }
                    //     })
                    //     .catch(() => {
                    //         window.location.href = PagePaths.Test;
                    //     })
                    // }
                    window.location.href = PagePaths.newUser;
                    // setUserData(_=> { return unsignedUserDataModel; });
                    // setUserID(_=> { return NOT_AN_AUTHENTICATED_USER; });
                }
            })
            .catch( err => {
                setUserData(_=> { return unsignedUserDataModel; });
                setUserID(_=> { return NOT_AN_AUTHENTICATED_USER; });
            })
        } else {
            setUserData(_=> { return unsignedUserDataModel; });
            setUserID(_=> { return NOT_AN_AUTHENTICATED_USER; });
        }
    }

    async function getUserProfileImage(uid: string) {
        getDownloadURL(ref(storage, `${userprofile_storage_path}/${uid}.jpg`))
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Work
            // People will always thingk you are worth nothing lean into it, what a beautiful world. Never leave it. This is what people are like in their soul.
            // Here in the united states there is no belief and love for humanity regardless of status. That will be its downfall. Not because the systems don't work. The people don't. Perhaps that will change. What a beauty.
            setUserProfileImageURL(_=> { return url; });
            // Let go.
        })
        .catch((error) => {
            // Handle any errors
        });
    }
    
    async function getLandingProfileImage(uid : string) {
        //userprofile_Landing_storage_path
        getDownloadURL(ref(storage, `${userprofile_Landing_storage_path}/${uid}.jpg`))
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Or inserted into an <img> element
            const userProfileImages = document.querySelectorAll('.user-profile-image');
            userProfileImages.forEach( imgElems => {
                if (imgElems) {
                    imgElems.setAttribute('src', url);
                }
            });
            // Work
            // People will always thingk you are worth nothing lean into it, what a beautiful world. Never leave it. This is what people are like in their soul.
            // Here in the united states there is no belief and love for humanity regardless of status. That will be its downfall. Not because the systems don't work. The people don't. Perhaps that will change. What a beauty.
            setUserLandingImageURL(_=> { return url; });
            // Let go.
        })
        .catch((error) => {
            // Handle any errors
        });
        
    }

    function ResetUserData(data: CurrentUserDataModel) {
        setUserData(data)
    }
    // File Comprression And Upload Of Images Related To User Profile
    // File Comprression And Upload Of Images Related To User Profile
    // File Comprression And Upload Of Images Related To User Profile
    // File Comprression And Upload Of Images Related To User Profile
    // File Comprression And Upload Of Images Related To User Profile
    // File Comprression And Upload Of Images Related To User Profile
    // File Comprression And Upload Of Images Related To User Profile
    const [ isUserProfileImageType, setIsUserProfileImageType ] = useState(true);
    //
    const [ file, setFile ] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    function SetUserProfileImage () {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.png, .jpg, .jpeg'; // "image/*"
        input.multiple = false;
        input.onchange = _ => {
            const file = input.files[0];
            if (!file.type.match(imageMimeType)) {
              return;
            }
            setFile(_=> { return file; });
            //new Compressor(file, { quality:  isUserProfileImageType ? 0.15 : 0.35, success: (compressedResult) => { setFile(compressedResult); }, });
        };
        input.click();
    }
    useEffect(() => {
        let fileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                // @ts-ignore
                const { result } =  e.target;
                if (result && !isCancel) {
                    setFileDataURL(result);
                    const headerProfileImage = document.querySelector('.user-profile-image');
                    if (headerProfileImage) {
                        headerProfileImage.setAttribute('src', result);
                    }
                }
            }
            fileReader.readAsDataURL(file);
            if (userID) {
                //uploadImage(file, userID);
                if (isUserProfileImageType) {
                    uploadUserProfileImage(file, "jpg", userID, (status, urlsObj) => {
                        if (status) {
                            setUserProfileImageURL(urlsObj.mediumImageUrl);
                            updateUserProfileImageObject(userID, urlsObj);
                        }
                    }, undefined)
                } else {
                    uploadLandingImageCall(file);
                }
            }
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [ file ]);
    
    async function updateUserProfileImageObject(userID: string, imageURLs : UrlObjectModel) {
        const ref_str = `${ userprofile_storage_path }/${ userID }`;
        const ref_doc = doc(db, ref_str);
        const update = await updateDoc(ref_doc, { imageProfileImageURLs: imageURLs } )
        .then(sucess => {
            showErrorPopup(true, "Your profile image photo has been uploaded", "Sucess!", "var(--app-green-85)", undefined, undefined, undefined, undefined)
        })
        .catch(err => {
        })
    }
    
    async function uploadImage(file: any, userprofileID: string ) {
        const storageRef = ref(storage, `${isUserProfileImageType ? userprofile_storage_path : userprofile_Landing_storage_path}/${ userprofileID }.jpg`);
        uploadBytes(storageRef, file).then((snapshot) => {
        });
    }

    function uploadLandingImageCall(file: any) {
        new Compressor(file, { 
        quality:  0.35, success: (compressedResult) => { 
            uploadLandingImage(compressedResult, userID)
        }});
    }

    async function uploadLandingImage(file: any, userprofileID: string ) {
        const storageRef = ref(storage, `${userprofile_Landing_storage_path}/${ userprofileID }.jpg`);
        uploadBytes(storageRef, file).then((snapshot) => {
            getLandingProfileImage(userprofileID);
        });
    }

    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    // Favorites Listener
    function listenToFavoritesDocuments(userID: string) {
        const userFavoritesRef = `${ userprofile_storage_path }/${ userID }/${ FavoritesCollectionRef }`;
        const q = query(collection(db, userFavoritesRef), orderBy("date_doc_created", "desc"));
        // Need Items for loop
        let favoriteDocCounter: number = 0;
        let favoritesList: FavoritesModel[] = [];
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((document) => {
                const data: FavoritesDocModel = document.data() as FavoritesDocModel;
                const dateToJSDateList = data.favorites.map(item => {
                    let cleanDate : any = item.dateAdded;
                    cleanDate = cleanDate.toDate();
                    const favoriteDateTime = new Date(cleanDate);
                    const newFavorite: FavoritesModel = { 
                        dateAdded: favoriteDateTime,
                        postID: item.postID,
                        favorites: item.favorites,
                        favorite_stored_document: item.favorite_stored_document,
                    }
                    return newFavorite;
                });
                //
                favoriteDocCounter = favoriteDocCounter + 1;
                if (favoriteDocCounter >= querySnapshot.size) {
                    let newList : FavoritesModel[] = [ ...favoritesList, ...dateToJSDateList ];
                    favoritesList = newList;
                    
                    // Insuring that there are no duplicates
                    const ids = favoritesList.map(({ postID }) => postID);
                    const filtered = favoritesList.filter(({ postID }, index) => !ids.includes(postID, index + 1));
                    // Making the new favorites list the list remain non duplicate post items...
                    favoritesList = filtered;
                    // Sorting the favorites list by date
                    //@ts-ignore 
                    favoritesList = favoritesList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                    setUserFavorites( _ => { return favoritesList } );
                    favoriteDocCounter = 0;
                } else {
                    let newList : FavoritesModel[] = [ ...favoritesList, ...dateToJSDateList ];
                    favoritesList = newList;
                }
            });
        });
    }

    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    // Interaction Listener
    function listenToInteractionDocuments(userID: string) {
        const userInteractionsRef = `${ userprofile_storage_path }/${ userID }/${ InteractionCollectionRef }`;
        const q = query(collection(db, userInteractionsRef), orderBy("date_doc_created", "desc"));
        // Need Items for loop
        let interactionDocCounter: number = 0;
        let interactionList: InteractionsModel[] = [];
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((document) => {
                const data: InteractionsDocModel = document.data() as InteractionsDocModel;
                const dateToJSDateList = data.interactions.map(item => {
                    let cleanDate : any = item.dateAdded;
                    cleanDate = cleanDate.toDate();
                    const interactionDateTime = new Date(cleanDate);
                    const newInteraction: InteractionsModel = { 
                        dateAdded: interactionDateTime,
                        postID: item.postID,
                        type: item.type,
                        interaction_stored_document: item.interaction_stored_document,
                    }
                    return newInteraction;
                });
                //
                interactionDocCounter = interactionDocCounter + 1;
                if (interactionDocCounter >= querySnapshot.size) {
                    let newList : InteractionsModel[] = [ ...interactionList, ...dateToJSDateList ];
                    interactionList = newList;
                    
                    // Insuring that there are no duplicates
                    const ids = interactionList.map(({ postID }) => postID);
                    const filtered = interactionList.filter(({ postID }, index) => !ids.includes(postID, index + 1));
                    // Making the new interactions list the list remain non duplicate post items...
                    interactionList = filtered;
                    // Sorting the interactions list by date
                    //@ts-ignore 
                    interactionList = interactionList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                    setUserInteractions( _ => { return interactionList } );
                } else {
                    let newList : InteractionsModel[] = [ ...interactionList, ...dateToJSDateList ];
                    interactionList = newList;
                }
            });
        });
    }


    // Video History Listener
    // Video History Listener
    // Video History Listener
    // Video History Listener
    // Video History Listener
    // Video History Listener
    // Video History Listener
    // Video History Listener
    // Video History Listener
    // Video History Listener
    function listenToVideoHistoryDocuments(userID: string) {
        const userFavoritesRef = `${ userprofile_storage_path }/${ userID }/${ VideoHistoryCollectionRef }`;
        const q = query(collection(db, userFavoritesRef), orderBy("date_doc_created", "desc"));
        // Need Items for loop
        let favoriteDocCounter: number = 0;
        let favoritesList: VideoHistoryModel[] = [];
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((document) => {
                const data: VideoHitoryDocModel = document.data() as VideoHitoryDocModel;
                const dateToJSDateList = data.videos.map(item => {
                    let cleanDate : any = item.dateAdded;
                    cleanDate = cleanDate.toDate();
                    const favoriteDateTime = new Date(cleanDate);
                    const newFavorite: VideoHistoryModel = { 
                        dateAdded: favoriteDateTime,
                        postID: item.postID,
                        _stored_document: item._stored_document,
                    }
                    return newFavorite;
                });
                //
                favoriteDocCounter = favoriteDocCounter + 1;
                if (favoriteDocCounter >= querySnapshot.size) {
                    let newList : VideoHistoryModel[] = [ ...favoritesList, ...dateToJSDateList ];
                    favoritesList = newList;
                    
                    // Insuring that there are no duplicates
                    const ids = favoritesList.map(({ postID }) => postID);
                    const filtered = favoritesList.filter(({ postID }, index) => !ids.includes(postID, index + 1));
                    // Making the new favorites list the list remain non duplicate post items...
                    favoritesList = filtered;
                    // Sorting the favorites list by date
                    //@ts-ignore 
                    favoritesList = favoritesList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                    setUserVideoHistory( _ => { return favoritesList } );
                    favoriteDocCounter = 0;
                } else {
                    let newList : VideoHistoryModel[] = [ ...favoritesList, ...dateToJSDateList ];
                    favoritesList = newList;
                }
            });
        });
    }
    
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    // View Later Listener
    function listenToViewLaterDocuments(userID: string) {
        const userViewLaterRef = `${ userprofile_storage_path }/${ userID }/${ ViewLaterCollectionRef }`;
        const q = query(collection(db, userViewLaterRef), orderBy("date_doc_created", "desc"));
        // Need Items for loop
        let favoriteDocCounter: number = 0;
        let ViewLaterList: ViewlaterModel[] = [];
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((document) => {
                const data: ViewlaterDocModel = document.data() as ViewlaterDocModel;
                const dateToJSDateList = data.posts.map(item => {
                    let cleanDate : any = item.dateAdded;
                    cleanDate = cleanDate.toDate();
                    const favoriteDateTime = new Date(cleanDate);
                    const newFavorite: ViewlaterModel = { 
                        dateAdded: favoriteDateTime,
                        postID: item.postID,
                        post: item.post,
                        stored_document: item.stored_document,
                    }
                    return newFavorite;
                });
                //
                favoriteDocCounter = favoriteDocCounter + 1;
                if (favoriteDocCounter >= querySnapshot.size) {
                    let newList : ViewlaterModel[] = [ ...ViewLaterList, ...dateToJSDateList ];
                    ViewLaterList = newList;
                    
                    // Insuring that there are no duplicates
                    const ids = ViewLaterList.map(({ postID }) => postID);
                    const filtered = ViewLaterList.filter(({ postID }, index) => !ids.includes(postID, index + 1));
                    // Making the new ViewLater list the list remain non duplicate post items...
                    ViewLaterList = filtered;
                    // Sorting the ViewLater list by date
                    //@ts-ignore 
                    ViewLaterList = ViewLaterList.sort((a,b) => Date.parse(new Date(`${ a.dateOfComment }`)) - Date.parse(new Date(`${b.dateOfComment}`)));
                    setUserViewLaters( _ => { return ViewLaterList } );
                    favoriteDocCounter = 0;
                } else {
                    let newList : ViewlaterModel[] = [ ...ViewLaterList, ...dateToJSDateList ];
                    ViewLaterList = newList;
                }
            });
        });
    }
    // Value Object
    // Value Object
    // Value Object
    // Value Object
    const valueObj = {
        init,
        tokenInit,
        userData,
        ResetUserData,
        userProfileImageURL,
        userLandingImageURL,
        SetUserProfileImage,
        isUserProfileImageType,
        setIsUserProfileImageType,
        setUserData: setUserData,
        userFavorites,
        userVideoHistory,
        userInteractions,
        userViewlaters,
    };
    // Return Of Context API
    // Return Of Context API
    // Return Of Context API
    // Return Of Context API
    return (
        <UserContext.Provider value={ valueObj }>
            { children }
        </UserContext.Provider>
    );
}

export default UserContext;











