import { db, storage } from "../fbSetup";
import { getDoc, doc, addDoc, updateDoc, setDoc, FieldValue, serverTimestamp, arrayUnion } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { uploadBytes, ref } from "firebase/storage";
import uuidGenerator from "../../../SharedItems/UUIDGenerator";
import { userprofile_storage_path } from "../dbMethods";
import { MessageRelationModel, CurrentUserDataModel } from "../../Models/Models";

export const message_storage_path = "_M_E_S_S_A_G_E_S";
export const chat_storage_path = "_C_H_A_T";

/**
 * MessageModel:
 * @firstname: string
 * @lastname: string
 * @from: string
 * @to: string
 * @ats: string[]
 * @message: message
 * @images: images
 * @date: date
 * @content: ( Doesn't Exist Yet ) will be a model of the content it relates to from the user... the link will be easy 
        {
            content: info
            contentType: post, project, etc.
            userID: string,
            // from here we can get the needed information in order to message based on this content
        }
 */
export interface MessageModel {
    firstname?: string;
    lastname?: string;
    userName?: string;
    from?: string; //
    to?: string; // Direct Message or To course chat
    ats?: string[];
    message?: string;
    images?: string[]; // A Set Of ID's that I can use to get images from the backend: if there are images They Are Shown Before the text
    date?: Date;
    triggerPostID? : string;
    /*
        content: will be a model of the content it relates to from the user... the link will be easy 
        {
            content: info
            contentType: post, project, etc.
            userID: string,
            // from here we can get the needed information in order to message based on this content
        }
    */ 
}

/**
 * _Start_A_New_Message_Chain:
 * @param senderUserData : CurrentUserDataModel
 * @param message : string
 * @param user_two_ID : string
 * @param user_two_username : string
 * @param user_two_firstName : string
 * @param user_two_lastName : string
 * @param callBack : (documentID: string, completionStatus: boolean) => void
 */

export async function _Start_A_New_Message_Chain(
    senderUserData: CurrentUserDataModel,
    // Message Starting out the conversation -> In the future it should easily relate to an object on their profile that inspired an interaction or a post, etc.
    message: string,
    //
    user_two_ID: string,
    user_two_username: string,
    user_two_firstName: string,
    user_two_lastName: string,
    //
    callBack:(documentID: string, completionStatus: boolean) => void,
){
    const documentID = uuidGenerator();
    const MessageDocumentReference = doc(db, `${message_storage_path}/${documentID}`);
    const DocumentData: MessageRelationModel = {
        //
        documentID: documentID,
        //
        user_one_ID: senderUserData.userID,
        user_one_username: senderUserData.customUserName,
        user_one_firstName: senderUserData.firstName,
        user_one_lastName: senderUserData.lastName,
        //
        user_two_ID: user_two_ID,
        user_two_username: user_two_username,
        user_two_firstName: user_two_firstName,
        user_two_lastName: user_two_lastName,
        //
        mostRecentMessage: message,
        mostRecentInteractionDate: new Date(), // Only the top level message date should be updated... it should be updated on every message...
        current_doc_index: 0,// default start at 0
    };

    const addDoc = await setDoc(MessageDocumentReference, DocumentData)
    .then(status => {
        console.log("Added Document Sucessfully")
        // Adding message chats to group
        Add_Message_Document_To_Users(senderUserData, message, DocumentData, callBack)
    })
    .catch(err => {
        console.log("Failed to add document")
        callBack(documentID, false);
    });
}

async function Add_Message_Document_To_Users(
    senderUserData: CurrentUserDataModel,
    message: string,
    messageRelation: MessageRelationModel, 
    callBack:(documentID: string, completionStatus: boolean) => void
) {
    const UserOneRef = doc(db, `${userprofile_storage_path}/${messageRelation.user_one_ID}`);
    const UserTwoRef = doc(db, `${userprofile_storage_path}/${messageRelation.user_two_ID}`);
    
    // Add User One Document Data Updated
    const userOneUpdate = await updateDoc(UserOneRef, {
        messagesRefferences: arrayUnion(messageRelation),//messageRelation
    })
    .then(status => {
        console.log("Document Uploaded Sucessfully 1");
    }).catch(err => {
        console.log("Error: ", err)
    })

    // Add User Two Document Data Updated
    const userTwoUpdate = await updateDoc(UserTwoRef, {
        messagesRefferences: arrayUnion(messageRelation),//messageRelation
    })
    .then(status => {
        console.log("Document Uploaded Sucessfully 2");
    }).catch(err => {
        console.log("Error: ", err)
    });

    Send_New_Message(senderUserData, message, messageRelation, callBack);
}

async function Send_New_Message(
    senderUserData: CurrentUserDataModel,
    message: string,
    messageRelation: MessageRelationModel, 
    callBack:(documentID: string, completionStatus: boolean) => void,
) {
    const newMessageDocument = doc(db, `${message_storage_path}/${messageRelation.documentID}/${chat_storage_path}/${messageRelation.current_doc_index}`);
    const messageObj: MessageModel = {
        firstname: senderUserData.firstName,
        lastname: senderUserData.lastName,
        userName: senderUserData.customUserName,
        from: senderUserData.userID, //
        to: messageRelation.user_one_ID === senderUserData.userID ? messageRelation.user_two_ID : messageRelation.user_one_ID, // Direct Message or To course chat
        ats:[],
        message: message,
        images:[], // A Set Of ID's that I can use to get images from the backend: if there are images They Are Shown Before the text
        date: new Date(),
    }
    const AddMessage = await setDoc(newMessageDocument, { 
        date_doc_created: new Date(),
        messages: [ messageObj ]
    })
    .then(status => {
        console.log("Messages Thread Started");
        callBack(messageRelation.documentID, true); // Calling call back from starting the chat
    })
    .catch(err => {
        console.log("Error: error starting conversation");
    })
}