import React, { useRef, useState, useEffect, useContext, createContext } from "react";
import { async } from "@firebase/util";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import { ErrorIconOption } from "../../../Components/ErrorMessage/ErrorMessage";
import ControllerContext from "../../../Contexts/ControllerContexts";
import UserContext from "../../../Contexts/UserContext";
import { db, storage } from "../../../fb/fbSetup";
import { Dunabar_CommunityStoragePath, DunabrChatDocModel, DunbarThreadItemModel, Dunbar_ChatStoragePath, Dunbar_SubthreadStoragePath, Dunbar_Subthread_Chat_StoragePath } from "../DunbarBackend/DunbarBackend";
import { DunbarColonyDocModel, DunbarSubThreadModel } from "../DunbarBackend/DunbarColonyMangament";
import console from "console";
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";

export const userProfileImagePrepend = "dun_u_prof_im";

export enum ChatThreadType {
    default = "default",
    subThread = "subThread",
}

// The Idea of being invited as a temporary dunbar colony guest: for a set duration...
interface DunbarContextModel {
    initializeView: () => void;
    dunbarColonyObj : DunbarColonyDocModel | undefined;
    dunbarThreadList: DunbarThreadItemModel[];
    threadType: ChatThreadType;
    selectedSubThreadID: string;
    checkToSeeIfSubThreadDataExist_and_grabDoc: (type: ChatThreadType, subThreadID?: string, subthread?:DunbarSubThreadModel) => void;
    profileImageURLLookUpTable: { lookUpIndex: number; userID: string; }[];
}

const init: DunbarContextModel = {
    initializeView : () => {},
    dunbarColonyObj: undefined,
    dunbarThreadList:[], 
    // Part of me thinks. That he thinks. I care. I don't I am here to work. I know how to be patient and string
    threadType: ChatThreadType.default,
    selectedSubThreadID: "",
    checkToSeeIfSubThreadDataExist_and_grabDoc: (type: ChatThreadType, subThreadID?: string, subthread?:DunbarSubThreadModel ) => {},
    profileImageURLLookUpTable: [],

}

const DunbarContext = createContext<DunbarContextModel>(init);
export default DunbarContext;

interface DunbarContextProviderModel { children: any; }
export const DunbarContextProvider = ({ children }) => {
    const { userData } = useContext( UserContext );
    const { showErrorPopup } = useContext(ControllerContext);
    const [ dunbarColonyObj , setDunbarColonyObj ] = useState<DunbarColonyDocModel | undefined>(undefined);
    const [ dunbarThreadList, setDunbarThreadList ] = useState<DunbarThreadItemModel[]>([]);
    const [ threadType, setThreadType ] = useState<ChatThreadType>(ChatThreadType.default); // It defaults to default... pun intended
    const [ selectedSubThreadID, setSelectedSubThreadID ] = useState<string>("");   
    
    // @ts-ignore
    const [ profileImageURLLookUpTable, setProfileImageURLLookUpTable ] = useState<{ lookUpIndex : number, userID: string }[]>([])

    useEffect(()=> { initializeView(); }, []);
    function initializeView() {
        getDunbarColonyDoc();
    }

    async function getDunbarColonyDoc() {
        const ref_str = `${ Dunabar_CommunityStoragePath }/${ userData.DunbarColonyID }`;
        const doc_ref = doc(db, ref_str);
        const getColonyDoc = await getDoc(doc_ref)
        .then(document => {
            if (document.exists()) {
                const data = document.data() as DunbarColonyDocModel;
                const userLookUpTable = data.colonyMembers.map((user, index) => { 
                    if (localStorage) {
                        localStorage.removeItem(`${ userProfileImagePrepend }${ index }`)
                    }
                    return { lookUpIndex: index, userID: user.userID } 
                })
                setProfileImageURLLookUpTable(_=> { return userLookUpTable; });// This will be for later such that the user can get a  list of user profile images
                setDunbarColonyObj(_=> { return data });
                ListenToMessageThread();
            } else {
                // @ts-ignore
                if (!userData.NOT_AN_AUTHENTICATED_USER) {
                    showErrorPopup(
                    true, 
                    `I don't think this dunbar colony exists... 🙁`, 
                    "Error: ",
                    "var(--app-red-90)", 
                    undefined, 
                    true, 
                    undefined, 
                    0);
                }

            }
        })
        .catch(err => {
            showErrorPopup(true, "Error: ", `${ err }`, "var(--app-red-90)", ErrorIconOption.hazard, true, undefined, 0);
        })
    }
    
    
    let unsubscribe: any;
    async function ListenToMessageThread() {
        // Calling the initialize function to start the view started off reading messages... this should read this document everytime a new message is added.
        const MessageThreadPath = `${ Dunabar_CommunityStoragePath}/${ userData.DunbarColonyID }/${ Dunbar_ChatStoragePath }`;
        const MessageThreadCollection = collection(db, MessageThreadPath );
        // This means it will always read the most recent doc..
        const MessageThreadQuery = query(MessageThreadCollection, orderBy("date_doc_created","desc") , limit(2));// Listening to two most recent documents in que
        unsubscribe = onSnapshot(MessageThreadQuery, (snapshot) => {
            const messages = [];
            snapshot.forEach((document) => {
                const ThreadDoc : DunabrChatDocModel = document.data() as DunabrChatDocModel
                ThreadDoc.colonyMessageThread.forEach(messageItem => {
                    messages.push(messageItem);
                })
            });
            setDunbarThreadList(_=> { return messages; });
        });
    }

    async function checkToSeeIfSubThreadDataExist_and_grabDoc(type: ChatThreadType,subThreadID?: string, subthread?:DunbarSubThreadModel ) {
        if (type !== ChatThreadType.default) {
            setSelectedSubThreadID(_=> { return subThreadID; });
            setThreadType(_=> { return type; });
            const SubThreadChatPath = `${ Dunabar_CommunityStoragePath}/${ userData.DunbarColonyID }/${ Dunbar_SubthreadStoragePath }/${ subThreadID }`;
            const SubThreadChatDoc = doc(db, SubThreadChatPath);
            const getSubthreadDoc = await getDoc(SubThreadChatDoc)
            .then(document => {
                if (document.exists()) {
                    // console.log(document);
                    ListenToSubthreadThread(subThreadID);
                } else {
                    setUpSubThreadDocument(userData.DunbarColonyID, subThreadID, subthread, (status) => {
                        if (status) {
                            ListenToSubthreadThread(subThreadID);
                        }
                        // We'll try agian another time or show user a prompt
                    });
                }
            })
            .catch(err => {
                // console.log("Error getting to subthread exchange: ", err);
            })
        } else {
            // listen to the main thread
            setSelectedSubThreadID(_=> { return ""; });
            setThreadType(_=> { return ChatThreadType.default; });
            ListenToMessageThread();
        }
    }

    async function ListenToSubthreadThread(subThreadID: string) {
        unsubscribe = undefined;
        const MessageThreadPath = `${ Dunabar_CommunityStoragePath}/${ userData.DunbarColonyID }/${ Dunbar_SubthreadStoragePath }/${ subThreadID }/${ Dunbar_Subthread_Chat_StoragePath }`;
        const MessageThreadCollection = collection(db, MessageThreadPath);
        const MessageThreadQuery = query(MessageThreadCollection, orderBy("date_doc_created","desc") , limit(2));// Listening to two most recent documents in que
        unsubscribe = onSnapshot(MessageThreadQuery, (snapshot) => {
            const messages = [];
            snapshot.forEach((document) => {
                const ThreadDoc : DunabrChatDocModel = document.data() as DunabrChatDocModel
                ThreadDoc.colonyMessageThread.forEach(messageItem => {
                    messages.push(messageItem);
                })
            });
            setDunbarThreadList(_=> { return messages; });
        });
    }

    const value : DunbarContextModel = {
        initializeView,
        dunbarColonyObj,
        dunbarThreadList,
        threadType,
        selectedSubThreadID,
        checkToSeeIfSubThreadDataExist_and_grabDoc,
        profileImageURLLookUpTable,
    }

    return (
        <DunbarContext.Provider value={ value }>
            { children }
        </DunbarContext.Provider>
    );
}






interface SubThreadDocModel {
    date_created: Date;
    title: string;
    purpose: string;
    subthread_founder: string;
    subscribers: string[];
}

export async function setUpSubThreadDocument(colonyID: string,  subthreadID: string, subThread: DunbarSubThreadModel, callBack: (status: boolean) => void,) {
    const ref_str = `${ Dunabar_CommunityStoragePath }/${ colonyID }/${ Dunbar_SubthreadStoragePath }/${ subthreadID }`;
    const ref_doc = doc(db, ref_str);
    const subThreadObj: SubThreadDocModel = {
        date_created: new Date(),
        title: subThread.title,
        purpose: subThread.purpose,
        subthread_founder: subThread.colonyFounder,
        subscribers: [ subThread.colonyFounder ],
    };
    const createSubThreadDoc = setDoc(ref_doc, subThreadObj)
    .then(sucess => {
        setupSubThreadChatCollection(colonyID, subthreadID, callBack);
    }) //
    .catch(err => {})
}

export async function setupSubThreadChatCollection(colonyID: string,  subthreadID: string, callBack: (status: boolean) => void, ) {
    const documentID: string =  uuidGenerator();
    const ref_str = `${ Dunabar_CommunityStoragePath}/${ colonyID }/${ Dunbar_SubthreadStoragePath }/${ subthreadID }/${ Dunbar_Subthread_Chat_StoragePath }/${ documentID }`;
    const ref_doc = doc(db, ref_str);
    const obj: DunabrChatDocModel = { date_doc_created: new Date(), colonyMessageThread : [] };
    const addDocument = await setDoc(ref_doc, obj)
    .then(sucess => {
        callBack(true);
    })
    .catch(err => {
        // console.log("error: ", err);
        callBack(false);
    })
}