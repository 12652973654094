import React, { useState, useEffect, useContext } from "react";
import ColorUI from "../ColorUI/ColorUI";

export enum BackgroundTypes {
	ColorUI,
	solidColor,
	blurImage,
}
export interface BackgroundUIView_Type {
	type : BackgroundTypes;
	color?: string;
	imageUrl?: string;
}
const BackgroundUI = ({ type, color, imageUrl}: BackgroundUIView_Type) => {
	return (
		<div className='product-background-ui'>
			{ (type === BackgroundTypes.ColorUI) && <ColorUI colonyCount={ 150 } opacity={ 0.2 }/> }
			{ (type === BackgroundTypes.solidColor) && <div style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: color }}/> }
			{ (type === BackgroundTypes.ColorUI) && <div className='product-bckg-img'><img loading={"lazy"} src={imageUrl}/></div> }
		</div>
	);
}

export default BackgroundUI;
