import React, { useState } from "react";
import CommunityItems from "./CommunityItems";
import ContentView from "./ContentView/ContentView";
import RightSideOptions from "./RightSideOptions/RightSideOptions";
import PostController from "../PostController/PostController";
import SolarSystem from "../../Components/SolarSystem/SolarSystem";
import { DEFAULTCATEGORY } from "../../fb/dbMethods";


const ProductMainConent = () => {
	const [ scrolled, setScrolled ] = useState(false);
	const [ callPostController, setCallPostController ] = useState(false);
	const [ selectedCommunityItem, setSelectedCommunityItem ] = useState<string>(DEFAULTCATEGORY);
	return (
		<>
			<div className='content'>
				<div className="" style={{
					position: "absolute",
					top: "0px",
					left: "0px",
					width: "calc(100% - var(--other-options-content-width))",
					height: "100%",
					display: "flex",
					justifyContent: "flex-start",
				}}>
					{/* <SolarSystem/> */}
				</div>
				<CommunityItems scrolled={ scrolled } withFilterButton={ true } selectedCommunityItem={ selectedCommunityItem } setSelectedCommunityItem={ setSelectedCommunityItem } />
				<ContentView setScrolled={ setScrolled } isMobile={ false } selectedCommunityItem={ selectedCommunityItem } setSelectedCommunityItem={ setSelectedCommunityItem } />
			</div>
			<RightSideOptions setCallPostController={ setCallPostController }/>
			<PostController showMe={ callPostController }  setCallPostController={ setCallPostController }/>
		</>
	);
}

export default ProductMainConent;

