import { db } from "../../../../fb/fbSetup";
import { collection, doc, getDocs, limit, query, setDoc, updateDoc, where } from "firebase/firestore";
import uuidGenerator from "../../../../../SharedItems/UUIDGenerator";
export const Live_Session_Storage_Ref = "_L_I_V_E__S_E_S_S_I_O_N_S_T_O_R_A_G_E";
export const nestedOfferCandidatePath = "offerCandidate";
export const nestedAnswerCandidatePath = "AnswerCandidate";
/**
 * LiveSessionModel:
 * @field createdBy -> used as a top level id to confirm that the the current user will not be jumping into their own session
 * @field timeOfSessionCreation -> Date: is used to excluded likely invalid sessions
 * @field SessionID -> string: A unique identifier to keep the session on track
 * @file documentID -> string: the uniquer document ID for this session
 * @field Caller -> _CallerModel: A model of the caller user
 * @field Answerer -> _AnswererModel: A model of the answering user.
 * @field isPaired -> boolean: A boolean describing rather or not a session has a paired partner
 * @field isActive -> boolean: A boolean that describes rather or not a session is active any longer
 * @note *** It's important to not that the role is generated randomly, but each participator has to assume one of the roles as to insure the ability to conversate.
 */
export interface LiveSessionModel {
    createdBy: string; // User ID 
    timeOfSessionCreation: Date;
    SessionID: string;
    documentID: string;
    Caller: _CallerModel;
    Answerer?: _AnswererModel;
    isPaired: boolean;
    isActive: boolean;
}

/**
 * _CallerModel:
 * @field userID -> string: this is used to keep track of the callers ID
 * @field sessionDescriptionProtocol -> string: this the session data needed to enable the intraction on the other users end
 */
export interface _CallerModel {
    userID: string;
    sessionDescriptionProtocol: customRTCSessionDescription;
    role: LiveSessionRole;
}

export enum LiveSessionRole {
    caller = "caller",
    answerer = "answerer"
}

/**
 * _CallerModel:
 * @field userID -> string: this is used to keep track of the callers ID
 * @field sessionDescriptionProtocol -> string: this the session data needed to enable the intraction on the other users end
 */
export interface _AnswererModel {
    userID: string;
    sessionDescriptionProtocol: customRTCSessionDescription;
    role: LiveSessionRole;
    answer?: customRTCSessionDescription; 
}

/*
Should be a one to one map to the regular RTCSessionDescriptionInit model. Only thing is this is custom types.
It should be able to be type casted back into RTCSessionDescriptionInit.

    RTCSessionDescriptionInit => {
        sdp?: string;
        type: RTCSdpType;
    }
    RTCSdpType => type RTCSdpType = "answer" | "offer" | "pranswer" | "rollback";

*/
export interface customRTCSessionDescription {
    sdp: string;
    type: customRTCSessionDescription_Type;
}

export enum customRTCSessionDescription_Type {
    answer = "answer",
    offer = "offer",
    pranswer = "pranswer",
    rollback = "rollback",
}

/*
    The setup here:
    Each time a user wants to jump into a session. They both assume the role of a caller and a calle.
    They create a document representing
*/

// 1. Check for any Open Sessions
export async function CheckForAnyOpenChatSessoions(
    userID: string, 
    sessionDescriptionProtocol: customRTCSessionDescription,
    callBack:(
        returnedErrorFree: boolean,
        wasCreatedByCurrentUser?: boolean,
        session?: LiveSessionModel
    ) => void
) {
    const strRef = Live_Session_Storage_Ref;
    const queryForStorage = collection(db, strRef);
    // Query for a session document that is not paired, is already active, and hasn't been active and unpaired for more than 5 minutes.
    const date = subtractMinutes(new Date(), 5);
    // https://stackoverflow.com/questions/52065946/firestore-compound-query-with
    /*
        For this error:
        "All where filters with an inequality (<, <=, !=, not-in, >, or >=) must be on the same field. But you have firebase...""
    */
    const _query = query(
        queryForStorage, 
        limit(2), // Only return one availble session. If no session is availble, I can try again.
        // where('createdBy', '!=', userID), // Not the same session as this user ** Can't have more than one inequality
        where("isPaired", "==", false), // Not already in a conversation
        where('isActive', '==', true), // Hasn't already eneded the interest in being in a conversation
        where('timeOfSessionCreation', '>=', date), // Isn't a forgotten / not closed out session
    );
    // const documents = 
    await getDocs(_query).then(documents => {
    console.log("CheckForAnyOpenChatSessoions -> -> -> -> documents:", documents)
    if (documents.size > 0) {
        const session =  documents.docs[0].data() as LiveSessionModel;
        if (session) {
            // PairUser(userID, sessionDescriptionProtocol, session, callBack);
        } else {
            callBack(false, undefined, undefined);
        }
    } else {
        // CreateASession(userID, sessionDescriptionProtocol, callBack);
    }
    })
    .catch(error => {
        console.log("CheckForAnyOpenChatSessoions -> -> -> -> error:", error)
        callBack(false, undefined, undefined)
    });
}


export async function ____CheckForAnyOpenChatSessoions(
    userID: string, 
    // sessionDescriptionProtocol: customRTCSessionDescription,
    callBack:(
        returnedErrorFree: boolean,
        foundASession?: boolean,
        session?: LiveSessionModel
    ) => void
) {
    const strRef = Live_Session_Storage_Ref;
    const queryForStorage = collection(db, strRef);
    // Query for a session document that is not paired, is already active, and hasn't been active and unpaired for more than 5 minutes.
    const date = subtractMinutes(new Date(), 5);
    // https://stackoverflow.com/questions/52065946/firestore-compound-query-with
    /*
        For this error:
        "All where filters with an inequality (<, <=, !=, not-in, >, or >=) must be on the same field. But you have firebase...""
    */
    const _query = query(
        queryForStorage, 
        limit(2), // Only return one availble session. If no session is availble, I can try again.
        // where('createdBy', '!=', userID), // Not the same session as this user ** Can't have more than one inequality
        where("isPaired", "==", false), // Not already in a conversation
        where('isActive', '==', true), // Hasn't already eneded the interest in being in a conversation
        where('timeOfSessionCreation', '>=', date), // Isn't a forgotten / not closed out session
    );
    // const documents = 
    await getDocs(_query).then(documents => {
    console.log("____CheckForAnyOpenChatSessoions -> -> -> -> documents:", documents)
    if (documents.size > 0) {
        const session =  documents.docs[0].data() as LiveSessionModel;
        if (session) {
            callBack(true, true, session);
        } else {
            callBack(false, undefined, undefined);
        }
    } else {
        callBack(true, false, undefined);
    }
    })
    .catch(error => {
        console.log("____CheckForAnyOpenChatSessoions -> -> -> -> error:", error)
        callBack(false, false, undefined)
    });
}



function subtractMinutes(date: Date, minutes: number): Date {
    date.setMinutes(date.getMinutes() - minutes);
    return date;
}
  
function subtractHours(date: Date, hours: number): Date {
    date.setHours(date.getHours() - hours);
    return date;
}

// 2. Join or create session
// 2A. If There Are Open Sessions 
export async function PairUser(
    userID: string,
    sessionDescriptionProtocol: customRTCSessionDescription,
    session: LiveSessionModel,
    callBack:(
        returnedErrorFree: boolean,
        // wasCreatedByCurrentUser?: boolean,
        session?: LiveSessionModel
    ) => void
) {

    // ******* Will need to be a transaction in the  coming future
    const str_ref = `${ Live_Session_Storage_Ref }/${ session.documentID }`;
    const answererObj = { Answerer: { "userID": userID, "sessionDescriptionProtocol": sessionDescriptionProtocol, "role": LiveSessionRole.answerer, }, isPaired: true, }
    await updateDoc(doc(db, str_ref), answererObj)
    .then(() => {
        const newSessionObj = { ...session, ...answererObj };
        callBack(true, newSessionObj); // The user then should be able to listen to that session info
    })
    .catch(error => {
        callBack(false, undefined)
    })
}

// 2B. If There Are Open Sessions Create One
export async function CreateASession(
    userID: string,
    sessionDescriptionProtocol: customRTCSessionDescription,
    callBack:(
        returnedErrorFree: boolean,
        // wasCreatedByCurrentUser?: boolean,
        session?: LiveSessionModel
    ) => void
) {
    const documentID = uuidGenerator();
    const sessionObj: LiveSessionModel = {
        createdBy: userID,
        timeOfSessionCreation: new Date(),
        SessionID: uuidGenerator(),
        documentID: documentID,
        Caller: {
            userID: userID,
            sessionDescriptionProtocol: sessionDescriptionProtocol,
            role: LiveSessionRole.caller,
        },
        // Answerer: undefined,
        isPaired: false,
        isActive: true,
    }

    const str_ref = `${ Live_Session_Storage_Ref }/${ documentID }`;
    await setDoc(doc(db, str_ref), sessionObj)
    .then(() => {
        callBack(true, sessionObj); // The user then should be able to listen to that session info
    })
    .catch(error => {
        callBack(false, undefined)
    })
}


export const stunServers = [
    { 'urls': "iphone-stun.strato-iphone.de:3478" },
    { 'urls': "numb.viagenie.ca:3478" },
    { 'urls': "stun.12connect.com:3478" },
    { 'urls': "stun.12voip.com:3478" },
    { 'urls': "stun.1und1.de:3478" },
    { 'urls': "stun.3cx.com:3478" },
    { 'urls': "stun.acrobits.cz:3478" },
    { 'urls': "stun.actionvoip.com:3478" },
    { 'urls': "stun.advfn.com:3478" },
    { 'urls': "stun.altar.com.pl:3478" },
    { 'urls': "stun.antisip.com:3478" },
    { 'urls': "stun.avigora.fr:3478" },
    { 'urls': "stun.bluesip.net:3478" },
    { 'urls': "stun.cablenet-as.net:3478" },
    { 'urls': "stun.callromania.ro:3478" },
    { 'urls': "stun.callwithus.com:3478" },
    { 'urls': "stun.cheapvoip.com:3478" },
    { 'urls': "stun.cloopen.com:3478" },
    { 'urls': "stun.commpeak.com:3478" },
    { 'urls': "stun.cope.es:3478" },
    { 'urls': "stun.counterpath.com:3478" },
    { 'urls': "stun.counterpath.net:3478" },
    { 'urls': "stun.dcalling.de:3478" },
    { 'urls': "stun.demos.ru:3478" },
    { 'urls': "stun.dus.net:3478" },
    { 'urls': "stun.easycall.pl:3478" },
    { 'urls': "stun.easyvoip.com:3478" },
    { 'urls': "stun.ekiga.net:3478" },
    { 'urls': "stun.epygi.com:3478" },
    { 'urls': "stun.etoilediese.fr:3478" },
    { 'urls': "stun.faktortel.com.au:3478" },
    { 'urls': "stun.freecall.com:3478" },
    { 'urls': "stun.freeswitch.org:3478" },
    { 'urls': "stun.freevoipdeal.com:3478" },
    { 'urls': "stun.gmx.de:3478" },
    { 'urls': "stun.gmx.net:3478" },
    { 'urls':  "stun.halonet.pl:3478" },
    { 'urls':  "stun.hoiio.com:3478" },
    { 'urls':  "stun.hosteurope.de:3478" },
    { 'urls':  "stun.infra.net:3478" },
    { 'urls':  "stun.internetcalls.com:3478" },
    { 'urls':  "stun.intervoip.com:3478" },
    { 'urls':  "stun.ipfire.org:3478" },
    { 'urls':  "stun.ippi.fr:3478" },
    { 'urls':  "stun.ipshka.com:3478" },
    { 'urls':  "stun.it1.hr:3478" },
    { 'urls':  "stun.ivao.aero:3478" },
    { 'urls':  "stun.jumblo.com:3478" },
    { 'urls':  "stun.justvoip.com:3478" },
    { 'urls':  "stun.l.google.com:19302" },
    { 'urls':  "stun.linphone.org:3478" },
    { 'urls':  "stun.liveo.fr:3478" },
    { 'urls':  "stun.lowratevoip.com:3478" },
    { 'urls':  "stun.lundimatin.fr:3478" },
    { 'urls':  "stun.mit.de:3478" },
    { 'urls':  "stun.miwifi.com:3478" },
    { 'urls':  "stun.modulus.gr:3478" },
    { 'urls':  "stun.myvoiptraffic.com:3478" },
    { 'urls':  "stun.netappel.com:3478" },
    { 'urls':  "stun.netgsm.com.tr:3478" },
    { 'urls':  "stun.nfon.net:3478" },
    { 'urls':  "stun.nonoh.net:3478" },
    { 'urls':  "stun.nottingham.ac.uk:3478" },
    { 'urls':  "stun.ooma.com:3478" },
    { 'urls':  "stun.ozekiphone.com:3478" },
    { 'urls':  "stun.pjsip.org:3478" },
    { 'urls':  "stun.poivy.com:3478" },
    { 'urls':  "stun.powervoip.com:3478" },
    { 'urls':  "stun.ppdi.com:3478" },
    { 'urls':  "stun.qq.com:3478" },
    { 'urls':  "stun.rackco.com:3478" },
    { 'urls':  "stun.rockenstein.de:3478" },
    { 'urls':  "stun.rolmail.net:3478" },
    { 'urls':  "stun.rynga.com:3478" },
    { 'urls':  "stun.schlund.de:3478" },
    { 'urls':  "stun.sigmavoip.com:3478" },
    { 'urls':  "stun.sip.us:3478" },
    { 'urls':  "stun.sipdiscount.com:3478" },
    { 'urls':  "stun.sipgate.net:10000" },
    { 'urls':  "stun.sipgate.net:3478" },
    { 'urls':  "stun.siplogin.de:3478" },
    { 'urls':  "stun.sipnet.net:3478" },
    { 'urls':  "stun.sipnet.ru:3478" },
    { 'urls':  "stun.sippeer.dk:3478" },
    { 'urls':  "stun.siptraffic.com:3478" },
    { 'urls':  "stun.sma.de:3478" },
    { 'urls':  "stun.smartvoip.com:3478" },
    { 'urls':  "stun.smsdiscount.com:3478" },
    { 'urls':  "stun.solcon.nl:3478" },
    { 'urls':  "stun.solnet.ch:3478" },
    { 'urls':  "stun.sonetel.com:3478" },
    { 'urls':  "stun.sonetel.net:3478" },
    { 'urls':  "stun.sovtest.ru:3478" },
    { 'urls':  "stun.srce.hr:3478" },
    { 'urls':  "stun.stunprotocol.org:3478" },
    { 'urls':  "stun.t-online.de:3478" },
    { 'urls':  "stun.tel.lu:3478" },
    { 'urls':  "stun.telbo.com:3478" },
    { 'urls':  "stun.tng.de:3478" },
    { 'urls':  "stun.twt.it:3478" },
    { 'urls':  "stun.uls.co.za:3478" },
    { 'urls':  "stun.unseen.is:3478" },
    { 'urls':  "stun.usfamily.net:3478" },
    { 'urls':  "stun.viva.gr:3478" },
    { 'urls':  "stun.vivox.com:3478" },
    { 'urls':  "stun.vo.lu:3478" },
    { 'urls':  "stun.voicetrading.com:3478" },
    { 'urls':  "stun.voip.aebc.com:3478" },
    { 'urls':  "stun.voip.blackberry.com:3478" },
    { 'urls':  "stun.voip.eutelia.it:3478" },
    { 'urls':  "stun.voipblast.com:3478" },
    { 'urls':  "stun.voipbuster.com:3478" },
    { 'urls':  "stun.voipbusterpro.com:3478" },
    { 'urls':  "stun.voipcheap.co.uk:3478" },
    { 'urls':  "stun.voipcheap.com:3478" },
    { 'urls':  "stun.voipgain.com:3478" },
    { 'urls':  "stun.voipgate.com:3478" },
    { 'urls':  "stun.voipinfocenter.com:3478" },
    { 'urls':  "stun.voipplanet.nl:3478" },
    { 'urls':  "stun.voippro.com:3478" },
    { 'urls':  "stun.voipraider.com:3478" },
    { 'urls':  "stun.voipstunt.com:3478" },
    { 'urls':  "stun.voipwise.com:3478" },
    { 'urls':  "stun.voipzoom.com:3478" },
    { 'urls':  "stun.voys.nl:3478" },
    { 'urls':  "stun.voztele.com:3478" },
    { 'urls':  "stun.webcalldirect.com:3478" },
    { 'urls':  "stun.wifirst.net:3478" },
    { 'urls':  "stun.xtratelecom.es:3478" },
    { 'urls':  "stun.zadarma.com:3478" },
    { 'urls':  "stun1.faktortel.com.au:3478" },
    { 'urls':  "stun1.l.google.com:19302" },
    { 'urls':  "stun2.l.google.com:19302" },
    { 'urls':  "stun3.l.google.com:19302" },
    { 'urls':  "stun4.l.google.com:19302" },
    { 'urls':  "stun.nextcloud.com:443" },
    { 'urls':  "relay.webwormhole.io:3478" },
];

export const freeTurnServers = [
    {
         url:"turn:turn01.hubl.in?transport=udp"
    },
    {  
        url: "turn:turn02.hubl.in?transport=tcp" 
    },
    {
        url: 'turn:numb.viagenie.ca',
        credential: 'muazkh',
        username: 'webrtc@live.com'
    },
    {
        url: 'turn:192.158.29.39:3478?transport=udp',
        credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
        username: '28224511:1379330808'
    },
    {
        url: 'turn:192.158.29.39:3478?transport=tcp',
        credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
        username: '28224511:1379330808'
    },
    {
        url: 'turn:turn.bistri.com:80',
        credential: 'homeo',
        username: 'homeo'
    },
    {
        url: 'turn:turn.anyfirewall.com:443?transport=tcp',
        credential: 'webrtc',
        username: 'webrtc'
    }
]
