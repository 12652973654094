import React, { useState, useEffect, useContext } from "react";
import { auth, db, storage } from "../../fb/fbSetup";
import { getDoc, doc, updateDoc, arrayUnion, onSnapshot } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import dayjs from "dayjs"
// Icons
import imageModeIcon from './Icons/image.png';
// Styles
import './DirectMessages.css';
import "./DirectMessagesMobile.css";
import UserContext from "../../Contexts/UserContext";
// Models
import { CurrentUserDataModel, MessageRelationModel } from "../../Models/Models";
import { message_storage_path, chat_storage_path, MessageModel } from "../../fb/MessageLogic/MessageLogic";
import { _Start_A_New_Message_Chain } from "../../fb/MessageLogic/MessageLogic";
import { userprofile_storage_path } from "../../fb/dbMethods";
import uuidGenerator from "../../../SharedItems/UUIDGenerator";
import Compressor from "compressorjs";
import ColorUI from "../../Components/ColorUI/ColorUI";
import ControllerContext from "../../Contexts/ControllerContexts";
//
import relativeTime from "dayjs/plugin/relativeTime";
import { signInWithCustomToken } from "firebase/auth";
dayjs.extend(relativeTime);

const DirectMessagesMobile = () => {
    //
    const [ userData, setUserData ] = useState<CurrentUserDataModel | undefined>(undefined);
	const [ userID, setUserID ] = useState<string | undefined>(undefined);
	const [ messageThreadID, setMessageThreadID ] = useState<string>(undefined);
	//
	//
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const tkn = urlParams.get('t');  
		const ud = urlParams.get('ud');   
		const MTID = urlParams.get('mtid'); // messageThreadID
		if (tkn) {
			signInWithCustomToken(auth, tkn)
			.then(sucess => {
				if (sucess.user.uid) { 
					setUserID(`${ud}`);
                    GetCurrentUserData(`${ud}`);
					setMessageThreadID(`${MTID}`);
				}
			})
			.catch(error => {
			});
		}
	}, []);

    async function GetCurrentUserData(_userID : string) {
        const docRef = `${ userprofile_storage_path }/${_userID}`;
        const document = doc(db, docRef);
        await getDoc(document)
        .then( docObj => {
            if (docObj.exists()) {
                const data = docObj.data() as CurrentUserDataModel;
                if (data) {
                    setUserData(_=> { return data; });
                }
            } else {}
        }).catch(err => {
            // 
        })
    }

	return (
		<> 
            { (messageThreadID && userData) &&
                <___DirectMessages userData={ userData } messageThreadID={messageThreadID}/>
            }
		</>
	);
    
}

export default DirectMessagesMobile;


interface MessageThreadProps {
    userData: CurrentUserDataModel;
	messageThreadID: string;
}
const ___DirectMessages = ({ messageThreadID, userData }: MessageThreadProps) => {
	const [ messageRelationModel, setMessageRelationModel ] = useState<MessageRelationModel | undefined>(undefined);
	//
	async function getMessageRelationModel() {
		if (messageThreadID) {
			const MessegeThreadDoc = doc(db, `${message_storage_path}/${messageThreadID}`);
			const getMessageThreadDoc = await getDoc(MessegeThreadDoc)
			.then( docRet => {
				const data: MessageRelationModel = docRet.data() as MessageRelationModel;
				console.log("docRet => ")
				if (data) { 
					setMessageRelationModel(_=> { return data; });
				}
			})
			.catch( err => { console.log("Having trouble pulling in message thread"); })
		}
	}
	
	useEffect(() => { getMessageRelationModel(); }, [])
	return (
		<div className="DirectMessage Mobile-Version">
			<ChatContainer messageRelationModel={ messageRelationModel } userData={ userData }/> 
		</div>
	);
};

// Chat Container
// Chat Container
// Chat Container
interface ChatContainerProps { messageRelationModel?: MessageRelationModel; userData: CurrentUserDataModel;}
const ChatContainer = ({ messageRelationModel, userData }: ChatContainerProps) => {
	const { toggleImageCloserLook } = useContext(ControllerContext);
	const [ chatMode, setChatMode ] = useState<ChatMode>(ChatMode.Default);
	//
	const [ imageFile , setImageFile ] = useState<File | undefined>(undefined);

	const [ message , setMessage ] = useState<string>("")
	const thisSessionTime : string = "Today 12:53pm";
	let currentMessageRef = `ref_placeholder/ref_placeholder`;
	const messageTime = new Date();
	async function sendMessage(imageURls?: string[]) {
		currentMessageRef =  messageRelationModel ? `${message_storage_path}/${messageRelationModel.documentID}/${chat_storage_path}/${ messageRelationModel.current_doc_index }` : `ref_placeholder/ref_placeholder`
		// If a thousand messages or more are coming from a document, then create a new document with an updated index to allow messages to come through and be sure to update the host document for the chat with that index
		const messageObject: MessageModel = {
			firstname: userData.firstName,
			lastname: userData.lastName,
			userName: userData.customUserName,
			from: userData.userID,
			to: "",
			ats:[],
			message: message,
			images: imageURls ? imageURls : [],
			date: messageTime,
		}
		await updateDoc(
			doc(
				
				db, 
				currentMessageRef
			),
			{
				"messages": arrayUnion(messageObject),
			}
		).then(status => {
			console.log("Document Being Added");
			UpdateChatSourceDoc(messageTime, message);
			clearAllItems();
		})
		.catch(err => {
			console.log("Error: ", err);
		})
	}

	async function sendMessage_Potentially_WithImage() {
		if (imageFile) {
			const Message_Image_Path = "MSSGIMGPTH";
			const storageRef = ref(storage, `${Message_Image_Path}/${uuidGenerator()}.jpg`);
			const compressImageFile = new Compressor(imageFile, 
				{ 
					quality: 0.15, 
					success: async (compressedFile) => {
						const uploadImage = await uploadBytes(storageRef, imageFile)
						.then((snapshot) => {
							getDownloadURL(snapshot.ref).then((url) => {
								sendMessage([ url ]);
							  });
						}).catch(err => {
							console.log("Error Uploading Message Image: ", err);
						})
					} 
				});
		} else {
			sendMessage();
		}
	}

	async function UpdateChatSourceDoc(messageTime : Date, message: string ) {
		const messageCollRef =  messageRelationModel ? `${message_storage_path}/${messageRelationModel.documentID}` : `ref_placeholder/ref_placeholder`
		const docToUpdate = await updateDoc(
			doc(db,  messageCollRef),
			{
				documentID: messageRelationModel.documentID,
				mostRecentMessage: message,
				mostRecentInteractionDate: messageTime,
				current_doc_index: Messages.length < 50000 ? messageRelationModel.current_doc_index : (messageRelationModel.current_doc_index + 1),
			}
		)
		.then(status => {
			console.log("Updated source doc");
		})
		.catch(err => {
			console.log("Error: dailed to update source doc");
		})
	}

	const [ Messages, setMessages ] = useState<MessageModel[]>([]);
	function initializeListeners() {
		currentMessageRef = messageRelationModel ? `${ message_storage_path }/${ messageRelationModel.documentID }/${ chat_storage_path }/${ messageRelationModel.current_doc_index }` : `ref_placeholder/ref_placeholder`;
		// @ts-ignore
		onSnapshot(doc(db, currentMessageRef), (doc) => {
			const data = doc.data();
			let sessionMessagList = (data && data.messages && data.messages as MessageModel[]) ? data.messages as MessageModel[] : [];//doc as QuestionModel;
			setMessages( m => {
				return sessionMessagList;
			});
			console.log("Messages data Call");
		});

		console.log("I The Initializer Keep Reloading: ");
	}

	useEffect(() => {
		//
		initializeListeners();
	}, [ messageRelationModel ])

	useEffect(() => {
		// scroll to bottom when there is a new message 
		const chatHistory = document.querySelector("#chat-scrollview");
		const chatContentView = document.querySelector("#chat-contentview");
		if (chatHistory && chatContentView) {
			chatHistory.scrollTop = chatHistory.scrollHeight; //(0, chatContentView.clientHeight); //= chatHistory.scrollHeight;
		}
	}, [ Messages ])

	function getDaysAgo(messages: MessageModel[]): string[] {
		let DaysAgoObj = {};
		messages.forEach(mess => {
			let mDate : any = mess.date;
			mDate = mDate.toDate();
			const mDateTime = new Date(mDate);
			const dateJS_messageDate_m = dayjs(mDateTime);
			//
			let currDate = new Date();
			const dateJS_messageDate_curr = dayjs(currDate);

			const library_DateDiff = dateJS_messageDate_curr.diff(dateJS_messageDate_m, 'days');
			
			if(DaysAgoObj[`${ library_DateDiff }`] === undefined) {
				//@ts-ignore
				DaysAgoObj[`${ library_DateDiff }`] = `${mess.message}${mess.date.seconds}`;
			}
			
		});
		
		let daysAgo: string[] = Object.keys(DaysAgoObj);
		return daysAgo;
	}

	function isNewSession(prevMessage: MessageModel, currentMessage: MessageModel): {hour: boolean, twentyFourHours: boolean} {
		const daysAgo: string[] = getDaysAgo(Messages);
		//
		let isNewSession : { hour: boolean, twentyFourHours: boolean } = { hour: false, twentyFourHours: false };
		let prevDate : any = prevMessage.date
		let currDate : any = currentMessage.date
		// @ts-ignore
		prevDate = prevDate.toDate();
		// @ts-ignore
		currDate = currDate.toDate();
		const prevMessageDate = new Date(prevDate);
		const currentDateTime = new Date(currDate);
		// https://day.js.org/docs/en/display/difference
		const dateJS_messageDate_prev = dayjs(prevMessageDate);
		const dateJS_messageDate_curr = dayjs(currentDateTime);
		const dateDiffernce = Math.floor(
			(
				(
					(
						currentDateTime.getTime() -  prevMessageDate.getTime()
					) / 1000
				) / 60
			) / 60
		);

		const library_DateDiff = dateJS_messageDate_curr.diff(dateJS_messageDate_prev, 'hour');
		//
		const todaysDate = new Date();
		const dateJS_todaysDate = dayjs(todaysDate);
		const library_daysAgoDiff = dateJS_messageDate_curr.diff(dateJS_todaysDate, "day");

		if ( (dateDiffernce >= 1) || (library_DateDiff >= 1) ) {
			isNewSession = { ...isNewSession, hour: true};
		}
		return isNewSession;
	}

	function getDate(message: MessageModel): string {
		let prevDate : any = message.date
		// @ts-ignore
		prevDate = prevDate.toDate();
		const prevMessageDate = new Date(prevDate);
		let dateStr = dayjs(prevMessageDate).format('ddd, MMM D, YYYY h:mm A');  //`${prevMessageDate}`;
		return dateStr;
	}

	// WILL NEED REWORK // // WILL NEED REWORK // // WILL NEED REWORK // // WILL NEED REWORK //
	// WILL NEED REWORK // // WILL NEED REWORK // // WILL NEED REWORK // // WILL NEED REWORK //
	// WILL NEED REWORK // // WILL NEED REWORK // // WILL NEED REWORK // // WILL NEED REWORK //
	function clearAllItems() {
		const mediaModeImage = document.querySelector(".media-mode-image-input");
		if (mediaModeImage) { mediaModeImage.setAttribute("src", "") }// Emptying the image src
		const textAreaElem = document.querySelector("textarea.chat-box");
		// @ts-ignore
		if (textAreaElem) { textAreaElem.value = ""; }
		const textAreaElem_MediaMode = document.querySelector("textarea.chat-box-image-mode-textarea");
		// @ts-ignore
		if (textAreaElem_MediaMode) { textAreaElem_MediaMode.value = ""; }
		const imageInputElem = document.querySelector("input.image-input");
		// @ts-ignore
		if (imageInputElem) { imageInputElem.value = ""; }
		setChatMode(_=> { return ChatMode.Default; })
		setMessage(_=> { return "" });
	}

	return (
		<div className="messages-thread-and-controls">
			{/* 
				<div className="message-controls">
					<ChatBox mode={ chatMode } setChatMode={ setChatMode } message={ message } setMessage={ setMessage } setImageFile={ setImageFile } userData={ userData }/>
					<SendAndMediaMode mode={ chatMode } setChatMode={ setChatMode } sendMessage={ sendMessage_Potentially_WithImage } message={ message } setMessage={ setMessage } userData={ userData }/>
				</div> 
			*/}

			<div className="message-thread-scrollview">
				<div className="message-thread-contentview">
					{
						Messages.map((message, index) => {
							let isNewSessionObj : { hour: boolean, twentyFourHours: boolean } = { hour: false, twentyFourHours: false }; 
							const prevMess = Messages[index - 1];
							if (prevMess) {
								isNewSessionObj = isNewSession(Messages[index - 1], message);
							}

							const chatHistory = document.querySelector("#chat-scrollview");
							const chatContentView = document.querySelector("#chat-contentview");
							if (chatHistory && chatContentView) {
								chatHistory.scrollTop = chatHistory.scrollHeight; //(0, chatContentView.clientHeight); //= chatHistory.scrollHeight;
							}

							return  (
								<>
									<div className="message-item">
										{/* @ts-ignore */}
										{ (
											(isNewSessionObj.hour === true) ||
											( index === 0)
										) && <label className="chat-session-time">{ getDate(message) }</label> }
									</div>
									<>
										<div key={ index } className={`message-item ${ userData.userID === message.from ? 'this-user' : 'other-user' }`}>
											{ (message.images.length) ? 
												<img 
													loading={"lazy"} 
													className="message-item-image" 
													src={ message.images[0] }
													onClick={() => { toggleImageCloserLook(message.images[0], true); }}
												/> : 
												<></> 
											}
											{ (message.message && message.message.trim() != "" ) &&
												<div className="message-container">
													<label className="message">{ message.message }</label>
												</div>
											}
										</div>
									</>
								</>
							);
						})
					}
				</div>
			</div>
		</div>
	);
}


interface MessageControlsProps {
    userData: CurrentUserDataModel;
	mode : ChatMode;
	setChatMode: React.Dispatch<React.SetStateAction<ChatMode>>;
	sendMessage : () => void;
	message: string;
	setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const SendAndMediaMode = ( { mode, setChatMode, sendMessage, message, userData }: MessageControlsProps ) => {
	return (
		<div className="send-and-media-mode-container">
			<div 
				className={`media-mode${ mode === ChatMode.MediaMode ? ' active' : ''}`}
				onClick={() => { setChatMode(mode => { return mode === ChatMode.MediaMode ? ChatMode.Default : ChatMode.MediaMode; })}}
			>
				<img loading={"lazy"} src={ imageModeIcon }/>
				<label>Media Mode</label>
			</div>
			<div className="send-button" onClick={() => { sendMessage(); }}>
				<label><span className="material-symbols-outlined">send</span></label>
				<label className="send-message">Send</label>
			</div>
		</div>
	);
};

enum ChatMode {
	Default,
	MediaMode
}

interface ChatProps {
	mode : ChatMode;
	setChatMode: React.Dispatch<React.SetStateAction<ChatMode>>
	message: string;
	setMessage: React.Dispatch<React.SetStateAction<string>>;
	setImageFile: React.Dispatch<React.SetStateAction<File>>;
    userData: CurrentUserDataModel;
}

const ChatBox = ({ mode, setChatMode, message, setMessage, setImageFile, }: ChatProps) => {
	const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);

	function SelectMessageImage () {
		const input = document.querySelector('.chat-box-media-mode input#image-input-id.image-input');
		if (input) {
			// @ts-ignore
			input.click();
		}
	}

	const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const files: File[] = Array.from(e.target.files)
		const file: File = files[0];
		const fileType = files[0].type;
		const src = URL.createObjectURL(files[0]);
		setImageFile(_=> { return file; });
		setImgSrc(src);
		console.log("files:", files)
	}

	return (
		<div className="chat-box-container">
			{ (mode === ChatMode.Default) && 
			<textarea className="chat-box"  placeholder="Write something..."
				value={ message }
				onChange={ e => {
					setMessage(m => {
						return e.target.value;
					})
				}}
			/>}
			{/* { (mode === ChatMode.MediaMode) && <div className="chat-box" placeholder="chat..." contentEditable="true"></div> } */}
			{ (mode === ChatMode.MediaMode) && 
				<div className="chat-box-media-mode">
					<div className="image-select" onClick={() => { SelectMessageImage(); }}>
						{ !imgSrc && <label className="image-mode-select-label"><p>select</p><p>image</p></label>}
						{ imgSrc ? <img loading={"lazy"} className="media-mode-image-input" src={ imgSrc }/> : <></> }
						<input className="image-input" id="image-input-id" type="file" onChange={ handleFileSelected } accept="image/x-png,image/jpeg"/>
					</div>
					<textarea className="chat-box-image-mode-textarea"  placeholder="Write something..."
						value={ message }
						onChange={ e => {
							setMessage(m => {
								return e.target.value;
							})
						}}
					/>
				</div> 
			}
		</div>
	);
}

